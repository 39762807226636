import { UseCase } from 'core/usecases/usecase';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import { FREQUENCY_BAND } from 'domain/types/frequency';
import { RwmDataRepository } from 'domain/types/rwm';
import { SettingType } from 'domain/types/setting';
import { TrackData } from 'domain/types/map';

export class FetchTrackData implements UseCase<Promise<TrackData[]>, FetchTrackDataParams> {
  private rwmDataRepository: RwmDataRepository;
  constructor(rwmDataRepository: RwmDataRepository) {
    this.rwmDataRepository = rwmDataRepository;
    // プラグインを使用できるように設定
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  public async call(params: FetchTrackDataParams): Promise<TrackData[]> {
    const startDate = params.date.add(-1 * Number(params.spans), 'second');
    const noiseCalcMethod: string = params.setting.noiseCalcuMethod;
    const occupancyCalcMethod: string = params.setting.occupancyCalcMethod;
    const noiseDataSource: string = params.setting.noiseDataSource;
    const trackDiv: number = params.setting.trackMapDivision;

    // 現在の日付と時刻を日本標準時 (JST) タイムゾーンで取得し、フォーマットする
    // 現在の日付と時刻を日本標準時（JST）タイムゾーンで取得し、フォーマットする
    const startTimeInJST = dayjs(startDate).tz('Asia/Tokyo').format();
    const endTimeInJST = dayjs(params.date).tz('Asia/Tokyo').format();
    // console.log('Start Time:', startTimeInJST); //例2024-02-28T10：20：15+09：00
    // console.log('End Time:', endTimeInJST); //例2024-02-28T10：20：15+09：00
    const dataType = params.dataType;

    return await this.rwmDataRepository.fetchTrackData(
      startTimeInJST,
      endTimeInJST,
      params.deviceId,
      dataType,
      params.heightMin,
      params.heightMax,
      params.hAcc,
      params.vAcc,
      trackDiv,
      noiseCalcMethod,
      occupancyCalcMethod,
      noiseDataSource
    );
  }
}

export class FetchTrackDataParams {
  constructor(
    public date: Dayjs,
    public spans: string,
    public deviceId: string,
    public dataType: string,
    public heightMin: number,
    public heightMax: number,
    public vAcc: number,
    public hAcc: number,
    public setting: SettingType
  ) {}
}

import { getToken, stage, appApiEndPoint } from '../base';
import { wrapForObjects } from '../../../core/commons/dataConverter';
import { ChannelRowType } from '../../../domain/types/channel';

export const getChannnel = async (
  startTime: string,
  endTime: string,
  deviceIds: string[],
  dataType: string,
  heightMin: number,
  heightMax: number,
  timeDiv: number,
  frequencyband: string,
  noiseCalcMethod: string,
  occupancyCalcMethod: string,
  noiseDataSource: string,
  topNoiseLevel: string,
  topOccupancyLevel: string,
  bottomNoiseLevel: string,
  bottomOccupancyLevel: string,
  isChannel: boolean
): Promise<ChannelRowType[]> => {
  const token = await getToken();
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    data_type: dataType,
    height_min: heightMin.toString(),
    height_max: heightMax.toString(),
    time_div: timeDiv.toString(),
    frequency_band: frequencyband,
    noise_calc_method: noiseCalcMethod,
    occupancy_calc_method: occupancyCalcMethod,
    noise_data_source: noiseDataSource,
    top_noise_level: topNoiseLevel.toString(),
    top_occupancy_level: topOccupancyLevel.toString(),
    bottom_noise_level: bottomNoiseLevel.toString(),
    bottom_occupancy_level: bottomOccupancyLevel.toString(),
    is_channel: isChannel ? 'true' : 'false',
  });

  deviceIds.forEach((deviceId) => {
    params.append('device_ids[]', deviceId);
  });
  // console.log('ログ');
  // console.log(token);
  const res = await fetch(`${appApiEndPoint}/${stage}/channel?${params.toString()}`, {
    headers: { Authorization: token },
  });
  const json = await res.json();
  const items = wrapForObjects<ChannelRowType>(json);
  return items;
};

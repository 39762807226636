/// <reference types="google.maps" />

// ------------------------------------------------------
// NOTE: インターフェースの定義
/**
 * @description ピン共通のインターフェース
 * @property {string} pinId マップピンの ID (文字数制限あり : 8 文字)  \
 * @property {string} label ラベル (文字数制限あり : 10 文字) \
 *  未入力なら対象のコンポーネントの id を格納 \
 * @property {(typeof PIN_TYPE)[keyof typeof PIN_TYPE]} pinType ピンの種別
 * @property {boolean} isRssiFix RSSI 固定 (現在の仕様は RSSI 固定＝シミュレーションモード)
 * @property {boolean} hasGpsInfo  GPS情報あり \
 *  デバイスから取得するデータで、GPS 情報を持っているかのフラグ \
 *  GPS 位置有効無効設定を開発する際は、isAutoPos のプロパティを使い、それを画面上で ON / OFF 切り替えできるようにする必要あり (機能開発時に改修の必要あり、BE の修正も必要)
 * @property {number} lat 緯度
 * @property {number} lng 経度
 * @interface
 */
export interface BasePinType {
  // TODO: 他に必要なプロパティは随時追加
  pinId: string;
  label: string;
  pinType: (typeof PIN_TYPE)[keyof typeof PIN_TYPE];
  isRssiFix: boolean;
  hasGpsInfo: boolean;
  lat: number;
  lng: number;
}

/**
 * @description マップピンのインターフェース
 * @extends BasePinType
 * @property {string} mapId 関連するマップの ID (文字数制限あり : 8 文字)  \
 * @property {number} ratio 占有率
 *  設定値により値が変化する \
 *    - MIN / AVE / MAX
 * @property {number} peakRatioFreq 占有率のピーク周波数 \
 *  ratio の値に該当する周波数の値
 * @property {number} noise ノイズ \
 *  設定値により値が変化する \
 *    - MIN / AVE / MAX
 *    - RSSIMAX / RSSIMIN
 * @property {number} peakNoiseFreq ノイズのピーク周波数
 *  noise の値に該当する周波数の値
 * @property {PointType} point ピンの表示領域上でのピクセル位置 (表示領域の左上を原点とする)
 * @property {CandidatePinStatusType} candidateStatus ピン候補のステータス (DB に登録済みかどうか)、ピン候補のみに設定するプロパティ \
 *  DB 登録済み : candidateStatus === undefined || candidateStatus === "registered"
 *  DB 未登録   : candidateStatus === "notRegistered"
 * @interface
 */
export interface MapPinType extends BasePinType {
  mapId: string;
  ratio: number | undefined;
  peakRatioFreq: number | undefined;
  noise: number | undefined;
  peakNoiseFreq: number | undefined;
  point?: PointType;
  candidateStatus?: CandidatePinStatusType;
  channel: number | undefined;
}

/**
 * @description マップオブジェクトのインターフェース
 * @property {string} mapId マップの ID (文字数制限あり : 8 文字)
 * @property {MapType} mapType マップの種別
 * @property {number} lat 緯度
 * @property {number} lng 経度
 * @property {number} elevation 標高
 * @property {number} zoom ズーム倍率
 * @property {MapTypeIdType} mapTypeId Google マップの種別 \
 *
 * minHeight, minHeight, vertical, horizontal のプロパティは DB に持たせない方がいい? \
 *  理由 : スライダーなどで変化が大きい値の為、都度 DB 書き込みが発生するとパフォーマンス + コスト面で難あり、書き込む場合は保存ボタンを押したら DB 保存などの何かしらのロジックは必要
 * @property {number} maxHeight 最大 [m] 値 (高さ範囲指定の設定項目) \
 * @property {number} minHeight 最小 [m] 値 (〃)
 * @property {number} vertical 垂直 [m] 値 (位置精度指定の設定項目)
 * @property {number} horizontal 水平 [m] 値 (〃)
 * @property {number} imgUrl フロア画像の URL
 * @property {number} imgWidth 画像の幅 (マップ位置・サイズ編集画面上の項目)
 * @property {number} imgHeight 画像の高さ (〃)
 * @property {number} imgRotate 画像の回転 (〃)
 * @property {number} imgLat 画像の緯度 (〃)
 * @property {number} imgLng 画像の経度 (〃)
 * @interface
 */
export interface MapObjType {
  mapId: string;
  mapType: MapType;
  lat: number;
  lng: number;
  elevation: number;
  zoom: number;
  mapTypeId: MapTypeIdType;
  maxHeight: number;
  minHeight: number;
  vertical: number;
  horizontal: number;
  pins: BasePinType[];
  imgUrl: string;
  imgWidth: number;
  imgHeight: number;
  imgRotate: number;
  imgLat: number;
  imgLng: number;
}

/**
 * @description ピン位置のインターフェース
 * @property {number} x 左上を原点とした時の画面上での x 座標 (ピクセル)  \
 * @property {number} y 左上を原点とした時の画面上での y 座標 (ピクセル)  \
 * @interface
 */
export interface PointType {
  x: number;
  y: number;
}

/**
 * @interface
 */
export interface HeatPointType {
  id: string;
  x: number;
  y: number;
  value: number;
}

/**
 * @description サイズのインターフェース
 */
export interface SizeType {
  width: number;
  height: number;
}

/**
 * @description クリップボード（ピン）
 */
export interface clipboardPinType {
  rightClickAction: RightClickActionType;
  mapPin: MapPinType;
}

/**
 * @description ピン右クリックメニューの操作の種別
 */
export type RightClickActionType = 'copy' | 'cut';

/**
 * @description ピン / グループ追加時の操作の種別
 */
export type PinActionType = 'create' | 'update';

/**
 * @description マップの種別 \
 *  null : 初期値の白い画面を表示
 *  googleMap : Google Map を表示
 *
 */
export type MapType = 'imageMap' | 'googleMap' | 'none';

/**
 * @description マップの種類 \
 *  roadmap : 通常のストリートマップ
 *  satellite: 衛星画像
 *  hybrid : 衛星画像の上に主要なストリートマップの情報を重ねて表示
 */
export type MapTypeIdType = 'roadmap' | 'satellite' | 'hybrid';

/**
 * @description マップの操作タイプ \
 *  read : 読み込み
 *  write : 編集
 */
export type MapModeType = 'read' | 'write';

/**
 * @description 方向の種別
 */
export type DirectionType = 'top' | 'bottom' | 'right' | 'left';

/**
 * @description ピン候補のステータス
 */
export type CandidatePinStatusType = 'registered' | 'notRegistered';

/**
 * @description マップに紐づくピンID配列 key:mapId value:pinIdの配列
 */
export type MapPinIdsType = {
  [key: string]: string[];
};

/**
 * @description iot-dataから取得するピンデータのインターフェース
 * @property {number} unitId ユニットID（PinIdとの突合せに利用する  \
 * @property {boolean} hasGpsInfo GPS情報を持つか（lat・lngが有効な値か）  \
 * @property {number} lat 緯度情報  \
 * @property {number} lng 経度情報  \
 * @property {FreqData[]} freqDataList 周波数データリスト  \
 * @interface
 */
export interface PinData {
  unitId: string;
  hasGpsInfo: boolean;
  lat: number;
  lng: number;
  freqDataList: FreqData[];
}

/**
 * @description iot-dataから取得する移動モードデータのインターフェース
 * @property {number} date タイムスタンプ  \
 * @interface
 * @extends PinData
 */
export interface TrackData extends PinData {
  date: number;
}

/**
 * @description 周波数データのインターフェース
 * @property {number} freq 周波数  \
 * @property {number} noise ノイズ値  \
 * @property {number} occupancy 占有率  \
 */
export interface FreqData {
  freq: number;
  noise: number;
  occupancy: number;
}

export interface HeatMapDataType {
  x: {
    max: number;
    min: number;
  };
  y: {
    max: number;
    min: number;
  };
  points: HeatPointType[];
}
// ------------------------------------------------------
// NOTE: 定数の定義
/**
 * @description マップピンの種別
 * @constant
 */
export const PIN_TYPE: { [key: string]: number } = {
  UnitPin: 0,
  GroupPin: 1,
  NoisePin: 2,
} as const;

/**
 * @description マップ設定の初期値
 * @constant
 */
export const DEFAULT_MAP_CONF = {
  center: {
    lat: 36.2048,
    lng: 138.2529,
  },
  zoom: 5,
  mapTypeId: 'satellite' as MapTypeIdType,
  elevation: 5,
  ne: { lat: 48.2213508622798, lng: 159.98385703125 },
  sw: { lat: 22.01992345798802, lng: 116.52194296875 },
  latToPixel: 0.054586307092274536,
  lngToPixel: 0.06790924072265628,
};

/**
 * @description マップのサイズ
 * @constant
 */
export const MAP_SIZE = {
  width: 640,
  height: 480,
};

/**
 * @description 表示領域の端からの距離 (移動範囲を制限する為の定数)
 * @constant
 */
export const EDGE_OFFSET = 40;

/**
 * @description アスペクト比 (4:3)
 * @constant
 */
export const ASPECT_RATIO = 4 / 3;

/**
 * @description マップのズーム倍率範囲
 * @constant
 */
export const ZOOM_OPTIONS = {
  max: 21,
  min: 5,
};

/**
 * @description ピンの初期値
 * @constant
 */
export const INITIAL_PIN: MapPinType = {
  pinId: '1111',
  label: '',
  pinType: PIN_TYPE.UnitPin,
  isRssiFix: false,
  hasGpsInfo: false,
  mapId: '',
  lat: DEFAULT_MAP_CONF.center.lat,
  lng: DEFAULT_MAP_CONF.center.lng,
  ratio: undefined,
  peakRatioFreq: undefined,
  noise: undefined,
  peakNoiseFreq: undefined,
  channel: undefined,
  point: undefined,
};

/**
 * @description メインマップの ID
 * @constant
 */
export const MAIN_MAP_ID: string = 'メインマップ';

/**
 * @description マップの初期値
 * @constant
 */
export const INITIAL_MAP: MapObjType = {
  mapId: '',
  lat: DEFAULT_MAP_CONF.center.lat,
  lng: DEFAULT_MAP_CONF.center.lng,
  elevation: DEFAULT_MAP_CONF.elevation,
  zoom: DEFAULT_MAP_CONF.zoom,
  mapType: 'none',
  mapTypeId: DEFAULT_MAP_CONF.mapTypeId,
  maxHeight: 2000,
  minHeight: 0,
  vertical: 20,
  horizontal: 20,
  pins: [] as BasePinType[],
  imgUrl: '',
  imgWidth: 640,
  imgHeight: 480,
  imgRotate: 0,
  imgLat: DEFAULT_MAP_CONF.center.lat,
  imgLng: DEFAULT_MAP_CONF.center.lng,
} as const;

/**
 * @description メインマップの初期値
 * @constant
 */
export const MAIN_MAP: MapObjType = {
  ...INITIAL_MAP,
  mapId: MAIN_MAP_ID,
  mapType: 'googleMap',
} as const;

export const INITIAL_MAP_INFO: { [key: number]: MapPinType } = {
  [PIN_TYPE.UnitPin]: {
    ...INITIAL_PIN,
    pinType: PIN_TYPE.UnitPin,
  },
  [PIN_TYPE.GroupPin]: {
    ...INITIAL_PIN,
    pinType: PIN_TYPE.GroupPin,
  },
  [PIN_TYPE.NoisePin]: {
    ...INITIAL_PIN,
    pinType: PIN_TYPE.NoisePin,
  },
};

/**
 * @constant
 * @param fullscreenControl 全画面表示ボタンを非表示
 * @param rotateControl 回転ボタンを非表示
 * @param streetViewControl ストリートビューのボタンを非表示
 * @param gestureHandling アイコンの設定
 * @param mapTypeControl 地図タイプ切り替えボタンを非表示
 * @param scrollwheel ドラッグによる地図移動を無効
 * @param zoomControl ズームコントロールを非表示
 * @param keyboardShortcuts キーボードショートカットメニューの非表示
 * @param clickableIcons アイコンの選択可否
 */
export const DEFAULT_MAP_OPTIONS = {
  clickableIcons: false,
  fullscreenControl: false,
  gestureHandling: 'none',
  keyboardShortcuts: false,
  mapTypeControl: false,
  maxZoom: ZOOM_OPTIONS.max,
  minZoom: ZOOM_OPTIONS.min,
  rotateControl: false,
  scrollwheel: false,
  streetViewControl: false,
  tilt: 0,
  zoomControl: false,
};

export const DEFAULT_HEIGHT_CONF = {
  max: 999999999999,
  min: -999999999999,
};

export const DEFAULT_POSITION_CONF = {
  vertical: 999999999999,
  horizontal: 999999999999,
};

// ------------------------------------------------------
// NOTE: インターフェースの定義

/**
 * @description 推奨チャンネルデータのインターフェス
 * @property {string} recommend 推奨度
 * @property {number} freq 周波数
 * @property {number} ch 周波数チャンネル
 * @property {number} occupancyLevel 占有率レベル
 * @property {number} noiseLevel ノイズレベル
 * @property {string} occupancy 占有率
 * @property {string} noise ノイズ値
 * @property {string} details 時間帯別詳細情報
 * @interface
 */
export interface ChannelRowType {
  recommend: string;
  freq: number;
  ch: number;
  occupancyLevel: number;
  noiseLevel: number;
  occupancy: number;
  noise: number;
  details: ChannelDetailRowType[];
}

/**
 * @description 推奨チャンネル詳細データのインターフェス
 * @property {string} occupancy 占有率
 * @property {string} noise ノイズ値
 * @property {number} level 推奨レベル
 * @property {boolean} isInvalid 無効なデータフラグ
 * @interface ChannelDetailRowType
 */
export interface ChannelDetailRowType {
  occupancy: number;
  noise: number;
  level: RecommendLevel;
  isInvalid: boolean;
}

/**
 * @description 推奨度の種別
 * @type
 */
export type RecommendLevel = 1 | 2 | 3;

// ------------------------------------------------------
// NOTE: 定数の定義
/**
 * @description 推奨度の定数
 * @constant
 */
export const RECOMMEND_LEVEL = {
  LOW: 1,
  MID: 2,
  HIGH: 3,
} as const;

/**
 * @description 推奨チャンネル詳細表示行数
 * @constant
 */
export const CHANNEL_DETAIL_ROW_COUNT = 12;

import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useContext, useState } from 'react';
import { FormControlLabel, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomCheckBox from 'components/Common/CustomCheckBox';
import { BasePinType, MapObjType, MapPinType, PIN_TYPE } from 'domain/types/map';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { getTargetPinData } from 'utils/extract';
import { convertInitialBasePinToMapPin, removeDuplicatedElements } from 'utils/transform';
import { RwmContext, useRwmCache } from 'RwmContext';
import { useErrorBoundary } from 'react-error-boundary';
import { useMaps } from 'MapsContext';

interface Props {
  checkBoxStates: boolean[];
  setCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;
  selectedPinIds: string[];
  setSelectedPinIds: Dispatch<SetStateAction<string[]>>;
  allPinIds: string[];
  setAllPinIds: Dispatch<SetStateAction<string[]>>;
  id: string;
  index: number;
  isChecked: boolean;
  mapPin: BasePinType;
  selectedMap: MapObjType;
  label: string;
  handleClickOpenedDialog: () => void;
  handleSetSelectedPinType: (pinType: number) => void;
  handleSetRightClickPin: (id: string) => void;
}

const PinSelectItem = ({
  checkBoxStates,
  setCheckBoxStates,
  selectedPinIds,
  setSelectedPinIds,
  allPinIds,
  setAllPinIds,
  id,
  index,
  isChecked,
  mapPin,
  selectedMap,
  label,
  handleClickOpenedDialog,
  handleSetSelectedPinType,
  handleSetRightClickPin,
}: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { addPinToMap, deletePinFromMap, deletePinFromMainMap } = useMaps();
  const { setCaSelectedPinIds } = useRwmCache();
  const { allMapIds, setAllMapIds, selectedMapId, registeredPinIds, setRegisteredPinIds, workMode, setHideMapPinIds } =
    useContext(LayoutContext);
  const { candidatePinIds } = useContext(RwmContext);

  const [isOpenedContextMenu, setIsOpenedContextMenu] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  /**
   * @description マップピンの各チェックボックス選択時の処理
   */
  const handleClickMapPinItem = async (e: ChangeEvent<HTMLInputElement>, targetPinId: string, index: number) => {
    const _states = [...checkBoxStates];

    if (workMode == 'Map') {
      // チェック時
      if (e.target.checked) {
        // NOTE: 対象のピンが登録済みか判定する
        if (registeredPinIds.includes(targetPinId)) {
          _states[index] = true;
        } else {
          const addPin: MapPinType = convertInitialBasePinToMapPin(
            {
              pinId: targetPinId,
              label: targetPinId,
              pinType: PIN_TYPE.UnitPin,
              isRssiFix: false,
              hasGpsInfo: false,
              lat: selectedMap.lat,
              lng: selectedMap.lng,
            },
            selectedMap.mapId
          );

          // NOTE: デバイスピンの追加
          try {
            await addPinToMap(selectedMapId, addPin, true);
          } catch (error) {
            showBoundary(error);
            return;
          }

          setAllPinIds([...allPinIds]);
          setRegisteredPinIds([...registeredPinIds, targetPinId]);
          _states[index] = true;
        }
        setSelectedPinIds([...selectedPinIds, targetPinId]);
        setCaSelectedPinIds([...selectedPinIds, targetPinId]);

        // NOTE: ピンの非表示リストから削除する
        setHideMapPinIds((prevState) => {
          const currentArray = prevState[selectedMapId] || [];
          // 配列からアイテムを削除
          const newArray = currentArray.filter((existingItem) => existingItem !== targetPinId);
          // 新しいオブジェクトを返す（キーが空になった場合も保持）
          return {
            ...prevState,
            [selectedMapId]: newArray,
          };
        });
      }
      // チェック外した時
      else {
        _states[index] = false;
        setSelectedPinIds([...selectedPinIds.filter((id) => id !== targetPinId)]);
        setCaSelectedPinIds([...selectedPinIds.filter((id) => id !== targetPinId)]);
        // NOTE: ピンの非表示リストに追加する
        setHideMapPinIds((prevState) => {
          const currentArray = prevState[selectedMapId] || [];
          // 重複チェック
          if (!currentArray.includes(targetPinId)) {
            return {
              ...prevState,
              [selectedMapId]: [...currentArray, targetPinId],
            };
          }
          // 重複があれば状態はそのまま
          return prevState;
        });
      }
    } else {
      // NOTE: Graphモードの場合ピンの選択は必ず1件
      if (e.target.checked) {
        _states.fill(false);
        _states[index] = true;
        setSelectedPinIds([targetPinId]);
      }
    }
    setCheckBoxStates(_states);
  };

  /**
   * @description ピン / グループの右クリック時の処理 (コンテキストメニューの表示)
   */
  const handleRightClickMapPin = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.preventDefault();
    if (workMode === 'Map' || workMode === 'Track') {
      const mapPin: BasePinType = getTargetPinData(selectedMap, id);

      if (mapPin) {
        handleSetRightClickPin(mapPin.pinId);
        handleSetSelectedPinType(mapPin.pinType ? mapPin?.pinType : PIN_TYPE.UnitPin);
        setAnchorElement(e.currentTarget);
        setIsOpenedContextMenu(true);
      }
    }
  };

  /**
   * @description コンテキストメニューを閉じる処理
   */
  const handleClosedContextMenu = () => {
    setAnchorElement(null);
    setIsOpenedContextMenu(false);
  };

  /**
   * @description ピンの設定変更選択時の処理
   */
  const handleClickUpdateMapPin = (id: string) => {
    handleSetRightClickPin(id);
    handleClickOpenedDialog();
    handleClosedContextMenu();
  };

  /**
   * @description ピンの削除選択時の処理
   */
  const handleClickRemoveMapPin = async (pinId: string, index: number) => {
    const mapPin: BasePinType = getTargetPinData(selectedMap, pinId);
    try {
      if (mapPin.pinType === PIN_TYPE.UnitPin || mapPin.pinType === PIN_TYPE.NoisePin) {
        // NOTE: ピン / マップの削除
        await deletePinFromMap(selectedMapId, pinId, true);
      } else if (mapPin.pinType === PIN_TYPE.GroupPin) {
        // NOTE: フロアピンの場合、pinId が削除対象のマップの ID と一致する (mapId はメインマップになるので注意!!)
        await deletePinFromMainMap(pinId, pinId, true);

        // NOTE: フロアピンの場合は、マップリストから削除
        setAllMapIds(removeDuplicatedElements([...allMapIds].filter((id) => id !== pinId)));
      }
    } catch (error) {
      showBoundary(error);
      return;
    }

    // NOTE: ピンとチェックボックスのステートを更新
    const _allPinIds = removeDuplicatedElements([...allPinIds].filter((id) => id !== pinId));
    const _states = [...checkBoxStates];
    _states.splice(index, 1);
    const _selectedPinIds = removeDuplicatedElements([...selectedPinIds].filter((id) => id !== pinId));

    // NOTE: ピン候補リストに含まれるならステータスを未登録に変更
    if (candidatePinIds.includes(pinId)) {
      _allPinIds.push(pinId);
      _states.push(false);
    }

    setAllPinIds(_allPinIds);
    setSelectedPinIds(_selectedPinIds);
    setRegisteredPinIds(removeDuplicatedElements([...registeredPinIds].filter((id) => id !== pinId)));
    setCheckBoxStates(_states);
    setCaSelectedPinIds(_selectedPinIds);
    handleClosedContextMenu();
  };

  return (
    <Fragment key={`fragment-${id}`}>
      <FormControlLabel
        key={`mapPinCheckBox-${id}`}
        sx={{ minWidth: 200, maxWidth: 200 }}
        control={
          <CustomCheckBox
            onChange={(e) => {
              handleClickMapPinItem(e, id, index);
            }}
            // NOTE: マップピンが少ないマップから多いマップに変更した場合、初回レンダリング時に不足している配列の要素分が undefined となる為、undefined → false に変更する為に二重否定を利用
            checked={!!isChecked}
          />
        }
        label={
          <Typography noWrap sx={{ textOverflow: 'ellipsis' }}>
            {t(label)}
          </Typography>
        }
        onContextMenu={(e) => handleRightClickMapPin(e, id)}
      />
      {mapPin && (
        <Menu
          key={`mapPinContextMenu-${id}`}
          open={isOpenedContextMenu}
          onClose={handleClosedContextMenu}
          anchorEl={anchorElement}
        >
          <MenuItem key={`updateMapPin-${id}`} onClick={() => handleClickUpdateMapPin(id)}>
            {t(`ピンの設定変更`)}
          </MenuItem>
          <MenuItem key={`removeMapPin-${id}`} onClick={() => handleClickRemoveMapPin(id, index)}>
            {t(`ピンを削除`)}
          </MenuItem>
        </Menu>
      )}
    </Fragment>
  );
};

export default React.memo(PinSelectItem);

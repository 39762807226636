import React, { useRef, useCallback, useState, useEffect } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Box } from '@mui/material';
import { MAP_SIZE, PointType } from 'domain/types/map';

interface Props {
  image: string;
  width?: number;
  height?: number;
  position?: PointType;
  zoom?: number;
  rotation?: number;
  parentWidth?: number;
  parentHeight?: number;
  setSizeState?: (width: number, height: number) => void;
  setPositionState?: (position: PointType) => void;
}

const EditImage = ({
  image,
  width,
  height,
  position = { x: 0, y: 0 },
  zoom = 1,
  rotation = 0,
  parentWidth = MAP_SIZE.width,
  parentHeight = MAP_SIZE.height,
  setSizeState,
  setPositionState,
}: Props) => {
  const [tmpX, setTmpX] = useState<number>(0);
  const [tmpY, setTmpY] = useState<number>(0);
  const [tmpWidth, setTmpWidth] = useState<number>(MAP_SIZE.width);
  const [tmpHeight, setTmpHeight] = useState<number>(MAP_SIZE.height);
  const [isDragging, setIsDragging] = useState(false);
  const draggableNodeRef = useRef(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const onStart = useCallback((e: DraggableEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setTmpX(data.x);
      setTmpY(data.y);
      if (setPositionState) setPositionState({ x: data.x, y: data.y });
    },
    [setPositionState]
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLImageElement>) => {
    setIsDragging(true);
    event.preventDefault();
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    setTmpX(position.x);
    setTmpY(position.y);
  }, [position.x, position.y]);

  useEffect(() => {
    if (!width || !height) {
      const img = imageRef.current;
      if (img) {
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          const imgAspect = height / width;
          const parentAspect = parentHeight / parentWidth;

          let _width: number;
          let _height: number;
          if (parentAspect > imgAspect) {
            _width = parentWidth;
            _height = parentWidth * imgAspect;
          } else {
            _width = parentHeight / imgAspect;
            _height = parentHeight;
          }

          setTmpWidth(_width);
          setTmpHeight(_height);
        };
      }
    }
  }, [height, parentHeight, parentWidth, width]);

  useEffect(() => {
    if (setSizeState) {
      const img = imageRef.current;
      if (img) {
        setSizeState(tmpWidth, tmpHeight);
      }
    }
  }, [setSizeState, tmpHeight, tmpWidth, zoom]);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.style.transform = `scale(${zoom}) rotate(${rotation}deg)`;
    }
  }, [zoom, rotation]);

  return (
    <Draggable nodeRef={draggableNodeRef} onStart={onStart} onStop={onStop} position={{ x: tmpX, y: tmpY }}>
      <Box
        ref={draggableNodeRef}
        sx={{
          cursor: isDragging ? 'grabbing' : 'grab',
          position: 'relative',
          transformOrigin: 'center',
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <Box
          component='img'
          src={image}
          ref={imageRef}
          sx={{
            width: width ? width : tmpWidth,
            height: height ? height : tmpHeight,
          }}
        />
      </Box>
    </Draggable>
  );
};

export default React.memo(EditImage);

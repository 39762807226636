import { getToken, stage, appApiEndPoint } from '../base';
import { wrapForObjects } from '../../../core/commons/dataConverter';
import { PinData, TrackData } from '../../../domain/types/map';

export const getPinData = async (
  startTime: string,
  endTime: string,
  deviceIds: string[],
  dataType: string,
  heightMin: number,
  heightMax: number,
  hAcc: number,
  vAcc: number,
  trackDiv: number,
  noiseCalcMethod: string,
  occupancyCalcMethod: string,
  noiseDataSource: string
): Promise<PinData[]> => {
  const token = await getToken();
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    data_type: dataType,
    height_min: heightMin.toString(),
    height_max: heightMax.toString(),
    hacc: hAcc.toString(),
    vacc: vAcc.toString(),
    noise_calc_method: noiseCalcMethod,
    occupancy_calc_method: occupancyCalcMethod,
    noise_data_source: noiseDataSource,
    time_div: trackDiv.toString(),
  });
  deviceIds.forEach((deviceId) => {
    params.append('device_ids[]', deviceId);
  });

  const res = await fetch(`${appApiEndPoint}/${stage}/pindata?${params.toString()}`, {
    headers: { Authorization: token },
  });
  const json = await res.json();
  const items = wrapForObjects<PinData>(json);
  return items;
};

export const getTrackData = async (
  startTime: string,
  endTime: string,
  deviceId: string,
  dataType: string,
  heightMin: number,
  heightMax: number,
  hAcc: number,
  vAcc: number,
  trackDiv: number,
  noiseCalcMethod: string,
  occupancyCalcMethod: string,
  noiseDataSource: string
): Promise<TrackData[]> => {
  const token = await getToken();
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    device_id: deviceId,
    data_type: dataType,
    height_min: heightMin.toString(),
    height_max: heightMax.toString(),
    hacc: hAcc.toString(),
    vacc: vAcc.toString(),
    noise_calc_method: noiseCalcMethod,
    occupancy_calc_method: occupancyCalcMethod,
    noise_data_source: noiseDataSource,
    track_div: trackDiv.toString(),
  });

  const res = await fetch(`${appApiEndPoint}/${stage}/pindata/track?${params.toString()}`, {
    headers: { Authorization: token },
  });
  const json = await res.json();
  const items = wrapForObjects<TrackData>(json);
  return items;
};

import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Box } from '@mui/material';
import StraightenIcon from '@mui/icons-material/Straighten';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { MAP_SIZE } from 'domain/types/map';
import CustomIconButton from 'components/Common/CustomIconButton';
import InputSlider from 'components/Common/SliderWithInputField';

interface Props {
  isOpenedPositionRange: boolean;
  verticalConf: number;
  horizontalConf: number;
  setIsOpenedPositionRange: Dispatch<SetStateAction<boolean>>;
  setVerticalConf: Dispatch<SetStateAction<number>>;
  setHorizontalConf: Dispatch<SetStateAction<number>>;
}

const PositionRangeButton = ({
  isOpenedPositionRange,
  verticalConf,
  horizontalConf,
  setIsOpenedPositionRange,
  setVerticalConf,
  setHorizontalConf,
}: Props) => {
  const { t } = useTranslation();

  const [isSliding, setIsSliding] = useState<boolean>(false);
  const [isInputting, setIsInputting] = useState<boolean>(false);
  const [tmpVertical, setTmpVertical] = useState<number>(verticalConf);
  const [tmpHorizontal, setTmpHorizontal] = useState<number>(horizontalConf);
  const [count, setCount] = useState<number>(0);

  /**
   * @description 位置精度指定ボタン選択時の処理
   */
  const handleClickSetPosition = () => {
    setIsOpenedPositionRange(!isOpenedPositionRange);
    if (isOpenedPositionRange) setCount(0);
  };

  const handleSetIsSliding = useCallback((state: boolean) => {
    setIsSliding(state);
    setCount((prev) => prev + 1);
  }, []);

  const handleSetIsInputting = useCallback((state: boolean) => {
    setIsInputting(state);
    setCount((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (isOpenedPositionRange && !isSliding && !isInputting && count > 0) {
      setVerticalConf(tmpVertical);
      setHorizontalConf(tmpHorizontal);
    }
  }, [
    count,
    isInputting,
    isOpenedPositionRange,
    isSliding,
    setHorizontalConf,
    setVerticalConf,
    tmpHorizontal,
    tmpVertical,
  ]);

  useEffect(() => {
    if (isOpenedPositionRange === true && count === 0) {
      setTmpVertical(verticalConf);
      setTmpHorizontal(horizontalConf);
    }
  }, [count, horizontalConf, isOpenedPositionRange, verticalConf]);

  return (
    <>
      <CustomIconButton
        onClick={handleClickSetPosition}
        Icon={StraightenIcon}
        tooltipTitle={['位置精度指定', '指定する精度以内の位置情報のみを有効な位置情報とします。']}
        size='small'
        isEnabled={isOpenedPositionRange}
        placement='right-start'
      />
      {isOpenedPositionRange && (
        <Stack direction='column' justifyContent='flex-start' sx={{ backgroundColor: '#000', opacity: 0.8, p: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Box>{t('位置精度指定')}</Box>
            <CustomIconButton
              onClick={handleClickSetPosition}
              Icon={ArrowDropUpIcon}
              tooltipTitle={'メニューを隠す'}
              size='small'
              placement='right-start'
            />
          </Box>
          <Stack direction='column' justifyContent='flex-start'>
            <InputSlider
              min={0}
              max={100}
              originalMin={0}
              originalMax={100}
              value={tmpVertical}
              setValue={setTmpVertical}
              title={t('垂直') + '[m]'}
              sx={{
                width: MAP_SIZE.width / 2,
              }}
              handleSetIsSliding={handleSetIsSliding}
              handleSetIsInputting={handleSetIsInputting}
              step={10}
            />
            <InputSlider
              min={0}
              max={100}
              originalMin={0}
              originalMax={100}
              value={tmpHorizontal}
              setValue={setTmpHorizontal}
              title={t('水平') + '[m]'}
              sx={{
                width: MAP_SIZE.width / 2,
              }}
              handleSetIsSliding={handleSetIsSliding}
              handleSetIsInputting={handleSetIsInputting}
              step={10}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default React.memo(PositionRangeButton);

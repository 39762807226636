import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import {
  GetObjectCommand,
  PutObjectCommand,
  DeleteObjectCommand,
  S3Client,
  // ListObjectsV2Command,
} from '@aws-sdk/client-s3';

const _getS3Client = (idToken: string): S3Client => {
  return new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: process.env.REACT_APP_REGION }),
      identityPoolId: process.env.REACT_APP_ID_POOL_ID || '',
      logins: {
        [`cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID}`]:
          idToken,
      },
    }),
  });
};

const IMAGE_BUCKET = process.env.REACT_APP_IMAGE_BUCKET;

/**
 * @param {Blob} data アップロード対象のオブジェクトデータ
 * @param {string} objKey アップロードするオブジェクトのキー
 * @param {string} idToken Cognito の ID トークン
 */
export const putFileObject = async (data: Blob, objKey: string, idToken: string) => {
  const client = _getS3Client(idToken);

  const command = new PutObjectCommand({
    Bucket: IMAGE_BUCKET,
    Key: objKey,
    Body: data,
  });

  try {
    await client.send(command);
  } catch (error) {
    throw new Error('File upload to S3 bucket failed. ' + error);
  }
};

/**
 * @param {string} objKey ダウンロードするオブジェクトのキー
 * @param {string} idToken Cognito の ID トークン
 * @return {Blob} 画像ファイルオブジェクト
 */
export const getImageObject = async (objKey: string, idToken: string) => {
  const client = _getS3Client(idToken);

  const command = new GetObjectCommand({ Bucket: IMAGE_BUCKET, Key: objKey });

  try {
    const response = await client.send(command);

    if (!response.Body) throw new Error('Response body is none.');

    const blob = new Blob([await response.Body.transformToByteArray()]);
    return blob;
  } catch (error) {
    throw new Error('File download from S3 bucket failed. ' + error);
  }
};

/**
 * @param {string} objKey 削除するオブジェクトのキー
 * @param {string} idToken Cognito の ID トークン
 */
export const removeFileObject = async (objKey: string, idToken: string) => {
  const client = _getS3Client(idToken);

  const command = new DeleteObjectCommand({ Bucket: IMAGE_BUCKET, Key: objKey });

  try {
    await client.send(command);
  } catch (error) {
    throw new Error('Failed to delete file in S3 bucket. ' + error);
  }
};

/**
 * @param {string} objPath 削除するオブジェクトのパス (prefix/* のオブジェクトをすべて削除する)
 * @param {string} idToken Cognito の ID トークン
 */
// export const removeSpecificFileObject = async (prefix: string, idToken: string) => {
//   const client = _getS3Client(idToken);
//   const _prefix = prefix.endsWith('/') ? prefix : prefix + '/';

//   const getCommand = new ListObjectsV2Command({ Bucket: IMAGE_BUCKET, Prefix: _prefix });

//   try {
//     const objList = await client.send(getCommand);

//     if (objList.Contents && objList.Contents.length > 0) {
//       const removeObjKey = objList.Contents.map((obj) => obj.Key).filter((key) => key !== undefined);

//       for (const key of removeObjKey) {
//         console.log(key);
//         const deleteCommand = new DeleteObjectCommand({
//           Bucket: IMAGE_BUCKET,
//           Key: 'test/1/G_001/20240729211645.png',
//         });
//         await client.send(deleteCommand);
//       }
//     }
//   } catch (error) {
//     throw new Error('Failed to delete file in S3 bucket. ' + error);
//   }
// };

import React, { ChangeEvent, forwardRef } from 'react';

interface Props {
  onUpload: (image: string | null) => void;
}

const UploadImage = forwardRef<HTMLInputElement, Props>(({ onUpload }, ref) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onUpload(reader.result as string);

        // NOTE: 同じファイルを再度選択できるようにinputの値をリセット
        if (ref && 'current' in ref && ref.current) {
          ref.current.value = '';
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return <input type='file' accept='image/*' ref={ref} hidden onChange={handleFileChange} />;
});

UploadImage.displayName = 'UploadImage';

export default React.memo(UploadImage);

import AWS from 'aws-sdk';
import { fetchAuthSession } from '@aws-amplify/auth';

class MqttClient {
  private client: AWS.IotData | null = null;

  async initialize(): Promise<void> {
    const session = await fetchAuthSession({ forceRefresh: true });

    this.client = new AWS.IotData({
      region: process.env.REACT_APP_REGION as string,
      endpoint: process.env.REACT_APP_AWS_IOT_DATA_ENDPOINT as string,
      accessKeyId: session.credentials?.accessKeyId,
      secretAccessKey: session.credentials?.secretAccessKey,
      sessionToken: session.credentials?.sessionToken,
    });
  }

  async publish(topic: string, payload: string): Promise<void> {
    try {
      if (!this.client) {
        await this.initialize();
      }

      if (this.client) {
        return new Promise((resolve, reject) => {
          this.client!.publish({ topic, payload }, async (err, data) => {
            if (err) {
              console.error('Error publishing:', err);
              if (err.code === 'NotAuthorizedException' || err.code === 'ExpiredTokenException') {
                console.warn('Session token expired. Re-initializing...');
                await this.initialize();
                try {
                  await this.publish(topic, payload); // 再試行
                  resolve();
                } catch (retryErr) {
                  reject(retryErr);
                }
              } else {
                reject(err);
              }
            } else {
              console.log('Successfully published:', data);
              resolve();
            }
          });
        });
      }
    } catch (error) {
      console.error('Error during publish operation:', error);
    }
  }
}

export default MqttClient;

import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import { Box, FormControlLabel, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

// NOTE: カスタムコンポーネント
import { FREQUENCY_BAND, FREQUENCIES, FrequencyChannelType, FrequencyType } from '../../../../domain/types/frequency';
import CustomCheckBox from '../../../Common/CustomCheckBox';
import { formatChannelGroupLabel, formatFrequencyLabel } from '../../../../utils/format';
import { removeDuplicatedElements } from '../../../../utils/transform';
import { getFrequenciesIncludeChannelGroup, getRemoveFrequencies } from '../../../../utils/extract';
import { RwmContext } from 'RwmContext';
import { ChannelGroupListContext } from 'radioMonitoringPage';

interface Props {
  checkBoxStates: boolean[];
  setCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;
  selectedChannelGroups: FrequencyChannelType[];
  setSelectedChannelGroups: Dispatch<SetStateAction<FrequencyChannelType[]>>;
}
const ChannelSelectArea = ({
  checkBoxStates,
  setCheckBoxStates,
  selectedChannelGroups,
  setSelectedChannelGroups,
}: Props) => {
  const { t } = useTranslation();

  const { selectedFrequencies, setSelectedFrequencies } = useContext(RwmContext);
  const { channelGroupList } = useContext(ChannelGroupListContext);

  /**
   * @description
   *  CHグループの各チェックボックス選択時の処理 \
   *  選択中のCHグループのリストとステート以外に、周波数のリストも更新する \
   */
  const handleChangeChannelItemCheck = (
    e: ChangeEvent<HTMLInputElement>,
    targetFrequencies: FrequencyType[],
    targetChannelGroup: FrequencyChannelType,
    index: number
  ) => {
    let tmpFrequencies: FrequencyType[];
    let tmpChannelGroups: FrequencyChannelType[];
    const tmpCheckBoxStates: boolean[] = [...checkBoxStates];

    if (e.target.checked) {
      tmpFrequencies = removeDuplicatedElements([...selectedFrequencies, ...targetFrequencies]);
      tmpChannelGroups = removeDuplicatedElements([...selectedChannelGroups, targetChannelGroup]);
      tmpCheckBoxStates[index] = true;
    } else {
      tmpFrequencies = getRemoveFrequencies(selectedChannelGroups, targetChannelGroup, selectedFrequencies);
      tmpChannelGroups = [...selectedChannelGroups].filter(
        (selectedChannelGroup) => selectedChannelGroup.frequency != targetChannelGroup.frequency
      );
      tmpCheckBoxStates[index] = false;
    }

    setSelectedFrequencies(tmpFrequencies);
    setSelectedChannelGroups(tmpChannelGroups);
    setCheckBoxStates(tmpCheckBoxStates);
  };

  return (
    <Box sx={{ alignContent: 'flex-start', flexWrap: 'wrap' }}>
      {channelGroupList.map((channelGroup: FrequencyChannelType, index: number) => {
        const frequenciesIncludeChannelGroup: FrequencyType[] = getFrequenciesIncludeChannelGroup(
          channelGroup,
          FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list
        );
        const label: string = formatChannelGroupLabel(channelGroup.channel);

        const tooltipTitle: ReactNode = (
          <React.Fragment>
            {t(label)}
            {frequenciesIncludeChannelGroup.map((frequency: FrequencyType, index: number) => {
              return (
                <span key={`labelFrequency-${index}`}>
                  <br />
                  {t(formatFrequencyLabel(frequency))}
                </span>
              );
            })}
          </React.Fragment>
        );

        const isChecked: boolean = checkBoxStates[index];

        return (
          <Tooltip
            title={tooltipTitle}
            key={`channelCheckBox-${channelGroup.channel}`}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [30, -25],
                    },
                  },
                ],
              },
            }}
            arrow
          >
            <FormControlLabel
              sx={{ width: 200, color: channelGroup.color ? channelGroup.color : '' }}
              control={
                <CustomCheckBox
                  onChange={(e) => {
                    handleChangeChannelItemCheck(e, frequenciesIncludeChannelGroup, channelGroup, index);
                  }}
                  // NOTE: 周波数リスト数が少ない周波数バンドから多いものに変更した場合、初回レンダリング時に不足している配列の要素分が undefined となる為、undefined → false に変更する為に二重否定を利用
                  checked={!!isChecked}
                />
              }
              label={t(label)}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default React.memo(ChannelSelectArea);

import { AreaMasterItem } from 'core/entities/areaMaster';
import { AreaMasterRepository } from 'domain/types/area';
import { UseCase } from 'core/usecases/usecase';

export class FetchAreaMasters implements UseCase<Promise<AreaMasterItem>, FetchAreaMastersParams> {
  private areaMasterRepository: AreaMasterRepository;
  constructor(areaMasterRepository: AreaMasterRepository) {
    this.areaMasterRepository = areaMasterRepository;
  }

  public async call(params: FetchAreaMastersParams): Promise<AreaMasterItem> {
    const areaId = params.areaId;
    return await this.areaMasterRepository.fetchOne(areaId);
  }
}

export class FetchAreaMastersParams {
  constructor(public areaId: string) {}
}

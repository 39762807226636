import React, { SetStateAction, Dispatch } from 'react';
import LayersIcon from '@mui/icons-material/Layers';

// NOTE: カスタムコンポーネント
import { MapTypeIdType } from 'domain/types/map';
import CustomIconButton from 'components/Common/CustomIconButton';

/**
 * @interface
 */
interface Props {
  setMapTypeId: Dispatch<SetStateAction<MapTypeIdType>>;
}

const ChangeMapTypeButton = ({ setMapTypeId }: Props) => {
  /**
   * @description マップタイプ切替ボタン選択時の処理
   */
  const handleClickChangeMapType = () => {
    setMapTypeId((prevMapTypeId) => (prevMapTypeId === 'satellite' ? 'roadmap' : 'satellite'));
  };

  return (
    <CustomIconButton
      onClick={handleClickChangeMapType}
      Icon={LayersIcon}
      tooltipTitle={'マップタイプ切替 (デフォルト⇔航空写真)'}
      size='medium'
    />
  );
};

export default React.memo(ChangeMapTypeButton);

import React, { useContext, useRef } from 'react';
import CustomIconButton from 'components/Common/CustomIconButton';
import CollectionsIcon from '@mui/icons-material/Collections';
import { RwmMapAreaContext } from '../RwmMapContext';
import UploadImage from '../Map/UploadImage';

const OpenEditImageWindowButton = React.forwardRef<HTMLButtonElement>((props, ref) => {
  const { setTmpImage, setIsOpenedImageWindow } = useContext(RwmMapAreaContext);
  const uploadImageRef = useRef<HTMLInputElement>(null);

  /**
   * @description イメージファイルを読み込みボタン選択時の処理
   */
  const handleClickReadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  /**
   * @description ファイルアップロード画面で OK 押下時の処理
   */
  const handleImageUpload = (uploadedImage: string | null) => {
    setTmpImage(uploadedImage);
    setIsOpenedImageWindow(true);
  };

  return (
    <>
      <CustomIconButton
        ref={ref}
        onClick={handleClickReadImage}
        Icon={CollectionsIcon}
        tooltipTitle={'イメージファイルを読み込み'}
        size='small'
      />
      <UploadImage ref={uploadImageRef} onUpload={handleImageUpload} />
    </>
  );
});

//NOTE: DisplayName を設定してエラーを解消
OpenEditImageWindowButton.displayName = 'OpenEditFileWindowButton';

export default React.memo(OpenEditImageWindowButton);

import React, { ReactNode } from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';

// size プロパティに応じたボタンとアイコンのスタイルを返す関数
const getSizeStyles = (size: 'extraSmall' | 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'medium':
      return {
        button: {
          width: '45px',
          height: '45px',
        },
        icon: {
          fontSize: '1.5rem', // 中間のアイコンのサイズ
        },
      };
    case 'large':
      return {
        button: {
          width: '60px',
          height: '60px',
        },
        icon: {
          fontSize: '2.0rem', // 大きいアイコンのサイズ
        },
      };
    case 'small':
    default:
      return {
        button: {
          width: '32px',
          height: '32px',
        },
        icon: {
          fontSize: '1.0rem', // 小さいアイコンのサイズ
        },
      };
    case 'extraSmall':
      return {
        button: {
          width: '24px', // smallより小さいサイズ
          height: '24px',
        },
        icon: {
          fontSize: '.75rem', // 小さいアイコンのサイズ
        },
      };
  }
};

type PlacementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'
  | undefined;

interface Props extends Omit<IconButtonProps, 'size'> {
  Icon: React.ComponentType<SvgIconProps>;
  tooltipTitle: string | string[];
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  placement?: PlacementType;
  isEnabled?: boolean;
}

const CustomIconButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ Icon, tooltipTitle, size: customSize = 'small', placement = undefined, isEnabled = false, ...props }, ref) => {
    // スタイルを動的に調整するためのオブジェクト
    const style = getSizeStyles(customSize);
    const baseStyle = { backgroundColor: isEnabled ? '#0078d7' : '#333333', color: '#FFFFFF', borderRadius: '3px' };
    const buttonStyle = { ...style.button, ...baseStyle };
    const iconStyle = { ...style.icon, ...baseStyle };
    const { t } = useTranslation();

    let tmpTooltipTitle: string | ReactNode = t(tooltipTitle);

    if (Array.isArray(tooltipTitle)) {
      tmpTooltipTitle = (
        <React.Fragment>
          {tooltipTitle.map((title: string, index: number) => {
            return (
              <span key={`tooltipTitle-${index}`}>
                {t(title)}
                <br />
              </span>
            );
          })}
        </React.Fragment>
      );
    }

    return (
      <Tooltip title={tmpTooltipTitle} placement={placement}>
        <IconButton {...props} style={buttonStyle} ref={ref}>
          <Icon style={iconStyle} />
        </IconButton>
      </Tooltip>
    );
  }
);

// NOTE: DisplayName を設定してエラーを解消
CustomIconButton.displayName = 'CustomIconButton';

export default React.memo(CustomIconButton);

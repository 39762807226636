import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Menu, MenuItem, styled, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { DraggableBounds } from 'react-draggable';
import { HeatPointType, MapObjType, MapPinType, PIN_TYPE, PinData, PointType } from 'domain/types/map';
import PinIcon from '../Map/PinIcon';
import { DATA_SOURCE, MAIN_PAGE_TAB_INDEX } from 'domain/types/common/consts';
import {
  SelectedDataSourceContext,
  SelectedFrequencyBandContext,
  SelectedGraphIndexContext,
  SettingContext,
} from 'radioMonitoringPage';
import SetUpDialogWindow from 'components/SetUpDialogWindow/SetUpDialogWindow';
import { formatMapPinWindowLabel } from 'utils/format';
import { getImagePinLatLng, getImagePinPosition } from 'utils/extract';
import { setPropertyForMapPin, setPropertyForGroupMapPin, removeDuplicatedElements } from 'utils/transform';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { RwmContext, useRwmCache } from 'RwmContext';
import { useErrorBoundary } from 'react-error-boundary';
import { useMaps } from 'MapsContext';
import { FrequencyType } from 'domain/types/frequency';
import { DataSourceType } from 'domain/types/setting';
import { GRAPH, GRAPHS } from 'domain/types/graph';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFA',
    boxShadow: theme.shadows[1],
    fontSize: 8,
    color: '#000',
  },
}));

const getInitialStates = (
  mapsData: MapObjType[],
  selectedMap: MapObjType,
  pinId: string,
  pinData: PinData[],
  selectedMapId: string,
  selectedFrequencies: FrequencyType[],
  selectedDataSource: DataSourceType,
  selectedFrequencyBand: string,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  mapWidth: number,
  mapHeight: number,
  imgLat: number,
  imgLng: number,
  imgWidth: number,
  imgHeight: number,
  imgRotate: number,
  isEnableGps: boolean,
  pinSize: number
) => {
  const basePin = selectedMap.pins.find((pin) => pin.pinId === pinId);
  let mapPin: MapPinType | undefined;
  let flag = false;

  if (basePin) {
    if (basePin.pinType === PIN_TYPE.GroupPin) {
      mapPin = setPropertyForGroupMapPin(mapsData, basePin, pinData, selectedFrequencies, selectedDataSource, pinId);
    } else {
      mapPin = setPropertyForMapPin(basePin, pinData, selectedFrequencies, selectedDataSource, selectedMapId);
    }

    const { hasGpsInfo } = mapPin;
    let { lat, lng } = mapPin;

    if (hasGpsInfo && pinData.length > 0) {
      const index = pinData.findIndex((data) => data.unitId === pinId);
      if (
        index !== -1 &&
        pinData[index].lat !== 0 &&
        pinData[index].lng !== 0 &&
        pinData[index].hasGpsInfo &&
        isEnableGps
      ) {
        const pin = pinData[index];
        lat = pin.lat;
        lng = pin.lng;
        mapPin.lat = pin.lat;
        mapPin.lng = pin.lng;
        flag = pinData[index].hasGpsInfo;
      }
    }
    const _pin = mapsData.filter((map) => map.mapId === selectedMapId)[0].pins.filter((pin) => pin.pinId === pinId)[0];
    if (
      _pin &&
      Object.prototype.hasOwnProperty.call('lat', _pin.lat) &&
      Object.prototype.hasOwnProperty.call('lng', _pin.lat)
    ) {
      _pin.lat = lat;
      _pin.lng = lng;
    }

    const position = getImagePinPosition(
      lat,
      lng,
      ne as google.maps.LatLng,
      sw as google.maps.LatLng,
      mapWidth,
      mapHeight,
      imgLat,
      imgLng,
      imgWidth,
      imgHeight,
      imgRotate,
      pinSize
    );

    if (Number.isNaN(position.x)) position.x = mapWidth / 2;
    if (Number.isNaN(position.y)) position.y = mapHeight / 2;

    const [idLabel, frequencyLabel] = formatMapPinWindowLabel(mapPin, selectedDataSource, selectedFrequencyBand);
    const value = selectedDataSource === DATA_SOURCE.NOISE ? mapPin.noise : mapPin.ratio;
    const channel = mapPin.channel;
    const pinType = mapPin.pinType;

    return {
      selectedMap,
      mapPin,
      position,
      idLabel,
      frequencyLabel,
      value,
      channel,
      pinType,
      flag,
    };
  }
};

interface Props {
  pinId: string;
  bounds: DraggableBounds;
  index: number;
  handleSetHeatPoints: (data: HeatPointType | undefined, pinId: string) => void;
  selectedMap: MapObjType;
}

const ImagePin = ({ pinId, bounds, index, handleSetHeatPoints, selectedMap }: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { setCaSelectedPinIds } = useRwmCache();
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { setSelectedGraphIndex } = useContext(SelectedGraphIndexContext);
  const { setting } = useContext(SettingContext);
  const { pinData, imageWidth, imageHeight, imageRotate, imageLat, imageLng } = useContext(RwmMapTabContext);
  const { mapWidth, mapHeight, ne, sw, handleSetSelectedMap, setClipboardPin } = useContext(RwmMapAreaContext);
  const { candidatePinIds, selectedFrequencies } = useContext(RwmContext);
  const {
    allMapIds,
    setAllMapIds,
    selectedMapId,
    setSelectedMapId,
    allPinIds,
    setAllPinIds,
    selectedPinIds,
    setSelectedPinIds,
    registeredPinIds,
    setRegisteredPinIds,
    pinCheckBoxStates,
    setPinCheckBoxStates,
    isEnableGps,
    setSelectedPinIdsForGraph,
    allPinIdsForGraph,
    setMainPageTabIndex,
    setWorkMode,
    setPinCheckBoxStatesForGraph,
  } = useContext(LayoutContext);
  const { mapsData, deletePinFromMap, deletePinFromMainMap, updatePinInMap } = useMaps();

  const memoizedInitialStates = useCallback(
    () =>
      getInitialStates(
        mapsData,
        selectedMap,
        pinId,
        pinData,
        selectedMapId,
        selectedFrequencies,
        selectedDataSource as DataSourceType,
        selectedFrequencyBand,
        ne as google.maps.LatLng,
        sw as google.maps.LatLng,
        mapWidth as number,
        mapHeight as number,
        imageLat,
        imageLng,
        imageWidth,
        imageHeight,
        imageRotate,
        isEnableGps,
        setting.pinSize
      ),
    [
      mapsData,
      selectedMap,
      pinId,
      pinData,
      selectedMapId,
      selectedFrequencies,
      selectedDataSource,
      selectedFrequencyBand,
      ne,
      sw,
      mapWidth,
      mapHeight,
      imageLat,
      imageLng,
      imageWidth,
      imageHeight,
      imageRotate,
      isEnableGps,
      setting.pinSize,
    ]
  );

  const [mapPin, setMapPin] = useState<MapPinType | undefined>(undefined);
  const [idLabel, setIdLabel] = useState<string | undefined>(undefined);
  const [frequencyLabel, setFrequencyLabel] = useState<string | undefined>(undefined);
  const [position, setPosition] = useState<PointType | undefined>(undefined);
  const [value, setValue] = useState<number | undefined>(undefined);
  const [channel, setChannel] = useState<number | undefined>(undefined);
  const [pinType, setPinType] = useState<number | undefined>(undefined);
  const [isOpenedContextMenu, setIsOpenedContextMenu] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const states = memoizedInitialStates();
    if (states !== undefined) {
      setMapPin(states.mapPin);
      setPosition(states.position);
      setIdLabel(states.idLabel);
      setFrequencyLabel(states.frequencyLabel);
      setValue(states.value);
      setChannel(states.channel);
      setPinType(states.pinType);
      setFlag(states.flag);
    }

    return () => {
      setMapPin(undefined);
      setPosition(undefined);
      setIdLabel(undefined);
      setFrequencyLabel(undefined);
      setValue(undefined);
      setChannel(undefined);
      setPinType(undefined);
    };
  }, [memoizedInitialStates]);

  useEffect(() => {
    if (position !== undefined && value !== undefined) {
      handleSetHeatPoints({ x: position.x, y: position.y, value, id: pinId }, pinId);
    } else handleSetHeatPoints(undefined, pinId);
  }, [handleSetHeatPoints, pinId, position, value]);

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      if (mapPin === undefined || selectedMap === undefined) return;
      if (ne === undefined || sw === undefined || mapWidth === undefined || mapHeight === undefined) return;

      if (!position) return;
      else {
        if (Math.abs(data.x - position.x) < 1) return;
        if (Math.abs(data.y - position.y) < 1) return;
      }

      let x = data.x;
      let y = data.y;

      if (flag) {
        x = position.x;
        y = position.y;
      }

      const { lat, lng } = getImagePinLatLng(
        x,
        y,
        ne,
        sw,
        mapWidth,
        mapHeight,
        imageLat,
        imageLng,
        imageWidth,
        imageHeight,
        imageRotate,
        setting.pinSize
      );

      const targetMapPin: MapPinType = {
        ...mapPin,
        lat: lat,
        lng: lng,
      };

      // NOTE: ピンの更新
      (async () => {
        try {
          await updatePinInMap(selectedMap.mapId, targetMapPin, true);
        } catch (error) {
          showBoundary(error);
          return;
        }
      })();
      setPosition({ x: data.x, y: data.y });

      // Deep copy
      const _selectedMap: MapObjType = JSON.parse(JSON.stringify(selectedMap));
      const _pins = _selectedMap.pins.map((pin) => {
        if (pin.pinId === targetMapPin.pinId) {
          return targetMapPin;
        } else {
          return pin;
        }
      });
      handleSetSelectedMap({ ..._selectedMap, pins: _pins });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleSetSelectedMap,
      imageHeight,
      imageRotate,
      imageWidth,
      imageLat,
      imageLng,
      mapHeight,
      mapPin,
      mapWidth,
      ne,
      pinId,
      selectedMap,
      showBoundary,
      sw,
      updatePinInMap,
    ]
  );

  /**
   * @description ピン / グループの右クリック時の処理 (コンテキストメニューの表示)
   */
  const handleRightClickMapPin = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorElement(e.currentTarget);
    setIsOpenedContextMenu(true);
  };

  /**
   * @description コンテキストメニューを閉じる処理
   */
  const handleClosedContextMenu = () => {
    setAnchorElement(null);
    setIsOpenedContextMenu(false);
  };

  /**
   * @description 詳細表示選択時の処理
   */
  const handleClickDetail = (pinId: string, pinType: number) => {
    if (pinType == PIN_TYPE.GroupPin) {
      const index: number = allMapIds.findIndex((id) => id === pinId);
      if (index !== -1) {
        setSelectedMapId(allMapIds[index]);
      }
    } else {
      const idIndex = allPinIdsForGraph.findIndex((value) => value == pinId);
      if (idIndex != -1) {
        setWorkMode('Graph');
        setMainPageTabIndex(MAIN_PAGE_TAB_INDEX.GRAPH);
        setSelectedGraphIndex(GRAPHS.indexOf(GRAPH.DEVICE));
        setSelectedPinIdsForGraph([pinId]);
        setPinCheckBoxStatesForGraph(() => {
          const ret: boolean[] = [];
          for (let i = 0; i <= idIndex; i++) {
            i == idIndex ? ret.push(true) : ret.push(false);
          }
          return ret;
        });
      }
    }
    handleClosedContextMenu();
  };

  /**
   * @description ピンの設定変更選択時の処理
   */
  const handleClickUpdatePin = () => {
    setIsOpenedDialog(true);
    handleClosedContextMenu();
  };

  /**
   * @description ダイアログを閉じる処理
   */
  const handleClickClosedDialog = () => {
    setIsOpenedDialog(false);
  };

  /**
   * @description ピンの削除選択時の処理
   */
  const handleClickRemovePin = async (pinId: string) => {
    try {
      if (pinType === PIN_TYPE.UnitPin || pinType === PIN_TYPE.NoisePin) {
        // NOTE: ピン / マップの削除
        await deletePinFromMap(selectedMapId, pinId, true);
      } else if (pinType === PIN_TYPE.GroupPin) {
        // NOTE: フロアピンの場合、pinId が削除対象のマップの ID と一致する (mapId はメインマップになるので注意!!)
        await deletePinFromMainMap(pinId, pinId, true);

        // NOTE: フロアピンの場合は、マップリストから削除
        setAllMapIds(removeDuplicatedElements([...allMapIds].filter((id) => id !== pinId)));
      }
    } catch (error) {
      showBoundary(error);
      return;
    }

    // NOTE: ピンとチェックボックスのステートを更新
    const _allPinIds = removeDuplicatedElements([...allPinIds].filter((id) => id !== pinId));
    const _states = [...pinCheckBoxStates];
    _states.splice(index, 1);
    const _selectedPinIds = removeDuplicatedElements([...selectedPinIds].filter((id) => id !== pinId));

    // NOTE: ピン候補リストに含まれるならステータスを未登録に変更
    if (candidatePinIds.includes(pinId)) {
      _allPinIds.push(pinId);
      _states.push(false);
    }

    setAllPinIds(_allPinIds);
    setSelectedPinIds(_selectedPinIds);
    setRegisteredPinIds(removeDuplicatedElements([...registeredPinIds].filter((id) => id !== pinId)));
    setPinCheckBoxStates(_states);
    setCaSelectedPinIds(_selectedPinIds);
    handleClosedContextMenu();
  };

  // NOTE: コピー処理
  const handleCopyPin = () => {
    if (mapPin) {
      setClipboardPin({ rightClickAction: 'copy', mapPin: mapPin });
    }
    handleClosedContextMenu();
  };

  // NOTE: 切り取り処理
  const handleCutPin = () => {
    if (mapPin) {
      setClipboardPin({ rightClickAction: 'cut', mapPin: mapPin });
    }
    handleClosedContextMenu();
  };

  if (pinType === undefined) return <></>;
  return (
    <>
      <Draggable bounds={bounds} onStop={(e, data) => onStop(e, data)} position={position}>
        <LightTooltip
          title={
            setting.isDisplayPinLabel && (
              <Typography sx={{ fontSize: 8, color: '#000', zIndex: 0 }}>
                {idLabel}
                <br />
                {frequencyLabel}
                {` (${channel ? channel : '-'})`}
                {flag && `[GPS]`}
              </Typography>
            )
          }
          open
          placement={position && mapWidth && position.x < mapWidth * 0.75 ? 'right' : 'left'}
          sx={{ pointerEvents: 'none' }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
              sx: {
                zIndex: 0,
              },
            },
          }}
        >
          <Box
            className='pin-element'
            sx={{
              position: 'absolute',
              display: 'inline-flex',
              width: setting.pinSize,
              height: setting.pinSize,
              left: 0,
              top: 0,
            }}
            onContextMenu={handleRightClickMapPin}
          >
            <PinIcon pinType={pinType} value={value} iconSize={setting.pinSize} />
            <Menu open={isOpenedContextMenu} onClose={handleClosedContextMenu} anchorEl={anchorElement}>
              <MenuItem
                onClick={() => {
                  handleClickDetail(pinId, pinType);
                }}
              >
                {t(`詳細表示`)}
              </MenuItem>

              <MenuItem onClick={handleClickUpdatePin}>{t(`設定変更`)}</MenuItem>
              <MenuItem onClick={handleCopyPin}>{t(`コピー`)}</MenuItem>
              <MenuItem onClick={handleCutPin}>{t(`切り取り`)}</MenuItem>
              <MenuItem
                onClick={() => {
                  handleClickRemovePin(pinId);
                }}
              >
                {t(`削除`)}
              </MenuItem>
            </Menu>
          </Box>
        </LightTooltip>
      </Draggable>
      {isOpenedDialog && (
        <SetUpDialogWindow
          isOpenedDialog={isOpenedDialog}
          onClosedDialog={handleClickClosedDialog}
          selectedPinId={pinId}
          selectedPinType={pinType}
          pinActionType={'update'}
          isEnableGps={isEnableGps}
        />
      )}
    </>
  );
};

export default React.memo(ImagePin);

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import { PIN_TYPE } from 'domain/types/map';
import { MAIN_MAP_ID } from 'domain/types/map';
import HeatCircle from './HeatCircle';
import { DATA_SOURCE } from 'domain/types/common/consts';
import { getColorFromLevelForMap } from 'utils/format';
import { checkRecommendLevelForRatio, checkRecommendLevelForNoise } from 'utils/validate';
import { SelectedDataSourceContext, SettingContext, SelectedFrequencyBandContext } from 'radioMonitoringPage';
import CustomCircle from 'components/Common/CustomCircle';
import { RECOMMEND_LEVEL_COLOR_MAP } from 'domain/types/color';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { getSelectedMap } from 'utils/extract';
import { PIN_SIZE } from 'domain/types/setting';

interface Props {
  pinType: number;
  value: number | undefined;
  iconSize?: number;
  sx?: SxProps;
}

/**
 * @property {number} pinType ピンの種別
 * @property {number} value noise / ratio の値
 * @property {number} iconSize noise / ratio の値
 * @property {SxProps} sx CSS の設定
 */
const PinIcon = ({ pinType, value, iconSize = PIN_SIZE.DEFAULT, sx }: Props) => {
  const { mapsData } = useMaps();
  const { selectedMapId, workMode } = useContext(LayoutContext);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { setting } = useContext(SettingContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);

  const [iconColor, setIconColor] = useState<string>(RECOMMEND_LEVEL_COLOR_MAP.NONE);

  /**
   * @description ピンの色を変更するイベント
   */
  useEffect(() => {
    const funcProps = {
      judgeItem: value,
      frequencyBand: selectedFrequencyBand,
      settings: setting,
    };
    const recommendLevel =
      selectedDataSource === DATA_SOURCE.OCCUPANCY_RATE
        ? checkRecommendLevelForRatio(funcProps)
        : checkRecommendLevelForNoise(funcProps);
    const color = getColorFromLevelForMap(recommendLevel);
    setIconColor(color);
  }, [selectedDataSource, setting, selectedFrequencyBand, value]);

  if (iconSize > 50) iconSize = 50;
  if (iconSize < 30) iconSize = 30;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: iconSize,
        height: iconSize,
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
        },
        ...sx,
      }}
    >
      <LocationOnIcon
        sx={{
          color: iconColor,
          width: 1,
          height: 1,
          zIndex: 5,
        }}
      />
      {pinType === PIN_TYPE.GroupPin && (
        <>
          <CustomCircle color={iconColor} circleSize={iconSize / 2} sx={{ top: '16%', zIndex: 10 }} />
          <HomeIcon
            sx={{
              width: iconSize * (2 / 5),
              height: iconSize * (2 / 5),
              position: 'absolute',
              top: '17%',
              zIndex: 15,
            }}
          />
        </>
      )}
      {value !== undefined && selectedMap.mapId === MAIN_MAP_ID && workMode !== 'Track' && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            transform: 'translateY(40%)',
          }}
        >
          <HeatCircle value={value} sx={{ zIndex: 0 }} />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(PinIcon);

import { UseCase } from '../../core/usecases/usecase';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ChannelRowType } from '../types/channel';
import { RwmDataRepository } from '../types/rwm';
import { DEFAULT_SETTING, SettingType } from '../types/setting';

export class FetchChannelData implements UseCase<Promise<ChannelRowType[]>, FetchChannelDataParams> {
  private rwmDataRepository: RwmDataRepository;
  constructor(rwmDataRepository: RwmDataRepository) {
    this.rwmDataRepository = rwmDataRepository;
    // プラグインを使用できるように設定
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  public async call(params: FetchChannelDataParams): Promise<ChannelRowType[]> {
    const startDate = params.date.add(-1 * Number(params.spans), 'second');
    const noiseCalcMethod: string = params.setting.noiseCalcuMethod;
    const occupancyCalcMethod: string = params.setting.occupancyCalcMethod;
    const noiseDataSource: string = params.setting.noiseDataSource;
    const levelThreshold = params.setting.levelThresholdList.filter(
      (item) => item.frequencyBand == params.frequencyBand
    );
    let topNoiseLevel: string;
    let topOccupancyLevel: string;
    let bottomNoiseLevel: string;
    let bottomOccupancyLevel: string;
    if (levelThreshold[0]) {
      if (params.setting.noiseDataSource == 'RSSIMAX') {
        topNoiseLevel = levelThreshold[0].topNoiseLevelRssiMax.toString();
        bottomNoiseLevel = levelThreshold[0].bottomNoiseLevelRssiMax.toString();
      } else {
        topNoiseLevel = levelThreshold[0].topNoiseLevelRssiMin.toString();
        bottomNoiseLevel = levelThreshold[0].bottomNoiseLevelRssiMin.toString();
      }
      topOccupancyLevel = levelThreshold[0].topOccupancyLevel.toString();
      bottomOccupancyLevel = levelThreshold[0].bottomOccupancyLevel.toString();
    } else {
      topNoiseLevel = DEFAULT_SETTING.levelThresholdList[0].topNoiseLevelRssiMax.toString();
      bottomNoiseLevel = DEFAULT_SETTING.levelThresholdList[0].bottomNoiseLevelRssiMax.toString();
      topOccupancyLevel = DEFAULT_SETTING.levelThresholdList[0].topOccupancyLevel.toString();
      bottomOccupancyLevel = DEFAULT_SETTING.levelThresholdList[0].bottomOccupancyLevel.toString();
    }

    // 現在の日付と時刻を日本標準時 (JST) タイムゾーンで取得し、フォーマットする
    const startTimeInJST = dayjs(startDate).tz('Asia/Tokyo').format();
    const endTimeInJST = dayjs(params.date).tz('Asia/Tokyo').format();
    // console.log('Start Time:', startTimeInJST); //例2024-02-28T10：20：15+09：00
    // console.log('End Time:', endTimeInJST); //例2024-02-28T10：20：15+09：00
    const deviceIds = params.deviceIds;
    const freqencyBand = params.frequencyBand;
    const dataType = params.dataType;

    return await this.rwmDataRepository.fetchChannel(
      startTimeInJST,
      endTimeInJST,
      deviceIds,
      dataType,
      params.heightMin,
      params.heightMax,
      params.timeDiv,
      freqencyBand,
      noiseCalcMethod,
      occupancyCalcMethod,
      noiseDataSource,
      topNoiseLevel,
      topOccupancyLevel,
      bottomNoiseLevel,
      bottomOccupancyLevel,
      params.isChannel
    );
  }
}

export class FetchChannelDataParams {
  constructor(
    public date: Dayjs,
    public spans: string,
    public deviceIds: string[],
    public dataType: string,
    public heightMin: number,
    public heightMax: number,
    public timeDiv: number,
    public frequencyBand: string,
    public setting: SettingType,
    public isChannel: boolean
  ) {}
}

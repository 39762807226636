import { UseCase } from 'core/usecases/usecase';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { RwmDataRepository } from 'domain/types/rwm';
import { DeviceData } from 'domain/types/device';

export class FetchDeviceData implements UseCase<Promise<DeviceData[]>, FetchDeviceDataParames> {
  private rwmDataRepository: RwmDataRepository;
  constructor(rwmDataRepository: RwmDataRepository) {
    this.rwmDataRepository = rwmDataRepository;
    // プラグインを使用できるように設定
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  public async call(params: FetchDeviceDataParames): Promise<DeviceData[]> {
    const startDate = params.date.add(-1 * Number(params.spans), 'second');

    // 現在の日付と時刻を日本標準時（JST）タイムゾーンで取得し、フォーマットする
    const startTimeInJST = dayjs(startDate).tz('Asia/Tokyo').format();
    const endTimeInJST = dayjs(params.date).tz('Asia/Tokyo').format();
    // console.log('Start Time:', startTimeInJST); //例2024-02-28T10：20：15+09：00
    // console.log('End Time:', endTimeInJST); //例2024-02-28T10：20：15+09：00
    const deviceId = params.deviceId;

    return await this.rwmDataRepository.fetchDeviceData(startTimeInJST, endTimeInJST, deviceId);
  }
}

export class FetchDeviceDataParames {
  constructor(
    public date: Dayjs,
    public spans: string,
    public deviceId: string
  ) {}
}

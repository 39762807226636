import React, { useContext } from 'react';
import CustomIconButton from 'components/Common/CustomIconButton';
import MapIcon from '@mui/icons-material/Map';
import { RwmMapAreaContext } from '../RwmMapContext';

const OpenSearchMapWindowButton = () => {
  const { setIsOpenedSearchWindow } = useContext(RwmMapAreaContext);

  return (
    <CustomIconButton
      onClick={() => setIsOpenedSearchWindow(true)}
      Icon={MapIcon}
      tooltipTitle={'地図からエリアの選択'}
      size='small'
    ></CustomIconButton>
  );
};

export default React.memo(OpenSearchMapWindowButton);

import { FetchDeviceDataParames } from 'domain/usecases/fetcfDeviceData';
import { FetchChannelDataParams } from 'domain/usecases/fetchChannelData';
import { FetchFreqGraphDataParames } from 'domain/usecases/fetchFreqGraphData';
import { FetchRmsDataParames } from 'domain/usecases/fetchRmsData';
import {
  fetchChannelDataUsecase,
  fetchDeviceDataUsecase,
  fetchFreqGraphDataUsecase,
  fetchRmsDataUsecase,
} from 'radioMonitoringPage';

/**
 * SWR用URL
 */
export const SWR_URLS = {
  CHANNEL: '/channel',
  DEVICE: '/device',
  RMS: '/rms',
  FREQUENCY_GRAPH: '/frequencygraph',
};

/**
 * Iotデータを取得する処理
 * @param {FetchRmsDataParames} params パラメータ
 * @returns
 */
export const handleGetRmsData = async (params: FetchRmsDataParames) => {
  const rmsData = await fetchRmsDataUsecase.call(params);
  return rmsData;
};

/**
 * デバイスデータを取得する処理
 * @param {FetchDeviceDataParames} params パラメータ
 * @returns
 */
export const handleGetDeviceData = async (params: FetchDeviceDataParames) => {
  const rmsData = await fetchDeviceDataUsecase.call(params);
  return rmsData;
};

/**
 * Frequencyグラフデータを取得する処理
 * @param {FetchFreqGraphDataParames} params パラメータ
 * @returns
 */
export const handleGetFreqGraphData = async (params: FetchFreqGraphDataParames) => {
  const frequencyGraphData = await fetchFreqGraphDataUsecase.call(params);
  return frequencyGraphData;
};

/**
 * 推奨チャンネルデータを取得する処理
 * @param {FetchChannelDataParams} params パラメータ
 * @returns
 */
export const handleGetChannelData = async (params: FetchChannelDataParams) => {
  const channelData = await fetchChannelDataUsecase.call(params);
  return channelData;
};

import React, { useContext, useState } from 'react';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  InputLabel,
  FormControl,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MAIN_MAP_ID, PIN_TYPE, PinActionType } from 'domain/types/map';
import SetUpDialogWindow from 'components/SetUpDialogWindow/SetUpDialogWindow';
import { InputLabelStyle, SelectStyle } from 'domain/types/sxProps';
import { CustomSlider } from 'components/Common/CustomSlider';
import { RwmMapTabContext } from './RwmMapContext';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { getMapLabel, getSelectedMap } from 'utils/extract';
import { useMaps } from 'MapsContext';
import TrackButton from './Button/TrackButton';
import GpsButton from './Button/GpsButton';

export const MapHeader = React.memo(() => {
  const { t } = useTranslation();
  const { mapsData } = useMaps();
  const { handleMapSelect, selectedMapId, allMapIds, workMode, isEnableGps } = useContext(LayoutContext);

  const mapBox: string = t('マップ');
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);
  const [pinActionType, setPinActionType] = useState<PinActionType>('create');
  const [selectedPinType, setSelectedPinType] = useState<number>(PIN_TYPE.UnitPin);

  /**
   * @description マップのコンボボックス選択時の処理
   */
  const handleChangeMapBox = (e: SelectChangeEvent) => {
    const tmpMapId = e.target.value as string;
    if (tmpMapId) {
      const index = allMapIds.indexOf(tmpMapId);
      if (index !== -1) {
        handleMapSelect(tmpMapId);
      } else {
        console.warn(t('選択したマップは存在しません'));
        handleMapSelect(MAIN_MAP_ID);
      }
    }
  };

  /**
   * @description グループ追加時の処理
   */
  const handleClickMapPinAdd = (pinType: number, pinAction: PinActionType) => {
    setPinActionType(pinAction);
    setSelectedPinType(pinType);
    setIsOpenedDialog(true);
  };

  /**
   * @description ダイアログを閉じる処理
   */
  const handleClickClosedDialog = () => {
    setIsOpenedDialog(false);
  };

  return (
    <Stack
      direction='row'
      spacing={2}
      sx={{
        height: 1 / 10,
        width: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {workMode === 'Track' && (
        <Box
          sx={{
            position: 'absolute',
            fontSize: 20,
            left: 10,
          }}
        >
          {t('移動モード')}
        </Box>
      )}
      {allMapIds.length > 0 && (
        <FormControl disabled={workMode === 'Track' ? true : false} sx={{ width: 300 }}>
          <InputLabel
            sx={{
              ...InputLabelStyle,
            }}
          >
            {mapBox}
          </InputLabel>
          {/* NOTE: CustomMapSelect を利用したかったが、推奨チャンネルと必要な機能が異なる為、sx のみ揃える */}
          <Select
            onChange={handleChangeMapBox}
            placeholder={mapBox}
            value={selectedMapId}
            label={<span style={{ color: '#FFFFFF' }}>{mapBox}</span>}
            sx={{
              ...SelectStyle,
              height: 45,
            }}
          >
            {allMapIds.map((id) => {
              return (
                <MenuItem key={`mapBoxList-${id}`} value={id}>
                  {/* 表示する値はフロアピンのラベルの値だが、実際に変数にセットされるのは ID の値である */}
                  {mapsData ? (id === MAIN_MAP_ID ? id : getMapLabel(mapsData, id)) : id}
                </MenuItem>
              );
            })}
            <MenuItem
              value={''}
              onClick={() => {
                handleClickMapPinAdd(PIN_TYPE.GroupPin, 'create');
              }}
            >
              {t('+ (グループを追加)')}
            </MenuItem>
          </Select>
        </FormControl>
      )}
      {isOpenedDialog && (
        <SetUpDialogWindow
          isOpenedDialog={isOpenedDialog}
          onClosedDialog={handleClickClosedDialog}
          selectedPinType={selectedPinType}
          pinActionType={pinActionType}
          isEnableGps={isEnableGps}
        />
      )}
      <TrackButton />
      <GpsButton />
    </Stack>
  );
});

MapHeader.displayName = 'MapHeader';

// ------------------------------
export const MapEditHeader = React.memo(() => {
  const { t } = useTranslation();
  const { mapsData } = useMaps();
  const { selectedMapId } = useContext(LayoutContext);
  const { tmpImageWidth, tmpImageHeight, tmpImageRotate, setTmpImageWidth, setTmpImageHeight, setTmpImageRotate } =
    useContext(RwmMapTabContext);
  const selectedMap = getSelectedMap(mapsData, selectedMapId);

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center', height: 1 / 10 }}
    >
      <Box
        sx={{
          fontSize: 20,
          width: '20%',
        }}
      >
        {t('マップ編集モード')}
      </Box>
      {selectedMap.mapType === 'imageMap' && (
        <>
          <Stack
            direction='column'
            sx={{
              width: '30%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            spacing={0}
          >
            <Grid container sx={{ p: 0, m: 0 }} alignItems='center'>
              <Grid item sx={{ width: '15%' }}>
                <Typography gutterBottom>{t('幅')}</Typography>
              </Grid>
              <Grid item sx={{ width: '80%' }}>
                <CustomSlider
                  defaultValue={320}
                  value={tmpImageWidth}
                  min={10}
                  max={960}
                  onChange={(e, value) => {
                    setTmpImageWidth(value as number);
                  }}
                  onChangeCommitted={undefined}
                  isLabel={true}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ p: 0, m: 0 }} alignItems='center'>
              <Grid item sx={{ width: '15%' }}>
                <Typography gutterBottom>{t('高さ')}</Typography>
              </Grid>
              <Grid item sx={{ width: '80%' }}>
                <CustomSlider
                  defaultValue={240}
                  value={tmpImageHeight}
                  min={10}
                  max={960}
                  onChange={(e, value) => {
                    setTmpImageHeight(value as number);
                  }}
                  onChangeCommitted={undefined}
                  isLabel={true}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction='column'
            sx={{
              width: '30%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            spacing={0}
          >
            <Grid container sx={{ p: 0, m: 0 }} alignItems='center'>
              <Grid item sx={{ width: '15%' }}>
                <Typography gutterBottom>{t('回転')}</Typography>
              </Grid>
              <Grid item sx={{ width: '80%' }}>
                <CustomSlider
                  defaultValue={0}
                  value={tmpImageRotate}
                  min={0}
                  max={360}
                  onChange={(e, value) => {
                    setTmpImageRotate(value as number);
                  }}
                  onChangeCommitted={undefined}
                  isLabel={true}
                />
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
    </Stack>
  );
});

MapEditHeader.displayName = 'MapEditHeader';

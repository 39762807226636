import React, { useContext, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HeightIcon from '@mui/icons-material/Height';
import { CssVarsProvider } from '@mui/joy/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  LegendItem,
  LegendElement,
  ChartEvent,
  LineController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  SelectedFrequencyBandContext,
  DateTimeContext,
  SelectedSpanContext,
  IsSyncContext,
  PlayingSpeedIndexContext,
} from '../../../../radioMonitoringPage';
import { createChartToolTipContent, createChartToolTipSetting, rgbaToRgb } from '../../../../utils/format';
// import { TEST_GRAPH_FREQUENCY } from '../../../../domain/types/testData';
import { FREQUENCIES, FREQUENCY_BAND } from '../../../../domain/types/frequency';
import CustomIconButton from '../../../Common/CustomIconButton';
import { FetchFreqGraphDataParames } from 'domain/usecases/fetchFreqGraphData';
import { getDateTimeWithTimeOffset } from 'utils/dateHelper';
import useSWR from 'swr';
import { FETCH_FAILED } from 'core/commons/messages';
import {
  ChartDataIndex,
  DEFAULT_OCCUPANCY_RANGE,
  DEFAULT_RSSI_RANGE,
  FrequencyGraphData,
  TOOLTIP_DISPLAY_TIME,
} from 'domain/types/graph';
import { handleGetFreqGraphData, SWR_URLS } from 'utils/swrWrapperApi';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { getExtremesIndicesAcrossDatasets } from 'utils/extract';
import DialogYAxis from './DialogYAxis';
import { PLAYING_SPEED } from 'domain/types/common/consts';

ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  zoomPlugin
);

interface Props {
  graphHeight: number;
  yMax1: number;
  yMin1: number;
  setYMax1: (max: number) => void;
  setYMin1: (max: number) => void;
  yMax2: number;
  yMin2: number;
  setYMax2: (max: number) => void;
  setYMin2: (max: number) => void;
}

export const FrequencyGraph = ({
  graphHeight,
  yMax1,
  yMin1,
  setYMax1,
  setYMin1,
  yMax2,
  yMin2,
  setYMax2,
  setYMin2,
}: Props) => {
  const { t } = useTranslation();
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { dateTime } = useContext(DateTimeContext);
  const { selectedSpan } = useContext(SelectedSpanContext);
  const { selectedPinIdsForGraph } = useContext(LayoutContext);
  const { isSync } = useContext(IsSyncContext);
  const { playingSpeedIndex } = useContext(PlayingSpeedIndexContext);

  const [openDialog, setOpenDialog] = useState(false);
  const chartRef = useRef<ChartJS<'line', number[], unknown>>();

  // パラメーター生成関数
  const generateParams = (timeoffset: number, pinId: string) =>
    new FetchFreqGraphDataParames(
      getDateTimeWithTimeOffset(
        dateTime,
        Number(selectedSpan) * PLAYING_SPEED[playingSpeedIndex.current ?? 0],
        timeoffset,
        isSync
      ),
      selectedSpan,
      pinId,
      selectedFrequencyBand
    );

  // データ取得用SWフック（初回）
  const pinId = selectedPinIdsForGraph[0] ? selectedPinIdsForGraph[0] : '';
  const params = generateParams(0, pinId);
  // console.log(params);
  const key = JSON.stringify(params);
  const { data, error } = useSWR(pinId ? [(SWR_URLS.FREQUENCY_GRAPH, key)] : null, () =>
    handleGetFreqGraphData(params)
  );

  // 次データ取得用SWR フック（3つ先まで取得する）
  const nextParams1 = generateParams(1, pinId);
  // console.log(nextParams1);
  const nextKey1 = JSON.stringify(nextParams1);
  useSWR(pinId ? [SWR_URLS.FREQUENCY_GRAPH, nextKey1] : null, () => handleGetFreqGraphData(nextParams1));

  const nextParams2 = generateParams(2, pinId);
  const nextKey2 = JSON.stringify(nextParams2);
  useSWR(pinId ? [SWR_URLS.FREQUENCY_GRAPH, nextKey2] : null, () => handleGetFreqGraphData(nextParams2));

  const nextParams3 = generateParams(3, pinId);
  const nextKey3 = JSON.stringify(nextParams3);
  useSWR(pinId ? [SWR_URLS.FREQUENCY_GRAPH, nextKey3] : null, () => handleGetFreqGraphData(nextParams3));

  // エラーハンドリング
  if (error) {
    console.log(error);
    alert(t(FETCH_FAILED));
  }

  // NOTE: 凡例で選択中かを保持するステート
  const isCheckedLegendItems: boolean[] = new Array(4).fill(false);

  const handleZoomResetButtonClick = () => {
    const chart = chartRef.current;
    if (chart) {
      chart.resetZoom();
    }
  };

  // NOTE: 凡例のクリックイベント処理
  const clickLegendItem = (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) => {
    const selectedItemIndex: number = legendItem.datasetIndex ? legendItem.datasetIndex : 0;
    // NOTE: 選択できるのは1件のみ
    const lastSelectedIndex: number = isCheckedLegendItems.findIndex((element) => element == true);
    if (lastSelectedIndex == -1) {
      // NOTE: 選択中の周波数が存在しなかった場合の処理
      isCheckedLegendItems[selectedItemIndex] = true;
    } else {
      // NOTE: 選択中の周波数が存在した場合の処理
      isCheckedLegendItems[lastSelectedIndex] = false;
      if (lastSelectedIndex == selectedItemIndex) return;
      isCheckedLegendItems[selectedItemIndex] = true;
      // NOTE: 前回選択中だった周波数は薄める
      let color: string | undefined = legend.chart.data.datasets[lastSelectedIndex].borderColor?.toString();
      color = color?.replace(/rgb|\(|\)/g, '');
      color = 'rgba(' + color + ', 0.1)';
      legend.chart.data.datasets[lastSelectedIndex].borderColor = color;
      // NOTE: 選択先の周波数の色を元に戻す
      color = legend.chart.data.datasets[selectedItemIndex].borderColor?.toString();
      color = color ? rgbaToRgb(color) : color;
      legend.chart.data.datasets[selectedItemIndex].borderColor = color;
    }
    legend.chart.update();
  };
  // NOTE: 凡例のホバーイベント処理
  const hoverLegendItem = (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) => {
    const selectedItemIndex: number = legendItem.datasetIndex ? legendItem.datasetIndex : 0;
    // NOTE：選択中の凡例がある場合なにもしない
    if (isCheckedLegendItems.find((element) => element == true) == true) {
      return;
    }
    legend.chart.data.datasets.forEach((value, index) => {
      if (index == selectedItemIndex) return;
      if (isCheckedLegendItems[index] == true) return;
      let color: string | undefined = legend.chart.data.datasets[index].borderColor?.toString();
      // NOTE: 選択中以外の線は薄める
      color = color?.replace(/rgb|\(|\)/g, '');
      color = 'rgba(' + color + ', 0.1)';
      legend.chart.data.datasets[index].borderColor = color;
    });
    legend.chart.update();
  };

  // NOTE: 凡例のリーブイベント処理
  const leaveLegendItem = (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) => {
    const selectedItemIndex: number = legendItem.datasetIndex ? legendItem.datasetIndex : 0;
    // NOTE：選択中の凡例がある場合なにもしない
    if (isCheckedLegendItems.find((element) => element == true) == true) {
      return;
    }
    legend.chart.data.datasets.forEach((value, index) => {
      if (index == selectedItemIndex) return;
      let color: string | undefined = legend.chart.data.datasets[index].borderColor?.toString();
      // NOTE: 色を初期状態に戻す
      color = color ? rgbaToRgb(color) : color;
      legend.chart.data.datasets[index].borderColor = color;
    });
    legend.chart.update();
  };

  const showTooltipAtExtremes = () => {
    const chartInstance = chartRef.current;
    if (chartInstance) {
      let datasets = chartInstance.data.datasets;
      if (datasets[0].data.length == 0) return;
      // ピックアップ中のindexを取得
      const pickUpDataSetIndex = isCheckedLegendItems.findIndex((value) => value == true);
      if (pickUpDataSetIndex >= 0) {
        datasets = [datasets[pickUpDataSetIndex]];
      }
      if (pickUpDataSetIndex >= 0) {
        // ピックアップされている場合はピックアップされている折れ線のみを対象とする
        const result1 = getExtremesIndicesAcrossDatasets([datasets[0]]);
        const maxIndex: ChartDataIndex = { datasetIndex: pickUpDataSetIndex, index: result1.maxIndex.index };
        const minIndex: ChartDataIndex = { datasetIndex: pickUpDataSetIndex, index: result1.minIndex.index };
        drawCustomTooltip(chartInstance, maxIndex, 'Peak Max');
        drawCustomTooltip(chartInstance, minIndex, 'Peak Min');
      } else {
        // Rssiのみの表示とする
        // 1つ目の折れ線
        const result1 = getExtremesIndicesAcrossDatasets([datasets[0]]);
        let maxIndex: ChartDataIndex = { datasetIndex: 0, index: result1.maxIndex.index };
        let minIndex: ChartDataIndex = { datasetIndex: 0, index: result1.minIndex.index };
        drawCustomTooltip(chartInstance, maxIndex, 'Peak Max');
        drawCustomTooltip(chartInstance, minIndex, 'Peak Min');

        // 2つ目の折れ線
        const result2 = getExtremesIndicesAcrossDatasets([datasets[1]]);
        maxIndex = { datasetIndex: 1, index: result2.maxIndex.index };
        minIndex = { datasetIndex: 1, index: result2.minIndex.index };
        drawCustomTooltip(chartInstance, maxIndex, 'Peak Max');
        drawCustomTooltip(chartInstance, minIndex, 'Peak Min');
      }
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const drawCustomTooltip = (
    chart: ChartJS<'line', number[], unknown> | undefined,
    targetPoint: ChartDataIndex,
    title: string
  ): void => {
    if (!chart) return;

    const metaDataPoint = chart.getDatasetMeta(targetPoint.datasetIndex).data[targetPoint.index];
    const { x, y } = metaDataPoint.getProps(['x', 'y'], true);

    const pixelRatio = chart.currentDevicePixelRatio;
    const canvasRect = chart.canvas.getBoundingClientRect();

    const tooltipElement = document.createElement('div');

    // データセット情報取得
    const dataset = chart.data.datasets[targetPoint.datasetIndex];

    Object.assign(tooltipElement.style, createChartToolTipSetting(canvasRect.left, canvasRect.top, pixelRatio, x, y));

    // 凡例とデータ値を設定
    tooltipElement.innerHTML = createChartToolTipContent(
      title,
      `${dataset.borderColor}`,
      '',
      dataset.data[targetPoint.index].toString(),
      dataset.label || ''
    );

    document.body.appendChild(tooltipElement);

    setTimeout(() => {
      document.body.removeChild(tooltipElement);
    }, TOOLTIP_DISPLAY_TIME);
  };

  const options = {
    animation: {
      duration: 0, // アニメーション無効
    },
    responsive: true,
    plugins: {
      //凡例の設定
      legend: {
        onClick: clickLegendItem,
        onHover: hoverLegendItem,
        onLeave: leaveLegendItem,
        position: 'right' as const,
        labels: {
          color: '#fff',
        },
      },

      tooltip: {
        intersect: false,
        mode: 'nearest' as const,
      },
      //ズーム
      zoom: {
        pan: {
          enabled: true,
          mode: 'x' as const,
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        } as const,
      },
    },
    scales: {
      //x軸関連
      x: {
        grid: {
          drawOnChartArea: false,
          color: '#fff', //borderの色
        },
        ticks: {
          color: '#fff',
          font: {
            size: 16,
          },
        },
        title: {
          display: true,
          text: t('Freq' + (selectedFrequencyBand == FREQUENCY_BAND.FREQUENCY_920M.ID ? '[MHz]' : '[GHz]')),
          color: '#fff',
          font: {
            size: 16,
          },
        },
      },

      //y軸関連
      y: {
        grid: {
          drawOnChartArea: false,
          color: '#fff',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 16,
          },
        },
        title: {
          display: true,
          text: t('RSSI[dBm]'),
          color: '#fff',
          font: {
            size: 16,
          },
        },
        position: 'left' as const,
        max: yMax1,
        min: yMin1,
      },
      y1: {
        ticks: {
          color: '#fff',
          font: {
            size: 16,
          },
        },
        title: {
          display: true,
          text: t('占有率[%]'),
          color: '#fff',
          font: {
            size: 16,
          },
        },
        position: 'right' as const,
        min: yMin2,
        max: yMax2,
      },
    },
  };

  let graphData: FrequencyGraphData = {
    freqList: [],
    rssiMaxList: [],
    rssiMinList: [],
    ratioMaxList: [],
    ratioAveList: [],
    ratioMinList: [],
  };
  graphData = data ? data : graphData;

  const lineData = {
    labels:
      graphData.freqList.length == 0
        ? FREQUENCIES[selectedFrequencyBand].list.map((freq) =>
            freq.frequency < 1000 ? `${freq.frequency.toFixed(1)}` : `${(freq.frequency / 1000).toFixed(3)}`
          )
        : graphData.freqList.map((freq) => (freq < 1000 ? `${freq.toFixed(1)}` : `${(freq / 1000).toFixed(3)}`)),
    datasets: [
      {
        label: t('RssiMax'),
        data: graphData.rssiMaxList,
        yAxisID: 'y',
        pointRadius: 0,
      },
      {
        label: t('RssiMin'),
        data: graphData.rssiMinList,
        yAxisID: 'y',
        pointRadius: 0,
      },
      {
        label: t('占有率（最大）'),
        data: graphData.ratioMaxList,
        yAxisID: 'y1',
        pointRadius: 0,
      },
      {
        label: t('占有率（平均）'),
        data: graphData.ratioAveList,
        yAxisID: 'y1',
        pointRadius: 0,
      },
      {
        label: t('占有率（最小）'),
        data: graphData.ratioMinList,
        yAxisID: 'y1',
        pointRadius: 0,
      },
    ],
  };
  return (
    <Box>
      <Box justifyContent={'right'} display='flex'>
        <Stack direction='row' spacing={2}>
          <CustomIconButton
            onClick={() => showTooltipAtExtremes()}
            Icon={QueryStatsIcon}
            tooltipTitle={t('ピーク検索')}
          />
          <CustomIconButton onClick={handleOpenDialog} Icon={HeightIcon} tooltipTitle={t('Y軸レンジ')} />
          <DialogYAxis
            open={openDialog}
            onClose={() => {
              if (chartRef.current) {
                chartRef.current.update();
              }
              setOpenDialog(false);
            }}
            title1='RSSI'
            initialMinValue1={yMin1}
            initialMaxValue1={yMax1}
            setYAxisMin1={setYMin1}
            setYAxisMax1={setYMax1}
            originalMin1={DEFAULT_RSSI_RANGE.MIN}
            originalMax1={DEFAULT_RSSI_RANGE.MAX}
            title2='占有率'
            initialMinValue2={yMin2}
            initialMaxValue2={yMax2}
            setYAxisMin2={setYMin2}
            setYAxisMax2={setYMax2}
            originalMin2={DEFAULT_OCCUPANCY_RANGE.MIN}
            originalMax2={DEFAULT_OCCUPANCY_RANGE.MAX}
          />
          <CustomIconButton
            onClick={handleZoomResetButtonClick}
            Icon={YoutubeSearchedForIcon}
            tooltipTitle={t('ズームリセット')}
          />
        </Stack>
      </Box>
      <CssVarsProvider>
        <AspectRatio variant='plain' ratio='2/1' sx={{ maxWidth: `${graphHeight * 0.9 * 2}px` }}>
          <Chart type='line' options={options} data={lineData} ref={chartRef} />
        </AspectRatio>
      </CssVarsProvider>
    </Box>
  );
};

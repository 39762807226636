import React, { type Dispatch, useState, useEffect } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, styled, Typography, Tooltip, Toolbar, AppBar, Stack } from '@mui/material';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import UpdateIcon from '@mui/icons-material/Update';
import SaveIcon from '@mui/icons-material/Save';
import BrightnessLowIcon from '@mui/icons-material/BrightnessLow';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import Select from '../Common/CustomSelect';
import { DialogDateTime } from './DialogDateTime';
import dayjs, { Dayjs } from 'dayjs';
import StopIcon from '@mui/icons-material/Stop';
import { DialogSetting } from './DialogSetting';
import { SPAN, SPAN_LABEL, DATA_SOURCE, DATA_SOURCE_LABEL, PLAYING_SPEED } from '../../domain/types/common/consts';
import { useTranslation } from 'react-i18next';
import { calcSecondsInDay, getDateFromSeconds, getSecondsFromDate } from '../../utils/dateHelper';
import CustomIconButton from '../Common/CustomIconButton';
import { CustomSlider } from '../Common/CustomSlider';
import { FREQUENCY_BAND } from '../../domain/types/frequency';
import { Gateway } from 'domain/types/gateway';
import { SettingType } from 'domain/types/setting';
import { getLabelFromCalcmethod, getPlayingSpeedLabel } from 'utils/format';
import { DialogSerial } from './DialogSerial';

interface Props {
  gateway: string;
  setGateway: Dispatch<React.SetStateAction<string>>;
  frequencyBand: string;
  setFrequencyBand: Dispatch<React.SetStateAction<string>>;
  span: string;
  setSpan: Dispatch<React.SetStateAction<string>>;
  source: string;
  setSource: Dispatch<React.SetStateAction<string>>;
  dateTime: Dayjs;
  setDateTime: Dispatch<React.SetStateAction<Dayjs>>;
  gateways: Gateway[] | undefined;
  isPlaying: boolean;
  setIsPlaying: Dispatch<React.SetStateAction<boolean>>;
  isSync: boolean;
  setIsSync: Dispatch<React.SetStateAction<boolean>>;
  displayDateTime: Dayjs;
  setDisplayDateTime: Dispatch<React.SetStateAction<Dayjs>>;
  setting: SettingType;
  playingSpeedIndex: React.MutableRefObject<number>;
}

const ToolBar = ({
  gateway,
  setGateway,
  frequencyBand,
  setFrequencyBand,
  span,
  setSpan,
  source,
  setSource,
  dateTime,
  setDateTime,
  gateways,
  isPlaying,
  setIsPlaying,
  isSync,
  setIsSync,
  displayDateTime,
  setDisplayDateTime,
  setting,
  playingSpeedIndex,
}: Props) => {
  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number>(getSecondsFromDate(dateTime));
  const [isSettingModalOpen, setIsSettingModalOpen] = useState<boolean>(false);
  const [isSerialModalOpen, setIsSerialModalOpen] = useState<boolean>(false);
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>();

  const sliderMin = 0;
  const sliderMax = 86399;
  const { t } = useTranslation();

  const frequencyBands = [
    { id: FREQUENCY_BAND.FREQUENCY_920M.ID, name: FREQUENCY_BAND.FREQUENCY_920M.LABEL },
    { id: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, name: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.LABEL },
    { id: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, name: FREQUENCY_BAND.FREQUENCY_WLAN5G.LABEL },
    { id: FREQUENCY_BAND.FREQUENCY_L5G.ID, name: FREQUENCY_BAND.FREQUENCY_L5G.LABEL },
  ];

  const spans = [
    { id: SPAN.ONE_MINUTES, name: SPAN_LABEL.ONE_MINUTES },
    { id: SPAN.TEN_MINUTES, name: SPAN_LABEL.TEN_MINUTES },
    { id: SPAN.ONE_HOURS, name: SPAN_LABEL.ONE_HOURS },
    { id: SPAN.TWO_HOURS, name: SPAN_LABEL.TWO_HOURS },
    { id: SPAN.FOUR_HOURS, name: SPAN_LABEL.FOUR_HOURS },
    { id: SPAN.EIGHT_HOURS, name: SPAN_LABEL.EIGHT_HOURS },
    { id: SPAN.TWELVE_HOURS, name: SPAN_LABEL.TWELVE_HOUS },
    { id: SPAN.TWENTY_FOUR_HOURS, name: SPAN_LABEL.TWENTYFOUR_HOURS },
  ];

  const dataSources = [
    { id: DATA_SOURCE.NOISE, name: DATA_SOURCE_LABEL.NOISE },
    { id: DATA_SOURCE.OCCUPANCY_RATE, name: DATA_SOURCE_LABEL.OCCUPANCY_RATE },
  ];

  //NOTE: 再生ボタン、リアルタイム表示連動（リアルタイム更新時に設定日時は15秒ごとの更新とする）
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let intervalId2: NodeJS.Timeout;
    if (isPlaying && !isSync) {
      intervalId = setInterval(() => {
        const addSeconds = (Number(span) / 12) * PLAYING_SPEED[playingSpeedIndex.current ?? 0];
        setDateTime((prev) => prev.add(addSeconds, 'second'));
        setDisplayDateTime((prev) => prev.add(addSeconds, 'second'));
        setSliderValue((prev) => calcSecondsInDay(prev, addSeconds));
      }, 1000);
    } else if (isPlaying && isSync) {
      setDateTime(dayjs());
      intervalId = setInterval(() => {
        setDisplayDateTime(dayjs());
        setSliderValue(getSecondsFromDate(dayjs()));
      }, 1000);
      intervalId2 = setInterval(() => {
        setDateTime(dayjs());
      }, 15000);
    }
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };
  }, [isPlaying, setDateTime, setDisplayDateTime, span, isSync, playingSpeedIndex]);

  //スライダーイベント
  const sliderComitted = (newValue: number | number[]) => {
    setSliderValue(newValue as number);
    const yyyyMMdd = displayDateTime?.format('YYYY-MM-DD');
    const hhmmss = getDateFromSeconds(newValue as number);
    setDateTime(dayjs(yyyyMMdd + ' ' + dayjs(hhmmss).format('HH:mm:ss')));
    setDisplayDateTime(dayjs(yyyyMMdd + ' ' + dayjs(hhmmss).format('HH:mm:ss')));
  };
  //日時設定イベント
  const onSubmit = (value: Dayjs) => {
    setDisplayDateTime(value);
    setDateTime(value);
  };
  //再生ボタンイベント
  const handlePlayButtonClick = () => {
    if (playingSpeedIndex) {
      playingSpeedIndex.current = 0;
    }
    setIsPlaying(!isPlaying);
    if (isSync) {
      setIsSync(false);
    }
  };
  //リアルタイム表示イベント
  const handleRealTimeButtonClick = () => {
    if (isSync) {
      setIsPlaying(false);
      setIsSync(false);
    } else {
      setIsPlaying(true);
      setIsSync(true);
    }
  };
  //再生速度切替イベント
  const handleChangePlayingSpeed = () => {
    if (PLAYING_SPEED.length - 1 > playingSpeedIndex.current) {
      playingSpeedIndex.current += 1;
    } else {
      playingSpeedIndex.current = 0;
    }
  };
  //日時スキップクリックイベント
  const handleClickSkipDate = (addSeconds: number) => {
    if (!clickTimeout) {
      const timeoutId = setTimeout(() => {
        addDate(addSeconds);
        setClickTimeout(null);
      }, 250); // 適切な間隔 (ms) を設定
      setClickTimeout(timeoutId);
    }
  };
  //日時スキップダブルクリックイベント
  const handleDoubleClickSkipDate = (addSeconds: number) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout); // シングルクリック処理をキャンセル
      setClickTimeout(null);
    }
    addDate(addSeconds);
  };
  const addDate = (addSeconds: number) => {
    setDateTime((prev) => prev.add(addSeconds, 'second'));
    setDisplayDateTime((prev) => prev.add(addSeconds, 'second'));
    setSliderValue((prev) => calcSecondsInDay(prev, addSeconds));
  };

  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const logout = () => {
    signOut();
    navigate('/');
  };

  const TinyText = styled(Typography)({
    fontSize: '0.75rem',
    opacity: 0.38,
    fontWeight: 500,
    letterSpacing: 0.2,
  });

  return (
    <AppBar position='fixed' color='primary'>
      <Box paddingRight={0} marginInline={'20px'} marginTop={'5px'}>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Toolbar style={{ paddingRight: 0, minHeight: '100px' }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Select
                  id='gateway'
                  title={t('ゲートウェイ')}
                  value={gateway}
                  setValue={setGateway}
                  options={gateways ? gateways : []}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  id='frequencyBand'
                  title={t('周波数バンド')}
                  value={frequencyBand}
                  setValue={setFrequencyBand}
                  options={frequencyBands}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={5} sx={{ overflow: 'hidden' }}>
                <Tooltip title={t('時刻')}>
                  <Button
                    onClick={() => setIsDateTimeModalOpen(true)}
                    sx={{ fontSize: '14px', color: '#FFFFFF', whiteSpace: 'nowrap' }}
                  >
                    {displayDateTime?.toDate().toLocaleString()}
                  </Button>
                </Tooltip>

                <DialogDateTime
                  open={isDateTimeModalOpen}
                  onClose={() => setIsDateTimeModalOpen(false)}
                  dateTime={displayDateTime}
                  onSubmit={(value) => onSubmit(value)}
                  setSliderValue={setSliderValue}
                  selectedFrequencyBand={frequencyBand}
                />
              </Grid>

              <Grid item xs={7}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', gap: 1 }}>
                  {/* gapで隙間設定 */}
                  <CustomIconButton
                    onClick={() => handleClickSkipDate(-1 * Number(span))}
                    onDoubleClick={() => handleDoubleClickSkipDate(-1 * Number(span) * 6)}
                    Icon={KeyboardDoubleArrowLeftIcon}
                    tooltipTitle='逆方向スキップ（大）'
                  />
                  <CustomIconButton
                    onClick={() => handleClickSkipDate((-1 * Number(span)) / 6)}
                    onDoubleClick={() => handleDoubleClickSkipDate(-1 * Number(span))}
                    Icon={KeyboardArrowLeftIcon}
                    tooltipTitle='逆方向スキップ（小）'
                  />
                  <CustomIconButton
                    onClick={handlePlayButtonClick}
                    Icon={isPlaying ? StopIcon : PlayArrowSharpIcon}
                    tooltipTitle='再生'
                  />
                  <CustomIconButton
                    onClick={handleChangePlayingSpeed}
                    Icon={DoubleArrowIcon}
                    tooltipTitle='速度切替（x1 → x5 → x10 → x30 → x1）'
                    disabled={isPlaying == false || (isPlaying && isSync)}
                  />
                  <CustomIconButton
                    onClick={() => handleClickSkipDate(Number(span) / 6)}
                    onDoubleClick={() => handleDoubleClickSkipDate(Number(span))}
                    Icon={KeyboardArrowRightIcon}
                    tooltipTitle='前方向スキップ（小）'
                  />
                  <CustomIconButton
                    onClick={() => handleClickSkipDate(Number(span))}
                    onDoubleClick={() => handleDoubleClickSkipDate(Number(span) * 6)}
                    Icon={KeyboardDoubleArrowRightIcon}
                    tooltipTitle='前方向スキップ（大）'
                  />
                  <CustomIconButton
                    onClick={handleRealTimeButtonClick}
                    Icon={UpdateIcon}
                    tooltipTitle='リアルタイム更新'
                    isEnabled={isSync}
                  />
                </Box>
              </Grid>

              {/* <Grid item xs={1}></Grid> */}

              <Grid item xs={9}>
                <Grid container>
                  <Grid item xs={1}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                      <CustomIconButton
                        onClick={() => handleClickSkipDate(-1 * Number(SPAN.TWENTY_FOUR_HOURS))}
                        Icon={KeyboardDoubleArrowLeftIcon}
                        tooltipTitle='前の日へ'
                        size='extraSmall'
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={t('時刻選択')}>
                          <Box width={'90%'}>
                            <CustomSlider
                              defaultValue={sliderValue}
                              value={sliderValue}
                              min={sliderMin}
                              max={sliderMax}
                              onChangeCommitted={(event, newValue) => sliderComitted(newValue)}
                            />
                          </Box>
                        </Tooltip>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mt: -2,
                        }}
                      >
                        <TinyText>{'00:00'}</TinyText>
                        <TinyText>{'23:59'}</TinyText>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mt: -1,
                        }}
                      ></Box>
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <CustomIconButton
                      onClick={() => handleClickSkipDate(Number(SPAN.TWENTY_FOUR_HOURS))}
                      Icon={KeyboardDoubleArrowRightIcon}
                      tooltipTitle='次の日へ'
                      size='extraSmall'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                {isPlaying && !isSync && (
                  <Stack alignItems={'left'}>
                    <Typography fontSize={'0.60rem'}>{'×' + PLAYING_SPEED[playingSpeedIndex.current]}</Typography>
                    <Typography fontSize={'0.60rem'}>
                      {getPlayingSpeedLabel(PLAYING_SPEED[playingSpeedIndex.current], Number(span))}
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Select id='span' title={t('期間')} value={span} setValue={setSpan} options={spans} />
          </Grid>
          <Grid item xs={2}>
            <Tooltip
              title={
                <React.Fragment>
                  {t('ヒートマップデータのタイプ')}
                  <br />
                  {t('占有率：上限閾値（UpperThreshold）を超える割合')}
                  <br />
                  {t(`ノイズ：電波強度（RSSI）の${getLabelFromCalcmethod(setting.noiseCalcuMethod)}`)}
                </React.Fragment>
              }
              placement='left'
            >
              <div>
                <Select
                  id='source'
                  title={t('データソース')}
                  value={source}
                  setValue={setSource}
                  options={dataSources}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <CustomIconButton
              onClick={() => setIsSettingModalOpen(true)}
              Icon={BrightnessLowIcon}
              tooltipTitle='設定ダイアログを開く'
            />

            <DialogSetting
              open={isSettingModalOpen}
              onClose={() => setIsSettingModalOpen(false)}
              frequencyBand={frequencyBand}
              setFrequencyBand={setFrequencyBand}
            />

            <CustomIconButton
              onClick={() => setIsSerialModalOpen(true)}
              Icon={SaveIcon}
              tooltipTitle='データ取り込み'
              sx={{ marginInline: '20px' }}
            />

            <DialogSerial open={isSerialModalOpen} onClose={() => setIsSerialModalOpen(false)} />
            <CustomIconButton Icon={LogoutIcon} tooltipTitle='ログアウト' onClick={logout} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default ToolBar;

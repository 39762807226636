import { DEFAULT_IMAGE, DEFAULT_IMAGE_CONF } from 'domain/types/image';
import { clipboardPinType, DEFAULT_MAP_CONF, MapModeType, MapObjType, MapTypeIdType, PinData } from 'domain/types/map';
import { createContext, Dispatch, SetStateAction } from 'react';
import { DraggableBounds } from 'react-draggable';

interface RwmMapTabContext {
  // NOTE: マップモード
  mapMode: MapModeType;
  setMapMode: Dispatch<SetStateAction<MapModeType>>;

  // NOTE: イメージファイル編集用
  imageWidth: number;
  setImageWidth: Dispatch<SetStateAction<number>>;
  imageHeight: number;
  setImageHeight: Dispatch<SetStateAction<number>>;
  imageRotate: number;
  setImageRotate: Dispatch<SetStateAction<number>>;
  imageLat: number;
  setImageLat: Dispatch<SetStateAction<number>>;
  imageLng: number;
  setImageLng: Dispatch<SetStateAction<number>>;

  // NOTE: イメージファイル編集用 (一時的)
  tmpImageWidth: number;
  setTmpImageWidth: Dispatch<SetStateAction<number>>;
  tmpImageHeight: number;
  setTmpImageHeight: Dispatch<SetStateAction<number>>;
  tmpImageRotate: number;
  setTmpImageRotate: Dispatch<SetStateAction<number>>;
  tmpImageLat: number;
  setTmpImageLat: Dispatch<SetStateAction<number>>;
  tmpImageLng: number;
  setTmpImageLng: Dispatch<SetStateAction<number>>;

  // NOTE: デバイスの IoT データ
  pinData: PinData[];
  setPinData: Dispatch<React.SetStateAction<PinData[]>>;
}

export const RwmMapTabContext = createContext<RwmMapTabContext>({
  mapMode: 'read',
  setMapMode: () => {},
  imageWidth: DEFAULT_IMAGE_CONF.width,
  setImageWidth: () => {},
  imageHeight: DEFAULT_IMAGE_CONF.height,
  setImageHeight: () => {},
  imageRotate: DEFAULT_IMAGE_CONF.rotate,
  setImageRotate: () => {},
  imageLat: DEFAULT_IMAGE_CONF.lat,
  setImageLat: () => {},
  imageLng: DEFAULT_IMAGE_CONF.lng,
  setImageLng: () => {},
  // 一時変数
  tmpImageWidth: DEFAULT_IMAGE_CONF.width,
  setTmpImageWidth: () => {},
  tmpImageHeight: DEFAULT_IMAGE_CONF.height,
  setTmpImageHeight: () => {},
  tmpImageRotate: DEFAULT_IMAGE_CONF.rotate,
  setTmpImageRotate: () => {},
  tmpImageLat: DEFAULT_IMAGE_CONF.lat,
  setTmpImageLat: () => {},
  tmpImageLng: DEFAULT_IMAGE_CONF.lng,
  setTmpImageLng: () => {},
  pinData: [],
  setPinData: () => {},
});

// ------------------------------------------
interface RwmMapAreaContext {
  // NOTE: マップの情報
  center: google.maps.LatLng | undefined;
  setCenter: Dispatch<SetStateAction<google.maps.LatLng | undefined>>;
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
  mapTypeId: MapTypeIdType;
  setMapTypeId: Dispatch<SetStateAction<MapTypeIdType>>;
  elevation: number;
  setElevation: Dispatch<SetStateAction<number>>;
  mapWidth: number | undefined;
  setMapWidth: Dispatch<SetStateAction<number | undefined>>;
  mapHeight: number | undefined;
  setMapHeight: Dispatch<SetStateAction<number | undefined>>;
  image: string;
  setImage: Dispatch<SetStateAction<string>>;

  // NOTE: 高さ範囲指定
  maxHeightConf: number;
  setMaxHeightConf: Dispatch<SetStateAction<number>>;
  minHeightConf: number;
  setMinHeightConf: Dispatch<SetStateAction<number>>;

  // NOTE: 位置精度指定
  verticalConf: number;
  setVerticalConf: Dispatch<SetStateAction<number>>;
  horizontalConf: number;
  setHorizontalConf: Dispatch<SetStateAction<number>>;

  // NOTE: 表示用のステート
  isOpenedHeightRange: boolean;
  setIsOpenedHeightRange: Dispatch<SetStateAction<boolean>>;
  isOpenedPositionRange: boolean;
  setIsOpenedPositionRange: Dispatch<SetStateAction<boolean>>;
  isOpenedSearchWindow: boolean;
  setIsOpenedSearchWindow: Dispatch<SetStateAction<boolean>>;
  isOpenedImageWindow: boolean;
  setIsOpenedImageWindow: Dispatch<SetStateAction<boolean>>;

  // NOTE: 1ピクセル当たりの緯度、経度
  latPerPixel: number;
  setLatPerPixel: Dispatch<SetStateAction<number>>;
  lngPerPixel: number;
  setLngPerPixel: Dispatch<SetStateAction<number>>;

  // NOTE: デフォルトの北東と南西の緯度経度
  ne: google.maps.LatLng | undefined;
  setNe: Dispatch<SetStateAction<google.maps.LatLng | undefined>>;
  sw: google.maps.LatLng | undefined;
  setSw: Dispatch<SetStateAction<google.maps.LatLng | undefined>>;

  // NOTE: ピンの移動可能領域
  bounds: DraggableBounds;
  setBounds: Dispatch<SetStateAction<DraggableBounds>>;

  // NOTE: マップ編集時に一時的に値を保管するステート
  tmpCenter: google.maps.LatLng | undefined;
  setTmpCenter: Dispatch<SetStateAction<google.maps.LatLng | undefined>>;
  tmpZoom: number;
  setTmpZoom: Dispatch<SetStateAction<number>>;
  tmpMapTypeId: MapTypeIdType;
  setTmpMapTypeId: Dispatch<SetStateAction<MapTypeIdType>>;
  tmpElevation: number;
  setTmpElevation: Dispatch<SetStateAction<number>>;
  tmpImage: string | null;
  setTmpImage: Dispatch<SetStateAction<string | null>>;

  selectedMap: MapObjType | undefined;
  handleSetSelectedMap: (map: MapObjType) => void;

  // NOTE: クリップボード用の状態管理（ピン）
  clipboardPin: clipboardPinType | null;
  setClipboardPin: Dispatch<React.SetStateAction<clipboardPinType | null>>;
}

export const RwmMapAreaContext = createContext<RwmMapAreaContext>({
  center: undefined,
  setCenter: () => {},
  zoom: DEFAULT_MAP_CONF.zoom,
  setZoom: () => {},
  mapTypeId: DEFAULT_MAP_CONF.mapTypeId,
  setMapTypeId: () => {},
  elevation: DEFAULT_MAP_CONF.elevation,
  setElevation: () => {},
  mapWidth: undefined,
  setMapWidth: () => {},
  mapHeight: undefined,
  setMapHeight: () => {},
  image: DEFAULT_IMAGE,
  setImage: () => {},
  maxHeightConf: 2000,
  setMaxHeightConf: () => {},
  minHeightConf: 0,
  setMinHeightConf: () => {},
  verticalConf: 20,
  setVerticalConf: () => {},
  horizontalConf: 20,
  setHorizontalConf: () => {},
  isOpenedHeightRange: false,
  setIsOpenedHeightRange: () => {},
  isOpenedPositionRange: false,
  setIsOpenedPositionRange: () => {},
  isOpenedSearchWindow: false,
  setIsOpenedSearchWindow: () => {},
  isOpenedImageWindow: false,
  setIsOpenedImageWindow: () => {},
  latPerPixel: DEFAULT_MAP_CONF.latToPixel,
  setLatPerPixel: () => {},
  lngPerPixel: DEFAULT_MAP_CONF.lngToPixel,
  setLngPerPixel: () => {},
  ne: undefined,
  setNe: () => {},
  sw: undefined,
  setSw: () => {},
  bounds: {},
  setBounds: () => {},
  tmpCenter: undefined,
  setTmpCenter: () => {},
  tmpZoom: DEFAULT_MAP_CONF.zoom,
  setTmpZoom: () => {},
  tmpMapTypeId: DEFAULT_MAP_CONF.mapTypeId,
  setTmpMapTypeId: () => {},
  tmpElevation: DEFAULT_MAP_CONF.elevation,
  setTmpElevation: () => {},
  tmpImage: null,
  setTmpImage: () => {},
  selectedMap: undefined,
  handleSetSelectedMap: () => {},
  clipboardPin: null,
  setClipboardPin: () => {},
});

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import {
  BasePinType,
  DEFAULT_HEIGHT_CONF,
  DEFAULT_MAP_OPTIONS,
  DEFAULT_POSITION_CONF,
  HeatPointType,
  MapObjType,
  PointType,
  SizeType,
  TrackData,
} from 'domain/types/map';
import { RwmMapAreaContext } from '../RwmMapContext';
import HeightRangeButton from '../Button/HeightRangeButton';
import PositionRangeButton from '../Button/PositionRangeButton';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { GoogleMap } from '@react-google-maps/api';
import TrackPin from '../Pin/TrackPin';
import { useErrorBoundary } from 'react-error-boundary';
import {
  fetchTrackDataUsecase,
  SelectedFrequencyBandContext,
  SelectedSpanContext,
  DateTimeContext,
  SettingContext,
  SelectedDataSourceContext,
} from 'radioMonitoringPage';
import { FetchTrackDataParams } from 'domain/usecases/fetchTrackData';
import HeatMap from '../Map/HeatMap';
import { getHeatMapData, getLevelThresholdFromFrequencyBand, getNoiseLevelFromNoiseDataSource } from 'utils/extract';
import { convertHeatMapPosition, convertHeatMapSize } from 'utils/transform';
import { DATA_SOURCE } from 'domain/types/common/consts';
import { LevelThresholdType } from 'domain/types/setting';
import { getLabelFromCalcmethod } from 'utils/format';
import { useTranslation } from 'react-i18next';
import CustomSlider from '../../../../Common/SliderWithInputField';

interface Props {
  selectedMap: MapObjType;
}

const TrackWindow = ({ selectedMap }: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { mapWidth, mapHeight, center, zoom, mapTypeId, bounds, setNe, setSw, image, elevation } =
    useContext(RwmMapAreaContext);
  const { selectedPinIdsForTrack } = useContext(LayoutContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedSpan } = useContext(SelectedSpanContext);
  const { dateTime } = useContext(DateTimeContext);
  const { setting } = useContext(SettingContext);
  const { selectedDataSource } = useContext(SelectedDataSourceContext);

  // NOTE: 高さ範囲指定
  const [maxHeightConf, setMaxHeightConf] = useState<number>(2000);
  const [minHeightConf, setMinHeightConf] = useState<number>(0);

  // NOTE: 位置精度指定
  const [verticalConf, setVerticalConf] = useState<number>(20);
  const [horizontalConf, setHorizontalConf] = useState<number>(20);

  // NOTE: 表示用のステート
  const [isOpenedHeightRange, setIsOpenedHeightRange] = useState<boolean>(false);
  const [isOpenedPositionRange, setIsOpenedPositionRange] = useState<boolean>(false);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  // NOTE: 移動モード用のデータ
  const [trackData, setTrackData] = useState<TrackData[]>([]);

  const [basePin, setBasePin] = useState<BasePinType | undefined>(
    selectedMap.pins.find((pin) => pin.pinId === selectedPinIdsForTrack[0])
  );
  const [position, setPosition] = useState<PointType | undefined>(undefined);
  const [size, setSize] = useState<SizeType>({ width: 0, height: 0 });
  const [points, setPoints] = useState<HeatPointType[]>([]);
  const [heatPoints, setHeatPoints] = useState<HeatPointType[]>([]);
  const [currentLevelThreshold, setCurrentLevelThreshold] = useState<LevelThresholdType | undefined>(
    getLevelThresholdFromFrequencyBand(setting, selectedFrequencyBand)
  );

  const displayFlag = selectedPinIdsForTrack.length > 0 && basePin !== undefined;

  const [alpha, setAlpha] = useState<number>(70);

  const handleSetHeatPoints = useCallback(
    (heatPoint: HeatPointType | undefined, pinId: string) => {
      const startTime = dateTime.add(-1 * Number(selectedSpan), 'second').unix() * 1000;
      const endTime = dateTime.unix() * 1000;

      if (heatPoint === undefined) {
        setHeatPoints((prev) => prev.filter((point) => Number(point.id) >= startTime && Number(point.id) <= endTime));
        return;
      }

      setHeatPoints((prev) => {
        const newHeatPoints = [...prev];
        const index = newHeatPoints.findIndex((heatPoint) => heatPoint.id === pinId);

        // 同 ID が存在するなら上書き、存在しないなら追加
        if (index !== -1) newHeatPoints[index] = heatPoint;
        else newHeatPoints.push(heatPoint);

        // ID は時刻になるため、開始 ~ 終了時刻内の ID にフィルタ
        return newHeatPoints.filter((point) => Number(point.id) >= startTime && Number(point.id) <= endTime);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackData]
  );

  /**
   * @description ヒートマップ用のデータを作成
   */
  useEffect(() => {
    if (heatPoints && heatPoints.length > 0) {
      const heatMapData = getHeatMapData(heatPoints);
      setPosition(convertHeatMapPosition(heatMapData.x.min, heatMapData.y.min, setting.heatMapEdgeSize));
      setSize(
        convertHeatMapSize(
          heatMapData.x.max,
          heatMapData.x.min,
          heatMapData.y.max,
          heatMapData.y.min,
          setting.heatMapEdgeSize
        )
      );
      setPoints(heatMapData.points);
    }

    return () => {
      setPosition(undefined);
      setSize({ width: 0, height: 0 });
      setPoints([]);
    };
  }, [heatPoints, setting.heatMapEdgeSize]);

  useEffect(() => {
    setHeatPoints([]);
  }, [selectedPinIdsForTrack]);

  useEffect(() => {
    const _ = selectedMap.pins.find((pin) => pin.pinId === selectedPinIdsForTrack[0]);
    setBasePin(_);
  }, [selectedMap, selectedPinIdsForTrack]);

  /**
   * @description 1ピクセル当たりの緯度経度と境界点 (北東、南西) を更新
   */
  useEffect(() => {
    if (map) {
      const overlayView = new google.maps.OverlayView();
      overlayView.onAdd = function () {
        const _bounds = map.getBounds();
        if (_bounds) {
          // NOTE : 表示されている地図の右上 (北東) の座標
          const _ne = _bounds.getNorthEast();
          // NOTE : 表示されている地図の左下 (南西) の座標
          const _sw = _bounds.getSouthWest();
          setNe(_ne);
          setSw(_sw);
        }
      };
      overlayView.draw = function () {};
      overlayView.setMap(map);
    }
  }, [map, setNe, setSw, selectedMap, mapWidth]);

  /**
   * @description IoT データを取得し変数にセット
   */
  useEffect(() => {
    if (selectedPinIdsForTrack.length > 0) {
      (async () => {
        const minHeight = isOpenedHeightRange ? Math.floor(minHeightConf + elevation) : DEFAULT_HEIGHT_CONF.min;
        const maxHeight = isOpenedHeightRange ? Math.floor(maxHeightConf + elevation) : DEFAULT_HEIGHT_CONF.max;
        const vertical = isOpenedPositionRange ? verticalConf : DEFAULT_POSITION_CONF.vertical;
        const horizontal = isOpenedPositionRange ? horizontalConf : DEFAULT_POSITION_CONF.horizontal;

        try {
          const params = new FetchTrackDataParams(
            dateTime,
            selectedSpan,
            selectedPinIdsForTrack[0],
            selectedFrequencyBand,
            minHeight,
            maxHeight,
            vertical,
            horizontal,
            setting
          );
          const trackData = await fetchTrackDataUsecase.call(params);

          setTrackData(trackData);
        } catch (error) {
          showBoundary(error);
        }
      })();
    }
  }, [
    dateTime,
    elevation,
    horizontalConf,
    isOpenedHeightRange,
    isOpenedPositionRange,
    maxHeightConf,
    minHeightConf,
    selectedFrequencyBand,
    selectedPinIdsForTrack,
    selectedSpan,
    setting,
    showBoundary,
    verticalConf,
  ]);

  useEffect(() => {
    setCurrentLevelThreshold(getLevelThresholdFromFrequencyBand(setting, selectedFrequencyBand));
  }, [setting, selectedFrequencyBand]);

  /**
   * @description マップインスタンスが読み込まれた時の処理
   */
  const onLoad = useCallback(async (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  /**
   * @description マップインスタンスがアンマウント (レンダリング対象から外れた) 時の処理
   */
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  /**
   * @description マップインスタンスに値を設定
   */
  useEffect(() => {
    if (map && center) {
      map.setCenter(center);
      map.setZoom(zoom);
      map.setMapTypeId(mapTypeId);
    }
  }, [map, center, mapTypeId, zoom, setNe, setSw, selectedMap.mapId]);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: mapWidth,
        height: mapHeight,
      }}
      center={center}
      zoom={zoom}
      mapTypeId={mapTypeId}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ ...DEFAULT_MAP_OPTIONS }}
    >
      <Box
        sx={{
          width: mapWidth,
          height: mapHeight,
          position: 'absolute',
          background: 'transparent',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {displayFlag && position !== undefined && (
          <HeatMap position={position} size={size} knownPoints={points} alpha={alpha * 0.01} />
        )}
        {displayFlag &&
          trackData.map((data, index) => {
            if (data.unitId === basePin.pinId)
              return (
                <TrackPin
                  key={`trackMapMarker-${index}`}
                  pinId={String(data.date)}
                  basePin={basePin}
                  bounds={bounds}
                  trackData={data}
                  selectedMap={selectedMap}
                  handleSetHeatPoints={handleSetHeatPoints}
                />
              );
          })}
        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-end'
          spacing={1}
          m={1}
          sx={{ zIndex: 10, width: 'fit-content', height: 'fit-content', position: 'absolute', top: 0, right: 0 }}
        >
          <HeightRangeButton
            elevation={elevation}
            isOpenedHeightRange={isOpenedHeightRange}
            maxHeightConf={maxHeightConf}
            minHeightConf={minHeightConf}
            setIsOpenedHeightRange={setIsOpenedHeightRange}
            setMaxHeightConf={setMaxHeightConf}
            setMinHeightConf={setMinHeightConf}
          />
          <PositionRangeButton
            isOpenedPositionRange={isOpenedPositionRange}
            verticalConf={verticalConf}
            horizontalConf={horizontalConf}
            setIsOpenedPositionRange={setIsOpenedPositionRange}
            setVerticalConf={setVerticalConf}
            setHorizontalConf={setHorizontalConf}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          backgroundColor: 'rgba(100, 100, 100, 0.8)',
          color: '#FFFFFF',
          padding: '5px',
          borderRadius: '0px',
          fontSize: '11px',
        }}
      >
        {selectedDataSource == DATA_SOURCE.OCCUPANCY_RATE && (
          <Stack direction='row' spacing={1}>
            <Box>{t('占有率：上限閾値を超える割合')}</Box>
            <Box>
              〇：{currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('未満')}
            </Box>
            <Box>
              △：{currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('以上')}
            </Box>
            <Box>
              ×：{currentLevelThreshold ? currentLevelThreshold.topOccupancyLevel : ''}%{t('以上')}
            </Box>
          </Stack>
        )}
        {selectedDataSource == DATA_SOURCE.NOISE && (
          <Stack direction='row' spacing={1}>
            <Box>{t(`ノイズ：電波強度（RSSIの${getLabelFromCalcmethod(setting.noiseCalcuMethod)}）`)}</Box>
            <Box>
              〇：
              {currentLevelThreshold
                ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
                : ''}
              dBm{t('未満')}
            </Box>
            <Box>
              △：
              {currentLevelThreshold
                ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
                : ''}
              dBm{t('以上')}
            </Box>
            <Box>
              ×：
              {currentLevelThreshold
                ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).topNoiseLevel
                : ''}
              dBm
              {t('以上')}
            </Box>
          </Stack>
        )}
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={6}>
          <Box
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 0,
              backgroundColor: 'rgba(100, 100, 100, 0.8)',
              color: '#FFFFFF',
              padding: '5px',
              borderRadius: '0px',
              fontSize: '11px',
            }}
          >
            {selectedDataSource == DATA_SOURCE.OCCUPANCY_RATE && (
              <Stack direction='row' spacing={1}>
                <Box>{t('占有率：上限閾値を超える割合')}</Box>
                <Box>
                  {t('青：')}
                  {currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('未満')}
                </Box>
                <Box>
                  {t('黄：')}
                  {currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('以上')}
                </Box>
                <Box>
                  {t('赤：')}
                  {currentLevelThreshold ? currentLevelThreshold.topOccupancyLevel : ''}%{t('以上')}
                </Box>
              </Stack>
            )}
            {selectedDataSource == DATA_SOURCE.NOISE && (
              <Stack direction='row' spacing={1}>
                <Box>{t(`ノイズ：電波強度（RSSIの${getLabelFromCalcmethod(setting.noiseCalcuMethod)}）`)}</Box>
                <Box>
                  {t('青：')}
                  {currentLevelThreshold
                    ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
                    : ''}
                  dBm{t('未満')}
                </Box>
                <Box>
                  {t('黄：')}
                  {currentLevelThreshold
                    ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
                    : ''}
                  dBm{t('以上')}
                </Box>
                <Box>
                  {t('赤：')}
                  {currentLevelThreshold
                    ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).topNoiseLevel
                    : ''}
                  dBm
                  {t('以上')}
                </Box>
              </Stack>
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              position: 'absolute',
              bottom: 10,
              left: 20,
              backgroundColor: 'rgba(100, 100, 100, 0.8)',
              color: '#FFFFFF',
              padding: '5px',
              borderRadius: '0px',
              fontSize: '11px',
              width: '250px',
              height: '25px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomSlider
              max={100}
              min={0}
              originalMin={0}
              originalMax={100}
              value={alpha}
              setValue={setAlpha}
              title={t('透明度：')}
              sx={{ width: '250px' }} // スライダーの幅を指定
            />
          </Box>
        </Grid>
      </Grid>
    </GoogleMap>
  );
};
export default React.memo(TrackWindow);

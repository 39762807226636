import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const getDateFromSeconds = (seconds: number): Date => {
  const hours = Math.floor(seconds / 3600); // 時間
  const minutes = Math.floor((seconds % 3600) / 60); // 分
  const remainingSeconds = seconds % 60; // 秒

  const dateTime = new Date();
  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  dateTime.setSeconds(remainingSeconds);
  return dateTime;
};

export const getSecondsFromDate = (date: Dayjs | null): number => {
  if (date === null) {
    return 0;
  }

  const hours = date.hour();
  const minutes = date.minute();
  const seconds = date.second();

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};

// currentSeconds：現在の秒数,addSeconds：加算する秒数
export const calcSecondsInDay = (currentSeconds: number, addSeconds: number): number => {
  const SECONDS_IN_A_DAY: number = 86400;
  const addResult: number = (currentSeconds + addSeconds) % SECONDS_IN_A_DAY;

  // 結果が負の場合、86400を加えて範囲内にする
  return addResult < 0 ? addResult + SECONDS_IN_A_DAY : addResult;
};

/**
 * 再生時・時刻同期時に対応した時刻を取得する
 * @param {Dayjs} dateTime 現在の日時
 * @param {number} span 期間
 * @param {number} timeOffset 調整値
 * @param {boolean} isSync リアルタイム中かの判定
 * @returns
 */
export const getDateTimeWithTimeOffset = (dateTime: Dayjs, span: number, timeOffset: number, isSync: boolean) => {
  const secondsAdjustment = timeOffset * 15;
  return isSync
    ? dateTime.add(secondsAdjustment, 'second').millisecond(0)
    : dateTime.add(((span / 12) * secondsAdjustment) / 15, 'second').millisecond(0);
};

/**
 * @description 現在時刻(YYYYMMDDhhmmss)を取得する処理
 * @returns {string}
 */
export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = ('0' + (now.getMonth() + 1)).slice(-2);
  const day = ('0' + now.getDate()).slice(-2);
  const hours = ('0' + now.getHours()).slice(-2);
  const minutes = ('0' + now.getMinutes()).slice(-2);
  const seconds = ('0' + now.getSeconds()).slice(-2);

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

/**
 * @description タイムスタンプを渡すと、指定したタイムゾーンとフォーマットで返す関数
 * @param {number} timestamp UNIX タイムスタンプ（ミリ秒）
 * @param {string} timezoneStr タイムゾーン（例: 'Asia/Tokyo'）\
 *  https://day.js.org/docs/en/timezone/timezone
 * @param {string} format 変換するフォーマット（例: 'HH:mm'）\
 *  https://day.js.org/docs/en/display/format
 * @returns {string} 指定したフォーマットの時間
 */
export const convertTimestampToTime = (timestamp: number, timezoneStr: string, format: string): string => {
  return dayjs(timestamp).tz(timezoneStr).format(format);
};

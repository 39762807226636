import { FrequencyBandType, FREQUENCY_BAND } from './frequency';
// ------------------------------------------------------
// NOTE: インターフェースの定義

/**
 * @description 設定のインターフェス
 * @property {NoiseDataSourceType} noiseDataSource ノイズデータソース
 * @property {CalcMethodType} noiseCalcuMethod ノイズ計算方法
 * @property {CalcMethodType} occupancyCalcMethod 占有率計算方法
 * @property {LevelThresholdType} levelThresholdList レベル閾値
 * @property {boolean} isDisplayPinLabel ピンラベル表示フラグ
 * @property {number} pinSize ピンサイズ
 * @property {number} heatMapEdgeSize ヒートマップ縁サイズ
 * @property {booelan} isHMEdgeLightColor ヒートマップ縁薄くするフラグ
 * @property {number} heatMapBlockSize ヒートマップブロックサイズ
 * @property {number} isDisplayHMValue ヒートマップに値表示
 * @property {number} trackMapDivision 軌跡マップ分割数
 * @property {number} chartMovingAverage 移動平均Nの値
 * @interface
 */
export interface SettingType {
  noiseDataSource: NoiseDataSourceType;
  noiseCalcuMethod: CalcMethodType;
  occupancyCalcMethod: CalcMethodType;
  levelThresholdList: LevelThresholdType[];
  isDisplayPinLabel: boolean;
  pinSize: number;
  heatMapEdgeSize: number;
  isHMEdgeLightColor: boolean;
  heatMapBlockSize: number;
  isDisplayHMValue: boolean;
  trackMapDivision: number;
  movingAverage: number;
}

/**
 * @description 設定のインターフェス
 * @property {number} bottomNoiseLevel ボトムノイズレベル
 * @property {number} topNoiseLevel トップノイズレベル
 * @interface
 */
export interface NoiseLevelType {
  bottomNoiseLevel: number;
  topNoiseLevel: number;
}

/**
 * @description レベル閾値のインターフェス
 * @property {string} frequencyBand
 * @property {number} topOccupancyLevel
 * @property {number} topNoiseLevelRssiMin
 * @property {number} bottomOccupancyLevel
 * @property {number} bottomNoiseLevelRssiMin
 * @property {number} topNoiseLevelRssiMax
 * @property {number} bottomNoiseLevelRssiMax
 * @interface LevelThreshold
 */
export interface LevelThresholdType {
  frequencyBand: FrequencyBandType;
  topOccupancyLevel: number;
  topNoiseLevelRssiMin: number;
  bottomOccupancyLevel: number;
  bottomNoiseLevelRssiMin: number;
  topNoiseLevelRssiMax: number;
  bottomNoiseLevelRssiMax: number;
}

/**
 * @description データソースの種別
 * @type
 */
export type DataSourceType = 'NOISE' | 'OCCUPANCY_RATE';

/**
 * @description ノイズデータソースの種別
 * @type
 */
export type NoiseDataSourceType = 'RSSIMAX' | 'RSSIMIN';

/**
 * @description 計算方法
 * @type
 */
export type CalcMethodType = 'MIN' | 'AVE' | 'MAX';

export type ConfirmDialogResult = 'add' | 'close';

// ------------------------------------------------------
// NOTE: 定数の定義
/**
 * @description ノイズデータソースの定数
 * @constant
 */
export const NOISE_DATA_SOURCE = {
  RSSIMAX: 'RSSIMAX',
  RSSIMIN: 'RSSIMIN',
} as const;

/**
 * @description 計算方法の定数
 * @constant
 */
export const CALC_METHOD = {
  MIN: { ID: 'MIN', LABEL: '最小値' },
  AVE: { ID: 'AVE', LABEL: '平均値' },
  MAX: { ID: 'MAX', LABEL: '最大値' },
} as const;

/**
 * @description 軌跡マップ分割数の定数
 * @constant
 */
export const Track_MAP_DIVISION = {
  DEFAULT: 20,
  MIN: 10,
  MAX: 100,
} as const;

/**
 * @description 移動平均Nの値
 * @constant
 */
export const MOVING_AVERAGE = {
  DEFAULT: 10,
  MIN: 1,
  MAX: 100,
} as const;

/**
 * @description ピンサイズ
 * @constant
 */
export const PIN_SIZE = {
  DEFAULT: 40,
  MIN: 20,
  MAX: 60,
} as const;

/**
 * @description ヒートマップの縁
 * @constant
 */
export const HEAT_MAP_EDGE_SIZE = {
  DEFAULT: 100,
  MIN: 0,
  MAX: 200,
} as const;

/**
 * @description ヒートマップのブロックサイズ
 * @constant
 */
export const HEAT_MAP_BLOCK_SIZE = {
  DEFAULT: 10,
  MIN: 10,
  MAX: 200,
} as const;

const DEFAULT_LEVEL_THRESHOLD: LevelThresholdType[] = [
  {
    frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID,
    topOccupancyLevel: 5,
    bottomOccupancyLevel: 3,
    topNoiseLevelRssiMin: -90,
    bottomNoiseLevelRssiMin: -100,
    topNoiseLevelRssiMax: -90,
    bottomNoiseLevelRssiMax: -100,
  } as LevelThresholdType,
  {
    frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID,
    topOccupancyLevel: 70,
    bottomOccupancyLevel: 30,
    topNoiseLevelRssiMin: -85,
    bottomNoiseLevelRssiMin: -90,
    topNoiseLevelRssiMax: -85,
    bottomNoiseLevelRssiMax: -90,
  } as LevelThresholdType,
  {
    frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID,
    topOccupancyLevel: 70,
    bottomOccupancyLevel: 30,
    topNoiseLevelRssiMin: -85,
    bottomNoiseLevelRssiMin: -90,
    topNoiseLevelRssiMax: -85,
    bottomNoiseLevelRssiMax: -90,
  } as LevelThresholdType,
  {
    frequencyBand: FREQUENCY_BAND.FREQUENCY_L5G.ID,
    topOccupancyLevel: 70,
    bottomOccupancyLevel: 30,
    topNoiseLevelRssiMin: -85,
    bottomNoiseLevelRssiMin: -90,
    topNoiseLevelRssiMax: -85,
    bottomNoiseLevelRssiMax: -90,
  } as LevelThresholdType,
];

export const DEFAULT_SETTING: SettingType = {
  noiseDataSource: NOISE_DATA_SOURCE.RSSIMIN,
  noiseCalcuMethod: CALC_METHOD.MAX.ID,
  occupancyCalcMethod: CALC_METHOD.MAX.ID,
  levelThresholdList: DEFAULT_LEVEL_THRESHOLD,
  trackMapDivision: Track_MAP_DIVISION.DEFAULT,
  movingAverage: MOVING_AVERAGE.DEFAULT,
  isDisplayPinLabel: true,
  isHMEdgeLightColor: false,
  isDisplayHMValue: false,
  pinSize: PIN_SIZE.DEFAULT,
  heatMapEdgeSize: HEAT_MAP_EDGE_SIZE.DEFAULT,
  heatMapBlockSize: HEAT_MAP_BLOCK_SIZE.DEFAULT,
};

import React, { useRef, useCallback, useContext, useState, useEffect } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Box } from '@mui/material';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { PointType } from 'domain/types/map';
import { getImageLatLng, getImagePosition } from 'utils/extract';

interface Props {
  ne: google.maps.LatLng;
  sw: google.maps.LatLng;
}

const FloorImage = ({ ne, sw }: Props) => {
  const { mapWidth, mapHeight, tmpZoom, image, bounds } = useContext(RwmMapAreaContext);
  const { tmpImageWidth, tmpImageHeight, tmpImageRotate, tmpImageLat, tmpImageLng, setTmpImageLat, setTmpImageLng } =
    useContext(RwmMapTabContext);

  const [scale, setScale] = useState<number>(1);
  const prevZoomRef = useRef<number>(tmpZoom);
  const draggableNodeRef = useRef(null);

  const [position, setPosition] = useState<PointType>({
    x: (mapWidth as number) / 2,
    y: (mapHeight as number) / 2,
  });

  // console.log(position.x + ',' + position.y);

  const onStart = useCallback((e: DraggableEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      // console.log(data);
      const latLng = getImageLatLng(data.x, data.y, ne, sw, mapWidth as number, mapHeight as number);
      console.log(latLng);
      setTmpImageLat(latLng.lat);
      setTmpImageLng(latLng.lng);
      setPosition({ x: data.x, y: data.y });
    },
    [mapHeight, mapWidth, ne, setTmpImageLat, setTmpImageLng, sw]
  );

  useEffect(() => {
    const prevZoom = prevZoomRef.current;
    setScale((prevScale) => (tmpZoom > prevZoom ? prevScale * 2 : tmpZoom < prevZoom ? prevScale / 2 : prevScale));
    prevZoomRef.current = tmpZoom;
  }, [tmpZoom]);

  useEffect(() => {
    const imgPosition = getImagePosition(
      tmpImageLat,
      tmpImageLng,
      mapWidth as number,
      mapHeight as number,
      ne,
      sw,
      bounds
    );
    setPosition(imgPosition);
  }, [bounds, mapHeight, mapWidth, ne, sw, tmpImageLat, tmpImageLng]);

  // console.log(
  //   tmpImageLat +
  //     ',' +
  //     tmpImageLng +
  //     ',' +
  //     position.x +
  //     ',' +
  //     position.y +
  //     ',' +
  //     ne.lat() +
  //     ',' +
  //     ne.lng() +
  //     ',' +
  //     sw.lat() +
  //     ',' +
  //     sw.lng()
  // );

  return (
    <Draggable nodeRef={draggableNodeRef} onStart={onStart} onStop={onStop} position={position}>
      <Box
        ref={draggableNodeRef}
        sx={{
          cursor: 'move',
          position: 'relative',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <Box
          sx={{
            transformOrigin: 'center',
            transform: `translate(-50%, -50%) scale(${scale}) rotate(${tmpImageRotate}deg)`,
          }}
        >
          <Box
            component='img'
            src={image}
            width={tmpImageWidth}
            height={tmpImageHeight}
            sx={{
              maxWidth: 'none',
              maxHeight: 'none',
            }}
          />
        </Box>
      </Box>
    </Draggable>
  );
};

export default React.memo(FloorImage);

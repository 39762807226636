import React from 'react';
import { Slider, styled } from '@mui/material';

interface Props {
  defaultValue: number;
  value: number;
  min: number;
  max: number;
  onChangeCommitted:
    | ((event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => void)
    | undefined;
  onChange?: (event: Event, value: number | number[]) => void | undefined;
  step?: number;
  isLabel?: boolean;
}
//色を指定
const CustomizedSlider = styled(Slider)({
  color: '#0078d7',
});
export const CustomSlider = ({
  defaultValue,
  value,
  min,
  max,
  onChangeCommitted,
  onChange,
  step = 1,
  isLabel = false,
}: Props) => {
  return (
    <CustomizedSlider
      defaultValue={defaultValue}
      value={value}
      min={min}
      max={max}
      onChangeCommitted={onChangeCommitted}
      onChange={onChange}
      step={step}
      aria-label={isLabel ? 'Small' : undefined}
      valueLabelDisplay={isLabel ? 'auto' : undefined}
    />
  );
};

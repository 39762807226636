import React, { SetStateAction, Dispatch } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// NOTE: カスタムコンポーネント
import { ZOOM_OPTIONS } from 'domain/types/map';
import CustomIconButton from 'components/Common/CustomIconButton';

/**
 * @interface
 */
interface Props {
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
}

const ZoomInMapButton = ({ zoom, setZoom }: Props) => {
  /**
   * @description ズームインボタン選択時の処理
   */
  const handleClickZoomIn = () => {
    if (zoom < ZOOM_OPTIONS.max) zoom++;
    setZoom(zoom);
  };

  return <CustomIconButton onClick={handleClickZoomIn} Icon={ZoomInIcon} tooltipTitle={'ズームイン'} size='medium' />;
};

export default React.memo(ZoomInMapButton);

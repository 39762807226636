import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

// NOTE: カスタムコンポーネント
import { FREQUENCIES, FREQUENCY_BAND, CHANNEL_GROUP_2_4G, FrequencyType } from '../../../../domain/types/frequency';
import { SelectedFrequencyBandContext } from '../../../../radioMonitoringPage';
import CustomIconButton from '../../../Common/CustomIconButton';
import ChannelSelectArea from './ChannelSelectArea';
import FrequencySelectArea from './FrequencySelectArea';
import { judgeSelectedFrequencyListIncludeChannelGroup } from '../../../../utils/extract';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { RwmContext } from 'RwmContext';

const FrequencySelectMenu = () => {
  const { t } = useTranslation();

  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedFrequencies, setSelectedFrequencies } = useContext(RwmContext);
  const [isOpenedSelectMenu, setIsOpenedSelectMenu] = useState<boolean>(true);
  const {
    isDisplayedChannel,
    setIsDisplayedChannel,
    frequencyCheckBoxStates,
    setFrequencyCheckBoxStates,
    channelCheckBoxStates,
    setChannelCheckBoxStates,
    selectedChannelGroups,
    setSelectedChannelGroups,
  } = useContext(LayoutContext);

  /**
   * @description 全てを選択ボタンの処理
   * @param {Dispatch<SetStateAction<boolean[]>>} setCheckBoxStates チェックボックスのステートを変更する関数
   */
  const handleClickAllItemCheck = (setCheckBoxStates: Dispatch<SetStateAction<boolean[]>>) => {
    const tmp = frequencyCheckBoxStates.map(() => true);
    setCheckBoxStates(tmp);
    setSelectedFrequencies(FREQUENCIES[selectedFrequencyBand].list);

    if (selectedFrequencyBand === FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID) {
      setSelectedChannelGroups(CHANNEL_GROUP_2_4G);
    }
  };

  /**
   * @description 選択解除ボタンの処理
   * @param {Dispatch<SetStateAction<boolean[]>>} setCheckBoxStates チェックボックスのステートを変更する関数
   */
  const handleClickAllItemCancel = (setCheckBoxStates: Dispatch<SetStateAction<boolean[]>>) => {
    const tmp = frequencyCheckBoxStates.map(() => false);
    setCheckBoxStates(tmp);
    setSelectedFrequencies([]);

    if (selectedFrequencyBand === FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID) {
      setSelectedChannelGroups([]);
    }
  };

  /**
   * @description CH表示切り替えボタンの処理
   */
  const handleClickChannelDisplay = () => {
    const tmpFrequencies = FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list;

    // NOTE: CH 表示 → 周波数表示に切替時の処理
    if (isDisplayedChannel) {
      const tmpCheckBoxStates: boolean[] = new Array(tmpFrequencies.length).fill(false);

      const checkedIndices: number[] = selectedFrequencies
        .map((frequency: FrequencyType) => tmpFrequencies.indexOf(frequency))
        .filter((index: number) => index >= 0);

      checkedIndices.forEach((index: number) => (tmpCheckBoxStates[index] = true));

      setFrequencyCheckBoxStates(tmpCheckBoxStates);
    }
    // NOTE: 周波数表示 → CH 表示に切替時の処理
    else {
      const tmpCheckBoxStates: boolean[] = judgeSelectedFrequencyListIncludeChannelGroup(selectedFrequencies);

      // ステートが true の要素のインデックスを取得
      const indices = tmpCheckBoxStates
        .map((state, index) => ({ index, state: state === true }))
        .filter((item) => item.state)
        .map((item) => item.index);
      const tmpSelectedChannelGroups = indices.map((index) => CHANNEL_GROUP_2_4G[index]);

      setSelectedChannelGroups(tmpSelectedChannelGroups);
      setChannelCheckBoxStates(tmpCheckBoxStates);
    }
    setIsDisplayedChannel(!isDisplayedChannel);
  };

  /**
   * @description 周波数バンドが変更された時に初期値をセットする
   */
  useEffect(() => {
    const len = FREQUENCIES[selectedFrequencyBand].list.length;
    setSelectedFrequencies(FREQUENCIES[selectedFrequencyBand].list);
    setFrequencyCheckBoxStates(new Array(len).fill(true));
    setIsOpenedSelectMenu(true);
    setIsDisplayedChannel(false);
    // setIsDisplayedChannelをdependencyListに含めないとwarningとなるため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFrequencyBand, setSelectedFrequencies]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Button
        onClick={() => {
          setIsOpenedSelectMenu(!isOpenedSelectMenu);
        }}
        style={{ color: 'white' }}
        variant='text'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          height: 30,
          top: 0,
        }}
      >
        {isOpenedSelectMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />} {t('周波数選択')}
      </Button>
      {isOpenedSelectMenu && (
        <Box
          sx={{
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            overflow: 'auto',
            height: 5 / 6,
            maxHeight: 250,
            minHeight: 0,
            ml: 4,
          }}
        >
          {isDisplayedChannel ? (
            <ChannelSelectArea
              checkBoxStates={channelCheckBoxStates}
              setCheckBoxStates={setChannelCheckBoxStates}
              selectedChannelGroups={selectedChannelGroups}
              setSelectedChannelGroups={setSelectedChannelGroups}
            />
          ) : (
            <FrequencySelectArea
              checkBoxStates={frequencyCheckBoxStates}
              setCheckBoxStates={setFrequencyCheckBoxStates}
            />
          )}
        </Box>
      )}
      {isOpenedSelectMenu ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '10px',
            height: 30,
            bottom: 0,
            m: 1,
          }}
        >
          <CustomIconButton
            onClick={() => {
              const setCheckBoxStates: Dispatch<SetStateAction<boolean[]>> = isDisplayedChannel
                ? setChannelCheckBoxStates
                : setFrequencyCheckBoxStates;
              handleClickAllItemCheck(setCheckBoxStates);
            }}
            tooltipTitle={'全てを選択'}
            Icon={SelectAllIcon}
          />
          <CustomIconButton
            onClick={() => {
              const setCheckBoxStates: Dispatch<SetStateAction<boolean[]>> = isDisplayedChannel
                ? setChannelCheckBoxStates
                : setFrequencyCheckBoxStates;
              handleClickAllItemCancel(setCheckBoxStates);
            }}
            tooltipTitle={'選択解除'}
            Icon={DeselectIcon}
          />
          {selectedFrequencyBand === FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID && (
            <CustomIconButton
              onClick={() => {
                handleClickChannelDisplay();
              }}
              tooltipTitle={'CHグループ表示'}
              Icon={DataObjectIcon}
              isEnabled={isDisplayedChannel}
            />
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default React.memo(FrequencySelectMenu);

export interface DefineValue {
  DEBUG_MODE_VAL: string;
  APP_SETTING_FOLDER_NAME: string;
  TMPCSV_START_NAME: string;
  TYPE_RF_MONITOR: string;
  TYPE_RF_MONITOR_ES2_VERSION: number;
  TYPE_ROUTER: string;
  USB_CMD_SIZE: number;
  USB_CHECKSUM_SIZE: number;
  WSN_RSSI_OFFSET: number;
  TYPE_MASK: number;
  TYPE_ISM900: number;
  TYPE_W2_4G: number;
  TYPE_L5G: number;
  TYPE_W5G: number;
  TYPE_RMS_DATA_MAX_NUM: number;
  TYPE_DEVICE_INFO: number;
  TYPE_ROUTE_I: number;
  TYPE_NONE: number;
  PKT_VER_MASK: number;
  PKT_VER_10: number;
  RADIO_DATA_ISM900_MAX_NUM: number;
  RADIO_DATA_W2_4G_CH1_13_NUM: number;
  RADIO_DATA_W2_4G_CH14_NUM: number;
  RADIO_DATA_W2_4G_MAX_NUM: number;
  RADIO_DATA_L5G_MAX_NUM: number;
  RADIO_DATA_W5G_W49_MAX_NUM: number;
  RADIO_DATA_W5G_W52_MAX_NUM: number;
  RADIO_DATA_W5G_W53_MAX_NUM: number;
  RADIO_DATA_W5G_W56_MAX_NUM: number;
  RADIO_DATA_W5G_W52_INDEX: number;
  RADIO_DATA_W5G_W56_INDEX: number;
  RADIO_DATA_W5G_MAX_NUM: number;
  RADIO_DATA_MAX_NUM: number;
  RADIO_DATA_ITEM_NUM: number;
  RADIO_DATA_START_FREQ_ISM900: number;
  RADIO_DATA_START_FREQ_W2_4G: number;
  RADIO_DATA_START_FREQ_L5G: number;
  RADIO_DATA_START_FREQ_W5G: number;
  RADIO_DATA_FREQ_GAP_ISM900: number;
  RADIO_DATA_FREQ_GAP_W2_4G: number;
  RADIO_DATA_FREQ_GAP_L5G: number;
  RADIO_DATA_FREQ_GAP_W5G: number;
  RADIO_DATA_FREQ_W2_4G_CH14: number;
  RADIO_DATA_FREQ_W5G_W49: number;
  RADIO_DATA_FREQ_W5G_W52: number;
  RADIO_DATA_FREQ_W5G_W53: number;
  RADIO_DATA_FREQ_W5G_W56: number;
  RADIO_MONITOR_ROUTE_MAX_NUM: number;
  RADIO_MONITOR_ROUTE_ITEM_NUM: number;
  ALL_RADIO_DATA_ISM900_MAX_NUM: number;
  ALL_RADIO_DATA_W2_4G_MAX_NUM: number;
  ALL_RADIO_DATA_L5G_MAX_NUM: number;
  ALL_RADIO_DATA_W5G_MAX_NUM: number;

  ALL_RADIO_DATA_START_FREQ_ISM900: number;
  ALL_RADIO_DATA_START_FREQ_W2_4G: number;
  ALL_RADIO_DATA_START_FREQ_L5G: number;
  ALL_RADIO_DATA_START_FREQ_W5G: number;
  ALL_RADIO_DATA_FREQ_GAP_ISM900: number;
  ALL_RADIO_DATA_FREQ_GAP_W2_4G: number;
  ALL_RADIO_DATA_FREQ_GAP_L5G: number;
  ALL_RADIO_DATA_FREQ_GAP_W5G: number;
  ROUTE_EACH_DATA_LEN: number;
  GNSS_LOCATION_INVALID_VALUE: string;
  GNSS_LOCATION_MULTIPLE: number;

  GNSS_INVALID_VALUE_DOUBLE: number;
  VCC_MULTIPLE: number;
  START_FREQ_MULTIPLE: number;
  START_FREQ_ALL_CH_MONITOR: string;
  RADIO_DATA_EACH_LEN: number;
  INVALID_RMS_DATA: string;
  FREQ_TABLE: { [key: number]: number[] }; // フィールドの型を適切に定義
  DATA_TYPE_ISM900: string;
  DATA_TYPE_W2_4G: string;
  DATA_TYPE_L5G: string;
  DATA_TYPE_W5G: string;
  JSON_DATA_TYPE_DEVICEINFO: string;
  JSON_DATA_TYPE_ISM900: string;
  JSON_DATA_TYPE_W2_4G: string;
  JSON_DATA_TYPE_L5G: string;
  JSON_DATA_TYPE_W5G: string;
  JSON_DATA_TYPE_ROUTE: string;
  INVALID: string;
  VALID: string;
  DISCONNECTED: string;
  CONNECTED: string;
  SAVE_DATA_COM_NONE: number;
  SAVE_DATA_COM_START: number;
  SAVE_DATA_COM_END: number;
  SAVE_DATA_NORMAL: number;
  SAVE_DATA_GARBAGE_DATA_EXIST: number;
  SAVE_DATA_ALERT_CAPACITY: number;
  SAVE_DATA_MAX_PAGE_NUM: number;
  SAVE_DATA_RADIO_DATA_EACH_LEN: number;
  SAVE_DATA_INVALID_RMS_DATA: string;
  PROGRESS_VAR_INIT: number;
  PROGRESS_VAR_PRE_PROCESS_START: number;
  PROGRESS_VAR_READ_START: number;
  PROGRESS_VAR_SAVE_CSV_START: number;
  PROGRESS_VAR_ERASE_START: number;
  PROGRESS_VAR_POST_PROCESS_START: number;
  PROGRESS_VAR_FIN: number;
  PROGRESS_VAR_SAVE_DATA_PROCESS_BLOCK_VAL: number;
}
export const DEFINE_VALUE: DefineValue = {
  DEBUG_MODE_VAL: '0270',
  APP_SETTING_FOLDER_NAME: 'AppSetting',
  TMPCSV_START_NAME: 'Tmp',
  TYPE_RF_MONITOR: '2C', // Type2FD
  TYPE_RF_MONITOR_ES2_VERSION: 649,
  TYPE_ROUTER: 'FE',
  USB_CMD_SIZE: 3,
  USB_CHECKSUM_SIZE: 2,
  WSN_RSSI_OFFSET: -107,
  TYPE_MASK: 0x0f,
  TYPE_ISM900: 0x00,
  TYPE_W2_4G: 0x01,
  TYPE_L5G: 0x02,
  TYPE_W5G: 0x03,
  TYPE_RMS_DATA_MAX_NUM: 4,
  TYPE_DEVICE_INFO: 0x0f,
  TYPE_ROUTE_I: 0x0100,
  TYPE_NONE: 0xffff,
  PKT_VER_MASK: 0xf0,
  PKT_VER_10: 0x10,
  RADIO_DATA_ISM900_MAX_NUM: 38,
  RADIO_DATA_W2_4G_CH1_13_NUM: 13,
  RADIO_DATA_W2_4G_CH14_NUM: 12,
  RADIO_DATA_W2_4G_MAX_NUM: 13 + 12, // RADIO_DATA_W2_4G_CH1_13_NUM + RADIO_DATA_W2_4G_CH14_NUM
  RADIO_DATA_L5G_MAX_NUM: 6,
  RADIO_DATA_W5G_W49_MAX_NUM: 4,
  RADIO_DATA_W5G_W52_MAX_NUM: 4,
  RADIO_DATA_W5G_W53_MAX_NUM: 4,
  RADIO_DATA_W5G_W56_MAX_NUM: 12,
  RADIO_DATA_W5G_W52_INDEX: 4,
  RADIO_DATA_W5G_W56_INDEX: 12,
  RADIO_DATA_W5G_MAX_NUM: 24,
  RADIO_DATA_MAX_NUM: 38,
  RADIO_DATA_ITEM_NUM: 7,
  RADIO_MONITOR_ROUTE_MAX_NUM: 6, // 最大ルート数（例：ゲートウェイモード含む）
  RADIO_MONITOR_ROUTE_ITEM_NUM: 9, // 各ルート項目数
  ALL_RADIO_DATA_ISM900_MAX_NUM: 38,
  ALL_RADIO_DATA_W2_4G_MAX_NUM: 47,
  ALL_RADIO_DATA_L5G_MAX_NUM: 151,
  ALL_RADIO_DATA_W5G_MAX_NUM: 243,
  RADIO_DATA_START_FREQ_ISM900: 920.6, // 920.6MHz
  RADIO_DATA_START_FREQ_W2_4G: 2412, // 2412MHz
  RADIO_DATA_START_FREQ_L5G: 4625, // 4625MHz
  RADIO_DATA_START_FREQ_W5G: 4920, // 4920MHz
  RADIO_DATA_FREQ_GAP_ISM900: 0.2, // 0.2MHz
  RADIO_DATA_FREQ_GAP_W2_4G: 5, // 5MHz
  RADIO_DATA_FREQ_GAP_L5G: 50, // 50MHz
  RADIO_DATA_FREQ_GAP_W5G: 20, // 20MHz
  RADIO_DATA_FREQ_W2_4G_CH14: 2484, // 2484MHz
  RADIO_DATA_FREQ_W5G_W49: 4920, // 4920MHz
  RADIO_DATA_FREQ_W5G_W52: 5180, // 5180MHz
  RADIO_DATA_FREQ_W5G_W53: 5260, // 5260MHz
  RADIO_DATA_FREQ_W5G_W56: 5500, // 5500MHz
  ALL_RADIO_DATA_START_FREQ_ISM900: 920.6, // 920.6MHz
  ALL_RADIO_DATA_START_FREQ_W2_4G: 2402, // 2402MHz
  ALL_RADIO_DATA_START_FREQ_L5G: 4600, // 4600MHz
  ALL_RADIO_DATA_START_FREQ_W5G: 4910, // 4910MHz
  ALL_RADIO_DATA_FREQ_GAP_ISM900: 0.2, // 0.2MHz
  ALL_RADIO_DATA_FREQ_GAP_W2_4G: 2, // 2MHz
  ALL_RADIO_DATA_FREQ_GAP_L5G: 2, // 2MHz
  ALL_RADIO_DATA_FREQ_GAP_W5G: 2, // 2MHz
  ROUTE_EACH_DATA_LEN: 30,
  GNSS_LOCATION_INVALID_VALUE: '80000000', // GNSS無効値指定用文字列定義
  GNSS_LOCATION_MULTIPLE: 10000000, // GNSS位置情報のスケーリング係数指定
  GNSS_INVALID_VALUE_DOUBLE: 1000,
  VCC_MULTIPLE: 100, // 電源電圧スケーリング係数指定
  START_FREQ_MULTIPLE: 10, // 開始周波数スケーリング係数指定
  START_FREQ_ALL_CH_MONITOR: 'FFFF', // 全チャネルモニタリング時開始周波数値指定用文字列
  RADIO_DATA_EACH_LEN: 3, // 各データ長さを設定
  INVALID_RMS_DATA: 'NNN',

  FREQ_TABLE: {
    0x00: [
      920.6, 920.8, 921.0, 921.2, 921.4, 921.6, 921.8, 922.0, 922.2, 922.4, 922.6, 922.8, 923.0, 923.2, 923.4, 923.6,
      923.8, 924.0, 924.2, 924.4, 924.6, 924.8, 925.0, 925.2, 925.4, 925.6, 925.8, 926.0, 926.2, 926.4, 926.6, 926.8,
      927.0, 927.2, 927.4, 927.6, 927.8, 928.0,
    ],
    0x01: [
      2412, 2417, 2422, 2427, 2432, 2437, 2442, 2447, 2452, 2457, 2462, 2467, 2472, 2474, 2476, 2478, 2480, 2482, 2484,
      2486, 2488, 2490, 2492, 2494, 2496, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    0x02: [
      4625, 4675, 4725, 4775, 4825, 4875, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0,
    ],
    0x03: [
      4920, 4940, 4960, 4980, 5180, 5200, 5220, 5240, 5260, 5280, 5300, 5320, 5500, 5520, 5540, 5560, 5580, 5600, 5620,
      5640, 5660, 5680, 5700, 5720, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  },

  DATA_TYPE_ISM900: 'ISM900',
  DATA_TYPE_W2_4G: 'W2_4G',
  DATA_TYPE_L5G: 'L5G',
  DATA_TYPE_W5G: 'W5G',
  JSON_DATA_TYPE_DEVICEINFO: 'deviceInfo',
  JSON_DATA_TYPE_ISM900: 'ism900',
  JSON_DATA_TYPE_W2_4G: 'w2_4g',
  JSON_DATA_TYPE_L5G: 'l5g',
  JSON_DATA_TYPE_W5G: 'w5g',
  JSON_DATA_TYPE_ROUTE: 'route',
  INVALID: '無効',
  VALID: '有効',
  DISCONNECTED: '未接続',
  CONNECTED: '接続',
  SAVE_DATA_COM_NONE: 0,
  SAVE_DATA_COM_START: 1,
  SAVE_DATA_COM_END: 2,
  SAVE_DATA_NORMAL: 0,
  SAVE_DATA_GARBAGE_DATA_EXIST: 1,
  SAVE_DATA_ALERT_CAPACITY: 2,
  SAVE_DATA_MAX_PAGE_NUM: 65024,
  SAVE_DATA_RADIO_DATA_EACH_LEN: 10,
  SAVE_DATA_INVALID_RMS_DATA: 'NNNNNNNNNN',
  PROGRESS_VAR_INIT: 5,
  PROGRESS_VAR_PRE_PROCESS_START: 10,
  PROGRESS_VAR_READ_START: 20,
  PROGRESS_VAR_SAVE_CSV_START: 45,
  PROGRESS_VAR_ERASE_START: 70,
  PROGRESS_VAR_POST_PROCESS_START: 95,
  PROGRESS_VAR_FIN: 100,
  PROGRESS_VAR_SAVE_DATA_PROCESS_BLOCK_VAL: 25,
};

export type JsonRms = {
  deviceId: string;
  timestamp: number;
  rssi: number;
  dataType: string;
  dataTypeNum: string;
  gnss: JsonGnssdata;
  data: JsonRmsData;
};

export type JsonRmsData = {
  powerSupplyVoltage: number;
  temperature: number;
  dataArray: JsonRmsRssiData[];
};

export type JsonGnssdata = {
  latitude: number | undefined;
  longitude: number | undefined;
  hAcc: number | undefined;
  vAcc: number | undefined;
  height: number | undefined;
};

export type JsonRmsRssiData = {
  frequency: number;
  upperRate: number;
  lowerRate: number;
  max: number;
  min: number;
  average: number;
};

export type DeviceInfo = {
  rfTrxSerialId: string;
  saveDataPageNum: number;
};

export type DeviceConfig = {
  ch: string;
  port: string;
  panid: string;
  myId: string;
  reportInterval: string;
  routerStatus: string;
  rssiTime: string;
  storageFunc: string;
  monitorExclusion: string;
  ism900MonFreq: string;
  ism900MonUpperTh: string;
  w24gMonFreq: string;
  w24gMonUpperTh: string;
  l5gMonFreq: string;
  l5gMonUpperTh: string;
  w5gMonFreq: string;
  w5gMonUpperTh: string;
};

export const COMMAND_TYPES = {
  GET_DEVICE_INFO: 'WIG00',
  GET_DEVICE_INFO_WITH_UPDATE: 'WIG01',
  GET_DEVICE_CONFIG: 'WCG',
  START_DATA_ACCESS: 'WSB',
  END_DATA_ACCESS: 'WSF',
  GET_IOT_DATA: 'WSG',
  DELETE_DEVICE_DATA: 'WSE',
};

export const RESPONSE_TYPES = {
  GET_DEVICE_INFO: 'PIG',
  GET_DEVICE_CONFIG: 'PCG',
  START_DATA_ACCESS: 'PSB',
  END_DATA_ACCESS: 'PSF',
  GET_IOT_DATA: 'PSG',
  DELETE_DEVICE_DATA: 'PSE',
};

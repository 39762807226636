import React from 'react';
import { TextField } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
  value: string;
  onChange: (event: { target: { value: React.SetStateAction<string> } }) => void;
  sx?: SxProps;
  inputProps?: { [key: string]: number | string | boolean };
}

const MemoizedTextField = ({ value, onChange, sx, inputProps }: Props) => {
  return <TextField variant='outlined' value={value} onChange={onChange} sx={sx} inputProps={inputProps} />;
};

export default React.memo(MemoizedTextField);

import React, { Dispatch, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';

// NOTE: カスタムコンポーネント
import { SelectedFrequencyBandContext } from '../../../../radioMonitoringPage';
import { ChannelRowType } from '../../../../domain/types/channel';
import {
  getRecommendSymbolFromLevel,
  formatFrequencyLabelForChannel,
  getColorFromLevel,
  getRecommendChannelLabel,
} from '../../../../utils/format';
import { MAIN_PAGE_TAB_INDEX } from 'domain/types/common/consts';
import { LayoutContext } from '../../LayoutContext';
import {
  CHANNEL_GROUP_2_4G,
  CHANNEL_GROUP_DETAIL_2_4G,
  FREQUENCIES,
  FREQUENCY_BAND,
  FrequencyType,
} from 'domain/types/frequency';
import { RwmContext } from '../../../../RwmContext';
import { getFrequenciesFromChannel, getFrequenciesIncludeChannelGroup } from 'utils/extract';
/**
 * @param {ChannelRowType[]} channelData 推奨チャンネルデータ
 */
interface Props {
  channelData: ChannelRowType[];
  isDisplayValue: boolean;
  setIsDisplayValue: Dispatch<React.SetStateAction<boolean>>;
}
// NOTO: 推奨チャンネルヘッダー定義
const header = {
  recommend: '推奨',
  freq: 'Freq',
  ch: 'CH',
  occupancyLevel: '(占有率)',
  noiseLevel: '(ノイズ)',
};

// NOTO: セルスタイル
const CustomTableCell = styled(TableCell)({
  borderWidth: '1px',
  borderColor: '#FFFFFF',
});

export const ChannelTable = ({ channelData, isDisplayValue, setIsDisplayValue }: Props) => {
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const {
    setMainPageTabIndex,
    setFrequencyCheckBoxStates,
    isDisplayedChannel,
    setSelectedChannelGroups,
    setChannelCheckBoxStates,
  } = useContext(LayoutContext);
  const { setSelectedFrequencies } = useContext(RwmContext);
  const handleCellClick = () => {
    setIsDisplayValue((prev) => !prev);
  };

  const handleDoubleClick = (channelRow: ChannelRowType) => {
    //マップタブへ遷移
    setMainPageTabIndex(MAIN_PAGE_TAB_INDEX.MAP);

    //CHグループ表示時の場合の設定
    if (isDisplayedChannel) {
      const chackBoxStates = CHANNEL_GROUP_2_4G.map((c) => {
        let ret: boolean = false;
        if (c.channel == channelRow.ch) {
          ret = true;
          setSelectedChannelGroups([c]);
        } else {
          ret = false;
        }
        return ret;
      });
      const frequencyChannel = CHANNEL_GROUP_2_4G.find((f) => f.frequency == channelRow.freq);
      if (frequencyChannel) {
        const frequenciesIncludeChannelGroup: FrequencyType[] = getFrequenciesIncludeChannelGroup(
          frequencyChannel,
          FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list
        );
        setSelectedFrequencies(frequenciesIncludeChannelGroup);
      }
      setChannelCheckBoxStates(chackBoxStates);
      return;
    }

    //周波数パネル選択
    const freqs = FREQUENCIES[selectedFrequencyBand].list;
    let chackBoxStates: boolean[] = [];
    let selectedFrequencies: FrequencyType[] = [];

    if (selectedFrequencyBand == FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID) {
      //2.4GHzの場合は特殊：指定した周波数が「FREQUENCIES」に存在しない場合は「CHANNEL_GROUP_DETAIL_2_4G」で定義しているmin-maxの範囲で指定する
      const frequencyChannel = CHANNEL_GROUP_DETAIL_2_4G.find((f) => f.frequency == channelRow.freq);
      if (frequencyChannel) {
        selectedFrequencies = getFrequenciesFromChannel(frequencyChannel, freqs);
        chackBoxStates = freqs.map((element) => {
          return selectedFrequencies.includes(element) ? true : false;
        });
      }
    } else {
      chackBoxStates = freqs.map((f) => {
        let ret: boolean = false;
        if (f.frequency == channelRow.freq) {
          ret = true;
          selectedFrequencies.push(f);
        } else {
          ret = false;
        }
        return ret;
      });
    }
    setSelectedFrequencies(selectedFrequencies);
    setFrequencyCheckBoxStates(chackBoxStates);
  };

  return (
    <TableContainer>
      <Table sx={{ maxWidth: '300px', margin: 'auto' }}>
        <TableHead />
        <TableBody>
          {Object.keys(header).map((key) => (
            <TableRow
              key={key}
              sx={{
                // NOTO: 各行の高さ設定
                height: key === 'recommend' ? '240px' : key === 'freq' ? '100px' : '50px',
              }}
            >
              <CustomTableCell align='center' sx={{ maxWidth: '80px', fontSize: '12px' }} onClick={handleCellClick}>
                {header[key as keyof typeof header]}
                {key === 'freq' && selectedFrequencyBand.includes('ism900')
                  ? '（MHz）'
                  : key === 'freq'
                    ? '（GHz）'
                    : ''}
              </CustomTableCell>

              {channelData.map((row) => (
                <Tooltip
                  title={
                    key === 'recommend' && isDisplayValue ? (
                      <React.Fragment>
                        <Typography>{row.occupancy}</Typography>
                        <Typography> {row.noise}</Typography>
                      </React.Fragment>
                    ) : (
                      ''
                    )
                  }
                  key={row.freq}
                >
                  <CustomTableCell
                    align='center'
                    sx={{
                      maxWidth: '30px',
                      minHeight: key === 'freq' ? '100px' : '',
                      padding: '1px',
                      fontSize: key === 'recommend' ? '9px' : '12px',
                      bgcolor: key === 'recommend' ? getColorFromLevel(Number(row.recommend)) : '#000000',
                      //周波数のみ縦書き
                      writingMode: key === 'freq' ? 'vertical-rl' : '',
                      textOrientation: key === 'freq' ? 'upright' : '',
                    }}
                    onDoubleClick={() => handleDoubleClick(row)}
                  >
                    {key === 'recommend' && isDisplayValue ? `${row.occupancy.toFixed(1)}%\n${row.noise}` : ''}
                    {key === 'freq' ? formatFrequencyLabelForChannel(row.freq) : ''}
                    {key === 'ch' ? getRecommendChannelLabel(selectedFrequencyBand, row.ch, row.freq) : ''}
                    {key === 'occupancyLevel' ? getRecommendSymbolFromLevel(row.occupancyLevel) : ''}
                    {key === 'noiseLevel' ? getRecommendSymbolFromLevel(row.noiseLevel) : ''}
                  </CustomTableCell>
                </Tooltip>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import React from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import { Typography, Box, ThemeProvider, CssBaseline } from '@mui/material';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {
  signUp,
  confirmSignUp,
  SignUpInput,
  ConfirmSignUpInput,
  SignUpOutput,
  ConfirmSignUpOutput,
  fetchAuthSession,
} from '@aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import { IoTClient, AttachPolicyCommand, ListAttachedPoliciesCommand } from '@aws-sdk/client-iot';
import { useTranslation } from 'react-i18next';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';
import { CustomTheme } from './theme';
import RadioMonitoringPage from './radioMonitoringPage';
import ErrorFallback from 'components/Common/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

const customTranslations = {
  'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
  'User does not exist.': 'ユーザーが存在しません',
  'Your passwords must match': 'パスワードを一致させてください',
  'Password must have at least 8 characters': 'パスワードは8文字以上にしてください',
  'Password did not conform with policy: Password must have uppercase characters':
    'パスワードには大文字を含めてください (8文字以上の大文字小文字数字記号を含む英数字)',
  'Password did not conform with policy: Password must have numeric characters':
    'パスワードには数字を含めてください (8文字以上の大文字小文字数字記号を含む英数字)',
  'Password did not conform with policy: Password must have symbol characters':
    'パスワードには記号を含めてください (8文字以上の大文字小文字数字記号を含む英数字)',
  'Password did not conform with policy: Password must have lowercase characters':
    'パスワードには小文字を含めてください (8文字以上の大文字小文字数字記号を含む英数字)',
  'Invalid verification code provided, please try again.': '指定された確認コードが無効です。もう一度お試しください',
  // 他のエラーメッセージも必要に応じて追加
};
I18n.putVocabulariesForLanguage('ja', customTranslations);
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID || '',
      userPoolClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_CLIENT_ID || '',
      identityPoolId: process.env.REACT_APP_ID_POOL_ID || '',
    },
  },
});

const AuthCheck = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  // console.log(query);
  const { route } = useAuthenticator((context) => [context.route]);
  const { t } = useTranslation();

  const handleSignUp = async ({ username, password }: SignUpInput): Promise<SignUpOutput> => {
    const tenantId = query.get('tenantid');
    // NOTE: クエリストリングにテナントが指定されているかチェック
    if (tenantId == null) {
      alert(t('URLが不正です。URLを確認してください。'));
      return {
        nextStep: {
          signUpStep: 'DONE',
        },
        isSignUpComplete: false,
      };
    }

    // NOTE: pfで定義されたドメイン制限に関するエラーメッセージに対応
    const getIsNotAllowedDomainMessage = (email: string): string => {
      const domain = email.split('@')[1];
      return `PreSignUp failed with error Domain "${domain}" is not allowed..`;
    };
    const notAllowedMessage: string = getIsNotAllowedDomainMessage(username);
    const customErrorMessage = {
      [notAllowedMessage]: '許可されていないドメインです',
      [`PreSignUp failed with error Tenant "${tenantId}" is not set allowed domains..`]: '許可されていないドメインです',
      [`PreSignUp failed with error Tenant "${tenantId}" is not exist..`]: 'テナントの指定が不正です',
      [`PreSignUp failed with error Email format does not conform to RFC5322. Email: ${username}.`]:
        'メールアドレスのフォーマットが不正です',
      [`Invalid email address format.`]: 'メールアドレスのフォーマットが不正です',
    };
    I18n.putVocabulariesForLanguage('ja', customErrorMessage);

    return await signUp({
      username,
      password,
      options: {
        userAttributes: {
          email: username,
          'custom:tenantId': tenantId,
        },
        autoSignIn: true,
      },
    });
  };

  const handleConfirmSignUp = async ({
    username,
    confirmationCode,
  }: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> => {
    return await confirmSignUp({
      username,
      confirmationCode,
    });
  };

  const attachIotPolicy = async () => {
    try {
      //NOTE: セッション情報取得
      const session = await fetchAuthSession({ forceRefresh: true });
      const sdkCredentials = {
        accessKeyId: session.credentials?.accessKeyId || '',
        secretAccessKey: session.credentials?.secretAccessKey || '',
        sessionToken: session.credentials?.sessionToken,
        expiration: session.credentials?.expiration,
      };
      // console.log('sdkcredentials', sdkCredentials);
      // const iotClient = new IoTClient(sdkCredentials);

      // NOTE: IDプールのIdentityIDに紐づくポリシーを確認（IoTポリシーがない場合はアタッチ）
      const iotClient = new IoTClient({ region: process.env.REACT_APP_REGION, credentials: sdkCredentials });
      const iotPolicyName = process.env.REACT_APP_AWS_IOT_POLICY || '';
      const identityId = session.identityId;

      let command;
      command = new ListAttachedPoliciesCommand({ target: identityId, recursive: true });
      const response = await iotClient.send(command);
      const iotPolicy = response.policies?.find((policy) => policy.policyName === iotPolicyName);

      if (!iotPolicy) {
        command = new AttachPolicyCommand({
          policyName: iotPolicyName,
          target: identityId,
        });

        await iotClient.send(command);
        console.log('IoT Policy attached successfully.', command);
      } else {
        console.log('IoT Policy already attached.', command);
      }
    } catch (error) {
      console.error('Error attaching IoT Policy:', error);
    }
  };

  if (route == 'authenticated') {
    (async () => {
      await attachIotPolicy();
    })();
  }

  return route == 'authenticated' ? (
    <Authenticator>
      <ThemeProvider theme={CustomTheme}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <RadioMonitoringPage />
        </ErrorBoundary>
      </ThemeProvider>
    </Authenticator>
  ) : (
    <Box sx={{ padding: '10%' }}>
      <Typography variant='h5' textAlign={'center'}>
        {t('電波監視')}
      </Typography>
      <Authenticator
        services={{ handleSignUp: handleSignUp, handleConfirmSignUp: handleConfirmSignUp }}
        loginMechanisms={['email']}
        signUpAttributes={['email']}
      />
    </Box>
  );
};

const Login = () => {
  return (
    <Authenticator.Provider>
      <AuthCheck />
    </Authenticator.Provider>
  );
};

export default Login;

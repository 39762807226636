import { RadioWave } from 'core/entities/radioWave';
import { getToken, stage, pfApiEndPoint } from '../base';
import { wrapForObjects } from 'core/commons/dataConverter';
export const getNearestIotData = async (
  timestamp: string,
  dataType: string,
  deviceIds: string[],
  isBeforeFlag: string
): Promise<RadioWave[]> => {
  const token = await getToken();
  const params = new URLSearchParams({
    time_stamp: timestamp,
    is_before_flag: isBeforeFlag,
  });
  // device_ids[] パラメータの追加
  deviceIds.forEach((deviceId) => {
    params.append('device_ids[]', deviceId);
  });

  console.log('fetch get:' + pfApiEndPoint);

  const res = await fetch(
    `${pfApiEndPoint}/${stage}/devices/list/datatypes/${dataType}/methods/nearest?${params.toString()}`,
    {
      headers: { Authorization: token },
    }
  );
  const unitListJson = await res.json();
  const item = wrapForObjects<RadioWave>(unitListJson);

  return item;
};

import React, { useContext, useMemo } from 'react';
import CustomButton from 'components/Common/CustomButton';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { getSelectedMap } from 'utils/extract';

const EditMapCancelButton = () => {
  const { center, zoom, mapTypeId, elevation, setTmpCenter, setTmpZoom, setTmpMapTypeId, setTmpElevation } =
    useContext(RwmMapAreaContext);
  const {
    imageWidth,
    imageHeight,
    imageRotate,
    imageLat,
    imageLng,
    setMapMode,
    setTmpImageWidth,
    setTmpImageHeight,
    setTmpImageRotate,
    setTmpImageLat,
    setTmpImageLng,
  } = useContext(RwmMapTabContext);
  const { mapsData } = useMaps();
  const { selectedMapId } = useContext(LayoutContext);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);

  /**
   * @description マップ位置編集キャンセル選択時の処理
   */
  const handleClick = () => {
    setTmpCenter(center);
    setTmpZoom(zoom);
    setTmpMapTypeId(mapTypeId);
    setTmpElevation(elevation);
    setMapMode('read');

    if (selectedMap.mapType === 'imageMap') {
      setTmpImageWidth(imageWidth);
      setTmpImageHeight(imageHeight);
      setTmpImageRotate(imageRotate);
      setTmpImageLat(imageLat);
      setTmpImageLng(imageLng);
    }
  };

  return <CustomButton title={'キャンセル'} sx={{ width: 'fit-content' }} onClick={() => handleClick()} />;
};

export default React.memo(EditMapCancelButton);

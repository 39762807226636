import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Split from 'react-split';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Box } from '@mui/material';
import CheckBoxList from './CheckBoxList/CheckBoxList';
import MainPage from './MainPage/MainPage';
import CustomIconButton from '../Common/CustomIconButton';
import { MAIN_PAGE_TAB_INDEX, WorkModeType } from 'domain/types/common/consts';
import { LayoutContext } from './LayoutContext';
import { MAIN_MAP_ID, MapObjType, MapPinIdsType } from 'domain/types/map';
import { getMapIds, getPinCheckBoxStates, getPinsAndIds } from 'utils/extract';
import { useMaps } from 'MapsContext';
import LoadingScreen from 'components/Common/LoadingScreen';
import { SelectedGatewayContext, SelectedFrequencyBandContext } from 'radioMonitoringPage';
import { useErrorBoundary } from 'react-error-boundary';
import { useRwmCache } from 'RwmContext';
import { CHANNEL_GROUP_2_4G, FREQUENCIES, FrequencyChannelType } from 'domain/types/frequency';

const getInitialStates = (
  mapsData: MapObjType[],
  selectedMapId: string,
  candidatePinIds: string[],
  caSelectedPinIds: string[] | undefined,
  hideMapPinIds: MapPinIdsType
) => {
  const allMapIds = getMapIds(mapsData);
  const { registeredPinIds, allPinIds } = getPinsAndIds(mapsData, selectedMapId, candidatePinIds);
  //NOTE: ピン非表示リストに追加されたピンはマップに表示しない
  let checkedPinIds = registeredPinIds;
  if (hideMapPinIds) {
    const hidePinIds = hideMapPinIds[selectedMapId];
    if (hidePinIds && hidePinIds.length >= 1) {
      checkedPinIds = checkedPinIds.filter((item) => !hidePinIds.includes(item));
    }
  }
  let selectedPinIds = checkedPinIds;
  let pinCheckBoxStates = getPinCheckBoxStates('Map', allPinIds, checkedPinIds);

  if (caSelectedPinIds !== undefined) {
    const _states: boolean[] = [];
    allPinIds.map((id) => {
      if (caSelectedPinIds.includes(id)) _states.push(true);
      else _states.push(false);
    });
    selectedPinIds = caSelectedPinIds;
    pinCheckBoxStates = _states;
  }

  return {
    allMapIds,
    allPinIds,
    selectedPinIds,
    pinCheckBoxStates,
    registeredPinIds,
    allPinIdsForGraph: candidatePinIds,
    selectedPinIdsForGraph: candidatePinIds,
    pinCheckBoxStatesForGraph: [true],
    isDisplayedChannel: false,
  };
};

interface Props {
  isExistMainMap: boolean;
  handleChangeFlag: (flag: boolean) => void;
  candidatePinIds: string[];
}

const SplitLayout = ({ isExistMainMap, handleChangeFlag, candidatePinIds }: Props) => {
  const { showBoundary } = useErrorBoundary();
  const { mapsData, getMaps } = useMaps();
  const { caSelectedPinIds, setCaSelectedPinIds } = useRwmCache();
  const { selectedGateway } = useContext(SelectedGatewayContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);

  // NOTE: チェックボックスメニューのプロパティ
  const [isDisplayedCheckBoxList, setIsDisplayedCheckBoxList] = useState<boolean>(true);
  const [splitLeftRatio, setSplitLeftRatio] = useState<number>(83);
  const [splitRightRatio, setSplitRightRatio] = useState<number>(17);
  const [splitGutterSize, setSplitGutterSize] = useState<number>(5);

  // NOTE: 選択中のマップ ID
  const [selectedMapId, setSelectedMapId] = useState<string>(MAIN_MAP_ID);

  // NOTE: ピン非表示リスト
  const [hideMapPinIds, setHideMapPinIds] = useState<MapPinIdsType>({});

  const initialStates = useMemo(
    () => getInitialStates(mapsData, selectedMapId, candidatePinIds, caSelectedPinIds, hideMapPinIds),
    [mapsData, selectedMapId, candidatePinIds, caSelectedPinIds, hideMapPinIds]
  );

  // NOTE: 全てのマップ ID リスト
  const [allMapIds, setAllMapIds] = useState<string[]>(initialStates.allMapIds);
  // NOTE: マップに紐づく全てのピン ID リスト
  const [allPinIds, setAllPinIds] = useState<string[]>(initialStates.allPinIds);
  // NOTE: 選択中のピン ID リスト
  const [selectedPinIds, setSelectedPinIds] = useState<string[]>(initialStates.selectedPinIds);
  // NOTE: ID 選択チェックボックスの状態リスト
  const [pinCheckBoxStates, setPinCheckBoxStates] = useState<boolean[]>(initialStates.pinCheckBoxStates);
  // NOTE: 登録済みのピン ID リスト
  const [registeredPinIds, setRegisteredPinIds] = useState<string[]>(initialStates.registeredPinIds);
  // NOTE: グラフ用全てのピン ID リスト
  const [allPinIdsForGraph, setAllPinIdsForGraph] = useState<string[]>(initialStates.allPinIdsForGraph);
  // NOTE: グラフ用選択中のピン ID リスト
  const [selectedPinIdsForGraph, setSelectedPinIdsForGraph] = useState<string[]>(initialStates.selectedPinIdsForGraph);
  // NOTE: グラフ用 ID 選択チェックボックスの状態リスト
  const [pinCheckBoxStatesForGraph, setPinCheckBoxStatesForGraph] = useState<boolean[]>(
    initialStates.pinCheckBoxStatesForGraph
  );
  // NOTE: 移動モード用全てのピン ID リスト
  const [allPinIdsForTrack, setAllPinIdsForTrack] = useState<string[]>(initialStates.registeredPinIds);
  // NOTE: 移動モード用選択中のピン ID リスト
  const [selectedPinIdsForTrack, setSelectedPinIdsForTrack] = useState<string[]>([]);
  // NOTE: 移動モード用 ID 選択チェックボックスの状態リスト
  const [pinCheckBoxStatesForTrack, setPinCheckBoxStatesForTrack] = useState<boolean[]>(
    new Array(initialStates.registeredPinIds.length).fill(false)
  );
  // NOTE: CH表示選択
  const [isDisplayedChannel, setIsDisplayedChannel] = useState<boolean>(initialStates.isDisplayedChannel);
  // NOTE: workMode（Map、Graph、Track）
  const [workMode, setWorkMode] = useState<WorkModeType>('Map');
  // NOTE: メインページタブインデックス（1：マップ、2：推奨チャンネル、3、グラフ）
  const [mainPageTabIndex, setMainPageTabIndex] = useState<string>(MAIN_PAGE_TAB_INDEX.MAP);
  // NOTE: GPS有効・無効
  const [isEnableGps, setIsEnableGps] = useState<boolean>(false);

  // NOTE: 周波数表示用の変数
  const numFrequencyBox: number = FREQUENCIES[selectedFrequencyBand].list.length;
  const [frequencyCheckBoxStates, setFrequencyCheckBoxStates] = useState<boolean[]>(
    new Array(numFrequencyBox).fill(true)
  );

  // NOTE: CHグループ表示用の変数
  const numChannelBox: number = CHANNEL_GROUP_2_4G.length;
  const [channelCheckBoxStates, setChannelCheckBoxStates] = useState<boolean[]>(new Array(numChannelBox).fill(false));
  const [selectedChannelGroups, setSelectedChannelGroups] = useState<FrequencyChannelType[]>([]);
  /**
   * @description 初期状態を更新
   */
  useEffect(() => {
    const {
      allMapIds,
      allPinIds,
      selectedPinIds,
      pinCheckBoxStates,
      registeredPinIds,
      allPinIdsForGraph,
      selectedPinIdsForGraph,
      pinCheckBoxStatesForGraph,
      isDisplayedChannel,
    } = getInitialStates(mapsData, selectedMapId, candidatePinIds, caSelectedPinIds, hideMapPinIds);

    setAllMapIds(allMapIds);
    setAllPinIds(allPinIds);
    setSelectedPinIds(selectedPinIds);
    setPinCheckBoxStates(pinCheckBoxStates);
    setRegisteredPinIds(registeredPinIds);
    setAllPinIdsForGraph(allPinIdsForGraph);
    setSelectedPinIdsForGraph(selectedPinIdsForGraph);
    setPinCheckBoxStatesForGraph(pinCheckBoxStatesForGraph);
    setAllPinIdsForTrack(registeredPinIds);
    setPinCheckBoxStatesForTrack(new Array(registeredPinIds.length).fill(false));
    setIsDisplayedChannel(isDisplayedChannel);
  }, [mapsData, selectedMapId, selectedGateway, candidatePinIds, caSelectedPinIds, hideMapPinIds]);

  useEffect(() => {
    setSelectedMapId(MAIN_MAP_ID);
  }, [selectedGateway]);

  useEffect(() => {
    setCaSelectedPinIds(undefined);
  }, [selectedGateway, selectedMapId, setCaSelectedPinIds]);

  /**
   * @description マップ選択ボックス用の処理
   */
  const handleMapSelect = useCallback((mapId: string) => {
    setSelectedMapId(mapId);
  }, []);

  /**
   * @description サイドメニューの開閉処理
   */
  const handleClickCheckBox = useCallback(() => {
    if (isDisplayedCheckBoxList) {
      setSplitLeftRatio(100);
      setSplitRightRatio(0);
      setSplitGutterSize(0);
    } else {
      setSplitLeftRatio(75);
      setSplitRightRatio(25);
      setSplitGutterSize(5);
    }
    setIsDisplayedCheckBoxList(!isDisplayedCheckBoxList);
  }, [isDisplayedCheckBoxList]);

  /**
   * @description タブを選択した際に動作する関数
   * @param {string} tabIndex 選択されたタブのインデックス
   */
  const handleTabChange = useCallback((tabIndex: string) => {
    setMainPageTabIndex(tabIndex);

    if (tabIndex == MAIN_PAGE_TAB_INDEX.GRAPH) {
      setWorkMode('Graph');
    } else {
      setWorkMode('Map');
    }
  }, []);

  if (!isExistMainMap) {
    (async () => {
      try {
        await getMaps(true);
      } catch (error) {
        showBoundary(error);
        return;
      }
      handleChangeFlag(true);
    })();
  }
  if (!mapsData || mapsData.length < 1) return <LoadingScreen />;
  return (
    <LayoutContext.Provider
      value={{
        allMapIds,
        handleMapSelect,
        setAllMapIds,
        selectedMapId,
        setSelectedMapId,
        allPinIds,
        setAllPinIds,
        selectedPinIds,
        setSelectedPinIds,
        pinCheckBoxStates,
        setPinCheckBoxStates,
        registeredPinIds,
        setRegisteredPinIds,
        allPinIdsForGraph,
        setAllPinIdsForGraph,
        selectedPinIdsForGraph,
        setSelectedPinIdsForGraph,
        pinCheckBoxStatesForGraph,
        setPinCheckBoxStatesForGraph,
        allPinIdsForTrack,
        setAllPinIdsForTrack,
        selectedPinIdsForTrack,
        setSelectedPinIdsForTrack,
        pinCheckBoxStatesForTrack,
        setPinCheckBoxStatesForTrack,
        workMode,
        setWorkMode,
        mainPageTabIndex,
        setMainPageTabIndex,
        isDisplayedChannel,
        setIsDisplayedChannel,
        isEnableGps,
        setIsEnableGps,
        frequencyCheckBoxStates,
        setFrequencyCheckBoxStates,
        channelCheckBoxStates,
        setChannelCheckBoxStates,
        selectedChannelGroups,
        setSelectedChannelGroups,
        hideMapPinIds,
        setHideMapPinIds,
      }}
    >
      <Box sx={{ height: 1 }}>
        <CustomIconButton
          onClick={() => {
            handleClickCheckBox();
          }}
          tooltipTitle={isDisplayedCheckBoxList ? 'サイドメニューを閉じる' : 'サイドメニューを開く'}
          Icon={isDisplayedCheckBoxList ? KeyboardDoubleArrowRightIcon : KeyboardDoubleArrowLeftIcon}
          sx={{ position: 'absolute', right: 10, zIndex: 10 }}
          placement='bottom-end'
        />
        <Split
          className='flex h-full'
          sizes={[splitLeftRatio, splitRightRatio]}
          minSize={[
            (window.innerWidth * 3) / 5,
            isDisplayedCheckBoxList ? (isDisplayedCheckBoxList ? window.innerWidth / 6 : 0) : 0,
          ]}
          snapOffset={0}
          gutterSize={splitGutterSize}
          gutter={() => {
            const gutterElement = document.createElement('div');
            gutterElement.className = 'bg-neutral-600 cursor-pointer';
            return gutterElement;
          }}
          direction='horizontal'
        >
          <MainPage className='px-1 pb-2' tabIndex={mainPageTabIndex} handleTabChange={handleTabChange} />
          <CheckBoxList className={isDisplayedCheckBoxList ? '' : 'hidden w-0 h-0'} />
        </Split>
      </Box>
    </LayoutContext.Provider>
  );
};

export default React.memo(SplitLayout);

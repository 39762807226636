import React, { ReactNode, Dispatch, SetStateAction } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CustomDialogButton } from './CustomDialogButton';

interface Props {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  confirmProcess: () => void;
  dialogTitle?: string;
  dialogText?: string | ReactNode;
}

const CustomPopup = ({
  isOpened,
  setIsOpened,
  confirmProcess,
  dialogTitle,
  dialogText = '以下のいずれかを選択してください',
}: Props) => {
  const handleClose = () => {
    setIsOpened(false);
  };

  const handleConfirm = () => {
    confirmProcess();
    setIsOpened(false);
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          background: '#000',
          borderBlockColor: '#333333',
          borderWidth: 1,
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'white' }}>{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomDialogButton onClick={handleClose} title={'キャンセル'}></CustomDialogButton>
        <CustomDialogButton onClick={handleConfirm} color='primary' autoFocus title={'OK'}></CustomDialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CustomPopup);

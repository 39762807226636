import { UseCase } from 'core/usecases/usecase';
import { AreaMasterRepository } from 'domain/types/area';
import { BasePinType, MapPinType } from 'domain/types/map';

export class StorePin implements UseCase<Promise<BasePinType>, StorePinParams> {
  private areaMasterRepository: AreaMasterRepository;
  constructor(areaMasterRepository: AreaMasterRepository) {
    this.areaMasterRepository = areaMasterRepository;
  }

  public async call(params: StorePinParams): Promise<BasePinType> {
    const areaId = params.areaId;
    const gatewayId = params.gatewayId;
    const mapId = params.mapId;
    const mapPin = params.mapPin;
    return await this.areaMasterRepository.storePin(areaId, gatewayId, mapId, mapPin);
  }
}

export class StorePinParams {
  constructor(
    public areaId: string,
    public gatewayId: string,
    public mapId: string,
    public mapPin: MapPinType
  ) {}
}

// ------------------------------------------------------
// NOTE: インターフェースの定義
/**
 * @description Rssiデータのインターフェース
 * @property {number} freq 周波数
 * @property {number} upperRate 上限超過割合
 * @property {number} rssiMax RSSI最大値
 * @property {number} rssiMin RSSI最小値

 * @interface
 */
export interface RssiData {
  // TODO: 他に必要な共通のプロパティがあれば随時追加
  frequency: number;
  upperRate: number;
  max: number;
  min: number;
}

/**
 * @description RssiDataListのインターフェース
 * @property {number} key 周波数
 * @property {string} RssiData Rssiデータ
 * @interface
 */

// ------------------------------------------------------
// NOTE: 定数の定義
/**
 * @description パケットの長さ
 * @constant
 */
export const RSSI_PACKET_LEN = 7;

/**
 * @description RSSI値
 * @constant
 */
export const RSSI = {
  NONE: -130,
  MAX: 0,
  MIN: -120,
};

/**
 * @description 占有率
 * @constant
 */
export const RATE = {
  NONE: -1,
  MAX: 0,
  MIN: 100,
};

import React, { useContext } from 'react';
import { Box, SxProps } from '@mui/material';
import { SelectedFrequencyBandContext, SelectedDataSourceContext, SettingContext } from 'radioMonitoringPage';
import { getLevelSetting, getColorIndex, getColorRGBA } from 'utils/extract';
import { formatColorRGBA } from 'utils/format';
import { normalization } from 'utils/transform';
import CustomCircle from 'components/Common/CustomCircle';
import { HEAT_CIRCLE_COLOR } from 'domain/types/color';

interface Props {
  value: number;
  circleSize?: number;
  sx?: SxProps;
}

/**
 * @property {number} value noise / ratio の値
 * @property {number} circleSize 一番下にあるサークルのサイズ (サークル毎に 1 / 3 倍される)
 * @property {SxProps} sx CSS の設定
 */
export const HeatCircle = ({ value, circleSize = 24, sx }: Props) => {
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { setting } = useContext(SettingContext);
  const { max, min } = getLevelSetting(setting, selectedFrequencyBand, selectedDataSource);

  // NOTE: サークル (大) の色
  const largeColor = formatColorRGBA(
    getColorRGBA(getColorIndex(normalization(value, max, min) - 0.0), 64 / 255, HEAT_CIRCLE_COLOR)
  );
  // NOTE: サークル (中) の色
  const middleColor = formatColorRGBA(
    getColorRGBA(getColorIndex(normalization(value, max, min) - 0.0), 128 / 255, HEAT_CIRCLE_COLOR)
  );
  // NOTE: サークル (小) の色
  const smallColor = formatColorRGBA(
    getColorRGBA(getColorIndex(normalization(value, max, min)), 192 / 255, HEAT_CIRCLE_COLOR)
  );

  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: circleSize,
        height: circleSize,
      }}
    >
      <CustomCircle color={largeColor} circleSize={circleSize} sx={{ zIndex: 1 }} />
      <CustomCircle color={middleColor} circleSize={circleSize * (2 / 3)} sx={{ zIndex: 2 }} />
      <CustomCircle color={smallColor} circleSize={circleSize * (1 / 3)} sx={{ zIndex: 3 }} />
    </Box>
  );
};

export default React.memo(HeatCircle);

import { createContext, Dispatch, SetStateAction } from 'react';
import { MAIN_PAGE_TAB_INDEX, WorkModeType } from 'domain/types/common/consts';
import { FrequencyChannelType } from 'domain/types/frequency';
import { MapPinIdsType } from 'domain/types/map';

interface LayoutContext {
  // NOTE: 全てのマップ ID リスト
  allMapIds: string[];
  handleMapSelect: (mapId: string) => void;
  setAllMapIds: Dispatch<SetStateAction<string[]>>;

  // NOTE: 選択中のマップ ID
  selectedMapId: string;
  setSelectedMapId: Dispatch<SetStateAction<string>>;

  // NOTE: マップに紐づく全てのピン ID リスト (要素順に表示の為、リスト順序に気を付けること)
  allPinIds: string[];
  setAllPinIds: Dispatch<SetStateAction<string[]>>;

  // NOTE: 選択中のピン ID リスト (順不同)
  selectedPinIds: string[];
  setSelectedPinIds: Dispatch<SetStateAction<string[]>>;

  // NOTE: ID 選択チェックボックスの状態リスト (ピンリスト ID と要素位置を合わせる必要あり)
  pinCheckBoxStates: boolean[];
  setPinCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;

  // NOTE: DB 登録済みのピン ID リスト (順不同)
  registeredPinIds: string[];
  setRegisteredPinIds: Dispatch<SetStateAction<string[]>>;

  // NOTE: グラフ用全てのピン ID リスト (要素順に表示の為、リスト順序に気を付けること)
  allPinIdsForGraph: string[];
  setAllPinIdsForGraph: Dispatch<SetStateAction<string[]>>;

  // NOTE: グラフ用選択中のピン ID リスト (順不同)
  selectedPinIdsForGraph: string[];
  setSelectedPinIdsForGraph: Dispatch<SetStateAction<string[]>>;

  // NOTE: グラフ用 ID 選択チェックボックスの状態リスト (ピンリスト ID と要素位置を合わせる必要あり)
  pinCheckBoxStatesForGraph: boolean[];
  setPinCheckBoxStatesForGraph: Dispatch<SetStateAction<boolean[]>>;

  // NOTE: 移動モード用全てのピン ID リスト (要素順に表示の為、リスト順序に気を付けること)
  allPinIdsForTrack: string[];
  setAllPinIdsForTrack: Dispatch<SetStateAction<string[]>>;

  // NOTE: 移動モード用選択中のピン ID リスト (順不同)
  selectedPinIdsForTrack: string[];
  setSelectedPinIdsForTrack: Dispatch<SetStateAction<string[]>>;

  // NOTE: 移動モード用 ID 選択チェックボックスの状態リスト (ピンリスト ID と要素位置を合わせる必要あり)
  pinCheckBoxStatesForTrack: boolean[];
  setPinCheckBoxStatesForTrack: Dispatch<SetStateAction<boolean[]>>;

  // NOTE: workMode（Map、Graph、Track）
  workMode: WorkModeType;
  setWorkMode: Dispatch<SetStateAction<WorkModeType>>;

  // NOTE: メインページタブインデックス（1：マップ、2：推奨チャンネル、3、グラフ）
  mainPageTabIndex: string;
  setMainPageTabIndex: Dispatch<SetStateAction<string>>;

  // NOTE: CH表示選択
  isDisplayedChannel: boolean;
  setIsDisplayedChannel: Dispatch<React.SetStateAction<boolean>>;

  // NOTE: GPS位置有効・無効
  isEnableGps: boolean;
  setIsEnableGps: Dispatch<SetStateAction<boolean>>;

  //NOTE: 周波数表示用
  frequencyCheckBoxStates: boolean[];
  setFrequencyCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;

  //NOTE: CHグループ表示用
  channelCheckBoxStates: boolean[];
  setChannelCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;
  selectedChannelGroups: FrequencyChannelType[];
  setSelectedChannelGroups: Dispatch<SetStateAction<FrequencyChannelType[]>>;

  // NOTE: ピン非表示リスト
  hideMapPinIds: MapPinIdsType;
  setHideMapPinIds: Dispatch<SetStateAction<MapPinIdsType>>;
}

export const LayoutContext = createContext<LayoutContext>({
  allMapIds: [],
  handleMapSelect: () => {},
  setAllMapIds: () => {},

  selectedMapId: '',
  setSelectedMapId: () => {},

  allPinIds: [],
  setAllPinIds: () => {},

  selectedPinIds: [],
  setSelectedPinIds: () => {},

  pinCheckBoxStates: [],
  setPinCheckBoxStates: () => {},

  registeredPinIds: [],
  setRegisteredPinIds: () => {},

  allPinIdsForGraph: [],
  setAllPinIdsForGraph: () => {},

  selectedPinIdsForGraph: [],
  setSelectedPinIdsForGraph: () => {},

  pinCheckBoxStatesForGraph: [],
  setPinCheckBoxStatesForGraph: () => {},

  allPinIdsForTrack: [],
  setAllPinIdsForTrack: () => {},

  selectedPinIdsForTrack: [],
  setSelectedPinIdsForTrack: () => {},

  pinCheckBoxStatesForTrack: [],
  setPinCheckBoxStatesForTrack: () => {},

  workMode: 'Map',
  setWorkMode: () => {},

  mainPageTabIndex: MAIN_PAGE_TAB_INDEX.MAP,
  setMainPageTabIndex: () => {},

  isDisplayedChannel: false,
  setIsDisplayedChannel: () => {},

  isEnableGps: false,
  setIsEnableGps: () => {},

  frequencyCheckBoxStates: [],
  setFrequencyCheckBoxStates: () => {},

  channelCheckBoxStates: [],
  setChannelCheckBoxStates: () => {},
  selectedChannelGroups: [],
  setSelectedChannelGroups: () => {},

  hideMapPinIds: {},
  setHideMapPinIds: () => {},
});

import { getToken, stage, appApiEndPoint } from '../base';
import { wrapForObjects } from '../../../core/commons/dataConverter';
import { DeviceDataItem } from '../../../core/entities/device';
import { RmsDataItem } from '../../../core/entities/rms';

export const getRmsData = async (
  startTime: string,
  endTime: string,
  deviceId: string,
  dataType: string
): Promise<RmsDataItem[]> => {
  const token = await getToken();
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    data_type: dataType,
    device_id: deviceId,
  });

  const res = await fetch(`${appApiEndPoint}/${stage}/iotdata/datatypes/rms?${params}`, {
    headers: { Authorization: token },
  });
  const json = await res.json();
  const item = wrapForObjects<RmsDataItem>(json);

  return item;
};

export const getDeviceData = async (
  startTime: string,
  endTime: string,
  deviceId: string
): Promise<DeviceDataItem[]> => {
  const token = await getToken();
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    device_id: deviceId,
  });

  const res = await fetch(`${appApiEndPoint}/${stage}/iotdata/datatypes/device?${params}`, {
    headers: { Authorization: token },
  });
  const json = await res.json();
  const item = wrapForObjects<DeviceDataItem>(json);

  return item;
};

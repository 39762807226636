import { getChannnel } from '../../core/api/rwm/channel';
import { getFreqGraph } from '../../core/api/rwm/chart';
import { getDeviceData, getRmsData } from '../../core/api/rwm/iotdata';
import { getPinData, getTrackData } from '../../core/api/rwm/pinData';
import dayjs from 'dayjs';
import { ChannelRowType } from '../types/channel';
import { DeviceData } from '../types/device';
import { FrequencyGraphData } from '../types/graph';
import { PinData, TrackData } from '../types/map';
import { RmsData } from '../types/rms';
import { RwmDataRepository } from '../types/rwm';

export class RwmApiRepository implements RwmDataRepository {
  async fetchChannel(
    startTime: string,
    endTime: string,
    deviceIds: string[],
    dataType: string,
    heightMin: number,
    heightMax: number,
    timeDiv: number,
    frequencyband: string,
    noiseCalcMethod: string,
    occupancyCalcMethod: string,
    noiseDataSource: string,
    topNoiseLevel: string,
    topOccupancyLevel: string,
    bottomNoiseLevel: string,
    bottomOccupancyLevel: string,
    isChannel: boolean
  ): Promise<ChannelRowType[]> {
    return await getChannnel(
      startTime,
      endTime,
      deviceIds,
      dataType,
      heightMin,
      heightMax,
      timeDiv,
      frequencyband,
      noiseCalcMethod,
      occupancyCalcMethod,
      noiseDataSource,
      topNoiseLevel,
      topOccupancyLevel,
      bottomNoiseLevel,
      bottomOccupancyLevel,
      isChannel
    );
  }

  async fetchFreqGraph(
    startTime: string,
    endTime: string,
    deviceId: string,
    dataType: string
  ): Promise<FrequencyGraphData> {
    return await getFreqGraph(startTime, endTime, deviceId, dataType);
  }

  async fetchPinData(
    startTime: string,
    endTime: string,
    deviceIds: string[],
    dataType: string,
    heightMin: number,
    heightMax: number,
    hAcc: number,
    vAcc: number,
    timeDiv: number,
    noiseCalcMethod: string,
    occupancyCalcMethod: string,
    noiseDataSource: string
  ): Promise<PinData[]> {
    return await getPinData(
      startTime,
      endTime,
      deviceIds,
      dataType,
      heightMin,
      heightMax,
      hAcc,
      vAcc,
      timeDiv,
      noiseCalcMethod,
      occupancyCalcMethod,
      noiseDataSource
    );
  }

  async fetchRmsData(startTime: string, endTime: string, deviceId: string, dataType: string): Promise<RmsData[]> {
    const rmsDataItems = await getRmsData(startTime, endTime, deviceId, dataType);
    const ret: RmsData[] = [];
    // convert domain model
    rmsDataItems.forEach((item) => {
      const rmsData: RmsData = {
        date: dayjs.unix(item.date / 1000),
        id: item.id,
        rssiDataList: item.rssiDataList,
      };
      ret.push(rmsData);
    });
    return ret;
  }

  async fetchDeviceData(startTime: string, endTime: string, deviceId: string): Promise<DeviceData[]> {
    const deviceDataItems = await getDeviceData(startTime, endTime, deviceId);
    const ret: DeviceData[] = [];
    // convert domain model
    deviceDataItems.forEach((item) => {
      const deviceData: DeviceData = {
        date: dayjs.unix(item.date / 1000),
        id: item.id,
        height: item.height,
        powerSupplyVoltage: item.powerSupplyVoltage,
        temperature: item.temperature,
      };
      ret.push(deviceData);
    });
    return ret;
  }

  async fetchTrackData(
    startTime: string,
    endTime: string,
    deviceId: string,
    dataType: string,
    heightMin: number,
    heightMax: number,
    hAcc: number,
    vAcc: number,
    trackDiv: number,
    noiseCalcMethod: string,
    occupancyCalcMethod: string,
    noiseDataSource: string
  ): Promise<TrackData[]> {
    return await getTrackData(
      startTime,
      endTime,
      deviceId,
      dataType,
      heightMin,
      heightMax,
      hAcc,
      vAcc,
      trackDiv,
      noiseCalcMethod,
      occupancyCalcMethod,
      noiseDataSource
    );
  }
}

import { MapTypeIdType } from 'domain/types/map';

/**
 * @description 緯度・経度情報から Google Map 上の標高を取得する関数
 * @param {number} lat 緯度
 * @param {number} lng 経度
 * @returns {number} 標高
 */
export const getElevation = async (lat: number, lng: number): Promise<number> => {
  const elevationService = new google.maps.ElevationService();
  const locations = [{ lat, lng }];

  return new Promise((resolve, reject) => {
    elevationService.getElevationForLocations({ locations }, (results, status) => {
      if (status === google.maps.ElevationStatus.OK) {
        if (results && results[0]) {
          resolve(results[0].elevation);
        } else {
          reject(new Error(`No elevation results found`));
        }
      } else {
        reject(new Error(`Elevation service failed due to: ${status}`));
      }
    });
  });
};

/**
 * @description 指定した緯度・経度・ズーム倍率の地図の URL を取得する関数
 * @param {number} lat 緯度
 * @param {number} lng 経度
 * @param {number} zoom ズーム倍率
 * @param {number[]} size サイズ [width, height]
 * @param {MapTypeIdType} mapTypeId マップの種別
 * @param {(string | undefined)} apiKey Google Map API キー
 * @return {string} マップの URL
 */
export const getMapImageUrl = (
  lat: number,
  lng: number,
  zoom: number,
  size: number[],
  mapTypeId: MapTypeIdType,
  apiKey: string | undefined
): string => {
  if (!apiKey) return '';
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
  const queryString = `center=${lat},${lng}&zoom=${zoom}&size=${Math.round(size[0])}x${Math.round(size[1])}&maptype=${mapTypeId}&key=${apiKey}`;
  return baseUrl + '?' + queryString;
};

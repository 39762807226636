/**
 * @description 動作モード グラフモード：グラフに描画するIDを1つ選択する（ピンチェックボックスリストに複数選択不可の制御が入る）
 * @type
 */
export type WorkModeType = 'Map' | 'Graph' | 'Track';

// ------------------------------------------------------
// NOTE: 定数の定義
/**
 * @description メインページタブインデックス
 * @constant
 */
export const MAIN_PAGE_TAB_INDEX = {
  MAP: '1',
  CHANNEL: '2',
  GRAPH: '3',
} as const;

/**
 * @description 期間（秒）
 * @constant
 */
export const SPAN = {
  ONE_MINUTES: '60',
  TEN_MINUTES: '600',
  ONE_HOURS: '3600',
  TWO_HOURS: '7200',
  FOUR_HOURS: '14400',
  EIGHT_HOURS: '28800',
  TWELVE_HOURS: '43200',
  TWENTY_FOUR_HOURS: '86400',
} as const;

/**
 * @description 期間ラベル
 * @constant
 */
export const SPAN_LABEL = {
  ONE_MINUTES: '1分',
  TEN_MINUTES: '10分',
  ONE_HOURS: '1時間',
  TWO_HOURS: '2時間',
  FOUR_HOURS: '4時間',
  EIGHT_HOURS: '8時間',
  TWELVE_HOUS: '12時間',
  TWENTYFOUR_HOURS: '24時間',
} as const;

/**
 * @description 期間表示の定数
 * @constant
 */
export const SPANS = [
  SPAN.ONE_MINUTES,
  SPAN.TEN_MINUTES,
  SPAN.ONE_HOURS,
  SPAN.TWO_HOURS,
  SPAN.FOUR_HOURS,
  SPAN.EIGHT_HOURS,
  SPAN.TWELVE_HOURS,
  SPAN.TWENTY_FOUR_HOURS,
] as const;

/**
 * @description データソース
 * @constant
 */
export const DATA_SOURCE = {
  NOISE: 'NOISE',
  OCCUPANCY_RATE: 'OCCUPANCY_RATE',
};

/**
 * @description データソースラベル
 * @constant
 */
export const DATA_SOURCE_LABEL = {
  NOISE: 'ノイズ',
  OCCUPANCY_RATE: '占有率',
};

/**
 * @description 再生速度倍率
 * @constant
 */
export const PLAYING_SPEED = [1, 5, 10, 30];

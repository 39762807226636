import React, { type Dispatch } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { InputLabelStyle, SelectStyle } from '../../domain/types/sxProps';

interface Props {
  id: string;
  title: string;
  value: string;
  setValue: Dispatch<React.SetStateAction<string>>;
  options: { id: string; name: string }[];
}

const SelectComponent = ({ id, title, value, options, setValue }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          ...InputLabelStyle,
        }}
        id={id}
      >
        {title}
      </InputLabel>
      <Select
        sx={{
          ...SelectStyle,
          height: '30px',
        }}
        labelId={id + '-select-label'}
        id={id + '-select'}
        value={value}
        label={<span style={{ color: '#FFFFFF' }}>{title}</span>}
        onChange={handleChange}
        defaultValue={value ? value : ''}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;

import React, { useContext, useMemo } from 'react';
import CustomIconButton from 'components/Common/CustomIconButton';
import EditIcon from '@mui/icons-material/Edit';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { getSelectedMap } from 'utils/extract';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';

const OpenEditMapWindowButton = () => {
  const { mapsData, getMaps } = useMaps();
  const { selectedMapId } = useContext(LayoutContext);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  const { center, zoom, mapTypeId, elevation, setTmpCenter, setTmpZoom, setTmpMapTypeId, setTmpElevation } =
    useContext(RwmMapAreaContext);
  const {
    imageWidth,
    imageHeight,
    imageRotate,
    imageLat,
    imageLng,
    setMapMode,
    setTmpImageWidth,
    setTmpImageHeight,
    setTmpImageRotate,
    setTmpImageLat,
    setTmpImageLng,
  } = useContext(RwmMapTabContext);

  const handleClickEditMap = () => {
    setTmpCenter(center);
    setTmpZoom(zoom);
    setTmpMapTypeId(mapTypeId);
    setTmpElevation(elevation);
    setMapMode('write');

    if (selectedMap.mapType === 'imageMap') {
      setTmpImageWidth(imageWidth);
      setTmpImageHeight(imageHeight);
      setTmpImageRotate(imageRotate);
      setTmpImageLat(imageLat);
      setTmpImageLng(imageLng);
    }
    (async () => {
      getMaps(true);
    })();
  };

  return (
    <CustomIconButton
      onClick={() => handleClickEditMap()}
      Icon={EditIcon}
      tooltipTitle={'マップ位置・サイズの編集'}
      size='small'
    ></CustomIconButton>
  );
};

export default React.memo(OpenEditMapWindowButton);

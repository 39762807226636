import { DirectionType, MapPinType } from '../domain/types/map';
import { RecommendLevel, RECOMMEND_LEVEL } from '../domain/types/channel';
import { NOISE_DATA_SOURCE, SettingType } from '../domain/types/setting';

/**
 * @description ダイアログの入力値をチェックする関数
 * @param {string} 対象の文字列
 * @param {number} 最大文字数 (指定した数値以下かを判定)
 * @param {number} \
 *  任意 \
 *  最小文字数 (指定した数値以上かを判定) \
 *  デフォルト値 : 0
 * @returns {[boolean, string]} 検査の合否とメッセージのタプル
 */
export const checkInputText = (targetText: string, maxLength: number, minLength: number = 0): [boolean, string] => {
  // NOTE: 利用可能な特殊文字を指定
  const regexPattern = /^[a-zA-Z0-9０-９ａ-ｚＡ-Ｚぁ-んァ-ン一-龥ー～・;:\-~_@*+.,\x20\u3000]*$/;

  // NOTE: 文字数チェック
  if (targetText.length >= minLength && targetText.length <= maxLength) {
    // NOTE: 文字パターンチェック
    if (regexPattern.test(targetText)) {
      return [true, 'OK'];
    } else {
      return [
        false,
        '無効な特殊文字が含まれています。利用可能な特殊文字 (ー ～ ・ ; : - ~ _ @ * + . , 半角スペース 全角スペース)',
      ];
    }
  } else {
    return [false, `${minLength}文字以上、${maxLength}文字以下の値を入力してください。`];
  }
};

/**
 * @description 各デバイスの占有率が設定の「占有率のレベル判別閾値」のどこに位置するか判定し、推奨度を返す関数 \
 *  RECOMMEND_LEVEL.LOW (青)  : ratio <  min \
 *  RECOMMEND_LEVEL.MID (黄)  : min <= ratio < max \
 *  RECOMMEND_LEVEL.HIGH (赤) : ratio >= max \
 *  データソース、周波数バンド毎に設定値は変わる
 * @returns {RecommendLevel | undefined} 推奨度、もしくは、値無し
 */
export const checkRecommendLevelForRatio = ({
  judgeItem,
  frequencyBand,
  settings,
}: {
  judgeItem: number | undefined | null;
  frequencyBand: string;
  settings: SettingType;
}): RecommendLevel | undefined => {
  if (judgeItem === undefined || judgeItem === null) return undefined;
  const levelThreshold = settings.levelThresholdList.filter((level) => level.frequencyBand === frequencyBand)[0];
  const maxLevel: number = levelThreshold.topOccupancyLevel;
  const minLevel: number = levelThreshold.bottomOccupancyLevel;

  if (judgeItem >= maxLevel) return RECOMMEND_LEVEL.HIGH;
  else if (judgeItem >= minLevel && judgeItem < maxLevel) return RECOMMEND_LEVEL.MID;
  else return RECOMMEND_LEVEL.LOW;
};

/**
 * @description 各デバイスのノイズが設定の「ノイズのレベル判別閾値」のどこに位置するか判定し、推奨度を返す関数 \
 *  RECOMMEND_LEVEL.LOW (青)  : noise <  min \
 *  RECOMMEND_LEVEL.MID (黄)  : min <= noise < max \
 *  RECOMMEND_LEVEL.HIGH (赤) : noise >= max \
 *  RSSIMAX / RSSIMIN、データソース、周波数バンド毎に設定値は変わる
 * @returns {RecommendLevel | null | undefined} 推奨度、もしくは、値無し
 */
export const checkRecommendLevelForNoise = ({
  judgeItem,
  frequencyBand,
  settings,
}: {
  judgeItem: number | undefined | null;
  frequencyBand: string;
  settings: SettingType;
}): RecommendLevel | undefined => {
  if (judgeItem === undefined || judgeItem === null) return undefined;
  const levelThreshold = settings.levelThresholdList.filter((level) => level.frequencyBand === frequencyBand)[0];
  const maxLevel: number =
    settings.noiseDataSource === NOISE_DATA_SOURCE.RSSIMAX
      ? levelThreshold.topNoiseLevelRssiMax
      : levelThreshold.topNoiseLevelRssiMin;
  const minLevel: number =
    settings.noiseDataSource === NOISE_DATA_SOURCE.RSSIMAX
      ? levelThreshold.bottomNoiseLevelRssiMax
      : levelThreshold.bottomNoiseLevelRssiMin;

  if (judgeItem >= maxLevel) return RECOMMEND_LEVEL.HIGH;
  else if (judgeItem >= minLevel && judgeItem < maxLevel) return RECOMMEND_LEVEL.MID;
  else return RECOMMEND_LEVEL.LOW;
};

/**
 * @description ピンが表示領域内に存在するか判定し、どの方向にオーバーしているか返す関数
 * @param pinLatLng ピンの緯度経度 (lat, lng)
 * @param bounds 表示領域の境界
 * @param gpsCenter 緯度経度から変換した Pixel 値 (x, y)
 * @return {DirectionType[]} どの方向にオーバーしているかの判定値
 */
export const checkPinBoundary = (
  pinLatLng: google.maps.LatLng | google.maps.LatLngLiteral,
  bounds: google.maps.LatLngBounds
): DirectionType[] => {
  const lat = pinLatLng instanceof google.maps.LatLng ? pinLatLng.lat() : pinLatLng.lat;
  const lng = pinLatLng instanceof google.maps.LatLng ? pinLatLng.lng() : pinLatLng.lng;
  const directions: DirectionType[] = [];

  // NOTE : 表示されている地図の右上 (北東) の座標
  const ne = bounds.getNorthEast();
  // NOTE : 表示されている地図の左下 (南西) の座標
  const sw = bounds.getSouthWest();

  // NOTE: 地図の境界の判定
  // ピンが境界より右
  if (lng > ne.lng()) directions.push('right');
  // ピンが境界より左
  if (lng < sw.lng()) directions.push('left');
  // ピンが境界より上
  if (lat > ne.lat()) directions.push('top');
  // ピンが境界より下
  if (lat < sw.lat()) directions.push('bottom');

  return directions;
};

/**
 * @description 指定したピンがピン候補リストに存在し、テーブルに登録済みかどうか判定する処理
 * @param {MapPinType} mapPin 対象のピン
 * @returns {boolean | undefined} true : 登録済み, false : 未登録, undefined: ピン候補リストに存在しない
 */
export const checkRegisteredCandidatePin = (mapPin: MapPinType): boolean | undefined => {
  if (mapPin.candidateStatus === undefined) return undefined;
  else {
    if (mapPin.candidateStatus === 'registered') return true;
    else return false;
  }
};

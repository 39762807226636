import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  LinearProgressProps,
  Typography,
  Box,
  Button,
  FormControlLabel,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { CustomDialogButton } from 'components/Common/CustomDialogButton';
import CustomCheckBox from 'components/Common/CustomCheckBox';
import { TenantIdContext } from 'radioMonitoringPage';
import {
  analyzeUsbCmdDeviceInfo,
  analyzeUsbCmdDeviceConfig,
  analyzeUsbCmdSaveDataRead,
  analyzeUsbSaveDataErase,
  analyzeUsbCmdSaveDataAccessStart,
  calcCheckSum,
  analyzeUsbCmdSaveDataAccessEnd,
} from 'utils/extract';
import { SelectedGatewayContext } from 'radioMonitoringPage';
import { RwmContext } from 'RwmContext';
// import { GatewaysContext } from 'radioMonitoringPage';
import { SensorUnit } from 'domain/types/sensorUnit';
import { AREA_ID } from 'radioMonitoringPage';
import { UNIT_TYPE_RWM } from 'domain/types/sensorUnit';
import dayjs from 'dayjs';
import {
  createDeviceConfigMessage,
  createTopicForSaveDataPublish,
  createTopicForDeviceDataPublish,
} from 'utils/format';
import MqttClient from 'core/api/aws/mqttClient';
import { DEFINE_VALUE, COMMAND_TYPES, RESPONSE_TYPES } from 'domain/types/serial';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const DialogSerial = (props: DialogProps) => {
  const { t } = useTranslation();
  const { onClose, open } = props;

  const [port, setPort] = useState<SerialPort | null>(null);
  // eslint-disable-next-line
  const [reader, setReader] = useState<ReadableStreamDefaultReader<string> | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // eslint-disable-next-line
  const [buffer, setBuffer] = useState('');
  const { tenantId } = useContext(TenantIdContext);
  const { candidatePinIds } = useContext(RwmContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentErasePage, setCurrentErasePage] = useState(0);
  const startReading = useRef(false);
  const isReady = useRef(false);
  const [isOnlyDeleteData, setIsOnlyDeleteData] = useState<boolean>(false);
  const [deviceConfigTxt, setDeviceConfigTxt] = useState<string | null>(null);
  const isCanceled = useRef(false);
  const { selectedGateway } = useContext(SelectedGatewayContext);
  const serialNo = useRef('');
  const [maxPages, setMaxPages] = useState(0);
  const [currentTimestamp, setCurrentTimestamp] = useState(dayjs());

  // MQTTクライアントのインスタンス化
  const mqttClient = new MqttClient();

  useEffect(() => {
    // 初期化はコンポーネントマウント時に行う
    mqttClient.initialize();
    // eslint-disable-next-line
  }, []);

  // シリアルポートへの接続処理
  const connectToSerialPort = async () => {
    if (!('serial' in navigator)) {
      console.error('Web Serial API not supported.');
      setErrorMessage(t('この機能はGoogle ChromeまたはEdgeの最新版で利用してください。'));
      return;
    }

    try {
      init();

      // シリアルポート選択ダイアログを表示
      const selectedPort = await navigator.serial.requestPort();
      await selectedPort.open({ baudRate: 115200 });

      setPort(selectedPort);

      // テキスト読み取り用ストリーム設定
      const textDecoder = new TextDecoderStream();
      selectedPort.readable?.pipeTo(textDecoder.writable).catch((error) => {
        console.error('Pipe error:', error);
        closePort(); // エラー時にはポートクローズ処理へ移動
      });

      const readerInstance = textDecoder.readable.getReader();
      setReader(readerInstance);
      // 接続後すぐにデータ読み取り開始
      readMessage(readerInstance);
    } catch (error) {
      console.error('Failed to connect to serial port:', error);
    }
  };

  const sendMessage = async (message: string) => {
    if (!port || !port.writable) return;

    // 削除の場合は0.1秒待機する
    if (message.includes(COMMAND_TYPES.DELETE_DEVICE_DATA)) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    } else {
      await new Promise((resolve) => setTimeout(resolve, 10));
    }

    const checkSum = calcCheckSum(message, message.length).toString(16).padStart(2, '0').toUpperCase();
    message = message + checkSum;
    try {
      const textEncoder = new TextEncoder();
      const writer = port.writable.getWriter();
      await writer.write(textEncoder.encode(message + '\r\n'));

      writer.releaseLock();
    } catch (error) {
      console.error('Send error:', error);
    }
  };

  // メッセージ読み取り処理
  const readMessage = async (readerInstance: ReadableStreamDefaultReader<string> | null) => {
    if (!readerInstance) return;

    try {
      //eslint-disable-next-line
      while (true) {
        const { value, done } = await readerInstance.read();
        if (done) break;
        // バッファ更新
        setBuffer((prevBuffer) => {
          const newBuffer = prevBuffer + value;
          // 完全なメッセージ判定
          if (newBuffer.includes('\r\n')) {
            const messages = newBuffer.split('\r\n');
            messages.slice(0, -1).forEach(processResponse);
            return messages[messages.length - 1]; // 未処理部分のみ保持
          }
          return newBuffer;
        });
      }
    } catch (error) {
      console.error('Read error:', error);
    } finally {
      readerInstance.releaseLock();
      closePort();
    }
  };

  // 応答メッセージの解析と処理
  const processResponse = async (response: string) => {
    // delete \n or \r\n
    response = response.replace(/\r?\n/g, '');
    // delete USB CHECKSUM SIZE
    if (response.length > 2) {
      response = response.slice(0, response.length - 2);
    }

    // ここで応答データを解析する
    const responseType = response.substring(0, 3);
    if (responseType == RESPONSE_TYPES.GET_DEVICE_INFO) {
      const deviceInfo = analyzeUsbCmdDeviceInfo(response); // 仮想的な関数
      if (deviceInfo) {
        serialNo.current = deviceInfo.rfTrxSerialId;
        setMaxPages(deviceInfo.saveDataPageNum);
      } else {
        console.error('デバイス情報取得通信エラー');
        setErrorMessage(t('デバイス情報取得通信エラー'));
      }
    } else if (responseType == RESPONSE_TYPES.GET_IOT_DATA) {
      //保存データ解析
      const result = analyzeUsbCmdSaveDataRead(response);
      if (result) {
        // 読み込み中のデータ情報
        setCurrentTimestamp(dayjs.unix(result.timestamp / 1000));

        // デバイスのIDは4桁のため12桁のIDに書き換え
        result.deviceId = serialNo.current;
        // alert(`success`);
        const topic = createTopicForSaveDataPublish(tenantId || '', result.deviceId, result.dataType);
        const payload = JSON.stringify(result);

        mqttClient.publish(topic, payload);
      } else {
        setErrorMessage(t('デバイスからのデータが不正です'));
        console.error('save data Invalid response received.');
      }
    } else if (responseType == RESPONSE_TYPES.START_DATA_ACCESS) {
      if (analyzeUsbCmdSaveDataAccessStart(response)) {
        startReading.current = true;
      } else {
        setErrorMessage(t('保存データアクセス開始通信エラー'));
      }
    } else if (responseType == 'PSE') {
      if (!analyzeUsbSaveDataErase(response)) {
        console.error('erase failed.');
      }
    } else if (responseType == RESPONSE_TYPES.GET_DEVICE_CONFIG) {
      const deviceConf = analyzeUsbCmdDeviceConfig(response);
      console.log('deviceConf', deviceConf);
      if (deviceConf) {
        const deviceConfMessage = createDeviceConfigMessage(deviceConf);
        isReady.current = true;
        setDeviceConfigTxt(deviceConfMessage);
        console.log('deviceConf', deviceConfMessage);
      } else {
        setDeviceConfigTxt(null);
        console.error(t('デバイス情報取得通信エラー'));
        setErrorMessage(t('デバイス情報取得通信エラー'));
      }
    } else if (responseType == RESPONSE_TYPES.END_DATA_ACCESS) {
      if (!analyzeUsbCmdSaveDataAccessEnd(response)) {
        setErrorMessage(t('保存データアクセス終了通信エラー'));
      }
    }
  };

  const init = () => {
    setMaxPages(0);
    setCurrentPage(0);
    setCurrentErasePage(0);
    startReading.current = false;
    isReady.current = false;
    serialNo.current = '';
    setErrorMessage(null);
    setDeviceConfigTxt(null);
    isCanceled.current = false;
    setCurrentTimestamp(dayjs());
  };
  const main = async () => {
    await sendMessage(COMMAND_TYPES.START_DATA_ACCESS);
    // アクセス開始コマンドからの応答を待つため、遅延
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (!startReading.current) {
      return;
    }
    await sendMessage(COMMAND_TYPES.GET_DEVICE_INFO);

    if (isReady.current) {
      // データ取り込み
      if (!isOnlyDeleteData) {
        //子機データ
        const sensorUnit: SensorUnit = {
          devId: serialNo.current,
          devType: UNIT_TYPE_RWM,
          status: '1',
          serialNo: serialNo.current,
          name: null,
        };
        saveDevice(sensorUnit);
        //監視データ
        await readAllDataPages();
      }
      // 削除開始
      await eraseAllDataPages();
    }

    // デバイスデータ更新
    await new Promise((resolve) => setTimeout(resolve, 100));
    await sendMessage(COMMAND_TYPES.GET_DEVICE_INFO_WITH_UPDATE);

    // デバイスデータアクセス終了
    await new Promise((resolve) => setTimeout(resolve, 100));
    await sendMessage(COMMAND_TYPES.END_DATA_ACCESS);

    if (isCanceled.current) {
      alert(t('処理を中断しました。'));
    } else {
      alert(t('データ取り込みが完了しました。'));
    }

    init();
  };

  // ポートを閉じる処理
  const closePort = async () => {
    if (!port) return;

    try {
      await port.close();
      setReader(null);
      setPort(null);
    } catch (error) {
      console.error('Close Port Error:', error);
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
    if (reason === 'backdropClick') return;
    handleSubmit();
  };

  const handleSubmit = async () => {
    onClose();
  };

  const handleCancel = () => {
    isCanceled.current = true;
  };

  const readAllDataPages = async () => {
    for (let page = 0; page < maxPages; page++) {
      if (isCanceled.current) break;
      const command = `${COMMAND_TYPES.GET_IOT_DATA}${page.toString(16).padStart(8, '0').toUpperCase()}`; // ページ番号を16進数でパディング
      await sendMessage(command);

      setCurrentPage(page + 1);
    }
  };

  const eraseAllDataPages = async () => {
    for (let page = 0; page <= DEFINE_VALUE.SAVE_DATA_MAX_PAGE_NUM; page += 256) {
      if (isCanceled.current) break;
      const command = `${COMMAND_TYPES.DELETE_DEVICE_DATA}${page.toString(16).padStart(8, '0').toUpperCase()}`; // ページ番号を16進数でパディング

      await sendMessage(command);

      setCurrentErasePage(page + 256);
    }
  };

  const connectDevice = async () => {
    await sendMessage(COMMAND_TYPES.GET_DEVICE_INFO);
    await sendMessage(COMMAND_TYPES.GET_DEVICE_CONFIG);
  };

  const isDataSaving = (): boolean => {
    if (currentPage != 0 && currentPage != maxPages) {
      return true;
    }
    return false;
  };

  const isDataDeleting = (): boolean => {
    if (currentErasePage != 0 && currentErasePage != DEFINE_VALUE.SAVE_DATA_MAX_PAGE_NUM) {
      return true;
    }
    return false;
  };

  const isConnected = (): boolean => {
    if (deviceConfigTxt != null) {
      return true;
    }
    return false;
  };

  const saveDevice = (sensorUnit: SensorUnit) => {
    if (!selectedGateway || !tenantId) return;

    // 既に登録済みのピンは処理しない
    if (candidatePinIds.includes(sensorUnit.devId)) return;

    const topic = createTopicForDeviceDataPublish(tenantId, selectedGateway);
    const unit = {
      tenantId: tenantId,
      areaId: AREA_ID,
      gateway_id: selectedGateway,
      unit: sensorUnit,
    };
    const unitStr = JSON.stringify(unit);
    const payload = { payload: unitStr };

    mqttClient.publish(topic, JSON.stringify(payload));
  };

  const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant='determinate'
            {...props}
            sx={{
              backgroundColor: '#555', // 背景色
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#fff', // プログレスバーの色
              },
            }}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant='body2'>{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth='sm'
      sx={{
        '& .MuiPaper-root': { background: '#000', borderBlockColor: '#333333', borderWidth: 1 },
      }}
    >
      <DialogTitle>{t('データ取り込み')}</DialogTitle>
      <DialogContent>
        <Typography color='textSecondary'>{`対象ゲートウェイ： ${selectedGateway}`}</Typography>
        <Typography fontWeight='bolder'>
          {maxPages === 0 ? (
            port ? (
              t('デバイスと接続を行ってください。')
            ) : (
              t('シリアルポートを選択してください。')
            )
          ) : currentPage === 0 ? (
            <>
              {t('データ取得準備ができました。')}
              <br />
              {t('完了に時間がかかる可能性があります。')}
              <br />
              {t('データを取り込む場合は、データ取得開始を押してください。')}
            </>
          ) : isDataSaving() ? (
            t('データ取り込み中...')
          ) : isDataDeleting() ? (
            t('デバイスデータ削除中...')
          ) : (
            t('データの取り込みとデバイスデータの削除が完了しました')
          )}
        </Typography>
        {/* ボタン群 */}
        <Box my={2}>
          <Button onClick={connectToSerialPort} disabled={isDataSaving() || isDataDeleting()} variant='contained'>
            {t('シリアルポートを選択')}
          </Button>

          <Button
            onClick={() => connectDevice()}
            disabled={!port || (maxPages != 0 && currentPage != maxPages) || isDataSaving() || isDataDeleting()}
            variant='contained'
            sx={{ ml: 1 }}
          >
            {t('デバイスと接続')}
          </Button>

          <Button
            onClick={() => main()}
            disabled={!port || maxPages == 0 || isDataSaving() || isDataDeleting()}
            variant='contained'
            sx={{ ml: 1 }}
          >
            {t('データ取得開始')}
          </Button>

          <Button
            onClick={() => handleCancel()}
            disabled={!(isReady.current && (currentErasePage != 0 || currentPage != 0))}
            variant='contained'
            sx={{ ml: 1 }}
          >
            {t('キャンセル')}
          </Button>

          <FormControlLabel
            key={`mapPinDialog-hasGpsInfo`}
            sx={{
              minWidth: 200,
              '& .MuiFormControlLabel-label': {
                color: isDataSaving() || isDataDeleting() ? grey[400] : '#FFF',
              },
              '&.Mui-disabled .MuiFormControlLabel-label': {
                color: grey[400],
                cursor: 'not-allowed',
              },
            }}
            control={
              <CustomCheckBox
                onChange={() => {
                  setIsOnlyDeleteData((prev) => !prev);
                }}
                disabled={isDataSaving() || isDataDeleting()}
              />
            }
            label={t('消去のみ')}
            checked={isOnlyDeleteData}
          />
        </Box>
        {/* プログレスバー */}
        {maxPages !== 0 && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgressWithLabel value={(currentPage / maxPages) * 100} />
          </Box>
        )}
        {currentErasePage !== 0 && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgressWithLabel value={(currentErasePage / DEFINE_VALUE.SAVE_DATA_MAX_PAGE_NUM) * 100} />
          </Box>
        )}
        {/* 監視データID、時刻 */}
        {isReady.current && (currentErasePage != 0 || currentPage != 0) && (
          <Box>{`ID:${serialNo.current} 日時:${currentTimestamp.format('YYYY-MM-DD HH:mm:ss')}`}</Box>
        )}
        {/* デバイス設定表示 */}
        {isConnected() && <Box sx={{ whiteSpace: 'pre-wrap' }}>{deviceConfigTxt}</Box>}
        {/* エラーメッセージ表示 */}
        {errorMessage && <p style={{ color: 'red', whiteSpace: 'preWrap' }}>{errorMessage}</p>}
      </DialogContent>

      <DialogActions>
        <CustomDialogButton
          onClick={handleSubmit}
          variant='contained'
          sx={{ backgroundColor: '#333333' }}
          title={t('閉じる')}
          disabled={isDataSaving() || isDataDeleting()}
        />
      </DialogActions>
    </Dialog>
  );
};

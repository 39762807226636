import ExploreIcon from '@mui/icons-material/Explore';
import React, { useContext } from 'react';
import CustomIconButton from 'components/Common/CustomIconButton';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';

const GpsButton = () => {
  const { isEnableGps, setIsEnableGps } = useContext(LayoutContext);

  /**
   * @description GPSボタン選択時の処理
   */
  const handleClickGps = () => {
    setIsEnableGps((prev) => !prev);
  };

  return (
    <CustomIconButton
      onClick={handleClickGps}
      Icon={ExploreIcon}
      tooltipTitle={'GPS位置有効・無効'}
      size='medium'
      isEnabled={isEnableGps}
    ></CustomIconButton>
  );
};

export default React.memo(GpsButton);

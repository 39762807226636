import React, { Dispatch, SetStateAction, useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Autocomplete,
  SxProps,
} from '@mui/material';
import {
  MapObjType,
  MapPinType,
  PIN_TYPE,
  PinActionType,
  INITIAL_MAP,
  INITIAL_PIN,
  BasePinType,
  PinData,
  MAIN_MAP_ID,
} from 'domain/types/map';
import CustomCheckBox from '../Common/CustomCheckBox';
import { CustomDialogButton } from '../Common/CustomDialogButton';
import MemoizedTextField from '../Common/MemoizedTextField';
import { grey } from '@mui/material/colors';
import { checkInputText } from 'utils/validate';
import { createInitiateGroupId } from 'utils/format';
import { INITIAL_MAP_INFO } from 'domain/types/map';
import { ConfirmDialogResult } from 'domain/types/setting';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { RwmContext, useRwmCache } from 'RwmContext';
import { getSelectedMap, getTargetPinData } from 'utils/extract';
import { useErrorBoundary } from 'react-error-boundary';
import { useMaps } from 'MapsContext';
import { removeDuplicatedElements } from 'utils/transform';
import { RwmMapTabContext } from 'components/SplitLayout/MainPage/MapTab/RwmMapContext';

const TextFieldStyle: SxProps = {
  width: 300,
  border: 1,
  textAlign: 'left',
};

const BoxStyle: SxProps = {
  width: 200,
  border: 1,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  pl: 1.5,
  height: 50,
};

const GridStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

/**
 * @param {boolean} isOpenedDialog ダイアログの ON / OFF フラグ
 * @param {(result: ConfirmDialogResult) => void} onClosedDialog ダイアログのフラグを変更する関数
 * @param {PinActionType} pinActionType ピン / グループ選択時の操作
 * @param {number} selectedPinType 選択中のピンタイプ (ユニット / グループ / ノイズ)
 * @param {string | undefined} selectedPinId 選択中のピン情報
 * @param {Dispatch<SetStateAction<string | undefined>>} setSelectedPinId ダイアログ表示可否
 */
interface Props {
  isOpenedDialog: boolean;
  onClosedDialog: (result: ConfirmDialogResult) => void;
  pinActionType: PinActionType;
  selectedPinType: number;
  selectedPinId?: string | undefined;
  setSelectedPinId?: Dispatch<SetStateAction<string | undefined>>;
  isEnableGps: boolean;
}

const SetUpDialogWindow = ({
  isOpenedDialog,
  onClosedDialog,
  pinActionType,
  selectedPinType,
  selectedPinId,
  setSelectedPinId,
  isEnableGps,
}: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { setCaSelectedPinIds } = useRwmCache();

  const { mapsData, addPinToMap, updatePinInMap, addPinToMainMap, updatePinInMainMap } = useMaps();
  const {
    allMapIds,
    setAllMapIds,
    selectedMapId,
    setSelectedMapId,
    allPinIds,
    setAllPinIds,
    selectedPinIds,
    setSelectedPinIds,
    pinCheckBoxStates,
    setPinCheckBoxStates,
    registeredPinIds,
    setRegisteredPinIds,
  } = useContext(LayoutContext);
  const { candidatePinIds } = useContext(RwmContext);
  const { pinData } = useContext(RwmMapTabContext);

  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  const [selectedPin, setSelectedPin] = useState<BasePinType | undefined>(undefined);

  const [idField, setIdField] = useState<string>('');
  const [labelField, setLabelField] = useState<string>('');
  const [isRssiFix, setIsRssiFix] = useState<boolean>(false);
  const [hasGpsInfo, setHasGpsInfo] = useState<boolean>(false);
  const isDisabled: boolean = selectedPinType === PIN_TYPE.GroupPin ? true : false;

  /**
   * @description ダイアログ外を選択した際に動作する関数
   * @param {React.MouseEvent<HTMLButtonElement>} event 対象のイベント
   * @param {[string]} reason ダイアログ外を選択したか判定する為の指標
   * @param {[string]} action 追加 / 閉じる為のアクション
   * @returns
   */
  const handleClickClosedDialog = (
    event: React.MouseEvent<HTMLButtonElement>,
    reason: string,
    action: ConfirmDialogResult
  ) => {
    if (reason === 'backdropClick') return;
    if (setSelectedPinId) setSelectedPinId(undefined);
    onClosedDialog(action);
  };

  /**
   * @description 追加を選択した際に動作する関数
   * @param {[string]} action 追加 / 閉じる為のアクション
   * @param {number} selectedPinType ピンの種別
   * @param {PinActionType} pinActionType ピンの追加か更新か
   * @returns
   */
  const handleClickSubmit = async (
    action: ConfirmDialogResult,
    selectedPinType: number,
    pinActionType: PinActionType
  ) => {
    const [isEnabledId, msgId]: [boolean, string] = checkInputText(idField, 12, 1);
    const [isEnabledLabel, msgLabel]: [boolean, string] = checkInputText(labelField, 12);

    if (!isEnabledId) {
      alert(t(`ID に無効な値が入力されています。${msgId}`));
      return;
    }

    if (!isEnabledLabel) {
      alert(t(`ラベルに無効な値が入力されています。${msgLabel}`));
      return;
    }

    // NOTE: 更新時に値が変更されなかった場合は、何もせず終了
    if (pinActionType === 'update') {
      if (
        idField === selectedPin?.pinId &&
        labelField === selectedPin?.label &&
        isRssiFix === selectedPin?.isRssiFix &&
        hasGpsInfo === selectedPin?.hasGpsInfo
      ) {
        onClosedDialog(action);
        return;
      }
    }

    const label: string = labelField ? labelField : idField;
    const updatedMapPin: MapPinType = {
      ...INITIAL_PIN,
      ...selectedPin,
      pinId: idField,
      label: label,
      pinType: selectedPinType,
      isRssiFix,
      hasGpsInfo,
      mapId: selectedMapId,
    };

    if (setSelectedPinId) setSelectedPinId(updatedMapPin.pinId);

    if (isEnableGps === true && updatedMapPin.hasGpsInfo === true && pinData.length > 0) {
      const index = pinData.findIndex((data: PinData) => data.unitId === idField);
      if (index !== -1 && pinData[index].lat !== 0 && pinData[index].lng !== 0) {
        updatedMapPin.lat = pinData[index].lat;
        updatedMapPin.lng = pinData[index].lng;
      }
    }

    let flag = false;
    if (selectedPinType === PIN_TYPE.UnitPin || selectedPinType === PIN_TYPE.NoisePin) {
      flag = await submitForDevice(label, updatedMapPin, pinActionType);
    } else if (selectedPinType === PIN_TYPE.GroupPin) {
      flag = await submitForGroup(label, updatedMapPin, pinActionType);
    }

    if (flag) setCaSelectedPinIds(removeDuplicatedElements([...selectedPinIds, updatedMapPin.pinId]));

    onClosedDialog(action);
  };

  /**
   * @description 追加を選択した際に動作する関数 (デバイスピン : UnitPin)
   * @param {string} label ラベルの値
   * @param {MapPinType} updatedMapPin 更新後のピンの値
   * @param {PinActionType} pinActionType ピンの追加か更新か
   * @returns
   */
  const submitForDevice = async (
    label: string,
    updatedMapPin: MapPinType,
    pinActionType: PinActionType
  ): Promise<boolean> => {
    if (selectedMap === undefined) return false;

    // NOTE: データ追加
    if (pinActionType === 'create') {
      // NOTE: 既に ID が存在するか判定
      if (allPinIds.some((id) => id === idField)) {
        alert(t('既に ID が存在しています。別の ID を入力してください。'));
        return false;
      }
      // NOTE: 追加の場合、初期位置はマップの中央とする
      updatedMapPin = { ...updatedMapPin, lat: selectedMap.lat, lng: selectedMap.lng };

      try {
        // NOTE: デバイスピンの追加
        await addPinToMap(selectedMap.mapId, updatedMapPin, true);
      } catch (error) {
        showBoundary(error);
        return false;
      }

      setAllPinIds(removeDuplicatedElements([...allPinIds, updatedMapPin.pinId]));
      setSelectedPinIds(removeDuplicatedElements([...selectedPinIds, updatedMapPin.pinId]));
      setPinCheckBoxStates([...pinCheckBoxStates, true]);
      setRegisteredPinIds(removeDuplicatedElements([...registeredPinIds, updatedMapPin.pinId]));
    }

    // NOTE: データ更新
    else if (pinActionType === 'update') {
      try {
        // NOTE: デバイスピンの更新
        await updatePinInMap(selectedMap.mapId, updatedMapPin, true);
      } catch (error) {
        showBoundary(error);
        return false;
      }
    }

    return true;
  };

  /**
   * @description 追加を選択した際に動作する関数 (フロアピン : GroupPin)
   * @param {string} label ラベルの値
   * @param {MapPinType} updatedMapPin 更新後のピンの値
   * @param {PinActionType} pinActionType ピンの追加か更新か
   * @returns
   */
  const submitForGroup = async (
    label: string,
    updatedMapPin: MapPinType,
    pinActionType: PinActionType
  ): Promise<boolean> => {
    if (selectedMap === undefined) return false;
    // NOTE: データ追加
    if (pinActionType === 'create') {
      // NOTE: メインマップのデバイス ID と候補ピンのリスト内に ID が存在するか判定
      const existPinIds = mapsData.filter((map) => map.mapId === MAIN_MAP_ID)[0].pins.map((pin) => pin.pinId);
      if (existPinIds.some((id) => id === idField) || candidatePinIds.some((id) => id === idField)) {
        alert(t('既に ID が存在しています。別の ID を入力してください。'));
        return false;
      }
      // NOTE: 追加の場合、初期位置はマップの中央とする
      updatedMapPin = { ...updatedMapPin, lat: selectedMap.lat, lng: selectedMap.lng };
      const tmpMapObj: MapObjType = {
        ...INITIAL_MAP,
        mapId: idField,
        pins: [],
      };

      try {
        // NOTE: マップの新規登録とメインマップにフロアピンを追加
        await addPinToMainMap(tmpMapObj, updatedMapPin, true);
      } catch (error) {
        showBoundary(error);
        return false;
      }

      setAllMapIds(removeDuplicatedElements([...allMapIds, idField]));
      setSelectedMapId(idField);
    }

    // NOTE: データ更新
    else if (pinActionType === 'update') {
      try {
        // NOTE: メインマップのフロアピンを更新
        await updatePinInMainMap(updatedMapPin, true);
      } catch (error) {
        showBoundary(error);
        return false;
      }
    }

    return true;
  };

  /**
   * @description 対象のピンデータをセット
   */
  useEffect(() => {
    if (selectedMap && selectedPinId) {
      setSelectedPin(getTargetPinData(selectedMap, selectedPinId));
    }
  }, [selectedMap, selectedPinId]);

  /**
   * @description ダイアログの初期化
   */
  useEffect(() => {
    let tmp: BasePinType = INITIAL_MAP_INFO[selectedPinType];

    // NOTE: ピンの設定変更の場合、既存の設定値を参照する
    if (pinActionType === 'update') {
      tmp = selectedPin ? selectedPin : INITIAL_MAP_INFO[selectedPinType];
    }

    // NOTE: グループの場合、ID の初期値を G_XXX の連番を設定する (既に存在している連番はスキップ)
    if (pinActionType === 'create' && selectedPinType === PIN_TYPE.GroupPin) {
      tmp.pinId = createInitiateGroupId(allMapIds.map((id: string) => id));
    }

    setIdField(tmp.pinId);
    setLabelField(tmp.label);
    setIsRssiFix(tmp.isRssiFix);
    setHasGpsInfo(tmp.hasGpsInfo);

    return () => {
      setIdField('');
      setLabelField('');
      setIsRssiFix(false);
      setHasGpsInfo(false);
    };
  }, [allMapIds, pinActionType, selectedPin, selectedPinType]);

  return (
    <Dialog
      open={isOpenedDialog}
      onClose={(e: React.MouseEvent<HTMLButtonElement>) => handleClickClosedDialog(e, 'backdropClick', 'close')}
      fullWidth={true}
      maxWidth='sm'
      sx={{
        '& .MuiPaper-root': {
          background: '#000',
          borderBlockColor: '#333',
          borderWidth: 1,
        },
      }}
    >
      <DialogTitle>{t('ピンの設定')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={2}>
          <Grid container item sx={{ ...GridStyle }}>
            <Box sx={{ width: 1 / 5 }}>{t(`ID`)}&nbsp;:&nbsp;</Box>
            {pinActionType === 'update' ? (
              <Box
                sx={{
                  ...BoxStyle,
                  width: 300,
                  cursor: 'not-allowed',
                }}
              >
                {idField}
              </Box>
            ) : selectedPinType === PIN_TYPE.GroupPin ? (
              <TextField
                variant='outlined'
                sx={{
                  ...TextFieldStyle,
                }}
                value={idField}
                onChange={(e) => {
                  setIdField(e.target.value);
                }}
              />
            ) : (
              <Autocomplete
                freeSolo
                value={idField}
                onChange={(_, newValue) => setIdField(newValue !== null ? newValue : '')}
                componentsProps={{
                  paper: { sx: { bgcolor: '#333', color: '#FFF' } },
                }}
                options={candidatePinIds}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    sx={{
                      ...TextFieldStyle,
                    }}
                    value={idField}
                    onChange={(e) => {
                      setIdField(e.target.value);
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid container item sx={{ ...GridStyle }}>
            <Box sx={{ width: 1 / 5 }}>{t(`ラベル`)}&nbsp;:&nbsp;</Box>
            <MemoizedTextField
              value={labelField}
              onChange={(e) => {
                setLabelField(e.target.value);
              }}
              sx={{ ...TextFieldStyle }}
            />
          </Grid>
          <Grid container item sx={{ ...GridStyle }}>
            <Box sx={{ width: 1 / 5 }}>{t(`タイプ`)}&nbsp;:&nbsp;</Box>
            <Box sx={{ ...BoxStyle, cursor: 'not-allowed' }}>
              {selectedPinType === PIN_TYPE.UnitPin
                ? t(`ユニット`)
                : selectedPinType === PIN_TYPE.GroupPin
                  ? t(`グループ`)
                  : selectedPinType === PIN_TYPE.NoisePin
                    ? t(`ノイズ`)
                    : t(`不明な値`)}
            </Box>
          </Grid>
          <Grid container item sx={{ ...GridStyle }}>
            <FormControlLabel
              key={`mapPinDialog-hasGpsInfo`}
              sx={{
                minWidth: 200,
                '& .MuiFormControlLabel-label': {
                  color: isDisabled ? grey[400] : '#FFF',
                },
                '&.Mui-disabled .MuiFormControlLabel-label': {
                  color: grey[400],
                  cursor: 'not-allowed',
                },
              }}
              control={
                <CustomCheckBox
                  onChange={() => {
                    setHasGpsInfo(!hasGpsInfo);
                  }}
                  disabled={isDisabled}
                />
              }
              label={t('GPS情報から自動位置調整')}
              checked={hasGpsInfo}
            />
          </Grid>
          <Grid container item sx={{ ...GridStyle }}>
            <FormControlLabel
              key={`mapPinDialog-isRssiFix`}
              sx={{
                minWidth: 200,
                '& .MuiFormControlLabel-label': {
                  color: isDisabled ? grey[400] : '#FFF',
                },
                '&.Mui-disabled .MuiFormControlLabel-label': {
                  color: grey[400],
                  cursor: 'not-allowed',
                },
              }}
              control={
                <CustomCheckBox
                  onChange={() => {
                    setIsRssiFix(!isRssiFix);
                  }}
                  disabled={isDisabled}
                />
              }
              label={t('RSSI固定')}
              checked={isRssiFix}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomDialogButton
          onClick={(e) => handleClickClosedDialog(e, '', 'close')}
          variant='contained'
          sx={{ backgroundColor: '#333' }}
          title={t('閉じる')}
        />
        <CustomDialogButton
          onClick={() => handleClickSubmit('add', selectedPinType, pinActionType)}
          variant='contained'
          sx={{ backgroundColor: '#333' }}
          title={pinActionType == 'update' ? t('OK') : t('追加')}
        />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(SetUpDialogWindow);

import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 1,
        height: 1,
      }}
    >
      <CircularProgress sx={{ color: '#0078d7' }} size={100} />
    </Box>
  );
};

export default React.memo(LoadingScreen);

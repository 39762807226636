import { createTheme } from '@mui/material/styles';

// フォントを設定
const fontFamily = ['Noto Sans JP'].join(',');

export const CustomTheme = createTheme({
  typography: {
    fontFamily: fontFamily,
    fontSize: 12,
  },
  palette: {
    mode: 'dark',
    // 背景色
    background: {
      default: '#000000',
    },
    // Primary カラー
    primary: {
      main: '#000000',
    },
    // Secondary カラー
    secondary: {
      main: '#333333',
    },

    // テキストカラー
    text: { primary: '#FFFFFF', secondary: '#DC0000' },
  },
  shape: {
    borderRadius: 3,
  },
});

import {
  getAreaData,
  storeMap,
  storePin,
  getSensorUnitList,
  storeSetting,
  deletePin,
  deleteMap,
} from 'core/api/pf/area';
import { wrapForObject } from 'core/commons/dataConverter';
import { AreaMasterItem, MapItem, PinItem, SettingBody } from 'core/entities/areaMaster';
import { AreaMasterRepository } from 'domain/types/area';
import { MapObjType, BasePinType, MapPinType } from 'domain/types/map';
import { SensorUnit } from 'domain/types/sensorUnit';
import { SettingType } from 'domain/types/setting';

export class PfApiAreaMasterRepository implements AreaMasterRepository {
  async fetchOne(areaId: string): Promise<AreaMasterItem> {
    return await getAreaData(areaId);
  }

  async fetchUnitList(areaId: string, gatewayId: string): Promise<SensorUnit[]> {
    return await getSensorUnitList(areaId, gatewayId);
  }

  async storeSetting(areaId: string, gatewayId: string, setting: SettingType): Promise<SettingType> {
    const body: SettingBody = {
      setting: {
        noiseDataSource: setting.noiseDataSource,
        noiseCalcuMethod: setting.noiseCalcuMethod,
        occupancyCalcMethod: setting.occupancyCalcMethod,
        levelThresholdList: setting.levelThresholdList.map((item) => ({
          frequencyBand: item.frequencyBand,
          topOccupancyLevel: item.topOccupancyLevel,
          topNoiseLevelRssiMin: item.topNoiseLevelRssiMin,
          bottomOccupancyLevel: item.bottomOccupancyLevel,
          bottomNoiseLevelRssiMin: item.bottomNoiseLevelRssiMin,
          topNoiseLevelRssiMax: item.topNoiseLevelRssiMax,
          bottomNoiseLevelRssiMax: item.bottomNoiseLevelRssiMax,
        })),
        trackMapDivision: setting.trackMapDivision,
        movingAverage: setting.movingAverage,
        isDisplayPinLabel: setting.isDisplayPinLabel,
        isHMEdgeLightColor: setting.isHMEdgeLightColor,
        isDisplayHMValue: setting.isDisplayHMValue,
        pinSize: setting.pinSize,
        heatMapEdgeSize: setting.heatMapEdgeSize,
        heatMapBlockSize: setting.heatMapBlockSize,
      },
    };
    // console.log(JSON.stringify(body));
    const resData = await storeSetting(areaId, gatewayId, body);
    const ret = wrapForObject<SettingType>(resData);
    return ret;
  }

  async storePin(areaId: string, gatewayId: string, mapId: string, mapPin: MapPinType): Promise<BasePinType> {
    const body: PinItem = {
      pin: {
        pinId: mapPin.pinId,
        label: mapPin.label,
        pinType: mapPin.pinType,
        isRssiFix: mapPin.isRssiFix,
        hasGpsInfo: mapPin.hasGpsInfo,
        lat: mapPin.lat,
        lng: mapPin.lng,
      },
    };
    // console.log(JSON.stringify(body));
    const resData = await storePin(areaId, gatewayId, mapId, body);
    const ret = wrapForObject<BasePinType>(resData);
    return ret;
  }

  async storeMap(areaId: string, gatewayId: string, mapObj: MapObjType): Promise<MapObjType> {
    const body: MapItem = {
      map: {
        mapId: mapObj.mapId,
        mapType: mapObj.mapType,
        lat: mapObj.lat,
        lng: mapObj.lng,
        elevation: mapObj.elevation,
        zoom: mapObj.zoom,
        mapTypeId: mapObj.mapTypeId,
        maxHeight: mapObj.maxHeight,
        minHeight: mapObj.minHeight,
        vertical: mapObj.vertical,
        horizontal: mapObj.horizontal,
        pins: mapObj.pins,
        imgUrl: mapObj.imgUrl,
        imgWidth: mapObj.imgWidth,
        imgHeight: mapObj.imgHeight,
        imgRotate: mapObj.imgRotate,
        imgLat: mapObj.imgLat,
        imgLng: mapObj.imgLng,
      },
    };
    // console.log(JSON.stringify(body));
    const resData = await storeMap(areaId, gatewayId, body);
    const ret = wrapForObject<MapObjType>(resData);
    return ret;
  }

  async deleteMap(areaId: string, gatewayId: string, mapId: string): Promise<boolean> {
    return await deleteMap(areaId, gatewayId, mapId);
  }

  async deletePin(areaId: string, gatewayId: string, mapId: string, pinId: string): Promise<boolean> {
    return await deletePin(areaId, gatewayId, mapId, pinId);
  }
}

import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import {
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  Tab,
  Stack,
  styled,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  SxProps,
  Slider,
} from '@mui/material';
import { DialogContent } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SliderWithInputField from '../Common/SliderWithInputField';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { CustomDialogButton } from '../Common/CustomDialogButton';
import { FREQUENCY_BAND } from '../../domain/types/frequency';
import {
  NOISE_DATA_SOURCE,
  CALC_METHOD,
  LevelThresholdType,
  SettingType,
  NoiseDataSourceType,
  CalcMethodType,
  DEFAULT_SETTING,
  Track_MAP_DIVISION,
  MOVING_AVERAGE,
  HEAT_MAP_EDGE_SIZE,
  HEAT_MAP_BLOCK_SIZE,
  PIN_SIZE,
} from '../../domain/types/setting';
import {
  SettingContext,
  storeTenantMasterUsecase,
  SelectedGatewayContext,
  AREA_ID,
  IsPlayingContext,
  IsSyncContext,
} from '../../radioMonitoringPage';
import { SelectStyle, InputLabelStyle } from '../../domain/types/sxProps';
import { StoreSettingParams } from 'domain/usecases/storeSetting';
import CustomButton from 'components/Common/CustomButton';
import CustomPopup from 'components/Common/CustomPopup';
import CustomCheckBox from 'components/Common/CustomCheckBox';

const TabStyle: SxProps = {
  color: '#FFFFFF',
  backgroundColor: '#333333',
};

//ダイアログ
interface DialogProps {
  open: boolean;
  onClose: () => void;
  frequencyBand: string;
  setFrequencyBand: Dispatch<React.SetStateAction<string>>;
}
export const DialogSetting = (props: DialogProps) => {
  const { onClose, open, frequencyBand, setFrequencyBand } = props;
  const { t } = useTranslation();
  const { setting, setSetting } = useContext(SettingContext);
  const isResumePlaying = useRef(false);
  const isResumeSync = useRef(false);

  // ダイアログを開いた際に再生中の場合は、停止する
  const { isPlaying, setIsPlaying } = useContext(IsPlayingContext);
  const { isSync, setIsSync } = useContext(IsSyncContext);

  const { selectedGateway } = useContext(SelectedGatewayContext);
  //NOTO：設定画面内で利用する設定のステート（保存時にアプリ全体の設定ステートへ反映する）
  const [newSetting, setNewSetting] = useState<SettingType>(setting);

  useEffect(() => {
    if (open) {
      if (isSync) {
        setIsSync(false);
        setIsPlaying(false);
        isResumeSync.current = true;
      } else if (isPlaying) {
        setIsPlaying(false);
        isResumePlaying.current = true;
      } else {
        isResumePlaying.current = false;
        isResumeSync.current = false;
      }
    }
    // eslint-disable-next-line
  }, [open]);

  const handleClose = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
    if (reason === 'backdropClick') return;
    handleCancel();
  };
  const handleCancel = () => {
    setNewSetting(setting);
    onClose();
    handleResume();
  };
  const handleSubmit = async () => {
    try {
      //TODO:DBの設定データ更新する
      await handleStoreSetting(AREA_ID, selectedGateway, newSetting);
      //NOTO：設定データをアプリ全体のステートへ反映
      setSetting({
        ...newSetting,
        levelThresholdList: newSetting.levelThresholdList.map((value) => {
          const newData: LevelThresholdType = {
            ...value,
          };
          return newData;
        }),
      });
      onClose();
      handleResume();
    } catch (e) {
      alert(t('設定の保存に失敗しました'));
    }
  };

  // 再生していた場合は再開する
  const handleResume = () => {
    if (isResumePlaying.current) {
      setIsPlaying(true);
    }
    if (isResumeSync.current) {
      setIsPlaying(true);
      setIsSync(true);
    }
  };

  useEffect(() => {
    setNewSetting(setting);
  }, [setting]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth='sm'
      sx={{
        '& .MuiPaper-root': {
          background: '#000',
          borderBlockColor: '#333333',
          borderWidth: 1,
        },
      }}
    >
      <DialogTitle>{t('設定')}</DialogTitle>
      <DialogContent>
        <LabTabs
          newSetting={newSetting}
          setNewSetting={setNewSetting}
          setSetting={setSetting}
          frequencyBand={frequencyBand}
          setFrequencyBand={setFrequencyBand}
        />
      </DialogContent>
      <DialogActions>
        <CustomDialogButton
          onClick={handleCancel}
          variant='contained'
          sx={{ backgroundColor: '#333333' }}
          title='キャンセル'
        />
        <CustomDialogButton onClick={handleSubmit} variant='contained' sx={{ backgroundColor: '#333333' }} title='OK' />
      </DialogActions>
    </Dialog>
  );
};

//設定保存
const handleStoreSetting = async (areaId: string, gatewayId: string, setting: SettingType) => {
  // NOTE: 以下は例外処理のデバッグ用
  // throw new Error('Debug test');
  const params = new StoreSettingParams(areaId, gatewayId, setting);
  const storedSetting = await storeTenantMasterUsecase.call(params);
  console.log(`stored => ${JSON.stringify(storedSetting)}`);
};

//タブ
interface LabTabsProps {
  frequencyBand: string;
  setFrequencyBand: Dispatch<React.SetStateAction<string>>;
  newSetting: SettingType;
  setNewSetting: Dispatch<React.SetStateAction<SettingType>>;
  setSetting: Dispatch<React.SetStateAction<SettingType>>;
}
const LabTabs = ({ frequencyBand, setFrequencyBand, newSetting, setNewSetting, setSetting }: LabTabsProps) => {
  const frequencyBands = [
    { id: FREQUENCY_BAND.FREQUENCY_920M.ID, name: FREQUENCY_BAND.FREQUENCY_920M.LABEL },
    { id: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, name: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.LABEL },
    { id: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, name: FREQUENCY_BAND.FREQUENCY_WLAN5G.LABEL },
    { id: FREQUENCY_BAND.FREQUENCY_L5G.ID, name: FREQUENCY_BAND.FREQUENCY_L5G.LABEL },
  ];
  const noiseDataSources = [
    { id: NOISE_DATA_SOURCE.RSSIMAX, name: NOISE_DATA_SOURCE.RSSIMAX },
    { id: NOISE_DATA_SOURCE.RSSIMIN, name: NOISE_DATA_SOURCE.RSSIMIN },
  ];
  const calcMethods = [
    { id: CALC_METHOD.MIN.ID, name: CALC_METHOD.MIN.LABEL },
    { id: CALC_METHOD.AVE.ID, name: CALC_METHOD.AVE.LABEL },
    { id: CALC_METHOD.MAX.ID, name: CALC_METHOD.MAX.LABEL },
  ];

  const [tabIndex, setTabIndex] = useState<string>('general');
  const { t } = useTranslation();
  const { selectedGateway } = useContext(SelectedGatewayContext);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
  };

  const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    flex: 1,
    textAlign: 'center',
  }));

  // マップタブのステート
  const [trackMapDivision, setTrackMapDivision] = useState<number>(
    newSetting.trackMapDivision ? newSetting.trackMapDivision : Track_MAP_DIVISION.DEFAULT
  );
  const [isDisplayPinLabel, setIsDisplayPinLabel] = useState<boolean>(newSetting.isDisplayPinLabel);
  const [pinSize, setPinSize] = useState<number>(newSetting.pinSize);
  const [heatMapEdgeSize, setHeatMapEdgeSize] = useState<number>(newSetting.heatMapEdgeSize);
  const [isHMEdgeLightColor, setIsHMEdgeLightColor] = useState<boolean>(newSetting.isHMEdgeLightColor);
  const [heatMapBlockSize, setHeatMapBlockSize] = useState<number>(newSetting.heatMapBlockSize);
  const [isDisplayHMValue, setIsDisplayHMValue] = useState<boolean>(newSetting.isDisplayHMValue);

  //レベル閾値タブのステート
  const firstLevelThresholdList = newSetting.levelThresholdList.filter((val) => val.frequencyBand == frequencyBand);
  const [topOccupancyLevel, setTopOccupancyLevel] = useState<number>(firstLevelThresholdList[0].topOccupancyLevel);
  const [bottomOccupancyLevel, setBottomOccupancyLevel] = useState<number>(
    firstLevelThresholdList[0].bottomOccupancyLevel
  );
  const [topNoiseLevelRssiMin, setTopNoiseLevelRssiMin] = useState<number>(
    firstLevelThresholdList[0].topNoiseLevelRssiMin
  );
  const [bottomNoiseLevelRssiMin, setBottomNoiseLevelRssiMin] = useState<number>(
    firstLevelThresholdList[0].bottomNoiseLevelRssiMin
  );
  const [topNoiseLevelRssiMax, setTopNoiseLevelRssiMax] = useState<number>(
    firstLevelThresholdList[0].topNoiseLevelRssiMax
  );
  const [bottomNoiseLevelRssiMax, setBottomNoiseLevelRssiMax] = useState<number>(
    firstLevelThresholdList[0].bottomNoiseLevelRssiMax
  );

  // グラフタブのステート
  const [movingAverage, setMovingAverage] = useState<number>(
    newSetting.movingAverage ? newSetting.movingAverage : MOVING_AVERAGE.DEFAULT
  );

  const [isOpenedPopup, setIsOpenedPopup] = useState<boolean>(false);

  //レベル閾値タブの各値が変更された際に配列オブジェクトへ反映する
  useEffect(() => {
    setNewSetting({
      ...newSetting,
      levelThresholdList: newSetting.levelThresholdList.map((data) => {
        if (data.frequencyBand === frequencyBand) {
          const newData: LevelThresholdType = {
            frequencyBand: frequencyBand,
            topOccupancyLevel: topOccupancyLevel,
            bottomOccupancyLevel: bottomOccupancyLevel,
            topNoiseLevelRssiMin: topNoiseLevelRssiMin,
            bottomNoiseLevelRssiMin: bottomNoiseLevelRssiMin,
            topNoiseLevelRssiMax: topNoiseLevelRssiMax,
            bottomNoiseLevelRssiMax: bottomNoiseLevelRssiMax,
          };
          return newData;
        } else {
          return data;
        }
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topOccupancyLevel,
    bottomOccupancyLevel,
    topNoiseLevelRssiMin,
    bottomNoiseLevelRssiMin,
    topNoiseLevelRssiMax,
    bottomNoiseLevelRssiMax,
  ]);

  useEffect(() => {
    setNewSetting({
      ...newSetting,
      trackMapDivision,
      movingAverage,
      isDisplayPinLabel,
      isDisplayHMValue,
      isHMEdgeLightColor,
      heatMapEdgeSize,
      heatMapBlockSize,
      pinSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trackMapDivision,
    movingAverage,
    isDisplayPinLabel,
    isDisplayHMValue,
    isHMEdgeLightColor,
    heatMapEdgeSize,
    heatMapBlockSize,
    pinSize,
  ]);

  //周波数バンド変更
  const handleSelectChange = (event: SelectChangeEvent) => {
    const newData = newSetting.levelThresholdList.find((val) => val.frequencyBand === event.target.value);
    if (newData) {
      setTopOccupancyLevel(newData.topOccupancyLevel);
      setBottomOccupancyLevel(newData.bottomOccupancyLevel);
      setTopNoiseLevelRssiMin(newData.topNoiseLevelRssiMin);
      setBottomNoiseLevelRssiMin(newData.bottomNoiseLevelRssiMin);
      setTopNoiseLevelRssiMax(newData.topNoiseLevelRssiMax);
      setBottomNoiseLevelRssiMax(newData.bottomNoiseLevelRssiMax);
    }
    setFrequencyBand(event.target.value);
  };

  //ノイズデータソース変更
  const handleNoiseDataSourceSelectChange = (event: SelectChangeEvent) => {
    setNewSetting({
      ...newSetting,
      noiseDataSource: event.target.value as NoiseDataSourceType,
    });
  };

  //ノイズ計算方法変更
  const handleNoiseCalcMethodSelectChange = (event: SelectChangeEvent) => {
    setNewSetting({
      ...newSetting,
      noiseCalcuMethod: event.target.value as CalcMethodType,
    });
  };

  //占有率計算方法変更
  const handleOccupancyCalcMethodSelectChange = (event: SelectChangeEvent) => {
    setNewSetting({
      ...newSetting,
      occupancyCalcMethod: event.target.value as CalcMethodType,
    });
  };

  // ポップアップの OK 選択時の処理
  const handlePopupConfirm = async () => {
    const updateSetting: SettingType = DEFAULT_SETTING;

    try {
      //TODO:DBの設定データ更新する
      await handleStoreSetting(AREA_ID, selectedGateway, updateSetting);
      //NOTO：設定データをアプリ全体のステートへ反映
      const newData = updateSetting.levelThresholdList.find((val) => val.frequencyBand === frequencyBand);
      if (newData) {
        setTopOccupancyLevel(newData.topOccupancyLevel);
        setBottomOccupancyLevel(newData.bottomOccupancyLevel);
        setTopNoiseLevelRssiMin(newData.topNoiseLevelRssiMin);
        setBottomNoiseLevelRssiMin(newData.bottomNoiseLevelRssiMin);
        setTopNoiseLevelRssiMax(newData.topNoiseLevelRssiMax);
        setBottomNoiseLevelRssiMax(newData.bottomNoiseLevelRssiMax);
      }

      setSetting({
        ...updateSetting,
      });
      setNewSetting({ ...updateSetting });
    } catch (e) {
      alert(t('設定の保存に失敗しました'));
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setPinSize(newValue as number);
  };

  const marks = [
    {
      value: PIN_SIZE.MIN,
      label: t('小'),
    },
    {
      value: PIN_SIZE.DEFAULT,
      label: t('中'),
    },
    {
      value: PIN_SIZE.MAX,
      label: t('大'),
    },
  ];

  return (
    <Box sx={{ width: '550px', height: '620px', typography: 'body1' }}>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            aria-label='lab API tabs example'
            sx={{
              '& .MuiTab-root.Mui-selected': { color: '#FFFFFF' },
              '& .MuiTabs-indicator': { bgcolor: '#FFFFFF' },
            }}
          >
            <Tab label={t('一般')} value='general' sx={{ ...TabStyle }} />
            <Tab label={t('マップ')} value='map' sx={{ ...TabStyle }} />
            <Tab label={t('レベル閾値')} value='levelThreshold' sx={{ ...TabStyle }} />
            <Tab label={t('グラフ')} value='chart' sx={{ ...TabStyle }} />
          </TabList>
        </Box>
        <TabPanel value='general'>
          <Stack direction='row'>
            <Box sx={{ marginTop: '20px', width: '150px' }}>{t('ノイズ：')}</Box>
            <Item>
              <Tooltip
                title={
                  <React.Fragment>
                    {t('ノイズ値のデータソース')}
                    <br />
                    {t('ノイズ値の計算に使用するデータソースを選択してください')}
                  </React.Fragment>
                }
                placement='top-start'
              >
                <div>
                  <FormControl sx={{ width: '200px' }}>
                    <InputLabel
                      sx={{
                        '&.MuiInputLabel-shrink': {
                          color: '#DC0000',
                        },
                      }}
                    >
                      {t('ノイズデータソース')}
                    </InputLabel>
                    <Select
                      labelId='select-noise-data-source'
                      id='select-noise-data-source'
                      value={newSetting.noiseDataSource}
                      label={t('ノイズデータソース')}
                      onChange={handleNoiseDataSourceSelectChange}
                      sx={{
                        textAlign: 'left',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#FFFFFF',
                        },
                      }}
                    >
                      {noiseDataSources.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Tooltip>
            </Item>

            <Item>
              <Tooltip
                title={
                  <React.Fragment>
                    {t('ノイズ値の計算方法')}
                    <br />
                    {t('ノイズ値の計算方法を選択してください')}
                  </React.Fragment>
                }
                placement='top'
              >
                <div>
                  <FormControl sx={{ width: '200px' }}>
                    <InputLabel
                      sx={{
                        '&.MuiInputLabel-shrink': {
                          color: '#DC0000',
                        },
                      }}
                    >
                      {t('ノイズ計算方法')}
                    </InputLabel>
                    <Select
                      labelId='select-noise-calc-method-label'
                      id='select-noise-calc-method'
                      value={newSetting.noiseCalcuMethod}
                      label={t('ノイズ計算方法')}
                      onChange={handleNoiseCalcMethodSelectChange}
                      sx={{
                        textAlign: 'left',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#FFFFFF',
                        },
                      }}
                    >
                      {calcMethods.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Tooltip>
            </Item>
          </Stack>
          <Stack direction='row'>
            <Box sx={{ marginTop: '20px', width: '150px' }}>{t('占有率：')}</Box>
            <Item>
              <Tooltip
                title={
                  <React.Fragment>
                    {t('占有率の計算方法')}
                    <br />
                    {t('占有率の計算方法を選択してください')}
                  </React.Fragment>
                }
                placement='right'
              >
                <div>
                  <FormControl sx={{ width: '200px' }}>
                    <InputLabel
                      sx={{
                        '&.MuiInputLabel-shrink': {
                          color: '#DC0000',
                        },
                      }}
                    >
                      {t('占有率計算方法')}
                    </InputLabel>
                    <Select
                      labelId='select-occupancy-calc-method'
                      id='select-occupancy-calc-method'
                      value={newSetting.occupancyCalcMethod}
                      label={t('占有率計算方法')}
                      onChange={handleOccupancyCalcMethodSelectChange}
                      sx={{
                        textAlign: 'left',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#FFFFFF',
                        },
                      }}
                    >
                      {calcMethods.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Tooltip>
            </Item>
            <Item>
              <Box sx={{ width: '200px' }}></Box>
            </Item>
          </Stack>
          <Box sx={{ mt: 6, width: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomButton
              title={t('全ての設定を初期化')}
              sx={{ p: 1, width: 1 / 2 }}
              onClick={() => setIsOpenedPopup(true)}
            />
          </Box>
          <CustomPopup
            isOpened={isOpenedPopup}
            setIsOpened={setIsOpenedPopup}
            confirmProcess={handlePopupConfirm}
            dialogText={<Box>{t('設定を初期化しますか?')}</Box>}
          />
        </TabPanel>
        <TabPanel value='map'>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ borderTop: 'solid gray' }}>
                  <Grid container spacing={1} sx={{ marginY: '10px', alignItems: 'center' }}>
                    <Grid item xs={5}>
                      {t('ピンのラベルを表示する')}
                    </Grid>
                    <Grid item xs={7}>
                      <CustomCheckBox
                        onChange={() => setIsDisplayPinLabel((prev) => !prev)}
                        checked={isDisplayPinLabel}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t('ピンのサイズ')}
                    </Grid>
                    <Grid item xs={5}>
                      <Slider
                        sx={{
                          color: '#0078d7',
                          '& .MuiSlider-markLabel': {
                            color: 'white',
                          },
                        }}
                        value={pinSize}
                        onChange={handleSliderChange}
                        min={PIN_SIZE.MIN}
                        max={PIN_SIZE.MAX}
                        marks={marks}
                        step={null}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ borderTop: 'solid gray', borderBottom: 'solid gray' }}>
                  <Grid container spacing={1} sx={{ marginY: '10px', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <SliderWithInputField
                        max={HEAT_MAP_EDGE_SIZE.MAX}
                        min={HEAT_MAP_EDGE_SIZE.MIN}
                        originalMin={HEAT_MAP_EDGE_SIZE.MIN}
                        originalMax={HEAT_MAP_EDGE_SIZE.MAX}
                        value={heatMapEdgeSize}
                        setValue={setHeatMapEdgeSize}
                        title={t('ヒートマップの縁')}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {'ヒートマップの縁を薄くする'}
                    </Grid>
                    <Grid item xs={7}>
                      <CustomCheckBox
                        checked={isHMEdgeLightColor}
                        onChange={() => setIsHMEdgeLightColor((prev) => !prev)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t('ヒートマップのブロックサイズ')}
                    </Grid>
                    <Grid item xs={7}>
                      <SliderWithInputField
                        max={HEAT_MAP_BLOCK_SIZE.MAX}
                        min={HEAT_MAP_BLOCK_SIZE.MIN}
                        originalMin={HEAT_MAP_BLOCK_SIZE.MIN}
                        originalMax={HEAT_MAP_BLOCK_SIZE.MAX}
                        value={heatMapBlockSize}
                        setValue={setHeatMapBlockSize}
                        title={''}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t('ヒートマップの値を表示する')}
                    </Grid>
                    <Grid item xs={7}>
                      <CustomCheckBox
                        checked={isDisplayHMValue}
                        onChange={() => setIsDisplayHMValue((prev) => !prev)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SliderWithInputField
                        max={Track_MAP_DIVISION.MAX}
                        min={Track_MAP_DIVISION.MIN}
                        originalMin={Track_MAP_DIVISION.MIN}
                        originalMax={Track_MAP_DIVISION.MAX}
                        value={trackMapDivision}
                        setValue={setTrackMapDivision}
                        title={t('軌跡マップの分割数')}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value='levelThreshold'>
          <FormControl sx={{ width: '200px' }}>
            <InputLabel
              sx={{
                ...InputLabelStyle,
              }}
            >
              {t('周波数バンド')}
            </InputLabel>
            <Select
              labelId='select-label'
              id='select'
              value={frequencyBand}
              label={t('周波数バンド')}
              onChange={handleSelectChange}
              sx={{
                ...SelectStyle,
              }}
            >
              {frequencyBands.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ borderTop: 'solid gray' }} marginTop={1}>
            <Grid container spacing={1} marginTop={2} marginBottom={2}>
              <Grid item xs={12} marginTop={1.5} margin={1}>
                {t('占有率のレベル判別閾値')}
              </Grid>
              <Grid item xs={7}>
                <SliderWithInputField
                  max={100}
                  min={bottomOccupancyLevel}
                  originalMin={0}
                  originalMax={100}
                  value={topOccupancyLevel}
                  setValue={setTopOccupancyLevel}
                  title={t('上')}
                />
              </Grid>
              <Grid item xs={7}>
                <SliderWithInputField
                  max={topOccupancyLevel}
                  min={0}
                  originalMin={0}
                  originalMax={100}
                  value={bottomOccupancyLevel}
                  setValue={setBottomOccupancyLevel}
                  title={t('下')}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ borderTop: 'solid gray' }}>
            <Grid container spacing={1} marginTop={1.5} marginBottom={2}>
              <Grid item xs={12} marginTop={2}>
                {t('ノイズのレベル判別閾値（データソース：RSSIMIN）')}
              </Grid>
              <Grid item xs={7}>
                <SliderWithInputField
                  max={-20}
                  min={bottomNoiseLevelRssiMin}
                  originalMin={-120}
                  originalMax={-20}
                  value={topNoiseLevelRssiMin}
                  setValue={setTopNoiseLevelRssiMin}
                  title={t('上')}
                />
              </Grid>
              <Grid item xs={7}>
                <SliderWithInputField
                  max={topNoiseLevelRssiMin}
                  min={-120}
                  originalMin={-120}
                  originalMax={-20}
                  value={bottomNoiseLevelRssiMin}
                  setValue={setBottomNoiseLevelRssiMin}
                  title={t('下')}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ borderTop: 'solid gray' }}>
            <Grid container spacing={1} marginTop={1.5}>
              <Grid item xs={12}>
                {t('ノイズのレベル判別閾値（データソース：RSSIMAX）')}
              </Grid>
              <Grid item xs={7}>
                <SliderWithInputField
                  max={-20}
                  min={bottomNoiseLevelRssiMax}
                  originalMin={-120}
                  originalMax={-20}
                  value={topNoiseLevelRssiMax}
                  setValue={setTopNoiseLevelRssiMax}
                  title={t('上')}
                />
              </Grid>
              <Grid item xs={7}>
                <SliderWithInputField
                  max={topNoiseLevelRssiMax}
                  min={-120}
                  originalMin={-120}
                  originalMax={-20}
                  value={bottomNoiseLevelRssiMax}
                  setValue={setBottomNoiseLevelRssiMax}
                  title={t('下')}
                />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value='chart'>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} marginTop={1.5} margin={1}>
                {t('移動平均Nの値')}
              </Grid>
              <Grid item xs={7}>
                <SliderWithInputField
                  max={MOVING_AVERAGE.MAX}
                  min={MOVING_AVERAGE.MIN}
                  originalMin={MOVING_AVERAGE.MIN}
                  originalMax={MOVING_AVERAGE.MAX}
                  value={movingAverage}
                  setValue={setMovingAverage}
                  title={''}
                />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

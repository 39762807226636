import React, { SyntheticEvent, useContext, useMemo, useEffect } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChannelTab } from './ChannelTab/ChannelTab';
import MapTab from './MapTab/MapTab';
import { GraphTab } from './GraphTab/GraphTab';
import { LayoutContext } from '../LayoutContext';
import ErrorFallback from 'components/Common/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import {
  SelectedFrequencyBandContext,
  SelectedSpanContext,
  DateTimeContext,
  SettingContext,
  IsSyncContext,
  AllFrequenciesContext,
  ChannelGroupListContext,
  PlayingSpeedIndexContext,
} from '../../../radioMonitoringPage';
import useSWR from 'swr';
import { handleGetChannelData, SWR_URLS } from 'utils/swrWrapperApi';
import { useMaps } from 'MapsContext';
import { CHANNEL_DETAIL_ROW_COUNT } from '../../../domain/types/channel';
import { getSelectedMap } from '../../../utils/extract';
import { getColorFromLevel } from 'utils/format';
import { FetchChannelDataParams } from 'domain/usecases/fetchChannelData';
import { getDateTimeWithTimeOffset } from 'utils/dateHelper';
import { FREQUENCIES, FREQUENCY_BAND } from 'domain/types/frequency';
import { PLAYING_SPEED } from 'domain/types/common/consts';

interface Props {
  tabIndex: string;
  handleTabChange: (tabIndex: string) => void;
  className?: string;
}

const MainPage = ({ tabIndex, handleTabChange, className }: Props) => {
  const { t } = useTranslation();
  const { selectedMapId, handleMapSelect } = useContext(LayoutContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedSpan } = useContext(SelectedSpanContext);
  const { dateTime } = useContext(DateTimeContext);
  const { setting } = useContext(SettingContext);
  const { isSync } = useContext(IsSyncContext);
  const { selectedPinIds, isDisplayedChannel } = useContext(LayoutContext);
  const { mapsData } = useMaps();
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  const { setAllFrequencies } = useContext(AllFrequenciesContext);
  const { setChannelGroupList } = useContext(ChannelGroupListContext);
  const { playingSpeedIndex } = useContext(PlayingSpeedIndexContext);

  // パラメーター生成
  const params = new FetchChannelDataParams(
    getDateTimeWithTimeOffset(
      dateTime,
      Number(selectedSpan) * PLAYING_SPEED[playingSpeedIndex.current ?? 0],
      0,
      isSync
    ),
    selectedSpan,
    selectedPinIds.length == 0 ? [''] : selectedPinIds.map((pinId) => pinId),
    selectedFrequencyBand,
    selectedMap.minHeight,
    selectedMap.maxHeight,
    CHANNEL_DETAIL_ROW_COUNT,
    selectedFrequencyBand,
    setting,
    isDisplayedChannel
  );
  // データ取得用SWフック
  const key = JSON.stringify(params);
  const { data } = useSWR([SWR_URLS.CHANNEL, key], () => handleGetChannelData(params));

  // 推奨チャンネル推奨度と周波数の色対応付け
  useEffect(() => {
    setAllFrequencies(FREQUENCIES[selectedFrequencyBand].list);
    if (data) {
      if (selectedFrequencyBand == FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID && isDisplayedChannel) {
        setChannelGroupList((prev) => {
          return prev.map((freq) => {
            const row = data.find((c) => freq.frequency === c.freq);
            if (row) {
              return { ...freq, color: getColorFromLevel(Number(row.recommend)) };
            }
            return freq;
          });
        });
      } else {
        setAllFrequencies((prev) => {
          return prev.map((freq) => {
            const row = data.find((c) => freq.frequency === c.freq);
            if (row) {
              return { ...freq, color: getColorFromLevel(Number(row.recommend)) };
            }
            return freq;
          });
        });
      }
    }
  }, [data, setAllFrequencies, setChannelGroupList, selectedFrequencyBand, isDisplayedChannel]);

  return (
    <>
      <div className={className}>
        <TabContext value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: '#333333', color: '#FFFFFF' }}>
            <TabList
              onChange={(event: SyntheticEvent, newValue: string) => handleTabChange(newValue)}
              sx={{
                '& .MuiTab-root.Mui-selected': { color: '#FFFFFF' },
                '& .MuiTabs-indicator': { bgcolor: '#FFFFFF' },
                minHeight: '35px',
                height: '35px',
              }}
            >
              <Tab label={t('マップ')} value='1' sx={{ color: '#FFFFFF' }} />
              <Tab label={t('推奨チャンネル')} value='2' sx={{ color: '#FFFFFF' }} />
              <Tab label={t('データ')} value='3' sx={{ color: '#FFFFFF' }} />
            </TabList>
          </Box>
          <TabPanel value='1' sx={{ px: 1, pt: 2, pb: 0, height: 1, maxHeight: '95%' }}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <MapTab tabIndex={tabIndex} />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel value='2' sx={{ px: 0, pt: 2, pb: 0, height: 1, maxHeight: '95%' }}>
            <ChannelTab selectedMapId={selectedMapId} handleMapSelect={handleMapSelect} />
          </TabPanel>
          <TabPanel value='3' sx={{ px: 1, pt: 2, pb: 0, height: 1, maxHeight: '95%' }}>
            <GraphTab />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default React.memo(MainPage);

import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  title: string;
}

const CustomButton = ({ title, ...props }: Props) => {
  // スタイルを動的に調整するためのオブジェクト
  const baseStyle = { backgroundColor: '#333333', color: '#FFFFFF', borderRadius: '3px', zIndex: 10 };
  const buttonStyle = { ...baseStyle };
  const { t } = useTranslation();

  return (
    <Button {...props} style={buttonStyle}>
      {t(`${title}`)}
    </Button>
  );
};

export default React.memo(CustomButton);

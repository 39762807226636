import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { Stack } from '@mui/material';
import { useJsApiLoader, Libraries } from '@react-google-maps/api';
import { MapEditHeader, MapHeader } from './MapHeader';
import HeatBarArea from './HeatBar/HeatBarArea';
import { PinData, MapModeType, MAIN_MAP_ID, MapObjType } from 'domain/types/map';
import MapArea from './MapArea';
import { RwmMapTabContext } from './RwmMapContext';
import { DEFAULT_IMAGE_CONF } from 'domain/types/image';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';

const LIBRARIES: Libraries = ['marker', 'core'];

interface Props {
  tabIndex: string;
}

const MapTab = ({ tabIndex }: Props) => {
  const { selectedMapId } = useContext(LayoutContext);
  const [mapMode, setMapMode] = useState<MapModeType>('read');
  const mapAreaRef = useRef<HTMLDivElement>(null);
  const [mapTabWidth, setMapTabWidth] = useState<number>(0);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
    libraries: LIBRARIES,
    version: 'weekly',
  });

  const [pinData, setPinData] = useState<PinData[]>([]);

  // NOTE: 画像の拡大・縮小・回転用のステート
  const [imageWidth, setImageWidth] = useState<number>(DEFAULT_IMAGE_CONF.width);
  const [imageHeight, setImageHeight] = useState<number>(DEFAULT_IMAGE_CONF.height);
  const [imageRotate, setImageRotate] = useState<number>(DEFAULT_IMAGE_CONF.rotate);
  const [imageLat, setImageLat] = useState<number>(DEFAULT_IMAGE_CONF.lat);
  const [imageLng, setImageLng] = useState<number>(DEFAULT_IMAGE_CONF.lng);

  const [tmpImageWidth, setTmpImageWidth] = useState<number>(imageWidth);
  const [tmpImageHeight, setTmpImageHeight] = useState<number>(imageHeight);
  const [tmpImageRotate, setTmpImageRotate] = useState<number>(imageRotate);
  const [tmpImageLat, setTmpImageLat] = useState<number>(imageLat);
  const [tmpImageLng, setTmpImageLng] = useState<number>(imageLng);

  const handleSetImageConf = useCallback((map: MapObjType) => {
    if (map.mapType === 'imageMap') {
      setImageWidth(map.imgWidth);
      setImageHeight(map.imgHeight);
      setImageRotate(map.imgRotate);
      setImageLat(map.imgLat);
      setImageLng(map.imgLng);
    } else {
      setImageWidth(DEFAULT_IMAGE_CONF.width);
      setImageHeight(DEFAULT_IMAGE_CONF.height);
      setImageRotate(DEFAULT_IMAGE_CONF.rotate);
      setImageLat(DEFAULT_IMAGE_CONF.lat);
      setImageLng(DEFAULT_IMAGE_CONF.lng);
    }
  }, []);

  const handleSetPinData = useCallback((pinData: PinData[]) => {
    setPinData(pinData);
  }, []);

  /**
   * @description マップタグの幅が変化した際の値を取得する処理
   */
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (mapAreaRef.current) {
        const { width } = entries[0].contentRect;
        setMapTabWidth(width);
      }
    });

    if (mapAreaRef.current) {
      resizeObserver.observe(mapAreaRef.current);
    }

    // コンポーネントがアンマウントされたときに監視を解除
    return () => {
      if (mapAreaRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(mapAreaRef.current);
      }
    };
  }, []);

  return (
    <RwmMapTabContext.Provider
      value={{
        mapMode,
        setMapMode,
        imageWidth,
        setImageWidth,
        imageHeight,
        setImageHeight,
        imageRotate,
        setImageRotate,
        imageLat,
        setImageLat,
        imageLng,
        setImageLng,
        tmpImageWidth,
        setTmpImageWidth,
        tmpImageHeight,
        setTmpImageHeight,
        tmpImageRotate,
        setTmpImageRotate,
        tmpImageLat,
        setTmpImageLat,
        tmpImageLng,
        setTmpImageLng,
        pinData,
        setPinData,
      }}
    >
      <Stack
        direction='column'
        spacing={1}
        sx={{
          height: 1,
          width: 1,
          justifyContent: 'flex-start',
          alignItems: 'stretch',
        }}
      >
        {mapMode === 'write' ? <MapEditHeader /> : <MapHeader />}
        <Stack direction='row' spacing={1} sx={{ justifyContent: 'center', height: 9 / 10 }} ref={mapAreaRef}>
          {isLoaded && (
            <MapArea
              tabIndex={tabIndex}
              mapTabWidth={mapTabWidth}
              handleSetImageConf={handleSetImageConf}
              handleSetPinData={handleSetPinData}
            />
          )}
          {selectedMapId !== MAIN_MAP_ID && <HeatBarArea />}
        </Stack>
      </Stack>
    </RwmMapTabContext.Provider>
  );
};

export default React.memo(MapTab);

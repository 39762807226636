import React, { ChangeEvent, Dispatch, SetStateAction, useContext } from 'react';
import { Box, FormControlLabel, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

// NOTE: カスタムコンポーネント
import CustomCheckBox from '../../../Common/CustomCheckBox';
import { FrequencyType } from '../../../../domain/types/frequency';
import { removeDuplicatedElements } from '../../../../utils/transform';
import { formatFrequencyLabel } from '../../../../utils/format';
import { RwmContext } from 'RwmContext';
import { AllFrequenciesContext } from '../../../../radioMonitoringPage';

interface Props {
  checkBoxStates: boolean[];
  setCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;
}

const FrequencySelectArea = ({ checkBoxStates, setCheckBoxStates }: Props) => {
  const { t } = useTranslation();
  const { selectedFrequencies, setSelectedFrequencies } = useContext(RwmContext);
  const { allFrequencies } = useContext(AllFrequenciesContext);

  /**
   * @description 周波数の各チェックボックス選択時の処理
   */
  const handleClickFrequencyItem = (
    e: ChangeEvent<HTMLInputElement>,
    targetFrequency: FrequencyType,
    index: number
  ) => {
    let tmpFrequencies: FrequencyType[];
    const tmpCheckBoxStates: boolean[] = [...checkBoxStates];

    if (e.target.checked) {
      tmpFrequencies = removeDuplicatedElements([...selectedFrequencies, targetFrequency]);
      tmpCheckBoxStates[index] = true;
    } else {
      tmpFrequencies = [...selectedFrequencies].filter(
        (selectedFrequency: FrequencyType) => selectedFrequency.frequency != targetFrequency.frequency
      );
      tmpCheckBoxStates[index] = false;
    }

    setSelectedFrequencies(tmpFrequencies);
    setCheckBoxStates(tmpCheckBoxStates);
  };
  return (
    <Box sx={{ alignContent: 'flex-start', flexWrap: 'wrap' }}>
      {allFrequencies.map((frequency: FrequencyType, index: number) => {
        const label: string = formatFrequencyLabel(frequency);
        const isChecked: boolean = checkBoxStates[index];

        return (
          <Tooltip title={t(label)} key={`frequencyCheckBox-${frequency.frequency}`}>
            <FormControlLabel
              sx={{ minWidth: 200, color: frequency.color ? frequency.color : '' }}
              control={
                <CustomCheckBox
                  onChange={(e) => {
                    handleClickFrequencyItem(e, frequency, index);
                  }}
                  // NOTE: 周波数リスト数が少ない周波数バンドから多いものに変更した場合、初回レンダリング時に不足している配列の要素分が undefined となる為、undefined → false に変更する為に二重否定を利用
                  checked={!!isChecked}
                />
              }
              label={t(label)}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default React.memo(FrequencySelectArea);

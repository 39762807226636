// ------------------------------------------------------

// NOTE: インターフェースの定義

/**
 * @description Frequencyグラフx軸のインタフェース
 * @property {string} key 周波数バンド
 * @property {number} min 周波数の最小値
 * @property {number} max 周波数の最大値
 * @property {number} interval 間隔
 * @interface
 */
export interface FREQUENCY_AXIS_X {
  [key: string]: { min: number; max: number; interval: number };
}

/**
 * @description Rssiグラフx軸のインタフェース
 * @property {string} key 期間
 * @property {number} interval 間隔（秒）
 * @property {number} count x軸表示数
 * @interface
 */
export interface RSSI_AXIS_X {
  [key: string]: { interval: number; count: number };
}

/**
 * @description 折れ線グラフデータのインタフェース
 * @property {string} label ラベル
 * @property {number} data データ
 * @property {number} yAxisID 紐づけるy軸
 * @interface
 */
export interface LineGraphData {
  label: string;
  data: TimeData[];
  yAxisID: string;
  pointRadius: number;
}

/**
 * @description 時系列データのインタフェース
 * @property {Date} x 日時
 * @property {number} y 値
 * @interface
 */
export interface TimeData {
  x: Date;
  y: number;
}

/**
 * @description Frequencyグラフx軸のインタフェース
 * @property {number} freqList 周波数リスト
 * @property {number} rssiMinList rssiMinリスト
 * @property {number} rssiMaxList rssiMaxリスト
 * @property {number} ratioMaxList ratioMaxリスト
 * @property {number} ratioAveList ratioAveリスト
 * @property {number} ratioMinList ratioMinリスト
 * @interface
 */
export interface FrequencyGraphData {
  freqList: number[];
  rssiMinList: number[];
  rssiMaxList: number[];
  ratioMaxList: number[];
  ratioAveList: number[];
  ratioMinList: number[];
}

/**
 * @description Deviceグラフ時系列データのインタフェース
 * @property {string} key 電圧・温度・高さ
 * @property {TimeData[]}  時系列データのリスト
 * @interface
 */
export interface DeviceTimeData {
  [key: string]: TimeData[];
}

/**
 * @description グラフの種別
 * @type
 */
export type GraphType = 'Frequency' | 'Device' | 'Rssi' | '上限割合';

/**
 * @description デバイスグラフラベルのインターフェース
 * @property {string} key グラフタイプ
 * @property {string} label ラベル
 * @interface
 */
export interface DEVICE_LABEL_TYPE {
  [key: string]: string;
}

/**
 * @description デバイスグラフ値のインターフェース
 * @property {string} key グラフタイプ
 * @property {string} value 値
 * @interface
 */
export interface DEVICE_VALUE_TYPE {
  [key: string]: number;
}

/**
 * @description ツールチップのCSS設定
 */
export interface ChartToolTipCSSSetting {
  position: string;
  left: string;
  top: string;
  backgroundColor: string;
  borderRadius: string;
  padding: string;
  color: string;
  fontSize: string;
  fontFamily: string;
  boxShadow: string;
  pointerEvents: string;
}

/**
 * @description グラフデータのインデックス値のインターフェース
 */
export interface ChartDataIndex {
  datasetIndex: number;
  index: number;
}

/**
 * @description インデックスの最大値・最小値のインターフェース
 */
export interface ChartDataMaxMinIndex {
  maxIndex: ChartDataIndex;
  minIndex: ChartDataIndex;
}

// ------------------------------------------------------

// NOTE: 定数の定義

/**
 * @description グラフの種類の定数
 * @constant
 */
export const GRAPH = {
  FREQUENCY: 'Frequency',
  DEVICE: 'Device',
  RSSI: 'RSSI',
  MAX_RATE: '上限割合',
} as const;

/**
 * @description グラフ表示順の定数
 * @constant
 */
export const GRAPHS = [GRAPH.FREQUENCY, GRAPH.DEVICE, GRAPH.RSSI, GRAPH.MAX_RATE] as const;

/**
 * @description デバイスのグラフタイプ
 * @constant
 */
export const DEVICE_GRAPH_TYPE = {
  VOLTAG_TEMP: '電圧・温度',
  VOLTAGE_HEIGHT: '電圧・高さ',
  TEMP_HEIGHT: '温度・高さ',
} as const;

/**
 * @description デバイスのグラフタイプリスト
 * @constant
 */
export const DEVICE_GRAPH_TYPE_LIST = [
  DEVICE_GRAPH_TYPE.VOLTAG_TEMP,
  DEVICE_GRAPH_TYPE.VOLTAGE_HEIGHT,
  DEVICE_GRAPH_TYPE.TEMP_HEIGHT,
] as const;

/**
 * @description デバイスグラフのタイトル
 * @constant
 */
export const DEVICE_GRAPH_TITLE = {
  VOLTAGE: 'Voltage',
  TEMPERATURE: 'Temperature',
  HEIGHT: 'Height',
} as const;

/**
 * @description デバイスグラフのラベル
 * @constant
 */
export const DEVICE_GRAPH_LABEL = {
  VOLTAGE: 'Voltage[V]',
  TEMPERATURE: 'Temperature[℃]',
  HEIGHT: 'Height[m]',
} as const;

/**
 * @description デバイスグラフのy軸のラベル
 * @constant
 */
export const DEVICE_LABEL_Y: DEVICE_LABEL_TYPE = {
  [DEVICE_GRAPH_TYPE.VOLTAG_TEMP]: DEVICE_GRAPH_LABEL.VOLTAGE,
  [DEVICE_GRAPH_TYPE.VOLTAGE_HEIGHT]: DEVICE_GRAPH_LABEL.VOLTAGE,
  [DEVICE_GRAPH_TYPE.TEMP_HEIGHT]: DEVICE_GRAPH_LABEL.TEMPERATURE,
};

/**
 * @description デバイスグラフのy軸のラベル
 * @constant
 */
export const DEVICE_LABEL_Y1: DEVICE_LABEL_TYPE = {
  [DEVICE_GRAPH_TYPE.VOLTAG_TEMP]: DEVICE_GRAPH_LABEL.TEMPERATURE,
  [DEVICE_GRAPH_TYPE.VOLTAGE_HEIGHT]: DEVICE_GRAPH_LABEL.HEIGHT,
  [DEVICE_GRAPH_TYPE.TEMP_HEIGHT]: DEVICE_GRAPH_LABEL.HEIGHT,
};

/**
 * @description デバイスグラフのy軸のタイトル
 * @constant
 */
export const DEVICE_LABEL_TITLE: DEVICE_LABEL_TYPE = {
  [DEVICE_GRAPH_LABEL.VOLTAGE]: DEVICE_GRAPH_TITLE.VOLTAGE,
  [DEVICE_GRAPH_LABEL.TEMPERATURE]: DEVICE_GRAPH_TITLE.TEMPERATURE,
  [DEVICE_GRAPH_LABEL.HEIGHT]: DEVICE_GRAPH_TITLE.HEIGHT,
};

/**
 * @description デバイスグラフの最大値
 * @constant
 */
export const DEVICE_MAX_LIST: DEVICE_VALUE_TYPE = {
  [DEVICE_GRAPH_LABEL.VOLTAGE]: 30,
  [DEVICE_GRAPH_LABEL.TEMPERATURE]: 70,
  [DEVICE_GRAPH_LABEL.HEIGHT]: 2000,
};

/**
 * @description デバイスグラフの最小値
 * @constant
 */
export const DEVICE_MIN_LIST: DEVICE_VALUE_TYPE = {
  [DEVICE_GRAPH_LABEL.VOLTAGE]: 0,
  [DEVICE_GRAPH_LABEL.TEMPERATURE]: -10,
  [DEVICE_GRAPH_LABEL.HEIGHT]: 0,
};

/**
 * @description デバイスグラフのステップサイズ
 * @constant
 */
export const DEVICE_STEP_SIZE: DEVICE_VALUE_TYPE = {
  [DEVICE_GRAPH_LABEL.VOLTAGE]: 5,
  [DEVICE_GRAPH_LABEL.TEMPERATURE]: 10,
  [DEVICE_GRAPH_LABEL.HEIGHT]: 100,
};

/**
 * @description 時間軸ラベル表示上限
 * @constant
 */
export const MAX_TICKS_LIMIT = 8;

/**
 * @description ポイントの大きさ
 * @constant
 */
export const DEFAULT_POINT_RADIUS = 5;

/**
 * @description ツールチップ表示時間
 */
export const TOOLTIP_DISPLAY_TIME = 5000;

/**
 * @description 占有率のデフォルト範囲
 */
export const DEFAULT_OCCUPANCY_RANGE = {
  MIN: 0,
  MAX: 100,
};

/**
 * @description RSSIのデフォルト範囲
 */
export const DEFAULT_RSSI_RANGE = {
  MIN: -120,
  MAX: -20,
};

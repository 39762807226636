import React, { createContext, useState, Dispatch, useEffect, useCallback, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Box } from '@mui/material';
import { fetchUserAttributes } from 'aws-amplify/auth';
import ToolBar from 'components/ToolBar/ToolBar';
import SplitLayout from 'components/SplitLayout/SplitLayout';
import {
  CHANNEL_GROUP_2_4G,
  FREQUENCIES,
  FREQUENCY_BAND,
  FrequencyChannelType,
  FrequencyType,
} from './domain/types/frequency';
import { SettingType, DEFAULT_SETTING } from 'domain/types/setting';
import { SPAN, DATA_SOURCE } from 'domain/types/common/consts';
import { PfApiAreaMasterRepository } from 'domain/repositories/pfApiAreaMasterRepository';
import { FetchAreaMasters, FetchAreaMastersParams } from 'domain/usecases/fetchOneAreaMaster';
import { StoreSetting } from 'domain/usecases/storeSetting';
import { Gateway } from 'domain/types/gateway';
import { RwmApiRepository } from 'domain/repositories/rwmRepository';
import { FetchChannelData } from 'domain/usecases/fetchChannelData';
import { FetchFreqGraphData } from 'domain/usecases/fetchFreqGraphData';
import { StoreMap } from 'domain/usecases/storeMap';
import { StorePin } from 'domain/usecases/storePin';
import { FetchPinData } from 'domain/usecases/fetchPinData';
import { FetchPinCandidateList } from 'domain/usecases/fetchPinCandidateList';
import { DeletePin } from 'domain/usecases/deletePin';
import { DeleteMap } from 'domain/usecases/deleteMap';
import { FetchRmsData } from 'domain/usecases/fetchRmsData';
import { FetchDeviceData } from 'domain/usecases/fetcfDeviceData';
import { handleGetGateway, handleStoreMainMap } from 'utils/wrapperApi';
import { MAIN_MAP_ID } from 'domain/types/map';
import { RwmCacheProvider, RwmContext } from 'RwmContext';
import { getCandidatePinIds } from 'utils/extract';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingScreen from 'components/Common/LoadingScreen';
import ErrorFallback from 'components/Common/ErrorFallback';
import { GatewayItem } from 'core/entities/areaMaster';
import { MapsProvider } from 'MapsContext';
import { FetchTrackData } from 'domain/usecases/fetchTrackData';
import { setTargetWithSource } from 'utils/transform';
import { PfApiIotDataRepository } from 'domain/repositories/pfApiIotDataRepository';
import { FetchNearestIotData } from 'domain/usecases/fetchNearestIotData';

// NOTE: 選択中のエリア
export const SelectedAreaContext = createContext(
  {} as {
    selectedArea: string;
    setSelectedArea: Dispatch<React.SetStateAction<string>>;
  }
);

// NOTE: 選択中のゲートウェイ
export const SelectedGatewayContext = createContext(
  {} as {
    selectedGateway: string;
    setSelectedGateway: Dispatch<React.SetStateAction<string>>;
  }
);

// NOTE: 選択中の期間
export const SelectedSpanContext = createContext(
  {} as {
    selectedSpan: string;
    setSelectedSpan: Dispatch<React.SetStateAction<string>>;
  }
);

// NOTE: 再生中
export const IsPlayingContext = createContext(
  {} as {
    isPlaying: boolean;
    setIsPlaying: Dispatch<React.SetStateAction<boolean>>;
  }
);

// NOTE: リアルタイム更新中
export const IsSyncContext = createContext(
  {} as {
    isSync: boolean;
    setIsSync: Dispatch<React.SetStateAction<boolean>>;
  }
);

// NOTE: 選択中のデータソース
export const SelectedDataSourceContext = createContext(
  {} as {
    selectedDataSource: string;
    setSelectedDataSource: Dispatch<React.SetStateAction<string>>;
  }
);
// NOTE: 選択中の設定日時
export const DateTimeContext = createContext(
  {} as {
    dateTime: Dayjs;
    setDateTime: Dispatch<React.SetStateAction<Dayjs>>;
  }
);

// NOTE: 表示用の設定日時
export const DisplayDateTimeContext = createContext(
  {} as {
    displayDateTime: Dayjs;
    setDisplayDateTime: Dispatch<React.SetStateAction<Dayjs>>;
  }
);

// NOTE: 選択中の周波数バンド
export const SelectedFrequencyBandContext = createContext(
  {} as {
    selectedFrequencyBand: string;
    setSelectedFrequencyBand: Dispatch<React.SetStateAction<string>>;
  }
);

// NOTE: 設定
export const SettingContext = createContext(
  {} as {
    setting: SettingType;
    setSetting: Dispatch<React.SetStateAction<SettingType>>;
  }
);

// NOTE: テナント ID
export const TenantIdContext = createContext(
  {} as {
    tenantId: string | undefined;
    setTenantId: Dispatch<React.SetStateAction<string | undefined>>;
  }
);

// NOTE: ゲートウェイのリスト
export const GatewaysContext = createContext(
  {} as {
    gateways: Gateway[];
    setGateways: Dispatch<React.SetStateAction<Gateway[] | undefined>>;
  }
);

// NOTE: 選択中グラフインデックス
export const SelectedGraphIndexContext = createContext(
  {} as {
    selectedGraphIndex: number;
    setSelectedGraphIndex: Dispatch<React.SetStateAction<number>>;
  }
);

// NOTE: 選択中周波数バンドの全ての周波数
export const AllFrequenciesContext = createContext(
  {} as {
    allFrequencies: FrequencyType[];
    setAllFrequencies: Dispatch<React.SetStateAction<FrequencyType[]>>;
  }
);

// NOTE: チャンネルグループ表示用CHリスト
export const ChannelGroupListContext = createContext(
  {} as {
    channelGroupList: FrequencyChannelType[];
    setChannelGroupList: Dispatch<React.SetStateAction<FrequencyChannelType[]>>;
  }
);

// NOTE: 再生速度インデックス
export const PlayingSpeedIndexContext = createContext(
  {} as {
    playingSpeedIndex: React.RefObject<number>;
  }
);

// NOTE: 各種インスタンスの準備
const areaMasterRepo = new PfApiAreaMasterRepository();
export const fetchAreaMastersUsecase = new FetchAreaMasters(areaMasterRepo);
export const storeTenantMasterUsecase = new StoreSetting(areaMasterRepo);
export const storePinUsecase = new StorePin(areaMasterRepo);
export const storeMapUsecase = new StoreMap(areaMasterRepo);
export const fetchUnitList = new FetchPinCandidateList(areaMasterRepo);
export const deleteMapUsecase = new DeleteMap(areaMasterRepo);
export const deletePinUsecase = new DeletePin(areaMasterRepo);

const rwmDatarepo = new RwmApiRepository();
export const fetchChannelDataUsecase = new FetchChannelData(rwmDatarepo);
export const fetchFreqGraphDataUsecase = new FetchFreqGraphData(rwmDatarepo);
export const fetchPinDataUsecase = new FetchPinData(rwmDatarepo);
export const fetchRmsDataUsecase = new FetchRmsData(rwmDatarepo);
export const fetchDeviceDataUsecase = new FetchDeviceData(rwmDatarepo);
export const fetchTrackDataUsecase = new FetchTrackData(rwmDatarepo);

const iotDataRepo = new PfApiIotDataRepository();
export const fetchNearestIotData = new FetchNearestIotData(iotDataRepo);

// NOTE: 固定エリアID
export const AREA_ID = process.env.REACT_APP_AREA_ID || 'undefined';

const RadioMonitoringPage = () => {
  const { t } = useTranslation();
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [selectedArea, setSelectedArea] = useState<string>('area1');
  const [selectedGateway, setSelectedGateway] = useState<string>('');
  const [selectedSpan, setSelectedSpan] = useState<string>(SPAN.FOUR_HOURS);
  const [selectedDataSource, setSelectedDataSource] = useState<string>(DATA_SOURCE.NOISE);
  const [dateTime, setDateTime] = React.useState<Dayjs>(dayjs);
  const [displayDateTime, setDisplayDateTime] = React.useState<Dayjs>(dayjs);
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [isSync, setIsSync] = React.useState<boolean>(false);
  const [selectedFrequencyBand, setSelectedFrequencyBand] = useState<string>(FREQUENCY_BAND.FREQUENCY_920M.ID);
  const [setting, setSetting] = useState<SettingType>(DEFAULT_SETTING);
  const [gateways, setGateways] = useState<Gateway[] | undefined>([]);
  const [candidatePinIds, setCandidatePinIds] = useState<string[]>([]);
  const [selectedGraphIndex, setSelectedGraphIndex] = useState<number>(0);
  const playingSpeedIndex = useRef<number>(0);
  // NOTE: 選択中の周波数 ID リスト
  const [selectedFrequencies, setSelectedFrequencies] = useState<FrequencyType[]>([]);
  const [allFrequencies, setAllFrequencies] = useState<FrequencyType[]>([]);
  const [channelGroupList, setChannelGroupList] = useState<FrequencyChannelType[]>(CHANNEL_GROUP_2_4G);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isExistMainMap, setIsExistMainMap] = useState<boolean>(true);

  const handleChangeExistMainMapFlag = useCallback((flag: boolean) => {
    setIsExistMainMap(flag);
  }, []);

  // NOTE: テナントIDを取得する
  const getTenantId = async (): Promise<string | undefined> => {
    try {
      const attributes = await fetchUserAttributes();
      return attributes['custom:tenantId'];
    } catch (error) {
      console.error('Error fetching tenant ID:', error);
      return undefined;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // NOTE : テナントIDの取得
        const id = await getTenantId();
        setTenantId(id);

        // NOTE : 初期読み込み時エリアデータの取得・設定
        const params = new FetchAreaMastersParams(AREA_ID);
        const areaMaster = await fetchAreaMastersUsecase.call(params);
        const gateways = areaMaster.gateways?.map((value) => {
          const gateway: Gateway = {
            id: value.devId,
            name: value.name ? value.name : value.devId,
          };
          return gateway;
        });
        // NOTE : 初期ゲートウェイの設定
        setGateways(gateways);
        if (gateways) {
          setSelectedGateway(gateways[0].id);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  // ゲートウェイ変更時ゲートウェイデータの取得・設定
  useEffect(() => {
    (async () => {
      try {
        const gateway: GatewayItem | undefined = await handleGetGateway(AREA_ID, selectedGateway);
        if (gateway) {
          // NOTE: ゲートウェイの設定値をセット
          if (gateway.payload.rwmSetting) {
            setTargetWithSource(gateway.payload.rwmSetting, DEFAULT_SETTING);
            setSetting(gateway.payload.rwmSetting);
          } else setSetting(DEFAULT_SETTING);

          // NOTE: ピン候補の ID リストをセット
          gateway.units ? setCandidatePinIds(getCandidatePinIds(gateway.units)) : setCandidatePinIds([]);

          // NOTE: マップの設定値をセット (undefined, length < 1, MAIN_MAP_ID のマップが存在しない場合は DB に登録する)
          const _rwmMaps = gateway.payload.rwmMaps;
          if (selectedGateway) {
            if (_rwmMaps === undefined || _rwmMaps.length < 1) {
              setIsExistMainMap(false);
              await handleStoreMainMap(AREA_ID, selectedGateway);
            } else {
              const mainMapIndex = _rwmMaps.findIndex((map) => map.mapId === MAIN_MAP_ID);
              if (mainMapIndex === -1) {
                setIsExistMainMap(false);
                await handleStoreMainMap(AREA_ID, selectedGateway);
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setSetting(DEFAULT_SETTING);
      setCandidatePinIds([]);
    };
  }, [selectedGateway, t]);

  /**
   * @description 選択中の周波数リストをセット
   */
  useEffect(() => {
    setSelectedFrequencies(FREQUENCIES[selectedFrequencyBand].list);
  }, [selectedFrequencyBand]);

  if (isLoading && !selectedGateway) return <LoadingScreen />;
  return (
    <RwmContext.Provider value={{ candidatePinIds, setCandidatePinIds, selectedFrequencies, setSelectedFrequencies }}>
      <MapsProvider areaId={AREA_ID} gatewayId={selectedGateway}>
        <RwmCacheProvider>
          <SelectedFrequencyBandContext.Provider value={{ selectedFrequencyBand, setSelectedFrequencyBand }}>
            <SelectedAreaContext.Provider value={{ selectedArea, setSelectedArea }}>
              <SelectedGatewayContext.Provider value={{ selectedGateway, setSelectedGateway }}>
                <SelectedSpanContext.Provider value={{ selectedSpan, setSelectedSpan }}>
                  <SelectedDataSourceContext.Provider value={{ selectedDataSource, setSelectedDataSource }}>
                    <DateTimeContext.Provider value={{ dateTime, setDateTime }}>
                      <IsPlayingContext.Provider value={{ isPlaying, setIsPlaying }}>
                        <IsSyncContext.Provider value={{ isSync, setIsSync }}>
                          <SettingContext.Provider value={{ setting, setSetting }}>
                            <TenantIdContext.Provider value={{ tenantId, setTenantId }}>
                              <SelectedGraphIndexContext.Provider value={{ selectedGraphIndex, setSelectedGraphIndex }}>
                                <DisplayDateTimeContext.Provider value={{ displayDateTime, setDisplayDateTime }}>
                                  <AllFrequenciesContext.Provider value={{ allFrequencies, setAllFrequencies }}>
                                    <ChannelGroupListContext.Provider value={{ channelGroupList, setChannelGroupList }}>
                                      <PlayingSpeedIndexContext.Provider value={{ playingSpeedIndex }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                                          <Box
                                            sx={{
                                              minHeight: '100px',
                                            }}
                                          >
                                            <ToolBar
                                              setGateway={setSelectedGateway}
                                              gateway={selectedGateway}
                                              frequencyBand={selectedFrequencyBand}
                                              setFrequencyBand={setSelectedFrequencyBand}
                                              span={selectedSpan}
                                              setSpan={setSelectedSpan}
                                              source={selectedDataSource}
                                              setSource={setSelectedDataSource}
                                              dateTime={dateTime}
                                              setDateTime={setDateTime}
                                              gateways={gateways}
                                              isPlaying={isPlaying}
                                              setIsPlaying={setIsPlaying}
                                              isSync={isSync}
                                              setIsSync={setIsSync}
                                              displayDateTime={displayDateTime}
                                              setDisplayDateTime={setDisplayDateTime}
                                              setting={setting}
                                              playingSpeedIndex={playingSpeedIndex}
                                            />
                                          </Box>
                                          <Box sx={{ height: 1, mt: 2 }}>
                                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                              <SplitLayout
                                                isExistMainMap={isExistMainMap}
                                                handleChangeFlag={handleChangeExistMainMapFlag}
                                                candidatePinIds={candidatePinIds}
                                              />
                                            </ErrorBoundary>
                                          </Box>
                                        </Box>
                                      </PlayingSpeedIndexContext.Provider>
                                    </ChannelGroupListContext.Provider>
                                  </AllFrequenciesContext.Provider>
                                </DisplayDateTimeContext.Provider>
                              </SelectedGraphIndexContext.Provider>
                            </TenantIdContext.Provider>
                          </SettingContext.Provider>
                        </IsSyncContext.Provider>
                      </IsPlayingContext.Provider>
                    </DateTimeContext.Provider>
                  </SelectedDataSourceContext.Provider>
                </SelectedSpanContext.Provider>
              </SelectedGatewayContext.Provider>
            </SelectedAreaContext.Provider>
          </SelectedFrequencyBandContext.Provider>
        </RwmCacheProvider>
      </MapsProvider>
    </RwmContext.Provider>
  );
};
export default RadioMonitoringPage;

import { pfApiIotDataRepository, RadioWave } from 'core/entities/radioWave';
import { getNearestIotData } from 'core/api/pf/iotdata';

export class PfApiIotDataRepository implements pfApiIotDataRepository {
  async fetchNearestIotData(
    timeStamp: string,
    dataType: string,
    deviceIds: string[],
    isBeforeFlag: string
  ): Promise<RadioWave[]> {
    return await getNearestIotData(timeStamp, dataType, deviceIds, isBeforeFlag);
  }
}

import React, { useContext, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CustomIconButton from '../../../Common/CustomIconButton';
import { MAIN_MAP_ID, PIN_TYPE, PinActionType } from '../../../../domain/types/map';
import SetUpDialogWindow from '../../../SetUpDialogWindow/SetUpDialogWindow';
import PinSelectArea from './PinSelectArea';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useRwmCache } from 'RwmContext';

const PinSelectMenu = () => {
  const { t } = useTranslation();

  const {
    selectedMapId,
    allPinIds,
    setAllPinIds,
    selectedPinIds,
    setSelectedPinIds,
    pinCheckBoxStates,
    setPinCheckBoxStates,
    registeredPinIds,
    allPinIdsForGraph,
    setAllPinIdsForGraph,
    selectedPinIdsForGraph,
    setSelectedPinIdsForGraph,
    pinCheckBoxStatesForGraph,
    setPinCheckBoxStatesForGraph,
    allPinIdsForTrack,
    setAllPinIdsForTrack,
    selectedPinIdsForTrack,
    setSelectedPinIdsForTrack,
    pinCheckBoxStatesForTrack,
    setPinCheckBoxStatesForTrack,
    workMode,
    isEnableGps,
    setHideMapPinIds,
  } = useContext(LayoutContext);
  const { setCaSelectedPinIds } = useRwmCache();

  const [isOpenedSelectMenu, setIsOpenedSelectMenu] = useState<boolean>(true);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);
  const [pinActionType, setPinActionType] = useState<PinActionType>('create');

  const [selectedPinType, setSelectedPinType] = useState<number>(PIN_TYPE.UnitPin);
  const [selectedPinId, setSelectedPinId] = useState<string | undefined>(undefined);

  /**
   * @description 全てを選択ボタンの処理
   */
  const handleClickAllItemCheck = () => {
    const _states: boolean[] = [];
    const _pinIds: string[] = [];

    allPinIds.map((id) => {
      // NOTE: ピンが登録済みか判定
      if (registeredPinIds.includes(id)) {
        _states.push(true);
        _pinIds.push(id);
      } else {
        _states.push(false);
      }
    });

    setPinCheckBoxStates(_states);
    setSelectedPinIds(_pinIds);
    setCaSelectedPinIds(_pinIds);
    // NOTE: ピンの非表示リストから削除する
    setHideMapPinIds((prevState) => {
      return {
        ...prevState,
        [selectedMapId]: [],
      };
    });
  };

  /**
   * @description 選択解除ボタンの処理
   */
  const handleClickAllItemCancel = () => {
    const _states = new Array(allPinIds.length).fill(false);
    setPinCheckBoxStates(_states);
    setSelectedPinIds([]);
    setCaSelectedPinIds([]);
    // NOTE: ピンの非表示リストに追加する
    setHideMapPinIds((prevState) => {
      return {
        ...prevState,
        [selectedMapId]: [...allPinIds],
      };
    });
  };

  /**
   * @description ピン / グループ追加時の処理
   */
  const handleClickMapPinAdd = (pinType: number, pinAction: PinActionType) => {
    if (pinAction === 'create') {
      setSelectedPinId(undefined);
    }
    setPinActionType(pinAction);
    setSelectedPinType(pinType);
    setIsOpenedDialog(true);
  };

  /**
   * @description ダイアログを閉じる処理
   */
  const handleClickClosedDialog = () => {
    setIsOpenedDialog(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Button
        onClick={() => {
          setIsOpenedSelectMenu(!isOpenedSelectMenu);
        }}
        style={{ color: 'white' }}
        variant='text'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          height: 30,
          top: 0,
        }}
      >
        {isOpenedSelectMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />} {t('ID 選択')}
      </Button>
      {isOpenedSelectMenu && (
        <Box
          sx={{
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            overflow: 'auto',
            height: 5 / 6,
            maxHeight: 250,
            minHeight: 0,
            ml: 4,
          }}
        >
          {workMode == 'Map' ? (
            <PinSelectArea
              checkBoxStates={pinCheckBoxStates}
              setCheckBoxStates={setPinCheckBoxStates}
              selectedPinIds={selectedPinIds}
              setSelectedPinIds={setSelectedPinIds}
              allPinIds={allPinIds}
              setAllPinIds={setAllPinIds}
            />
          ) : workMode == 'Track' ? (
            <PinSelectArea
              checkBoxStates={pinCheckBoxStatesForTrack}
              setCheckBoxStates={setPinCheckBoxStatesForTrack}
              selectedPinIds={selectedPinIdsForTrack}
              setSelectedPinIds={setSelectedPinIdsForTrack}
              allPinIds={allPinIdsForTrack}
              setAllPinIds={setAllPinIdsForTrack}
            />
          ) : (
            <PinSelectArea
              checkBoxStates={pinCheckBoxStatesForGraph}
              setCheckBoxStates={setPinCheckBoxStatesForGraph}
              selectedPinIds={selectedPinIdsForGraph}
              setSelectedPinIds={setSelectedPinIdsForGraph}
              allPinIds={allPinIdsForGraph}
              setAllPinIds={setAllPinIdsForGraph}
            />
          )}
        </Box>
      )}
      {workMode == 'Map' && isOpenedSelectMenu ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '10px',
            height: 30,
            bottom: 0,
            m: 1,
          }}
        >
          <CustomIconButton
            onClick={() => {
              handleClickAllItemCheck();
            }}
            tooltipTitle={'全てを選択'}
            Icon={SelectAllIcon}
          />
          <CustomIconButton
            onClick={() => {
              handleClickAllItemCancel();
            }}
            tooltipTitle={'選択解除'}
            Icon={DeselectIcon}
          />
          <CustomIconButton
            onClick={() => {
              handleClickMapPinAdd(PIN_TYPE.UnitPin, 'create');
            }}
            tooltipTitle={'ピンを追加'}
            Icon={AddLocationAltIcon}
          />
          {/* NOTE: メインマップのみグループ追加ボタンを表示 */}
          {selectedMapId === MAIN_MAP_ID && (
            <CustomIconButton
              onClick={() => {
                handleClickMapPinAdd(PIN_TYPE.GroupPin, 'create');
              }}
              tooltipTitle={'グループを追加'}
              Icon={AddBusinessIcon}
            />
          )}
          {isOpenedDialog && (
            <SetUpDialogWindow
              isOpenedDialog={isOpenedDialog}
              onClosedDialog={handleClickClosedDialog}
              selectedPinId={selectedPinId}
              selectedPinType={selectedPinType}
              setSelectedPinId={setSelectedPinId}
              pinActionType={pinActionType}
              isEnableGps={isEnableGps}
            />
          )}
        </Box>
      ) : workMode != 'Map' && isOpenedSelectMenu ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '10px',
            height: 30,
            bottom: 0,
            m: 1,
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default React.memo(PinSelectMenu);

import { useRef, useEffect, useState, useContext, useCallback } from 'react';
import { SxProps, Box } from '@mui/material';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import {
  DateTimeContext,
  SelectedFrequencyBandContext,
  SelectedGatewayContext,
  SelectedSpanContext,
  SettingContext,
} from 'radioMonitoringPage';
import {
  ASPECT_RATIO,
  MapTypeIdType,
  DEFAULT_MAP_CONF,
  MapObjType,
  MAP_SIZE,
  PinData,
  DEFAULT_HEIGHT_CONF,
  DEFAULT_POSITION_CONF,
  clipboardPinType,
} from 'domain/types/map';
import { getImageObject } from 'core/api/aws/s3';
import { DEFAULT_IMAGE } from 'domain/types/image';
import EditImageWindow from './Window/EditImageWindow';
import MapWindow from './Window/MapWindow';
import { calcLatPerPixel, calcLngPerPixel } from 'utils/transform';
import { DraggableBounds } from 'react-draggable';
import { RwmMapAreaContext, RwmMapTabContext } from './RwmMapContext';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { useErrorBoundary } from 'react-error-boundary';
import { handleGetPinDataForMap } from 'utils/wrapperApi';
import EditMapWindow from './Window/EditMapWindow';
import SearchMapWindow from './Window/SearchMapWindow';
import ImageWindow from './Window/ImageWindow';
import LoadingScreen from 'components/Common/LoadingScreen';
import TrackWindow from './Window/TrackWindow';

interface Props {
  tabIndex: string;
  mapTabWidth: number;
  handleSetImageConf: (map: MapObjType) => void;
  handleSetPinData: (pinData: PinData[]) => void;
  sx?: SxProps;
}

const MapArea = ({ tabIndex, mapTabWidth, handleSetImageConf, handleSetPinData }: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedSpan } = useContext(SelectedSpanContext);
  const { dateTime } = useContext(DateTimeContext);
  const { setting } = useContext(SettingContext);
  const { getMap, updateMap } = useMaps();
  const { selectedMapId, allPinIds, workMode } = useContext(LayoutContext);
  const { selectedGateway } = useContext(SelectedGatewayContext);
  const { mapMode, setImageWidth, setImageHeight, setImageRotate, setImageLat, setImageLng } =
    useContext(RwmMapTabContext);

  // NOTE: 選択中のマップデータ
  const [selectedMap, setSelectedMap] = useState<MapObjType | undefined>(undefined);

  // NOTE: マップの情報,
  const [center, setCenter] = useState<google.maps.LatLng | undefined>(new google.maps.LatLng(DEFAULT_MAP_CONF.center));
  const [zoom, setZoom] = useState<number>(DEFAULT_MAP_CONF.zoom);
  const [mapTypeId, setMapTypeId] = useState<MapTypeIdType>(DEFAULT_MAP_CONF.mapTypeId);
  const [elevation, setElevation] = useState<number>(DEFAULT_MAP_CONF.elevation);

  // NOTE: マップ / 画像表示
  const [mapWidth, setMapWidth] = useState<number | undefined>(MAP_SIZE.width);
  const [mapHeight, setMapHeight] = useState<number | undefined>(MAP_SIZE.height);
  const [image, setImage] = useState<string>(DEFAULT_IMAGE);

  // NOTE: 高さ範囲指定
  const [maxHeightConf, setMaxHeightConf] = useState<number>(2000);
  const [minHeightConf, setMinHeightConf] = useState<number>(0);

  // NOTE: 位置精度指定
  const [verticalConf, setVerticalConf] = useState<number>(20);
  const [horizontalConf, setHorizontalConf] = useState<number>(20);

  // NOTE: 表示用のステート
  const [isOpenedHeightRange, setIsOpenedHeightRange] = useState<boolean>(false);
  const [isOpenedPositionRange, setIsOpenedPositionRange] = useState<boolean>(false);
  const [isOpenedSearchWindow, setIsOpenedSearchWindow] = useState<boolean>(false);
  const [isOpenedImageWindow, setIsOpenedImageWindow] = useState<boolean>(false);

  // NOTE: 1ピクセル当たりの緯度、経度
  const [latPerPixel, setLatPerPixel] = useState<number>(DEFAULT_MAP_CONF.latToPixel);
  const [lngPerPixel, setLngPerPixel] = useState<number>(DEFAULT_MAP_CONF.lngToPixel);

  // NOTE: デフォルトの北東と南西の緯度経度
  const [ne, setNe] = useState<google.maps.LatLng | undefined>(new google.maps.LatLng(DEFAULT_MAP_CONF.ne));
  const [sw, setSw] = useState<google.maps.LatLng | undefined>(new google.maps.LatLng(DEFAULT_MAP_CONF.sw));

  // NOTE: ピンの移動可能領域
  const [bounds, setBounds] = useState<DraggableBounds>({});

  // NOTE: Ref オブジェクト
  const mapParentRef = useRef<HTMLDivElement | null>(null);

  // NOTE: 一時的に値を保管するステート
  const [tmpCenter, setTmpCenter] = useState<google.maps.LatLng | undefined>(center);
  const [tmpZoom, setTmpZoom] = useState<number>(zoom);
  const [tmpMapTypeId, setTmpMapTypeId] = useState<MapTypeIdType>(mapTypeId);
  const [tmpElevation, setTmpElevation] = useState<number>(elevation);
  const [tmpImage, setTmpImage] = useState<string | null>(null);
  const [clipboardPin, setClipboardPin] = useState<clipboardPinType | null>(null);

  // マップ初期設定を関数化
  const initializeMapSettings = useCallback(
    (map: MapObjType) => {
      setCenter(new google.maps.LatLng({ lat: map.lat, lng: map.lng }));
      setZoom(map.zoom);
      setMapTypeId(map.mapTypeId);
      setElevation(map.elevation);
      setMaxHeightConf(map.maxHeight);
      setMinHeightConf(map.minHeight);
      setVerticalConf(map.vertical);
      setHorizontalConf(map.horizontal);
      setImageWidth(map.imgWidth);
      setImageHeight(map.imgHeight);
      setImageRotate(map.imgRotate);
      setImageLat(map.imgLat);
      setImageLng(map.imgLng);
    },
    [setImageHeight, setImageRotate, setImageWidth, setImageLat, setImageLng]
  );

  const handleSetSelectedMap = useCallback((map: MapObjType) => {
    setSelectedMap(map);
  }, []);

  useEffect(() => {
    if (!selectedMap) return;
    handleSetImageConf(selectedMap);
  }, [handleSetImageConf, selectedMap]);

  useEffect(() => {
    (async () => {
      try {
        const map = await getMap(selectedMapId);
        setSelectedMap(map);
      } catch (error) {
        showBoundary(error);
        return;
      }
    })();
  }, [selectedMapId, selectedGateway, tabIndex, getMap, showBoundary]);

  useEffect(() => {
    if (!selectedMap) return;
    initializeMapSettings(selectedMap);
  }, [initializeMapSettings, selectedMap]);

  /**
   * @description マップサイズの変化を検知、変更値をセット
   */
  useEffect(() => {
    const updateMapSize = () => {
      if (mapParentRef.current) {
        let _height = mapParentRef.current.offsetHeight;
        let _width = _height * ASPECT_RATIO;

        if (_width > mapTabWidth - 50) {
          _width = mapTabWidth - 50;
          _height = _width * (1 / ASPECT_RATIO);
        }
        if (_width < 640 || _height < 480) {
          _width = MAP_SIZE.width;
          _height = MAP_SIZE.height;
        }

        setMapWidth(_width);
        setMapHeight(_height);
      }
    };
    // ResizeObserver でサイズ変更を監視
    const resizeObserver = new ResizeObserver(updateMapSize);

    // コンポーネントがマウントされたらサイズを更新
    updateMapSize();

    if (mapParentRef.current) {
      resizeObserver.observe(mapParentRef.current);
    }

    // コンポーネントがアンマウントされるときに監視を解除
    return () => {
      resizeObserver.disconnect();
    };
  }, [mapTabWidth]);

  /**
   * @description ピンの移動可能領域をセット
   */
  useEffect(() => {
    if (mapWidth && mapHeight) {
      setBounds({
        top: setting.pinSize,
        bottom: mapHeight - setting.pinSize * 2,
        right: mapWidth - setting.pinSize * 2,
        left: setting.pinSize / 2,
      });
    }
  }, [mapWidth, mapHeight, setting.pinSize]);

  /**
   * @description 1ピクセル当たりの緯度経度をセット
   */
  useEffect(() => {
    if (mapHeight && mapWidth && ne && sw) {
      setLatPerPixel(calcLatPerPixel(mapHeight, ne, sw));
      setLngPerPixel(calcLngPerPixel(mapWidth, ne, sw));
    }
  }, [mapHeight, mapWidth, ne, sw]);

  /**
   * @description 画像イメージのセット
   */
  useEffect(() => {
    if (!selectedMap) return;
    if (selectedMap.mapType === 'imageMap') {
      // NOTE: 表示する画像の URL をセット
      if (selectedMap.imgUrl) {
        (async () => {
          try {
            const { tokens } = await fetchAuthSession();
            if (!tokens || !tokens.idToken) throw 'Token is not existed.';

            const blob = await getImageObject(selectedMap.imgUrl, tokens.idToken.toString());
            setImage(URL.createObjectURL(blob));
          } catch (error) {
            setImage(DEFAULT_IMAGE);
            alert(t('ファイルの読み込みに失敗しました。\n') + error);
          }
        })();
      } else {
        alert(t('画像の読み込みに失敗しました'));
        setImage(DEFAULT_IMAGE);
      }
    } else {
      setImage('');
    }
  }, [selectedMap, t]);

  /**
   * @description IoT データを取得し変数にセット
   */
  useEffect(() => {
    if (allPinIds.length > 0) {
      (async () => {
        const minHeight = isOpenedHeightRange ? Math.floor(minHeightConf + elevation) : DEFAULT_HEIGHT_CONF.min;
        const maxHeight = isOpenedHeightRange ? Math.floor(maxHeightConf + elevation) : DEFAULT_HEIGHT_CONF.max;
        const vertical = isOpenedPositionRange ? verticalConf : DEFAULT_POSITION_CONF.vertical;
        const horizontal = isOpenedPositionRange ? horizontalConf : DEFAULT_POSITION_CONF.horizontal;

        try {
          const pinData: PinData[] = await handleGetPinDataForMap(
            dateTime,
            selectedSpan,
            allPinIds,
            selectedFrequencyBand,
            minHeight,
            maxHeight,
            vertical,
            horizontal,
            setting
          );
          handleSetPinData(pinData);
        } catch (error) {
          showBoundary(error);
        }
      })();
    }
  }, [
    allPinIds,
    dateTime,
    elevation,
    handleSetPinData,
    horizontalConf,
    isOpenedHeightRange,
    isOpenedPositionRange,
    maxHeightConf,
    minHeightConf,
    selectedFrequencyBand,
    selectedSpan,
    setting,
    showBoundary,
    verticalConf,
  ]);

  /**
   * @description 高さ範囲・位置精度変更時のデータ更新
   */
  useEffect(() => {
    if (!selectedMap) return;
    if (isOpenedHeightRange === true || isOpenedPositionRange === true) {
      (async () => {
        const targetMap: MapObjType = { ...selectedMap };

        if (isOpenedHeightRange === true) {
          targetMap.maxHeight = maxHeightConf;
          targetMap.minHeight = minHeightConf;
        }

        if (isOpenedPositionRange === true) {
          targetMap.vertical = verticalConf;
          targetMap.horizontal = horizontalConf;
        }

        try {
          await updateMap(targetMap, true);
        } catch (error) {
          showBoundary(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    horizontalConf,
    isOpenedHeightRange,
    isOpenedPositionRange,
    maxHeightConf,
    minHeightConf,
    showBoundary,
    verticalConf,
  ]);

  return (
    <RwmMapAreaContext.Provider
      value={{
        center,
        setCenter,
        zoom,
        setZoom,
        mapTypeId,
        setMapTypeId,
        elevation,
        setElevation,
        mapWidth,
        setMapWidth,
        mapHeight,
        setMapHeight,
        image,
        setImage,
        maxHeightConf,
        setMaxHeightConf,
        minHeightConf,
        setMinHeightConf,
        verticalConf,
        setVerticalConf,
        horizontalConf,
        setHorizontalConf,
        isOpenedHeightRange,
        setIsOpenedHeightRange,
        isOpenedPositionRange,
        setIsOpenedPositionRange,
        isOpenedSearchWindow,
        setIsOpenedSearchWindow,
        isOpenedImageWindow,
        setIsOpenedImageWindow,
        latPerPixel,
        setLatPerPixel,
        lngPerPixel,
        setLngPerPixel,
        ne,
        setNe,
        sw,
        setSw,
        bounds,
        setBounds,
        tmpCenter,
        setTmpCenter,
        tmpZoom,
        setTmpZoom,
        tmpMapTypeId,
        setTmpMapTypeId,
        tmpElevation,
        setTmpElevation,
        tmpImage,
        setTmpImage,
        selectedMap,
        handleSetSelectedMap,
        clipboardPin,
        setClipboardPin,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 'fit-content',
          height: 1,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
        ref={mapParentRef}
      >
        {selectedMap === undefined ? (
          <LoadingScreen />
        ) : (
          mapHeight &&
          mapWidth &&
          ne &&
          sw &&
          (mapMode === 'read' && selectedMap ? (
            workMode === 'Track' ? (
              <TrackWindow selectedMap={selectedMap} />
            ) : selectedMap.mapType === 'imageMap' ? (
              <ImageWindow selectedMap={selectedMap} />
            ) : (
              <MapWindow selectedMap={selectedMap} />
            )
          ) : (
            <EditMapWindow />
          ))
        )}
      </Box>
      {isOpenedSearchWindow && <SearchMapWindow />}
      {isOpenedImageWindow && (
        <EditImageWindow open={isOpenedImageWindow} handleClose={() => setIsOpenedImageWindow(false)} />
      )}
    </RwmMapAreaContext.Provider>
  );
};

export default MapArea;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

// NOTE: カスタムコンポーネント
import HeatBar from './HeatBar';
import { SettingContext, SelectedDataSourceContext, SelectedFrequencyBandContext } from 'radioMonitoringPage';
import { getLevelSetting } from 'utils/extract';

const HeatBarArea = () => {
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { setting } = useContext(SettingContext);

  const [max, setMax] = useState<number>(-95);
  const [min, setMin] = useState<number>(-107);

  /**
   * @description ヒートバー上に表示している数値をセット
   */
  useEffect(() => {
    const { max, min } = getLevelSetting(setting, selectedFrequencyBand, selectedDataSource);
    setMax(max);
    setMin(min);
  }, [selectedDataSource, selectedFrequencyBand, setting]);

  return (
    <Box position='relative' sx={{ height: 1 }}>
      <HeatBar />
      <Stack
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          height: 1,
          width: 50,
          color: '#000',
        }}
        position='absolute'
        top={0}
      >
        <Box>{max}</Box>
        <Box>{min + (max - min) / 2}</Box>
        <Box>{min}</Box>
      </Stack>
    </Box>
  );
};

export default React.memo(HeatBarArea);

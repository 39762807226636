import React, { useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { SelectedFrequencyBandContext } from '../../../../radioMonitoringPage';
import { ChannelRowType } from '../../../../domain/types/channel';
import { getRecommendSymbolFromLevel, getColorFromLevel, getRecommendChannelLabel } from '../../../../utils/format';
import {
  FREQUENCIES,
  FREQUENCY_BAND,
  FrequencyType,
  FrequencyChannelType,
  CHANNEL_GROUP_2_4G,
  CHANNEL_GROUP_DETAIL_2_4G,
} from '../../../../domain/types/frequency';
import {
  formatFrequencyLabel,
  formatChannelGroupLabel,
  formatFrequencyLabelForChannel,
  getChannelDetailRowHeaderLabel,
} from '../../../../utils/format';
import { getFrequenciesIncludeChannelGroup } from '../../../../utils/extract';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { RwmContext } from '../../../../RwmContext';
import { MAIN_PAGE_TAB_INDEX } from 'domain/types/common/consts';

/**
 * @param {ChannelRowType[]} channelData 推奨チャンネルデータ
 * @param {Dayjs} dateTime 設定日時
 * @param {string} selectedSpan 選択された期間
 */
interface Props {
  channelData: ChannelRowType[];
  dateTime: dayjs.Dayjs;
  selectedSpan: string;
  isDisplayValue: boolean;
}
// NOTE: セルのスタイル
const CustomTableCell = styled(TableCell)({
  borderWidth: '1px',
  borderColor: '#FFFFFF',
  padding: '0px 16px',
});

export const DetailTable = ({ channelData, selectedSpan, dateTime, isDisplayValue }: Props) => {
  const { t } = useTranslation();
  // NOTE: 選択中の周波数バンド
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);

  const {
    setMainPageTabIndex,
    setFrequencyCheckBoxStates,
    isDisplayedChannel,
    setSelectedChannelGroups,
    setChannelCheckBoxStates,
  } = useContext(LayoutContext);
  const { setSelectedFrequencies } = useContext(RwmContext);

  // NOTE: 詳細表示ヘッダー用時刻取得
  const timeDivs = getChannelDetailRowHeaderLabel(Number(selectedSpan), dateTime);

  // NOTE: ヘッダー上部（詳細行）の定義
  const topHeader: { [key: string]: string } = {};
  timeDivs.forEach((timeDiv, index) => {
    topHeader[`timeDiv${index + 1}`] = timeDiv;
  });

  // NOTE: ヘッダー下部の定義
  const bottomHeder = {
    freq: 'Freq',
    ch: 'CH',
    occupancyLevel: '(占有率)',
    noiseLevel: '(ノイズ)',
  };

  // NOTE: ヘッダーの定義（topHeaderとbottomHederマージ）
  const header = { ...topHeader, ...bottomHeder };

  const handleDoubleClick = (channelRow: ChannelRowType) => {
    //マップタブへ遷移
    setMainPageTabIndex(MAIN_PAGE_TAB_INDEX.MAP);

    //CHグループ表示時の場合の設定
    if (isDisplayedChannel) {
      const chackBoxStates = CHANNEL_GROUP_2_4G.map((c) => {
        let ret: boolean = false;
        if (c.channel == channelRow.ch) {
          ret = true;
          setSelectedChannelGroups([c]);
        } else {
          ret = false;
        }
        return ret;
      });
      const frequencyChannel = CHANNEL_GROUP_2_4G.find((f) => f.frequency == channelRow.freq);
      if (frequencyChannel) {
        const frequenciesIncludeChannelGroup: FrequencyType[] = getFrequenciesIncludeChannelGroup(
          frequencyChannel,
          FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list
        );
        setSelectedFrequencies(frequenciesIncludeChannelGroup);
      }
      setChannelCheckBoxStates(chackBoxStates);
      return;
    }

    //周波数パネル選択
    const freqs = FREQUENCIES[selectedFrequencyBand].list;
    let chackBoxStates: boolean[] = [];
    let selectedFrequencies: FrequencyType[] = [];

    if (selectedFrequencyBand == FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID) {
      //2.4GHzの場合は特殊：指定した周波数が「FREQUENCIES」に存在しない場合は「CHANNEL_GROUP_DETAIL_2_4G」で定義しているmin-maxの範囲で指定する
      const frequencyChannel = CHANNEL_GROUP_DETAIL_2_4G.find((f) => f.frequency == channelRow.freq);
      if (frequencyChannel) {
        selectedFrequencies = getFrequenciesIncludeChannelGroup(frequencyChannel, freqs);
        chackBoxStates = freqs.map((element) => {
          return selectedFrequencies.includes(element) ? true : false;
        });
      }
    } else {
      chackBoxStates = freqs.map((f) => {
        let ret: boolean = false;
        if (f.frequency == channelRow.freq) {
          ret = true;
          selectedFrequencies.push(f);
        } else {
          ret = false;
        }
        return ret;
      });
    }
    setSelectedFrequencies(selectedFrequencies);
    setFrequencyCheckBoxStates(chackBoxStates);
  };

  return (
    <TableContainer>
      <Table sx={{ maxWidth: '300px', margin: 'auto' }}>
        <TableHead />
        <TableBody>
          {
            // NOTE: ヘッダーマッピング
            Object.keys(header).map((key) => (
              <TableRow
                key={key}
                sx={{
                  fontSize: '12px',
                  height: key.includes('timeDiv') ? '20px' : key === 'freq' ? '100px' : '50px',
                }}
              >
                <CustomTableCell
                  align='center'
                  sx={{
                    maxWidth: '80px',
                    fontSize: key.includes('timeDiv') ? '12px' : '',
                  }}
                >
                  {header[key as keyof typeof header]}
                  {key === 'freq' && selectedFrequencyBand.includes('ism900')
                    ? '（MHz）'
                    : key === 'freq'
                      ? '（GHz）'
                      : ''}
                </CustomTableCell>
                {
                  // NOTE: 推奨チャンネルデータマッピング
                  channelData.map((row) => {
                    let frequencyChannelObj: FrequencyChannelType;
                    let frequenciesIncludeChannelGroup: FrequencyType[] = [];
                    if (selectedFrequencyBand == FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID && isDisplayedChannel) {
                      frequencyChannelObj = CHANNEL_GROUP_2_4G[row.ch - 1];
                      frequenciesIncludeChannelGroup = getFrequenciesIncludeChannelGroup(
                        frequencyChannelObj,
                        FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list
                      );
                    }

                    return (
                      <Tooltip
                        title={
                          key.includes('timeDiv') && isDisplayValue ? (
                            <React.Fragment>
                              <Typography> {row.occupancy}</Typography>
                              <Typography>{row.noise}</Typography>
                            </React.Fragment>
                          ) : key == 'freq' &&
                            selectedFrequencyBand == FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID &&
                            isDisplayedChannel ? (
                            <React.Fragment>
                              <Typography>{t(formatChannelGroupLabel(row.ch))}</Typography>
                              {frequenciesIncludeChannelGroup.map((frequency: FrequencyType, index: number) => {
                                return (
                                  <Typography key={'label-channel-' + index}>
                                    {t(formatFrequencyLabel(frequency))}
                                  </Typography>
                                );
                              })}
                            </React.Fragment>
                          ) : (
                            ''
                          )
                        }
                        key={row.freq}
                      >
                        <CustomTableCell
                          key={row.freq}
                          align='center'
                          sx={{
                            minWidth: '30px',
                            maxWidth: '30px',
                            padding: '1px',
                            fontSize: key.includes('timeDiv') ? '6px' : '12px',
                            // NOTE: 詳細データ配列の添え字はtimeDivの数字部分を使用
                            bgcolor:
                              key.includes('timeDiv') &&
                              row.details[Number(key.replace('timeDiv', '')) - 1].isInvalid == false
                                ? getColorFromLevel(row.details[Number(key.replace('timeDiv', '')) - 1].level)
                                : '#000000',
                            writingMode: key === 'freq' ? 'vertical-rl' : '',
                            textOrientation: key === 'freq' ? 'upright' : '',
                          }}
                          onDoubleClick={() => handleDoubleClick(row)}
                        >
                          {key.includes('timeDiv') && isDisplayValue
                            ? `${row.details[Number(key.replace('timeDiv', '')) - 1].occupancy.toFixed(1)}%\n${row.details[Number(key.replace('timeDiv', '')) - 1].noise}`
                            : ''}
                          {key === 'freq' ? formatFrequencyLabelForChannel(row.freq) : ''}
                          {key === 'ch' ? getRecommendChannelLabel(selectedFrequencyBand, row.ch, row.freq) : ''}
                          {key === 'occupancyLevel' ? getRecommendSymbolFromLevel(row.occupancyLevel) : ''}
                          {key === 'noiseLevel' ? getRecommendSymbolFromLevel(row.noiseLevel) : ''}
                        </CustomTableCell>
                      </Tooltip>
                    );
                  })
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

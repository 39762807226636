import React from 'react';
import { Box, Stack } from '@mui/material';

// NOTE: カスタムコンポーネント
import { COLOR_BAR } from 'domain/types/color';
import { getColorRGBA } from 'utils/extract';
import { formatColorRGBA } from 'utils/format';

const HeatBar = () => {
  return (
    <Stack
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        height: 1,
        width: 50,
        backgroundColor: 'transparent',
        p: 0,
      }}
    >
      {COLOR_BAR.map((_, index: number) => {
        return (
          <Box
            key={`heatBar-color-${index}`}
            sx={{
              background: formatColorRGBA(getColorRGBA(COLOR_BAR.length - (index + 1))),
              minWidth: 1,
              minHeight: `calc(100% / ${COLOR_BAR.length})`,
            }}
          />
        );
      })}
    </Stack>
  );
};

export default React.memo(HeatBar);

export const wrapForObject = <T>(json: object): T => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return json as any;
};

export const wrapForObjects = <T>(jsons: object[]): T[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: T[] = [];
  jsons.forEach((j) => {
    res.push(wrapForObject<T>(j));
  });
  return res;
};

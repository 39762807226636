import { LOG_LEVEL } from '../domain/types/logging';

/**
 * @description ログ出力処理 \
 *  時刻は ISO8601 に則る
 * @param {string} message ログメッセージ
 * @param {LOG_TYPE} type ログの種類
 */
export const WriteLog = (message: string, type: LOG_LEVEL): void => {
  const now = new Date().toISOString();
  const _message = `${now} [${type}] ${message}`;

  if (type === 'ERROR') console.error(_message);
  else if (type === 'WARN') console.warn(_message);
  else console.info(_message);
};

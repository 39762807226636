import { SxProps } from '@mui/material';

export const InputLabelStyle: SxProps = {
  '&.MuiInputLabel-shrink': {
    color: '#DC0000',
  },
};

export const SelectStyle: SxProps = {
  textAlign: 'left',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#FFFFFF',
  },
};

import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Box } from '@mui/material';
import HeightIcon from '@mui/icons-material/Height';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { MAP_SIZE } from 'domain/types/map';
import CustomIconButton from 'components/Common/CustomIconButton';
import InputSlider from 'components/Common/SliderWithInputField';

interface Props {
  elevation: number;
  isOpenedHeightRange: boolean;
  maxHeightConf: number;
  minHeightConf: number;
  setIsOpenedHeightRange: Dispatch<SetStateAction<boolean>>;
  setMaxHeightConf: Dispatch<SetStateAction<number>>;
  setMinHeightConf: Dispatch<SetStateAction<number>>;
}

const HeightRangeButton = ({
  elevation,
  isOpenedHeightRange,
  maxHeightConf,
  minHeightConf,
  setIsOpenedHeightRange,
  setMaxHeightConf,
  setMinHeightConf,
}: Props) => {
  const { t } = useTranslation();

  const [isSliding, setIsSliding] = useState<boolean>(false);
  const [isInputting, setIsInputting] = useState<boolean>(false);
  const [tmpMaxHeight, setTmpMaxHeight] = useState<number>(maxHeightConf);
  const [tmpMinHeight, setTmpMinHeight] = useState<number>(minHeightConf);
  const [count, setCount] = useState<number>(0);

  /**
   * @description 高さ範囲指定ボタン選択時の処理
   */
  const handleClickSetHeightRange = () => {
    setIsOpenedHeightRange(!isOpenedHeightRange);
    if (isOpenedHeightRange) setCount(0);
  };

  const handleSetIsSliding = useCallback((state: boolean) => {
    setIsSliding(state);
    setCount((prev) => prev + 1);
  }, []);

  const handleSetIsInputting = useCallback((state: boolean) => {
    setIsInputting(state);
    setCount((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (isOpenedHeightRange && !isSliding && !isInputting && count > 0) {
      setMaxHeightConf(tmpMaxHeight);
      setMinHeightConf(tmpMinHeight);
    }
  }, [
    count,
    isInputting,
    isOpenedHeightRange,
    isSliding,
    setMaxHeightConf,
    setMinHeightConf,
    tmpMaxHeight,
    tmpMinHeight,
  ]);

  useEffect(() => {
    if (isOpenedHeightRange === true && count === 0) {
      setTmpMaxHeight(maxHeightConf);
      setTmpMinHeight(minHeightConf);
    }
  }, [count, isOpenedHeightRange, maxHeightConf, minHeightConf]);

  return (
    <>
      <CustomIconButton
        onClick={() => handleClickSetHeightRange()}
        Icon={HeightIcon}
        tooltipTitle={['高さ範囲指定', '指定範囲の高さのデータのみを有効とします。']}
        size='small'
        isEnabled={isOpenedHeightRange}
        placement='right-start'
      />
      {isOpenedHeightRange && (
        <Stack direction='column' justifyContent='flex-start' sx={{ backgroundColor: '#000', opacity: 0.8, p: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Box>{t('高さ範囲指定')}</Box>
            <CustomIconButton
              onClick={handleClickSetHeightRange}
              Icon={ArrowDropUpIcon}
              tooltipTitle={'メニューを隠す'}
              size='small'
              placement='right-start'
            />
          </Box>
          <Stack direction='column' justifyContent='flex-start'>
            <InputSlider
              min={0}
              max={tmpMaxHeight}
              originalMin={0}
              originalMax={2000}
              value={tmpMinHeight}
              setValue={setTmpMinHeight}
              title={t('最小') + '[m]'}
              sx={{
                width: MAP_SIZE.width / 2,
              }}
              handleSetIsSliding={handleSetIsSliding}
              handleSetIsInputting={handleSetIsInputting}
              step={10}
            />
            <InputSlider
              min={tmpMinHeight}
              max={2000}
              originalMin={0}
              originalMax={2000}
              value={tmpMaxHeight}
              setValue={setTmpMaxHeight}
              title={t('最大') + '[m]'}
              sx={{
                width: MAP_SIZE.width / 2,
              }}
              handleSetIsSliding={handleSetIsSliding}
              handleSetIsInputting={handleSetIsInputting}
              step={10}
            />
          </Stack>
          {/* TODO: 以下の高さ範囲指定の値は、暫定的に表示用に「高さの限界値 + 標高」としているが、実際に比較する際は標高を考慮した処理にする必要がある */}
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 1 }}>
            {t(`標高`)} : {minHeightConf + Math.floor(elevation)} [m] ~ {maxHeightConf + Math.floor(elevation)} [m] (
            {t(`基準`)} : {Math.floor(elevation)} [m])
          </Box>
        </Stack>
      )}
    </>
  );
};

export default React.memo(HeightRangeButton);

import { HeatPointType, PointType } from '../domain/types/map';

/**
 * @description 2 次元座標の距離計算
 * @param {PointType[]} p1
 * @param {PointType[]} p2
 * @returns {number} 距離
 */
export const getDistance2D = (p1: PointType, p2: PointType): number => {
  return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
};

/**
 * @description 逆距離加重法（Inverse Distance Weighting: IDW）
 * @param {HeatPointType[]} knownPoints 既知のピンデータ (座標値 + 測定値)
 * @param {PointType[]} interpolationPoint 補間対象の座標値
 * @param {number} power 指数値
 * @returns 補間値
 */
export const idwInterpolation = (
  knownPoints: HeatPointType[],
  interpolationPoint: PointType,
  power: number = 2
): number => {
  if (knownPoints.length === 0) {
    throw new Error('Known points array is empty.');
  }

  const distances = knownPoints.map((point) => getDistance2D(point, interpolationPoint));

  // NOTE: 距離 = 0 であればサンプル点と一致している為、サンプル点 の value を返す (複数一致する点がある場合、Max (value) を返す)
  if (distances.some((value) => value == 0)) {
    const indices = distances.map((value, index) => (value === 0 ? index : -1)).filter((value) => value !== -1);
    const values = indices.map((index) => knownPoints.map((point) => point.value)[index]);
    return Math.max(...values);
  }

  const weights = distances.map((distance) => 1 / Math.pow(distance, power));
  const weightSum = weights.reduce((sum, weight) => sum + weight, 0);

  const interpolatedValue =
    knownPoints.reduce((sum, point, index) => sum + weights[index] * point.value, 0) / weightSum;
  return interpolatedValue;
};

// import * as numeric from 'numeric';
// /**
//  * @description サイトごとに計算式が若干異なるので、Alglib のサイトを採用 \
//  *  https://www.alglib.net/interpolation/introductiontorbfs.php
//  * @param r
//  * @param epsilon
//  * @returns
//  */
// const gaussianRBF = (r: number, epsilon: number): number => {
//   return Math.exp(-((r / epsilon) ** 2));
// };

// /**
//  * @description RBF 補間の重みを計算
//  * @param knownPoints
//  * @param epsilon
//  * @returns
//  */
// const getWeightRBF = (knownPoints: HeatPointType[], epsilon: number): number[] => {
//   const N = knownPoints.length;
//   const phi: number[][] = new Array(N).fill(0).map(() => new Array(N).fill(0));
//   const b: number[] = new Array(N).fill(0);

//   for (let i = 0; i < N; i++) {
//     b[i] = knownPoints[i].value;
//     for (let j = 0; j < N; j++) {
//       const d = getDistance2D(knownPoints[i], knownPoints[j]);
//       phi[i][j] = gaussianRBF(d, epsilon);
//     }
//   }

//   // 重みの計算 (行列方程式 : phi * w = b)
//   const w: number[] = numeric.solve(phi, b);
//   return w;
// };

// const computeAverageDistance = (points: PointType[]): number => {
//   const N = points.length;
//   let totalDistance = 0;
//   let count = 0;

//   for (let i = 0; i < N; i++) {
//     for (let j = i + 1; j < N; j++) {
//       totalDistance += getDistance2D(points[i], points[j]);
//       count++;
//     }
//   }
//   return totalDistance / count;
// };

// export const rbfInterpolation = (
//   knownPoints: HeatPointType[],
//   interpolationPoint: PointType,
//   epsilon?: number
// ): number => {
//   if (epsilon === undefined) {
//     const averageDistance = computeAverageDistance(knownPoints);
//     epsilon = averageDistance * 4.0; // 調整可能なスケーリングファクター
//   }

//   const weight: number[] = getWeightRBF(knownPoints, epsilon);
//   let interpolatedValue = 0;

//   const tmp = { x: interpolationPoint.x + 40, y: interpolationPoint.y + 40 };
//   for (let i = 0; i < knownPoints.length; i++) {
//     interpolatedValue += weight[i] * gaussianRBF(getDistance2D(knownPoints[i], tmp), epsilon);
//   }
//   return interpolatedValue;
// };

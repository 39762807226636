import { GatewayItem } from '../core/entities/areaMaster';
import { Dayjs } from 'dayjs';
import { BasePinType, MAIN_MAP, MAIN_MAP_ID, MapObjType, MapPinType, PinData } from 'domain/types/map';
import { SettingType } from '../domain/types/setting';
import { DeleteMapParams } from '../domain/usecases/deleteMap';
import { DeletePinParams } from '../domain/usecases/deletePin';
import { FetchAreaMastersParams } from '../domain/usecases/fetchOneAreaMaster';
import { FetchPinDataParams } from '../domain/usecases/fetchPinData';
import { StoreMapParams } from '../domain/usecases/storeMap';
import { StorePinParams } from '../domain/usecases/storePin';
import {
  deleteMapUsecase,
  deletePinUsecase,
  fetchAreaMastersUsecase,
  fetchPinDataUsecase,
  storeMapUsecase,
  storePinUsecase,
  fetchNearestIotData,
} from '../radioMonitoringPage';
import { updateDuplicateProperty } from './transform';
import { FetchNearestIotDataParams } from 'domain/usecases/fetchNearestIotData';
import { RadioWave } from 'core/entities/radioWave';

/**
 * @description 指定したゲートウェイを取得する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @returns {Promise<GatewayItem | undefined>} 指定したゲートウェイの情報
 */
export const handleGetGateway = async (areaId: string, gatewayId: string): Promise<GatewayItem | undefined> => {
  console.log('api : handleGetGateway');
  const params = new FetchAreaMastersParams(areaId);
  const areaMaster = await fetchAreaMastersUsecase.call(params);
  if (areaMaster.gateways) {
    return areaMaster.gateways.find((value) => value.devId == gatewayId);
  } else return undefined;
};

/**
 * @description 指定したマップのリストを取得する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @returns {Promise<MapObjType[]>} マップのリスト
 */
export const handleGetMaps = async (areaId: string, gatewayId: string): Promise<MapObjType[]> => {
  console.log('api : handleGetMaps');
  const params = new FetchAreaMastersParams(areaId);
  const areaMaster = await fetchAreaMastersUsecase.call(params);
  if (areaMaster.gateways) {
    const gateway = areaMaster.gateways.find((value) => value.devId == gatewayId);
    if (gateway && gateway.payload.rwmMaps) return gateway.payload.rwmMaps;
  }
  return [];
};

/**
 * @description 指定したマップを取得する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {string} mapId 対象のマップ ID
 * @returns {Promise<MapObjType|undefined>} マップのリスト
 */
export const handleGetMap = async (
  areaId: string,
  gatewayId: string,
  mapId: string
): Promise<MapObjType | undefined> => {
  console.log('api : handleGetMap');
  const params = new FetchAreaMastersParams(areaId);
  const areaMaster = await fetchAreaMastersUsecase.call(params);
  if (areaMaster.gateways) {
    const gateway = areaMaster.gateways.find((value) => value.devId == gatewayId);
    if (gateway && gateway.payload.rwmMaps) {
      const map = gateway.payload.rwmMaps.find((map) => map.mapId === mapId);
      if (map) return map;
    }
  }
  return undefined;
};

/**
 * @description マップ表示用の IoT データを取得する処理
 * @param {Dayjs} date 取得対象の開始日時
 * @param {string} spans 取得期間
 * @param {string[]} deviceIds 取得対象のデバイス ID リスト
 * @param {string} dataType 取得対象のデータタイプ (現状は周波数バンド)
 * @param {number} heightMin 高さの最小値
 * @param {number} heightMax 高さの最大値
 * @param {number} vAcc 水平位置精度
 * @param {number} hAcc 垂直位置精度
 * @param {SettingType} setting 設定値
 * @param {number} timeDiv データの分割数 (推奨チャンネルでのみ利用の為、マップでは固定値 1 を渡す)
 * @returns {Promise<PinData[]>} マップ表示用の IoT データ
 */
export const handleGetPinDataForMap = async (
  date: Dayjs,
  spans: string,
  deviceIds: string[],
  dataType: string,
  heightMin: number,
  heightMax: number,
  vAcc: number,
  hAcc: number,
  setting: SettingType,
  timeDiv: number = 1
): Promise<PinData[]> => {
  const params = new FetchPinDataParams(
    date,
    spans,
    deviceIds,
    dataType,
    heightMin,
    heightMax,
    vAcc,
    hAcc,
    timeDiv,
    setting
  );
  console.log('api : handleGetPinDataForMap');
  const pinData = await fetchPinDataUsecase.call(params);
  return pinData;
};

/**
 * @description 指定したマップを登録する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {MapObjType} map 登録するマップの情報
 */
export const handleStoreMap = async (areaId: string, gatewayId: string, map: MapObjType): Promise<MapObjType> => {
  console.log('api : handleStoreMap');
  const params = new StoreMapParams(areaId, gatewayId, map);
  const response = await storeMapUsecase.call(params);
  return response;
};

/**
 * @description 指定したマップを更新する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {MapObjType} map 更新するマップの情報
 */
export const handleUpdateMap = async (areaId: string, gatewayId: string, map: MapObjType) => {
  console.log('api : handleUpdateMap');
  const gateway = await handleGetGateway(areaId, gatewayId);
  if (gateway && gateway.payload.rwmMaps) {
    const targetMap = gateway.payload.rwmMaps.filter((_map) => _map.mapId === map.mapId)[0];
    const _map = updateDuplicateProperty(targetMap, map);
    _map.pins = targetMap.pins;
    const params = new StoreMapParams(areaId, gatewayId, _map);
    await storeMapUsecase.call(params);
  }
};

/**
 * @description メインマップを登録 / 更新する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 */
export const handleStoreMainMap = async (areaId: string, gatewayId: string) => {
  console.log('api : handleStoreMainMap');
  const params = new StoreMapParams(areaId, gatewayId, MAIN_MAP);
  await storeMapUsecase.call(params);
};

/**
 * @description 指定したマップにピン情報を登録 / 更新する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {string} mapId 対象のマップ ID
 * @param {MapPinType} pin 登録 / 更新対象のピンの情報
 */
export const handleStorePin = async (
  areaId: string,
  gatewayId: string,
  mapId: string,
  pin: MapPinType
): Promise<BasePinType> => {
  console.log('api : handleStorePin');
  const params = new StorePinParams(areaId, gatewayId, mapId, pin);
  const response = await storePinUsecase.call(params);
  return response;
};

/**
 * @description メインマップにピン情報を登録 / 更新する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {MapPinType} pin 登録 / 更新対象のピンの情報
 */
export const handleStorePinToMainMap = async (
  areaId: string,
  gatewayId: string,
  pin: MapPinType
): Promise<BasePinType> => {
  console.log('api : handleStorePinToMainMap');
  const params = new StorePinParams(areaId, gatewayId, MAIN_MAP_ID, pin);
  const response = await storePinUsecase.call(params);
  return response;
};

/**
 * @description 指定したマップを削除する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {string} mapId 対象のマップ ID
 */
export const handleDeleteMap = async (areaId: string, gatewayId: string, mapId: string) => {
  console.log('api : handleDeleteMap');
  const params = new DeleteMapParams(areaId, gatewayId, mapId);
  await deleteMapUsecase.call(params);
};

/**
 * @description 指定したピンを削除する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {string} mapId 対象のマップ ID
 * @param {string} pinId 対象のピン ID
 */
export const handleDeletePin = async (areaId: string, gatewayId: string, mapId: string, pinId: string) => {
  console.log('api : handleDeletePin');
  const params = new DeletePinParams(areaId, gatewayId, mapId, pinId);
  await deletePinUsecase.call(params);
};

/**
 * @description メインマップの指定したピンを削除する処理
 * @param {string} areaId 対象のエリア ID
 * @param {string} gatewayId 対象のゲートウェイ ID
 * @param {string} pinId 対象のピン ID
 */
export const handleDeletePinToMainMap = async (areaId: string, gatewayId: string, pinId: string) => {
  console.log('api : handleDeletePinToMainMap');
  const params = new DeletePinParams(areaId, gatewayId, MAIN_MAP_ID, pinId);
  await deletePinUsecase.call(params);
};

/**
 * @description 指定した日時に最も近いIot-Dataを取得する
 * @param {dayjs} timeStamp 基準日時
 * @param {string} dataType データタイプ
 * @param {string[]} deviceIds デバイスIDリスト
 * @param {booleam} isBeforeFlag 基準日以前のデータを取得するかのフラグ
 * @returns
 */
export const handleGetNearestIotData = async (
  timeStamp: Dayjs,
  dataType: string,
  deviceIds: string[],
  isBeforeFlag: boolean
): Promise<RadioWave[]> => {
  const params = new FetchNearestIotDataParams(timeStamp, dataType, deviceIds, isBeforeFlag);
  console.log('api : handleGetNearestIotData');
  const radioWaves = await fetchNearestIotData.call(params);
  return radioWaves;
};

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { RssiGraph } from './RssiGraph';
import { FrequencyGraph } from './FrequencyGraph';
import CustomIconButton from '../../../Common/CustomIconButton';
import {
  DEFAULT_OCCUPANCY_RANGE,
  DEFAULT_RSSI_RANGE,
  DEVICE_GRAPH_TYPE,
  DEVICE_LABEL_Y,
  DEVICE_LABEL_Y1,
  DEVICE_MAX_LIST,
  DEVICE_MIN_LIST,
  GRAPH,
  GRAPHS,
} from '../../../../domain/types/graph';
import { MaxRateGraph } from './MaxRateGraph';
import { DeviceGraph } from './DeviceGraph';
import { SelectedGraphIndexContext } from 'radioMonitoringPage';

export const GraphTab = () => {
  const { selectedGraphIndex, setSelectedGraphIndex } = useContext(SelectedGraphIndexContext);
  const [graphTabHeight, setGraphTabHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [freqYRange, setFreqYRange] = useState({
    yMin: DEFAULT_RSSI_RANGE.MIN,
    yMax: DEFAULT_RSSI_RANGE.MAX,
    y1Min: DEFAULT_OCCUPANCY_RANGE.MIN,
    y1Max: DEFAULT_OCCUPANCY_RANGE.MAX,
  });
  const [rssiYRange, setRssiYRange] = useState({ yMin: DEFAULT_RSSI_RANGE.MIN, yMax: DEFAULT_RSSI_RANGE.MAX });
  const [maxRateYRange, setMaxRateYRange] = useState({
    yMin: DEFAULT_OCCUPANCY_RANGE.MIN,
    yMax: DEFAULT_OCCUPANCY_RANGE.MAX,
  });

  const [graphType, setGraphType] = useState<string>(DEVICE_GRAPH_TYPE.VOLTAG_TEMP);
  const [deviceYRange, setDeviceYRange] = useState({
    yMin: DEVICE_MIN_LIST[DEVICE_LABEL_Y[graphType]],
    yMax: DEVICE_MAX_LIST[DEVICE_LABEL_Y[graphType]],
    y1Min: DEVICE_MIN_LIST[DEVICE_LABEL_Y1[graphType]],
    y1Max: DEVICE_MAX_LIST[DEVICE_LABEL_Y1[graphType]],
  });

  /**
   * @description グラフタブのサイズが変化した際の値を取得する処理
   */
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (ref.current) {
        const height = entries[0].contentRect.height;
        setGraphTabHeight(height);
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    // コンポーネントがアンマウントされたときに監視を解除
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    setDeviceYRange(() => ({
      yMin: DEVICE_MIN_LIST[DEVICE_LABEL_Y[graphType]],
      yMax: DEVICE_MAX_LIST[DEVICE_LABEL_Y[graphType]],
      y1Min: DEVICE_MIN_LIST[DEVICE_LABEL_Y1[graphType]],
      y1Max: DEVICE_MAX_LIST[DEVICE_LABEL_Y1[graphType]],
    }));
  }, [graphType]);

  const handleExpandLessButtonClick = () => {
    if (selectedGraphIndex + 1 < GRAPHS.length) {
      setSelectedGraphIndex(selectedGraphIndex + 1);
    } else {
      setSelectedGraphIndex(0);
    }
  };

  const handleExpandMoreButtonClick = () => {
    if (selectedGraphIndex - 1 < 0) {
      setSelectedGraphIndex(GRAPHS.length - 1);
    } else {
      setSelectedGraphIndex(selectedGraphIndex - 1);
    }
  };

  return (
    <Stack
      ref={ref}
      spacing={2}
      sx={{
        height: 1,
        width: 1,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      }}
    >
      <Box sx={{ height: '3%', justifyContent: 'center' }} display={'flex'}>
        <Stack direction='row' spacing={3}>
          <CustomIconButton
            onClick={handleExpandLessButtonClick}
            Icon={ArrowBackIosIcon}
            tooltipTitle={(selectedGraphIndex == GRAPHS.length - 1 ? GRAPHS[0] : GRAPHS[selectedGraphIndex + 1]) + 'へ'}
          />
          <Typography variant='h5' sx={{ width: '100px', textAlign: 'center' }}>
            {GRAPHS[selectedGraphIndex]}
          </Typography>
          <CustomIconButton
            onClick={handleExpandMoreButtonClick}
            Icon={ArrowForwardIosIcon}
            tooltipTitle={(selectedGraphIndex == 0 ? GRAPHS[GRAPHS.length - 1] : GRAPHS[selectedGraphIndex - 1]) + 'へ'}
          />
        </Stack>
      </Box>
      <Box sx={{ justifyContent: 'center' }}>
        {GRAPHS[selectedGraphIndex] == GRAPH.FREQUENCY ? (
          <FrequencyGraph
            graphHeight={graphTabHeight}
            yMax1={freqYRange.yMax}
            yMin1={freqYRange.yMin}
            setYMin1={(yMin: number) => setFreqYRange((prev) => ({ ...prev, yMin: yMin }))}
            setYMax1={(yMax: number) => setFreqYRange((prev) => ({ ...prev, yMax: yMax }))}
            yMax2={freqYRange.y1Max}
            yMin2={freqYRange.y1Min}
            setYMin2={(y1Min: number) => setFreqYRange((prev) => ({ ...prev, y1Min: y1Min }))}
            setYMax2={(y1Max: number) => setFreqYRange((prev) => ({ ...prev, y1Max: y1Max }))}
          />
        ) : GRAPHS[selectedGraphIndex] == GRAPH.DEVICE ? (
          <DeviceGraph
            graphHeight={graphTabHeight}
            yMax1={deviceYRange.yMax}
            yMin1={deviceYRange.yMin}
            setYMin1={(yMin: number) => setDeviceYRange((prev) => ({ ...prev, yMin: yMin }))}
            setYMax1={(yMax: number) => setDeviceYRange((prev) => ({ ...prev, yMax: yMax }))}
            yMax2={deviceYRange.y1Max}
            yMin2={deviceYRange.y1Min}
            setYMin2={(y1Min: number) => setDeviceYRange((prev) => ({ ...prev, y1Min: y1Min }))}
            setYMax2={(y1Max: number) => setDeviceYRange((prev) => ({ ...prev, y1Max: y1Max }))}
            graphType={graphType}
            setGraphType={setGraphType}
          />
        ) : GRAPHS[selectedGraphIndex] == GRAPH.RSSI ? (
          <RssiGraph
            graphHeight={graphTabHeight}
            yMax={rssiYRange.yMax}
            yMin={rssiYRange.yMin}
            setYMin={(yMin: number) => setRssiYRange((prev) => ({ ...prev, yMin: yMin }))}
            setYMax={(yMax: number) => setRssiYRange((prev) => ({ ...prev, yMax: yMax }))}
          />
        ) : (
          <MaxRateGraph
            graphHeight={graphTabHeight}
            yMax={maxRateYRange.yMax}
            yMin={maxRateYRange.yMin}
            setYMin={(yMin: number) => setMaxRateYRange((prev) => ({ ...prev, yMin: yMin }))}
            setYMax={(yMax: number) => setMaxRateYRange((prev) => ({ ...prev, yMax: yMax }))}
          />
        )}
      </Box>
    </Stack>
  );
};

import { UseCase } from 'core/usecases/usecase';
import { AreaMasterRepository } from 'domain/types/area';

export class DeletePin implements UseCase<Promise<boolean>, DeletePinParams> {
  private areaMasterRepository: AreaMasterRepository;
  constructor(areaMasterRepository: AreaMasterRepository) {
    this.areaMasterRepository = areaMasterRepository;
  }

  public async call(params: DeletePinParams): Promise<boolean> {
    const areaId = params.areaId;
    const gatewayId = params.gatewayId;
    const mapId = params.mapId;
    const pinId = params.pinId;
    return await this.areaMasterRepository.deletePin(areaId, gatewayId, mapId, pinId);
  }
}

export class DeletePinParams {
  constructor(
    public areaId: string,
    public gatewayId: string,
    public mapId: string,
    public pinId: string
  ) {}
}

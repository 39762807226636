import { FallbackProps } from 'react-error-boundary';
import { Box, Typography } from '@mui/material';
import { WriteLog } from 'utils/log';
import { useTranslation } from 'react-i18next';

const ErrorFallback = ({ error }: FallbackProps) => {
  const { t } = useTranslation();
  WriteLog(error.message, 'ERROR');
  return (
    <Box sx={{ p: 6 }}>
      <Typography variant='h3' gutterBottom>
        {t('アプリケーションエラー')}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        {t('エラーが発生しました。ブラウザの更新をお試しください。')}
      </Typography>
      <Typography variant='body1' gutterBottom>
        {error.message}
      </Typography>
    </Box>
  );
};
export default ErrorFallback;

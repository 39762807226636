import React, { useRef, KeyboardEvent } from 'react';
import { SxProps, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import Stack from '@mui/material/Stack';

interface Props {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  originalMax: number;
  originalMin: number;
  max: number;
  min: number;
  title: string;
  handleSetIsSliding?: (state: boolean) => void;
  handleSetIsInputting?: (state: boolean) => void;
  step?: number;
  sx?: SxProps;
}

const Input = styled(MuiInput)`
  width: 100px;
`;
//青色を指定
const CustomizedSlider = styled(Slider)({
  color: '#0078d7',
});
const InputSlider = ({
  value,
  setValue,
  originalMax,
  originalMin,
  title,
  max,
  min,
  handleSetIsSliding,
  handleSetIsInputting,
  step = 1,
  sx,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (handleSetIsSliding) handleSetIsSliding(true);
    if ((newValue as number) >= max) {
      setValue(max as number);
    } else if ((newValue as number) <= min) {
      setValue(min as number);
    } else {
      setValue(newValue as number);
    }
  };

  const handleSliderChangeCommitted = () => {
    if (handleSetIsSliding) handleSetIsSliding(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (handleSetIsInputting) handleSetIsInputting(true);
    setValue(
      event.target.value === ''
        ? 0
        : originalMax < 0 && Number(event.target.value) > 0
          ? -1 * Number(event.target.value)
          : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (value < originalMin) {
      setValue(originalMin);
    } else if (value > originalMax) {
      setValue(originalMax);
    }
    if (value < min) {
      setValue(min);
    } else if (value > max) {
      setValue(max);
    }
    if (handleSetIsInputting) handleSetIsInputting(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (inputRef.current && event.key === 'Enter') {
      if (handleSetIsInputting) handleSetIsInputting(false);
      inputRef.current.blur();
    }
  };

  return (
    <Stack direction='row' justifyContent='flex-start' alignContent='center' spacing={2} sx={{ ...sx }}>
      <Typography id={'input-slider-title-' + title} gutterBottom sx={{ whiteSpace: 'nowrap' }} alignContent='center'>
        {title}
      </Typography>
      <CustomizedSlider
        value={typeof value === 'number' ? value : originalMin}
        onChange={handleSliderChange}
        aria-labelledby='input-slider'
        max={originalMax}
        min={originalMin}
        onChangeCommitted={handleSliderChangeCommitted}
        step={step}
      />
      <Input
        inputRef={inputRef}
        value={value}
        size='medium'
        onChange={handleInputChange}
        onBlur={handleBlur}
        inputProps={{
          step: 1,
          min: originalMin,
          max: originalMax,
          type: 'number',
          'aria-labelledby': 'input-slider',
        }}
        onKeyDown={handleKeyDown}
      />
    </Stack>
  );
};
export default InputSlider;

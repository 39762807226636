import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { formatMapPinLabel } from 'utils/format';
import { BasePinType, PIN_TYPE } from 'domain/types/map';
import SetUpDialogWindow from 'components/SetUpDialogWindow/SetUpDialogWindow';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { getSelectedMap, getTargetPinData } from 'utils/extract';
import PinSelectItem from './PinSelectItem';
import { useMaps } from 'MapsContext';

interface Props {
  checkBoxStates: boolean[];
  setCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;
  selectedPinIds: string[];
  setSelectedPinIds: Dispatch<SetStateAction<string[]>>;
  allPinIds: string[];
  setAllPinIds: Dispatch<SetStateAction<string[]>>;
}

const PinSelectArea = ({
  checkBoxStates,
  setCheckBoxStates,
  selectedPinIds,
  setSelectedPinIds,
  allPinIds,
  setAllPinIds,
}: Props) => {
  const { mapsData } = useMaps();
  const { selectedMapId, registeredPinIds, workMode, isEnableGps } = useContext(LayoutContext);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);
  const [selectedPinType, setSelectedPinType] = useState<number>(PIN_TYPE.UnitPin);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  // NOTE: 右クリックされたピンの情報を保持するためのステート
  const [rightClickedPinId, setRightClickedPinId] = useState<string | undefined>(undefined);

  /**
   * @description ダイアログを開く処理
   */
  const handleClickOpenedDialog = () => {
    setIsOpenedDialog(true);
  };

  /**
   * @description ダイアログを閉じる処理
   */
  const handleClickClosedDialog = () => {
    setIsOpenedDialog(false);
  };

  /**
   * @description ピンタイプを設定する処理
   */
  const handleSetSelectedPinType = (pinType: number) => {
    setSelectedPinType(pinType);
  };

  /**
   * @description 右クリック時のピン ID を設定する処理
   */
  const handleSetRightClickPin = (id: string) => {
    setRightClickedPinId(id);
  };

  if (selectedMap === undefined) return <></>;
  return (
    <Box sx={{ alignContent: 'flex-start', flexWrap: 'wrap', overflowX: 'hidden' }}>
      {allPinIds.map((id: string, index: number) => {
        const mapPin: BasePinType = getTargetPinData(selectedMap, id);
        let label: string = `${id} (未登録)`;
        if (workMode === 'Map') {
          if (mapPin) label = formatMapPinLabel(mapPin, registeredPinIds);
        } else {
          if (mapPin) label = `${id} : ${mapPin.label}`;
          else label = `${id} : ${id}`;
        }
        const isChecked: boolean = checkBoxStates[index];

        return (
          <PinSelectItem
            key={`pinSelectItem-${id}`}
            checkBoxStates={checkBoxStates}
            setCheckBoxStates={setCheckBoxStates}
            selectedPinIds={selectedPinIds}
            setSelectedPinIds={setSelectedPinIds}
            allPinIds={allPinIds}
            setAllPinIds={setAllPinIds}
            id={id}
            index={index}
            isChecked={isChecked}
            mapPin={mapPin}
            selectedMap={selectedMap}
            label={label}
            handleClickOpenedDialog={handleClickOpenedDialog}
            handleSetSelectedPinType={handleSetSelectedPinType}
            handleSetRightClickPin={handleSetRightClickPin}
          />
        );
      })}
      {isOpenedDialog && (
        <SetUpDialogWindow
          isOpenedDialog={isOpenedDialog}
          onClosedDialog={handleClickClosedDialog}
          selectedPinId={rightClickedPinId}
          selectedPinType={selectedPinType}
          setSelectedPinId={setRightClickedPinId}
          pinActionType={'update'}
          isEnableGps={isEnableGps}
        />
      )}
    </Box>
  );
};

export default React.memo(PinSelectArea);

// NOTE: カスタムコンポーネント
import { RECOMMEND_LEVEL_COLOR_MAP } from '../domain/types/color';
import { FREQUENCY_BAND, FREQUENCY_UNIT, FrequencyType, FREQUENCIES, FREQ_NOTE } from '../domain/types/frequency';
import { BasePinType, MapPinType, PIN_TYPE } from '../domain/types/map';
import { RECOMMEND_LEVEL, CHANNEL_DETAIL_ROW_COUNT } from '../domain/types/channel';
import { RECOMMEND_LEVEL_COLOR } from '../domain/types/color';
import { Dayjs } from 'dayjs';
import { DATA_SOURCE } from '../domain/types/common/consts';
import { CALC_METHOD, CalcMethodType } from '../domain/types/setting';
import { DeviceConfig } from 'domain/types/serial';
import { ChartToolTipCSSSetting } from 'domain/types/graph';

/**
 * @description 周波数の値を画面表示用にフォーマットする関数
 * @param {FrequencyType} frequency 対象の周波数
 * @returns {string} フォーマットされた周波数
 */
export const formatFrequencyLabel = (frequency: FrequencyType): string => {
  let label: string =
    frequency.frequency < 1000
      ? `${frequency.frequency.toFixed(1)} ${FREQUENCY_UNIT.FREQ_MHZ}`
      : `${(frequency.frequency / 1000).toFixed(3)} ${FREQUENCY_UNIT.FREQ_GHZ}`;
  label = label + ` (${frequency.channel || '-'})`;
  return label;
};

/**
 * @description CHグループのラベルを取得する関数
 * @param {number} channel 対象のチャンネル
 * @returns {string} チャンネルラベル
 */
export const formatChannelGroupLabel = (channel: number): string => {
  return `無線LAN ${String(channel)}ch`;
};

/**
 * @description ID の値を画面表示用にフォーマットする関数
 * @param {MapPinType | BasePinType} mapPin 対象のマップピン ID
 * @param registeredPinIds
 * @returns {string} マップピンラベル
 */
export const formatMapPinLabel = (mapPin: MapPinType | BasePinType, registeredPinIds: string[]): string => {
  let displayedLabel: string = '';

  if (mapPin.pinType === PIN_TYPE.GroupPin) {
    displayedLabel = `${mapPin.label}`;
  } else {
    if (registeredPinIds.includes(mapPin.pinId)) displayedLabel = `${mapPin.pinId} : ${mapPin.label}`;
    else displayedLabel = `${mapPin.pinId} (未登録)`;
  }

  return displayedLabel;
};

/**
 * @description グループ ID の初期値を生成する関数
 * @param {Array<string>} 対象のグループのリスト
 * @returns {string} 生成されたグループ ID
 */
export const createInitiateGroupId = (groupIds: Array<string>): string => {
  if (!groupIds || groupIds.length < 1) return 'G_001';

  // NOTE: G_XXX のグループ ID を抽出
  const groupIdPattern = /^G_\d{3}$/;
  const matchGroups: Array<string> = groupIds.filter((groupId: string) => groupIdPattern.test(groupId));

  if (!matchGroups || matchGroups.length < 1) {
    return 'G_001';
  } else {
    // NOTE: G_XXX の数値部分のリストと最小値を抽出
    const numberPattern = /\d{3}/;
    const numberIds: Array<number> = matchGroups
      .map((groupId: string) => {
        const matchedIds = groupId.match(numberPattern);
        if (matchedIds) return matchedIds[0];
      })
      .filter((number) => number !== null)
      .map((number) => Number(number));
    const minNumberId: number = numberIds.reduce((num1: number, num2: number) => Math.min(num1, num2));

    if (minNumberId > 1) {
      return 'G_001';
    }

    // NOTE: グループ ID の内、連続する値の中で最小値を取得する (2以上の値で)
    let cnt = 2;
    while (numberIds.includes(cnt) && cnt < 1000) {
      cnt += 1;
    }
    return `G_${String(cnt).padStart(3, '0')}`;
  }
};

/**
 * @description 推奨チャンネルの推奨度をフォーマットする関数
 * @param {number} level 推奨度
 * @returns {string} 推奨度を表す記号
 */
export const getRecommendSymbolFromLevel = (level: number) => {
  if (level === RECOMMEND_LEVEL.LOW) {
    return '〇';
  } else if (level === RECOMMEND_LEVEL.MID) {
    return '△';
  } else {
    return '×';
  }
};

/**
 * @description 推奨チャンネル用周波数の値を画面表示用にフォーマットする関数
 * @param {FrequencyType} frequency 対象の周波数
 * @returns {string} フォーマットされた周波数
 */
export const formatFrequencyLabelForChannel = (frequency: number): string => {
  const label: string = frequency < 1000 ? `${frequency.toFixed(1)}` : `${(frequency / 1000).toFixed(3)}`;
  return label;
};
/**
 * @description 推奨度から色を取得する関数
 * @param {number} level 推奨度
 * @return {string}
 */
export const getColorFromLevel = (level: number) => {
  //色の判定（low：青、high：赤、mid:黄色）
  if (level == RECOMMEND_LEVEL.HIGH) {
    return RECOMMEND_LEVEL_COLOR.HIGH; //赤
  } else if (level == RECOMMEND_LEVEL.MID) {
    return RECOMMEND_LEVEL_COLOR.MID; //黄
  } else {
    return RECOMMEND_LEVEL_COLOR.LOW; //青
  }
};

/**
 * @description 推奨度からマップピン用の色を取得する関数
 * @param {number | undefined} level 推奨度
 * @return {string}
 */
export const getColorFromLevelForMap = (level: number | undefined) => {
  if (!level) return RECOMMEND_LEVEL_COLOR_MAP.NONE;

  //色の判定（low：青、high：赤、mid:黄色）
  if (level == RECOMMEND_LEVEL.HIGH) {
    return RECOMMEND_LEVEL_COLOR_MAP.HIGH; //赤
  } else if (level == RECOMMEND_LEVEL.MID) {
    return RECOMMEND_LEVEL_COLOR_MAP.MID; //黄
  } else {
    return RECOMMEND_LEVEL_COLOR_MAP.LOW; //青
  }
};

/**
 * @description 推奨チャンネル詳細行のヘッダー表示用配列を取得する関数
 * @param {number} span 期間
 * @param {Dayjs} dateTime 日時
 * @return {string[]}
 */
export const getChannelDetailRowHeaderLabel = (span: number, dateTime: Dayjs) => {
  const timeDivSpan = span / CHANNEL_DETAIL_ROW_COUNT;
  const startTime = dateTime.subtract(span, 'second');
  const timeDivs: string[] = new Array(CHANNEL_DETAIL_ROW_COUNT);
  for (let i = 0; i < timeDivs.length; i++) {
    const divTime = startTime.add(timeDivSpan * i, 'second');
    timeDivs[i] = divTime.format('HH:mm') + '～';
  }
  return timeDivs;
};

/**
 * @description ピンの情報ウィンドウに表示させるラベルを取得する関数
 * @param {MapPinType} mapPin 対象のピン
 * @param {string} selectedDataSource 選択中のデータソース
 * @param {string} selectedFrequencyBand 選択中の周波数バンド
 * @return {string[]} 情報ウィンドウに表示するラベル, [0] : ID ラベル、[1] : 周波数ラベル
 */
export const formatMapPinWindowLabel = (
  mapPin: MapPinType,
  selectedDataSource: string,
  selectedFrequencyBand: string
) => {
  const ratio = String(mapPin.ratio !== undefined ? mapPin.ratio : '-');
  const noise = String(mapPin.noise !== undefined ? mapPin.noise : '-');
  const idLabel: string =
    mapPin.pinType === PIN_TYPE.GroupPin
      ? mapPin.label
      : mapPin.pinId === mapPin.label
        ? mapPin.pinId
        : `${mapPin.pinId} : ${mapPin.label}`;
  const frequencyLabel: string = selectedDataSource === DATA_SOURCE.NOISE ? `ノイズ : ${noise}` : `占有率 : ${ratio} %`;
  const unitLabel: string =
    selectedFrequencyBand === FREQUENCY_BAND.FREQUENCY_920M.ID ? FREQUENCY_UNIT.FREQ_MHZ : FREQUENCY_UNIT.FREQ_GHZ;
  const bandLabel: string =
    selectedDataSource === DATA_SOURCE.NOISE
      ? `(${mapPin.peakNoiseFreq ? mapPin.peakNoiseFreq : '-'} ${unitLabel})`
      : `(${mapPin.peakRatioFreq ? mapPin.peakRatioFreq : '-'} ${unitLabel})`;

  return [idLabel, `${frequencyLabel} ${bandLabel}`];
};

/**
 * @description RGBA形式からRGB形式に変換する関数
 * @param {string} rgbaColor rgba形式の文字列
 * @return {string[]}
 */
export const rgbaToRgb = (rgbaColor: string): string => {
  const rgbaValues = rgbaColor.match(/\d+/g);
  if (rgbaValues && rgbaValues.length >= 3) {
    return `rgb(${rgbaValues[0]}, ${rgbaValues[1]}, ${rgbaValues[2]})`;
  } else {
    return '';
  }
};

/**
 * @description 文字列を URL エンコードする処理
 * @param {string} str エンコードする文字列
 * @returns {string} URL エンコードされた文字列
 */
export const encodeString = (str: string) => {
  return encodeURIComponent(str);
};

/**
 * @description RGBA のフォーマット処理
 * @param {number} rgba 色配列のインデックス [r, g, b, a]
 * @param {string} フォーマットされた RGBA 文字列
 */
export const formatColorRGBA = (rgba: number[]): string => {
  return `rgba(${rgba.join(',')})`;
};

/**
 * @description 周波数の値をグラフ表示用にフォーマットする関数
 * @param {FrequencyType} frequency 対象の周波数
 * @returns {string} フォーマットされた周波数
 */
export const formatGraphFrequencyLabel = (frequency: FrequencyType): string => {
  let label: string =
    frequency.frequency < 1000 ? `${frequency.frequency.toFixed(1)}` : `${(frequency.frequency / 1000).toFixed(3)}`;
  label = label + `(${frequency.channel || '-'})`;
  return label;
};

/**
 * @description 計算方法のラベルを取得する関数
 * @param {CalcMethodType} calcMethod 計算方法ID
 * @returns {string} 計算方法ラベル
 */
export const getLabelFromCalcmethod = (calcMethod: CalcMethodType): string => {
  if (calcMethod == CALC_METHOD.AVE.ID) {
    return CALC_METHOD.AVE.LABEL;
  } else if (calcMethod == CALC_METHOD.MAX.ID) {
    return CALC_METHOD.MAX.LABEL;
  } else {
    return CALC_METHOD.MIN.LABEL;
  }
};

/**
 * @description デバイスの設定を表示用に成形する関数
 * @param {DeviceConfig} config デバイス設定情報
 * @returns
 */
export const createDeviceConfigMessage = (config: DeviceConfig): string => {
  let message = 'デバイス設定情報\n\n';

  const isNwJoin = !(config.ch === '00' || config.port === '0000' || config.panid === '0000' || config.myId === '0000');

  if (isNwJoin) {
    const routerStatusText = config.routerStatus === '00' ? '無効' : '有効';
    const rssiTimeOutInt = parseInt(config.rssiTime, 16);
    let rssiTimeoutText = '';
    if (rssiTimeOutInt === 0) {
      rssiTimeoutText = 'タイムアウトなし';
    } else {
      const time = rssiTimeOutInt * 10;
      if (time / 60 < 1) {
        rssiTimeoutText = `${rssiTimeOutInt}秒`;
      } else {
        rssiTimeoutText = `${Math.floor(time / 60)}分${time % 60 !== 0 ? `${time % 60}秒` : ''}`;
      }
    }

    const storageFuncText = config.storageFunc === '00' ? '無効' : '有効';

    const monitorExclusionText = config.monitorExclusion === '00' ? '除外なし' : '自動送信除外';

    const reportIntervalInt = parseInt(config.reportInterval, 16);

    const ism900MonFreqText = convertFrequencyRange(config.ism900MonFreq);
    const w24gMonFreqText = convertFrequencyRange(config.w24gMonFreq);
    const l5gMonFreqText = convertFrequencyRange(config.l5gMonFreq);
    const w5gMonFreqText = convertFrequencyRange(config.w5gMonFreq);

    const ism900MonUpperThText = convertThreshold(config.ism900MonUpperTh);
    const w24gMonUpperThText = convertThreshold(config.w24gMonUpperTh);
    const l5gMonUpperThText = convertThreshold(config.l5gMonUpperTh);
    const w5gMonUpperThText = convertThreshold(config.w5gMonUpperTh);

    // 時間変換関数を使用して文字列に変換
    const reportIntervalText = convertSecondsToTimeString(reportIntervalInt);

    message += '[無線設定]\n';

    message += `CH：${config.ch} Port：${config.port} PanID：${config.panid} my_id：${config.myId}\n\n`;

    message += '[動作設定 基本設定]\n';
    message += `送信間隔：${reportIntervalText}\n`;
    message += `中継機能：${routerStatusText}\n`;
    message += `RSSI確認時間：${rssiTimeoutText}\n`;
    message += `データ保存機能：${storageFuncText}\n\n`;

    message += '[動作設定 無線監視設定]\n';
    message += `電波監視対象の除外:${monitorExclusionText}\n`;
    message += 'ISM900\n ';
    message += `監視周波数範囲:${ism900MonFreqText} RSSI上限閾値:${ism900MonUpperThText}\n`;
    message += 'Wi-Fi2.4G\n';
    message += `監視周波数範囲:${w24gMonFreqText} RSSI上限閾値:${w24gMonUpperThText}\n`;
    message += 'L5G\n';
    message += `監視周波数範囲:${l5gMonFreqText} RSSI上限閾値:${l5gMonUpperThText}\n`;
    message += 'Wi-Fi5G\n';
    message += `監視周波数範囲:${w5gMonFreqText} RSSI上限閾値:${w5gMonUpperThText}\n`;
  } else {
    message = '[無線設定]\n未構築\n';
    message += '[動作設定 ]なし';
  }

  return message;
};

/**
 * @description 秒から時刻表示用文字列に変換する関数
 * @param {number} seconds 秒
 * @returns {string} 時刻
 */
export const convertSecondsToTimeString = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours}時間`;
  }

  if (minutes > 0) {
    result += `${minutes}分`;
  }

  if (secs > 0 || result === '') {
    // 秒を表示する条件
    result += `${secs}秒`;
  }

  return result;
};

/**
 * 周波数範囲を表示用文字列に変換する関数
 * @param {string} freqData 周波数
 * @returns {string} 対象周波数
 */
export const convertFrequencyRange = (freqData: string): string => {
  if (freqData === 'FFFFFFFF') {
    return 'なし';
  }

  const startFreq = parseInt(freqData.substring(0, 4), 16) / 10;
  const endFreq = parseInt(freqData.substring(4), 16) / 10;

  return startFreq === endFreq ? `${startFreq}MHzのみ` : '全て';
};

/**
 * 閾値を表示用文字列に変換する関数
 * @param {string} threshold 閾値
 * @returns {string} 閾値
 */
export const convertThreshold = (threshold: string): string => {
  const thInt = parseInt(threshold, 16);

  if (isNaN(thInt)) {
    console.error('ConvertThreshold() data error:', threshold);
    return '';
  }

  // 値を調整する（例: -90にする場合）
  const adjustedValue = thInt > 127 ? thInt - 256 : thInt;

  return adjustedValue.toString();
};

/**
 * 監視データパブリッシュ用トピックを生成する関数
 * @param {string} tenantId
 * @param {string} deviceId
 * @param {string} dataType
 * @returns {string} トピック
 */
export const createTopicForSaveDataPublish = (tenantId: string, deviceId: string, dataType: string): string => {
  const topic = 'tenants/' + tenantId + '/devices/' + deviceId + '/datatypes/' + dataType;
  return topic;
};

/**
 * デバイスデータパブリッシュ用トピックを生成する関数
 * @param {string} tenantId
 * @param {string} gatewayId
 * @returns {string} トピック
 */
export const createTopicForDeviceDataPublish = (tenantId: string, gatewayId: string): string => {
  const topic = 'tenants/' + tenantId + '/devices/' + gatewayId + '/dynamoDB/SensorUnitInfo';
  return topic;
};

/**
 * @description グラフのツールチップの設定を作成する関数
 * @param {number} left キャンバスの左端
 * @param {number} top キャンバスの上端
 * @param {number} pixelRatio 比率
 * @param {number} x グラフ内のx座標
 * @param {number} y グラフ内のy座標
 * @returns
 */
export const createChartToolTipSetting = (
  left: number,
  top: number,
  pixelRatio: number,
  x: number,
  y: number
): ChartToolTipCSSSetting => {
  const chartToolTipSetting: ChartToolTipCSSSetting = {
    position: 'absolute',
    left: `${left + x / pixelRatio}px`,
    top: `${top + y / pixelRatio - 10}px`,
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: '3px',
    padding: '8px',
    color: '#fff',
    fontSize: '12px',
    fontFamily: `'Helvetica Neue', 'Helvetica', 'Arial', sans-serif`,
    boxShadow: '0px2px4pxrgba(0,0,0,0.1)',
    pointerEvents: 'none',
  };
  return chartToolTipSetting;
};

/**
 * グラフのツールチップの内容を作成する
 * @param {string} title タイトル
 * @param {string} color 色
 * @param {string} xValue x軸値
 * @param {string} yValue y軸値
 * @param {string} label 凡例
 * @returns
 */
export const createChartToolTipContent = (
  title: string,
  color: string,
  xValue: string,
  yValue: string,
  label: string
): string => {
  return `
  <div>
    <div>
      <span style="background-color:${color}; 
                   width:12px; height:12px; display:inline-block; margin-right:4px;"></span>
      <span>${title}</span>
    </div>
    <div style="display:flex; align-items:center;">
      <span>${xValue}</span>
    </div>
    <div>
      <span>${label}: ${yValue}</span>
    </div>
  </div>
`;
};

/* @description 推奨チャンネル用CHの値を画面表示用にフォーマットする関数
 * @param {string} frequencyBand 周波数バンド
 * @param {number} ch チャンネル
 * @param {number} frequency 周波数
 * @returns {string} チャンネル表示文字列
 */
export const getRecommendChannelLabel = (frequencyBand: string, ch: number, frequency: number): string => {
  if (ch == 0) {
    return '-';
  }

  if (frequencyBand == FREQUENCY_BAND.FREQUENCY_WLAN5G.ID) {
    const freqList = FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN5G.ID].list;
    const tgtFreq = freqList.find((freq) => freq.frequency == frequency);
    if (!tgtFreq) return ch.toString();
    if (!tgtFreq.note) return ch.toString();

    return ch.toString() + '\n' + FREQ_NOTE[tgtFreq.note].label;
  } else {
    return ch.toString();
  }
};

/**
 * @description 再生中の表示用ラベル文字列を取得する
 * @param playingSpeed 再生スピード
 * @param selectedSpan 選択中の期間秒数
 */
export const getPlayingSpeedLabel = (playingSpeed: number, selectedSpan: number): string => {
  const speed = (selectedSpan * playingSpeed) / 12;

  if (speed >= 86400) {
    // 1日以上
    const days = (speed / 86400).toFixed(1);
    return `${days}日`;
  } else if (speed >= 3600) {
    // 1時間以上
    const hours = (speed / 3600).toFixed(1);
    return `${hours}時間`;
  } else if (speed >= 60) {
    // 1分以上
    const minutes = (speed / 60).toFixed(1);
    return `${minutes}分`;
  } else {
    // 1分未満
    const seconds = Math.floor(speed);
    return `${seconds}秒`;
  }
};

import React, { useContext, useEffect, useState } from 'react';
import CustomIconButton from 'components/Common/CustomIconButton';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { LuFootprints } from 'react-icons/lu';
import { SelectedGatewayContext } from 'radioMonitoringPage';

const TrackButton = () => {
  const { setWorkMode, setSelectedPinIdsForTrack, setPinCheckBoxStatesForTrack } = useContext(LayoutContext);
  const { selectedGateway } = useContext(SelectedGatewayContext);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    setWorkMode('Map');
    setIsEnabled(false);
  }, [setWorkMode, selectedGateway]);

  /**
   * @description 移動モードボタン選択時の処理
   */
  const handleClickTrackMode = () => {
    setIsEnabled((prev) => {
      if (prev) setWorkMode('Map');
      else setWorkMode('Track');
      return !prev;
    });

    setSelectedPinIdsForTrack([]);
    setPinCheckBoxStatesForTrack((prev) => prev.fill(false));
  };

  return (
    <CustomIconButton
      onClick={handleClickTrackMode}
      Icon={LuFootprints}
      tooltipTitle={'移動モード'}
      size='medium'
      isEnabled={isEnabled}
    ></CustomIconButton>
  );
};

export default React.memo(TrackButton);

import { UseCase } from 'core/usecases/usecase';
import { pfApiIotDataRepository, RadioWave } from 'core/entities/radioWave';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export class FetchNearestIotData implements UseCase<Promise<RadioWave[]>, FetchNearestIotDataParams> {
  private pfApiIotDataRepository: pfApiIotDataRepository;
  constructor(areaMasterRepository: pfApiIotDataRepository) {
    this.pfApiIotDataRepository = areaMasterRepository;
    // プラグインを使用できるように設定
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }

  public async call(params: FetchNearestIotDataParams): Promise<RadioWave[]> {
    const timeStamp = params.timeStamp;
    const dataType = params.dataType;
    const deviceIds = params.deviceIds;
    const isBeforeFlag = params.isBeforeFlag ? '1' : '0';

    const timeStampInJST = dayjs(timeStamp).tz('Asia/Tokyo').format();
    return await this.pfApiIotDataRepository.fetchNearestIotData(timeStampInJST, dataType, deviceIds, isBeforeFlag);
  }
}

export class FetchNearestIotDataParams {
  constructor(
    public timeStamp: Dayjs,
    public dataType: string,
    public deviceIds: string[],
    public isBeforeFlag: boolean
  ) {}
}

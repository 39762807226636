import React, { SetStateAction, Dispatch } from 'react';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

// NOTE: カスタムコンポーネント
import { ZOOM_OPTIONS } from 'domain/types/map';
import CustomIconButton from 'components/Common/CustomIconButton';

/**
 * @interface
 */
interface Props {
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
}

const ZoomOutMapButton = ({ zoom, setZoom }: Props) => {
  /**
   * @description ズームアウトボタン選択時の処理
   */
  const handleClickZoomOut = () => {
    if (zoom > ZOOM_OPTIONS.min) zoom--;
    setZoom(zoom);
  };

  return (
    <CustomIconButton onClick={handleClickZoomOut} Icon={ZoomOutIcon} tooltipTitle={'ズームアウト'} size='medium' />
  );
};

export default React.memo(ZoomOutMapButton);

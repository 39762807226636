import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack, Box, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/Common/CustomButton';
import { CustomDialogButton } from 'components/Common/CustomDialogButton';

// Propsの型定義
interface DialogYAxisProps {
  open: boolean;
  onClose: () => void;
  title1: string;
  initialMinValue1: number;
  initialMaxValue1: number;
  setYAxisMin1: (value: number) => void;
  setYAxisMax1: (value: number) => void;
  originalMin1: number;
  originalMax1: number;
  title2?: string;
  initialMinValue2?: number;
  initialMaxValue2?: number;
  setYAxisMin2?: (value: number) => void;
  setYAxisMax2?: (value: number) => void;
  originalMin2?: number;
  originalMax2?: number;
}

const DialogYAxis: React.FC<DialogYAxisProps> = ({
  open,
  onClose,
  title1,
  initialMinValue1,
  initialMaxValue1,
  setYAxisMin1,
  setYAxisMax1,
  originalMin1,
  originalMax1,
  title2,
  initialMinValue2,
  initialMaxValue2,
  setYAxisMin2,
  setYAxisMax2,
  originalMin2,
  originalMax2,
}) => {
  const [minValue1, setMinValue1] = useState(initialMinValue1.toString());
  const [maxValue1, setMaxValue1] = useState(initialMaxValue1.toString());
  const [yAxisMin1, setYAxisMinInternal1] = useState(initialMinValue1);
  const [yAxisMax1, setYAxisMaxInternal1] = useState(initialMaxValue1);
  const [minValue2, setMinValue2] = useState(initialMinValue2?.toString());
  const [maxValue2, setMaxValue2] = useState(initialMaxValue2?.toString());
  const [yAxisMin2, setYAxisMinInternal2] = useState(initialMinValue2);
  const [yAxisMax2, setYAxisMaxInternal2] = useState(initialMaxValue2);

  const { t } = useTranslation();

  useEffect(() => {
    setYAxisMin1(initialMinValue1);
    setYAxisMax1(initialMaxValue1);
    setYAxisMinInternal1(initialMinValue1);
    setYAxisMaxInternal1(initialMaxValue1);
    setMinValue1(initialMinValue1.toString());
    setMaxValue1(initialMaxValue1.toString());
    if (
      initialMinValue2 != undefined &&
      initialMaxValue2 != undefined &&
      setYAxisMin2 != undefined &&
      setYAxisMax2 != undefined
    ) {
      setYAxisMin2(initialMinValue2);
      setYAxisMax2(initialMaxValue2);
      setYAxisMinInternal2(initialMinValue2);
      setYAxisMaxInternal2(initialMaxValue2);
      setMinValue2(initialMinValue2.toString());
      setMaxValue2(initialMaxValue2.toString());
    }
    // eslint-disable-next-line
  }, [initialMinValue1, initialMaxValue1, initialMinValue2, initialMaxValue2]);

  const handleSave = () => {
    const range1 = validate(title1, maxValue1, minValue1);
    if (range1 != undefined) {
      setYAxisMin1(range1.min);
      setYAxisMax1(range1.max);
      setYAxisMinInternal1(range1.min);
      setYAxisMaxInternal1(range1.max);
      setMinValue1(range1.min.toString());
      setMaxValue1(range1.max.toString());
    }
    // 1ペアのみの場合は閉じる
    if (range1 != undefined && minValue2 == undefined) {
      onClose();
    }
    if (range1 != undefined && maxValue2 != undefined && minValue2 != undefined && title2 != undefined) {
      const range2 = validate(title2, maxValue2, minValue2);
      if (setYAxisMin2 != undefined && setYAxisMax2 != undefined && range2 != undefined) {
        setYAxisMin2(range2.min);
        setYAxisMax2(range2.max);
        setYAxisMinInternal2(range2.min);
        setYAxisMaxInternal2(range2.max);
        setMinValue2(range2.min.toString());
        setMaxValue2(range2.max.toString());
        onClose();
      }
    }
  };

  const validate = (title: string, maxValue: string, minValue: string): undefined | { min: number; max: number } => {
    // 値入力チェック
    if (minValue.trim() === '' || maxValue.trim() === '') {
      alert(`値を入力してください！\n${title}（最大値）：${maxValue}\n${title}（最小値:${minValue}`);
      return undefined;
    }

    const min = Number(minValue.trim());
    const max = Number(maxValue.trim());

    // 数値チェック
    if (isNaN(min) || isNaN(max)) {
      alert(`数値を入力してくだい！\n最大値 : ${maxValue}\n${title}（最小値）: ${minValue}`);
      return undefined;
    }

    // 大小チェック
    if (min >= max) {
      alert(
        `最小値は最大値より小さい値にしてください！\n${title}（最大値） : ${maxValue}\n${title}（最小値）: ${minValue}`
      );
      return undefined;
    }

    // 最大最小チェック
    if (min <= -9999 || max >= 9999) {
      alert(
        `-9999～9999の範囲内で設定してください！\n${title}（最大値） : ${maxValue}\n${title}（最小値）: ${minValue}`
      );
      return undefined;
    }
    return { min, max };
  };

  const handleDefault = () => {
    setMinValue1(originalMin1.toString());
    setMaxValue1(originalMax1.toString());
    if (originalMin2 != undefined && originalMax2 != undefined) {
      setMinValue2(originalMin2.toString());
      setMaxValue2(originalMax2.toString());
    }
  };

  const handleCancel = () => {
    setMinValue1(yAxisMin1.toString());
    setMaxValue1(yAxisMax1.toString());
    if (yAxisMin2 != undefined && yAxisMax2 != undefined) {
      setMinValue2(yAxisMin2.toString());
      setMaxValue2(yAxisMax2.toString());
    }
    onClose();
  };

  const TextFieldStyle: SxProps = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc', // 通常時の色
      },
      '&:hover fieldset': {
        borderColor: '#aaa', // ホバー時の色
      },
      '&.Mui-focused fieldset': {
        borderColor: '#333', // フォーカス時の色
      },
    },
  };

  const BoxStyle: SxProps = {
    marginTop: '20px',
    width: '350px',
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      sx={{
        '& .MuiPaper-root': {
          background: '#000',
          borderBlockColor: '#333',
          borderWidth: 1,
        },
      }}
    >
      <DialogTitle>{t('Y軸の設定')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1, marginTop: '5px' }}>
          <Stack direction={'row'}>
            <Box sx={{ ...BoxStyle }}>{t(`${title1}（最大値）`)}&nbsp;:&nbsp;</Box>
            <TextField
              value={maxValue1}
              fullWidth
              variant='outlined'
              sx={{ ...TextFieldStyle }}
              onChange={(e) => {
                setMaxValue1(e.target.value);
              }}
            />
          </Stack>
          <Stack direction={'row'}>
            <Box sx={{ ...BoxStyle }}>{t(`${title1}（最小値）`)}&nbsp;:&nbsp;</Box>
            <TextField
              value={minValue1}
              fullWidth
              variant='outlined'
              sx={{ ...TextFieldStyle }}
              onChange={(e) => {
                setMinValue1(e.target.value);
              }}
            />
          </Stack>
          {minValue2 != undefined && (
            <>
              <Stack direction={'row'}>
                <Box sx={{ ...BoxStyle }}>{t(`${title2}（最大値）`)}&nbsp;:&nbsp;</Box>
                <TextField
                  value={maxValue2}
                  fullWidth
                  variant='outlined'
                  sx={{ ...TextFieldStyle }}
                  onChange={(e) => {
                    setMaxValue2(e.target.value);
                  }}
                />
              </Stack>
              <Stack direction={'row'}>
                <Box sx={{ ...BoxStyle }}>{t(`${title2}（最小値）`)}&nbsp;:&nbsp;</Box>
                <TextField
                  value={minValue2}
                  fullWidth
                  variant='outlined'
                  sx={{ ...TextFieldStyle }}
                  onChange={(e) => {
                    setMinValue2(e.target.value);
                  }}
                />
              </Stack>
            </>
          )}

          <CustomButton title={t('デフォルトに戻す')} sx={{ p: 1, width: 1 / 2 }} onClick={handleDefault} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <CustomDialogButton
          onClick={handleCancel}
          variant='contained'
          sx={{ backgroundColor: '#333333' }}
          title={t('キャンセル')}
        />
        <CustomDialogButton
          onClick={handleSave}
          variant='contained'
          sx={{ backgroundColor: '#333333' }}
          title={t('OK')}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogYAxis;

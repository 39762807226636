import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { DEFAULT_MAP_CONF, DEFAULT_MAP_OPTIONS } from 'domain/types/map';
import { getElevation } from 'core/api/googleMap/googleMap';
import EditPin from '../Pin/EditPin';
import { Stack } from '@mui/material';
import HeightRangeButton from '../Button/HeightRangeButton';
import PositionRangeButton from '../Button/PositionRangeButton';
import { RwmMapAreaContext } from '../RwmMapContext';
import EditMapConfirmButton from '../Button/EditMapConfirmButton';
import EditMapCancelButton from '../Button/EditMapCancelButton';
import ChangeMapTypeButton from '../Button/ChangeMapTypeButton';
import ZoomInMapButton from '../Button/ZoomInMapButton';
import ZoomOutMapButton from '../Button/ZoomOutMapButton';
import FloorImage from '../Map/FloorImage';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { getSelectedMap } from 'utils/extract';

const EditMapWindow = () => {
  const { mapsData } = useMaps();
  const { selectedMapId, selectedPinIds } = useContext(LayoutContext);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);

  const {
    mapWidth,
    mapHeight,
    tmpCenter,
    tmpZoom,
    tmpMapTypeId,
    setTmpCenter,
    setTmpZoom,
    setTmpMapTypeId,
    setTmpElevation,
    elevation,
    isOpenedHeightRange,
    maxHeightConf,
    minHeightConf,
    setIsOpenedHeightRange,
    setMaxHeightConf,
    setMinHeightConf,
    isOpenedPositionRange,
    verticalConf,
    horizontalConf,
    setIsOpenedPositionRange,
    setVerticalConf,
    setHorizontalConf,
  } = useContext(RwmMapAreaContext);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [ne, setNe] = useState<google.maps.LatLng | undefined>(new google.maps.LatLng(DEFAULT_MAP_CONF.ne));
  const [sw, setSw] = useState<google.maps.LatLng | undefined>(new google.maps.LatLng(DEFAULT_MAP_CONF.sw));

  /**
   * @description マップインスタンスが読み込まれた時の処理
   */
  const onLoad = useCallback(async (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  /**
   * @description マップインスタンスがアンマウント (レンダリング対象から外れた) 時の処理
   */
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  /**
   * @description マップをドラッグで移動し、止まった時の処理
   */
  const onDragEnd = useCallback(async () => {
    if (map) {
      const newCenter = map.getCenter();
      if (newCenter) {
        const newElevation = await getElevation(newCenter.lat(), newCenter.lng());

        setTmpCenter(newCenter);
        setTmpElevation(newElevation);
      }
    }
  }, [map, setTmpCenter, setTmpElevation]);

  /**
   * @description マップのズーム倍率が変更された時の処理
   */
  const onZoomChanged = useCallback(() => {
    if (map) {
      const newZoom = map.getZoom();
      if (newZoom) setTmpZoom(newZoom);
    }
  }, [map, setTmpZoom]);

  /**
   * @description マップインスタンスに値を設定
   */
  useEffect(() => {
    if (map && tmpCenter) {
      map.setCenter(tmpCenter);
      map.setZoom(tmpZoom);
      map.setMapTypeId(tmpMapTypeId);
    }
  }, [map, tmpCenter, tmpMapTypeId, tmpZoom]);

  /**
   * @description 1ピクセル当たりの緯度経度と境界点 (北東、南西) を更新
   */
  useEffect(() => {
    if (map) {
      const overlayView = new google.maps.OverlayView();
      overlayView.onAdd = function () {
        const _bounds = map.getBounds();
        if (_bounds) {
          // NOTE : 表示されている地図の右上 (北東) の座標
          const _ne = _bounds.getNorthEast();
          // NOTE : 表示されている地図の左下 (南西) の座標
          const _sw = _bounds.getSouthWest();
          setNe(_ne);
          setSw(_sw);
        }
      };
      overlayView.draw = function () {};
      overlayView.setMap(map);
    }
  }, [map, setNe, setSw, selectedMap, mapWidth]);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: mapWidth,
        height: mapHeight,
      }}
      center={tmpCenter}
      zoom={tmpZoom}
      mapTypeId={tmpMapTypeId}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragEnd={onDragEnd}
      onZoomChanged={onZoomChanged}
      options={{ ...DEFAULT_MAP_OPTIONS, gestureHandling: 'greedy', scrollwheel: true }}
    >
      {selectedMap.mapType === 'imageMap' && ne && sw && <FloorImage ne={ne} sw={sw} />}
      {selectedMap.mapType === 'googleMap' &&
        selectedPinIds.map((id, index) => {
          return <EditPin key={`editMapMarker-${id}`} map={map} pinId={id} index={index} />;
        })}
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={1}
        m={1}
        sx={{ zIndex: 10, width: 'fit-content', height: 'fit-content', position: 'absolute', top: 0, left: 0 }}
      >
        <EditMapConfirmButton />
        <EditMapCancelButton />
      </Stack>
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-end'
        spacing={1}
        m={1}
        sx={{ zIndex: 10, width: 'fit-content', height: 'fit-content', position: 'absolute', top: 0, right: 0 }}
      >
        <HeightRangeButton
          elevation={elevation}
          isOpenedHeightRange={isOpenedHeightRange}
          maxHeightConf={maxHeightConf}
          minHeightConf={minHeightConf}
          setIsOpenedHeightRange={setIsOpenedHeightRange}
          setMaxHeightConf={setMaxHeightConf}
          setMinHeightConf={setMinHeightConf}
        />
        <PositionRangeButton
          isOpenedPositionRange={isOpenedPositionRange}
          verticalConf={verticalConf}
          horizontalConf={horizontalConf}
          setIsOpenedPositionRange={setIsOpenedPositionRange}
          setVerticalConf={setVerticalConf}
          setHorizontalConf={setHorizontalConf}
        />
      </Stack>
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-end'
        spacing={1}
        m={1}
        sx={{ zIndex: 10, width: 'fit-content', height: 'fit-content', position: 'absolute', bottom: 0, right: 0 }}
      >
        <ChangeMapTypeButton setMapTypeId={setTmpMapTypeId} />
        <ZoomInMapButton zoom={tmpZoom} setZoom={setTmpZoom} />
        <ZoomOutMapButton zoom={tmpZoom} setZoom={setTmpZoom} />
      </Stack>
    </GoogleMap>
  );
};

export default React.memo(EditMapWindow);

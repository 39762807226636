import React, { useContext, useRef, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HeightIcon from '@mui/icons-material/Height';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { CssVarsProvider } from '@mui/joy/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  LegendItem,
  LegendElement,
  ChartEvent,
  TimeScale,
  LineController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  SelectedFrequencyBandContext,
  DateTimeContext,
  SelectedSpanContext,
  IsSyncContext,
  DisplayDateTimeContext,
  SettingContext,
  PlayingSpeedIndexContext,
} from '../../../../radioMonitoringPage';
import {
  TimeData,
  MAX_TICKS_LIMIT,
  ChartDataIndex,
  TOOLTIP_DISPLAY_TIME,
  DEFAULT_OCCUPANCY_RANGE,
} from '../../../../domain/types/graph';
import CustomIconButton from '../../../Common/CustomIconButton';
import { getExtremesIndicesAcrossTimeDatasets, getMaxRateGraphData } from '../../../../utils/extract';
import { createChartToolTipContent, createChartToolTipSetting, rgbaToRgb } from '../../../../utils/format';
import dayjs from 'dayjs';
import { FetchRmsDataParames } from 'domain/usecases/fetchRmsData';
import { getDateTimeWithTimeOffset } from 'utils/dateHelper';
import useSWR from 'swr';
import { FETCH_FAILED } from 'core/commons/messages';
import { handleGetRmsData, SWR_URLS } from 'utils/swrWrapperApi';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { RwmContext } from 'RwmContext';
import DialogYAxis from './DialogYAxis';
import { PLAYING_SPEED } from 'domain/types/common/consts';

ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  Colors,
  zoomPlugin
);

interface Props {
  graphHeight: number;
  yMax: number;
  yMin: number;
  setYMax: (max: number) => void;
  setYMin: (max: number) => void;
}

export const MaxRateGraph = ({ graphHeight, yMax, yMin, setYMax, setYMin }: Props) => {
  const { t } = useTranslation();
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { setDateTime, dateTime } = useContext(DateTimeContext);
  const { selectedSpan } = useContext(SelectedSpanContext);
  const { selectedFrequencies } = useContext(RwmContext);
  const { selectedPinIdsForGraph } = useContext(LayoutContext);
  const { isSync } = useContext(IsSyncContext);
  const { setDisplayDateTime } = useContext(DisplayDateTimeContext);
  const [isMovingAverage, setIsMovingAverage] = useState(false);
  const { setting } = useContext(SettingContext);
  const { playingSpeedIndex } = useContext(PlayingSpeedIndexContext);

  const chartRef = useRef<ChartJS<'line', TimeData[], unknown>>();
  const savedScaleXMin = useRef<number>(0);
  const savedScaleXMax = useRef<number>(0);

  // NOTE: 凡例で選択中かを保持するステート
  const isCheckedLegendItems: boolean[] = new Array(selectedFrequencies.length).fill(false);

  // y軸の最大値と最小値を管理する状態
  const [openDialog, setOpenDialog] = useState(false);

  // パラメーター生成関数
  const generateParams = (timeoffset: number, pinId: string) =>
    new FetchRmsDataParames(
      getDateTimeWithTimeOffset(
        dateTime,
        Number(selectedSpan) * PLAYING_SPEED[playingSpeedIndex.current ?? 0],
        timeoffset,
        isSync
      ),
      selectedSpan,
      pinId,
      selectedFrequencyBand
    );
  // データ取得用SWフック（初回）
  const pinId = selectedPinIdsForGraph[0] ? selectedPinIdsForGraph[0] : '';
  const params = generateParams(0, pinId);
  // console.log(params);
  const key = JSON.stringify(params);
  const { data, error } = useSWR(pinId ? [SWR_URLS.RMS, key] : null, () => handleGetRmsData(params));

  // 次データ取得用SWR フック（3つ先まで取得する）
  const nextParams1 = generateParams(1, pinId);
  // console.log(nextParams1);
  const nextKey1 = JSON.stringify(nextParams1);
  useSWR(pinId ? [SWR_URLS.RMS, nextKey1] : null, () => handleGetRmsData(nextParams1));

  const nextParams2 = generateParams(2, pinId);
  const nextKey2 = JSON.stringify(nextParams2);
  useSWR(pinId ? [SWR_URLS.RMS, nextKey2] : null, () => handleGetRmsData(nextParams2));

  const nextParams3 = generateParams(3, pinId);
  const nextKey3 = JSON.stringify(nextParams3);
  useSWR(pinId ? [SWR_URLS.RMS, nextKey3] : null, () => handleGetRmsData(nextParams3));

  // エラーハンドリング
  if (error) {
    console.log(error);
    alert(t(FETCH_FAILED));
  }

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.data.datasets = getMaxRateGraphData(
        selectedFrequencies,
        data ? data : [],
        isMovingAverage,
        setting.movingAverage
      );
      chart.update();
      if (savedScaleXMin.current != 0 || savedScaleXMax.current != 0) {
        chart.zoomScale('x', { min: savedScaleXMin.current, max: savedScaleXMax.current });
      }
    }
  }, [selectedFrequencies, data, isMovingAverage, setting]);

  useEffect(() => {
    savedScaleXMin.current = 0;
    savedScaleXMax.current = 0;
  }, [dateTime, selectedSpan]);

  const handleZoomResetButtonClick = () => {
    const chart = chartRef.current;
    if (chart) {
      const startDate: dayjs.Dayjs = dateTime.add(-1 * Number(selectedSpan), 'second');
      const startDateNumber = startDate.toDate().getTime();
      const endDateNumber = dateTime.toDate().getTime();
      savedScaleXMin.current = startDateNumber;
      savedScaleXMax.current = endDateNumber;
      chart.zoomScale('x', { min: startDateNumber, max: endDateNumber });
    }
  };

  const showTooltipAtExtremes = () => {
    const chartInstance = chartRef.current;
    if (chartInstance) {
      let datasets = chartInstance.data.datasets;
      if (datasets[0].data.length == 0) return;
      // ピックアップ中のindexを取得
      const pickUpDataSetIndex = isCheckedLegendItems.findIndex((value) => value == true);
      let maxIndex: ChartDataIndex;
      let minIndex: ChartDataIndex;
      // ピックアップされている場合はピックアップされている折れ線のみを対象とする
      if (pickUpDataSetIndex >= 0) {
        datasets = [datasets[pickUpDataSetIndex]];
        ({ maxIndex, minIndex } = getExtremesIndicesAcrossTimeDatasets(datasets));
        maxIndex.datasetIndex = pickUpDataSetIndex;
        minIndex.datasetIndex = pickUpDataSetIndex;
      } else {
        ({ maxIndex, minIndex } = getExtremesIndicesAcrossTimeDatasets(datasets));
      }
      // 描画処理
      drawCustomTooltip(chartInstance, maxIndex, 'Peak Max');
      drawCustomTooltip(chartInstance, minIndex, 'Peak Min');

      chartInstance.update();
    }
  };

  const drawCustomTooltip = (
    chart: ChartJS<'line', TimeData[], unknown> | undefined,
    targetPoint: ChartDataIndex,
    title: string
  ): void => {
    if (!chart) return;

    const metaDataPoint = chart.getDatasetMeta(targetPoint.datasetIndex).data[targetPoint.index];
    const { x, y } = metaDataPoint.getProps(['x', 'y'], true);

    const pixelRatio = chart.currentDevicePixelRatio;
    const canvasRect = chart.canvas.getBoundingClientRect();

    const tooltipElement = document.createElement('div');

    // データセット情報取得
    const dataset = chart.data.datasets[targetPoint.datasetIndex];

    Object.assign(tooltipElement.style, createChartToolTipSetting(canvasRect.left, canvasRect.top, pixelRatio, x, y));

    // 凡例とデータ値を設定
    tooltipElement.innerHTML = createChartToolTipContent(
      title,
      `${dataset.borderColor}`,
      dataset.data[targetPoint.index].x.toLocaleString(),
      dataset.data[targetPoint.index].y.toString(),
      dataset.label || ''
    );

    document.body.appendChild(tooltipElement);

    setTimeout(() => {
      document.body.removeChild(tooltipElement);
    }, TOOLTIP_DISPLAY_TIME);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // NOTE: 凡例のクリックイベント処理
  const clickLegendItem = (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) => {
    const selectedItemIndex: number = legendItem.datasetIndex ? legendItem.datasetIndex : 0;
    // NOTE: 選択できるのは1件のみ
    const lastSelectedIndex: number = isCheckedLegendItems.findIndex((element) => element == true);
    if (lastSelectedIndex == -1) {
      // NOTE: 選択中の周波数が存在しなかった場合の処理
      isCheckedLegendItems[selectedItemIndex] = true;
    } else {
      // NOTE: 選択中の周波数が存在した場合の処理
      isCheckedLegendItems[lastSelectedIndex] = false;
      if (lastSelectedIndex == selectedItemIndex) return;
      isCheckedLegendItems[selectedItemIndex] = true;
      // NOTE: 前回選択中だった周波数は薄める
      let color: string | undefined = legend.chart.data.datasets[lastSelectedIndex].borderColor?.toString();
      color = color?.replace(/rgb|\(|\)/g, '');
      color = 'rgba(' + color + ', 0.1)';
      legend.chart.data.datasets[lastSelectedIndex].borderColor = color;
      // NOTE: 選択先の周波数の色を元に戻す
      color = legend.chart.data.datasets[selectedItemIndex].borderColor?.toString();
      color = color ? rgbaToRgb(color) : color;
      legend.chart.data.datasets[selectedItemIndex].borderColor = color;
    }
    legend.chart.update();
  };

  // NOTE: 凡例のホバーイベント処理
  const hoverLegendItem = (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) => {
    const selectedItemIndex: number = legendItem.datasetIndex ? legendItem.datasetIndex : 0;
    // NOTE：選択中の凡例がある場合なにもしない
    if (isCheckedLegendItems.find((element) => element == true) == true) {
      return;
    }
    legend.chart.data.datasets.forEach((value, index) => {
      if (index == selectedItemIndex) return;
      if (isCheckedLegendItems[index] == true) return;
      let color: string | undefined = legend.chart.data.datasets[index].borderColor?.toString();
      // NOTE: 選択中以外の線は薄める
      color = color?.replace(/rgb|\(|\)/g, '');
      color = 'rgba(' + color + ', 0.1)';
      legend.chart.data.datasets[index].borderColor = color;
    });
    legend.chart.update();
  };

  // NOTE: 凡例のリーブイベント処理
  const leaveLegendItem = (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) => {
    const selectedItemIndex: number = legendItem.datasetIndex ? legendItem.datasetIndex : 0;
    // NOTE：選択中の凡例がある場合なにもしない
    if (isCheckedLegendItems.find((element) => element == true) == true) {
      return;
    }
    legend.chart.data.datasets.forEach((value, index) => {
      if (index == selectedItemIndex) return;
      let color: string | undefined = legend.chart.data.datasets[index].borderColor?.toString();
      // NOTE: 色を初期状態に戻す
      color = color ? rgbaToRgb(color) : color;
      legend.chart.data.datasets[index].borderColor = color;
    });
    legend.chart.update();
  };

  // NOTE: ドラッグ終了イベント処理
  const onPanComplete = ({ chart }: { chart: ChartJS }) => {
    savedScaleXMax.current = chart.scales.x.max;
    savedScaleXMin.current = chart.scales.x.min;
    // NOTE：範囲外の場合のみ設定日時を変更する
    const startDate: dayjs.Dayjs = dateTime.add(-1 * Number(selectedSpan), 'second');
    const startDateNumber = startDate.toDate().getTime();
    const endDateNumber = dateTime.toDate().getTime();
    if (chart.scales.x.min < startDateNumber || chart.scales.x.max > endDateNumber) {
      setDateTime(dayjs(chart.scales.x.max));
      setDisplayDateTime(dayjs(chart.scales.x.max));
    }
  };

  // NOTE: ズーム完了イベント処理
  const onZoomComplete = ({ chart }: { chart: ChartJS }) => {
    savedScaleXMin.current = chart.scales.x.min;
    savedScaleXMax.current = chart.scales.x.max;
  };

  // NOTE: グラフの設定
  const options = {
    animation: {
      duration: 0, // アニメーション無効
    },
    responsive: true,
    plugins: {
      //凡例の設定
      legend: {
        onClick: clickLegendItem,
        onHover: hoverLegendItem,
        onLeave: leaveLegendItem,
        position: 'right' as const,
        labels: {
          color: '#fff',
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        intersect: false,
        mode: 'nearest' as const,
      },
      //ズーム
      zoom: {
        pan: {
          enabled: true,
          mode: 'x' as const,
          // ドラッグ開始時と終了時のイベントハンドリング
          onPanComplete: onPanComplete,
        },
        zoom: {
          onZoomComplete: onZoomComplete,
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        } as const,
      },
    },
    scales: {
      //x軸関連
      x: {
        type: 'time' as const,
        time: {
          tooltipFormat: 'YYYY/MM/DD HH:mm:ss',
          displayFormats: {
            second: 'HH:mm:ss',
            hour: 'HH:mm',
            minute: 'HH:mm',
          } as const,
        } as const,
        grid: {
          drawOnChartArea: false,
          color: '#fff', //borderの色
        },
        ticks: {
          maxTicksLimit: MAX_TICKS_LIMIT, // x軸ticksの数を指定
          display: true,
          color: '#fff',
          font: {
            size: 16,
          },
        },
        title: {
          display: true,
          text: t('time'),
          color: '#fff',
          font: {
            size: 16,
          },
        },
        min: dateTime
          .add(-1 * Number(selectedSpan), 'second')
          .toDate()
          .getTime(),
        max: dateTime.toDate().getTime(),
      },
      //y軸関連
      y: {
        grid: {
          drawOnChartArea: false,
          color: '#fff',
        },
        ticks: {
          color: '#fff',
          font: {
            size: 16,
          },
        },
        title: {
          display: true,
          text: t('Ratio[%]'),
          color: '#fff',
          font: {
            size: 16,
          },
        },
        min: yMin,
        max: yMax,
      },
      //y軸関連（凡例との間をあけるため）
      y1: {
        ticks: {
          color: '#000',
        },
        position: 'right' as const,
      },
    },
  };

  const graphData = getMaxRateGraphData(selectedFrequencies, data ? data : [], isMovingAverage, setting.movingAverage);
  const lineData = {
    datasets: graphData,
  };
  if (selectedFrequencies.length == 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20%',
        }}
      >
        <Typography variant='h4'>{t('観測したい周波数を選択してください')}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box justifyContent={'right'} display='flex'>
        <Stack direction='row' spacing={2}>
          <CustomIconButton
            Icon={ShowChartIcon}
            tooltipTitle={t('移動平均')}
            size='small'
            onClick={() => setIsMovingAverage((prev) => !prev)}
            isEnabled={isMovingAverage}
          />

          <CustomIconButton
            onClick={() => showTooltipAtExtremes()}
            Icon={QueryStatsIcon}
            tooltipTitle={t('ピーク検索')}
          />
          <CustomIconButton onClick={handleOpenDialog} Icon={HeightIcon} tooltipTitle={t('Y軸レンジ')} />
          <DialogYAxis
            open={openDialog}
            onClose={() => {
              if (chartRef.current) {
                chartRef.current.update();
              }
              setOpenDialog(false);
            }}
            title1='Ratio'
            initialMinValue1={yMin}
            initialMaxValue1={yMax}
            setYAxisMin1={setYMin}
            setYAxisMax1={setYMax}
            originalMin1={DEFAULT_OCCUPANCY_RANGE.MIN}
            originalMax1={DEFAULT_OCCUPANCY_RANGE.MAX}
          />
          <CustomIconButton
            onClick={handleZoomResetButtonClick}
            Icon={YoutubeSearchedForIcon}
            tooltipTitle={t('ズームリセット')}
          />
        </Stack>
      </Box>

      <CssVarsProvider>
        <AspectRatio variant='plain' ratio='2/1' sx={{ maxWidth: `${graphHeight * 0.9 * 2}px` }}>
          <Chart type='line' options={options} data={lineData} ref={chartRef} />
        </AspectRatio>
      </CssVarsProvider>
    </Box>
  );
};

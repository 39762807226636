import { ChartDataset } from 'chart.js';
import {
  CHANNEL_GROUP_2_4G,
  FREQUENCIES,
  FREQUENCY_BAND,
  FrequencyChannelType,
  FrequencyType,
} from '../domain/types/frequency';
import {
  SettingType,
  LevelThresholdType,
  NoiseDataSourceType,
  NOISE_DATA_SOURCE,
  NoiseLevelType,
  DataSourceType,
} from '../domain/types/setting';
import {
  BasePinType,
  FreqData,
  HeatMapDataType,
  HeatPointType,
  MAIN_MAP,
  MAIN_MAP_ID,
  MapObjType,
  MapPinType,
  PIN_TYPE,
  PinData,
  PointType,
} from '../domain/types/map';
import { DATA_SOURCE, WorkModeType } from '../domain/types/common/consts';
import { RmsData } from '../domain/types/rms';
import {
  LineGraphData,
  TimeData,
  DeviceTimeData,
  DEVICE_GRAPH_TITLE,
  DEFAULT_POINT_RADIUS,
  ChartDataMaxMinIndex,
  ChartDataIndex,
} from '../domain/types/graph';
import { RSSI, RATE, RssiData } from '../domain/types/rssi';
import { DeviceData } from '../domain/types/device';
import { COLOR_BAR } from '../domain/types/color';
import { SensorUnitData } from '../core/entities/areaMaster';
import { UNIT_TYPE_RWM } from '../domain/types/sensorUnit';
import { formatGraphFrequencyLabel } from './format';
import {
  calcLatPerPixel,
  calcLngPerPixel,
  convertActualPosition,
  convertCorrectionPosition,
  convertLatLngToPixel,
  mergeUnique,
} from './transform';
import { DraggableBounds } from 'react-draggable';
import { DeviceInfo, DeviceConfig, JsonRms, DEFINE_VALUE, JsonRmsRssiData } from '../domain/types/serial';

/**
 * @description
 *  CHグループリストの範囲に含まれる周波数を取得する関数 \
 *  無線 LAN 2.4 でのみ利用可能
 * @param {FrequencyChannelType} channelGroup 対象のCHグループ
 * @param {FrequencyType[]} targetFrequencies 対象の周波数リスト
 * @returns {FrequencyType[]} 対象の周波数のリスト
 */
export const getFrequenciesIncludeChannelGroup = (
  channelGroup: FrequencyChannelType,
  targetFrequencies: FrequencyType[]
): FrequencyType[] => {
  const minFreq = channelGroup.min || channelGroup.frequency;
  const maxFreq = channelGroup.max || channelGroup.frequency;
  return targetFrequencies.filter(
    (frequency: FrequencyType) => frequency.frequency >= minFreq && frequency.frequency <= maxFreq
  );
};

/**
 * @description ある CH グループに含まれる周波数の内、選択中の周波数リストが全て選択されているか判定する関数
 * @param {FrequencyType[]} selectedFrequencies 選択中の周波数リスト
 * @returns {boolean[]} CH 表示切替時のチェックボックスのステート配列
 */
export const judgeSelectedFrequencyListIncludeChannelGroup = (selectedFrequencies: FrequencyType[]): boolean[] => {
  const checkedStateList: boolean[] = new Array(CHANNEL_GROUP_2_4G.length).fill(false);
  CHANNEL_GROUP_2_4G.forEach((channelGroup: FrequencyChannelType, index: number) => {
    const frequencyListInChannelGroup = getFrequenciesIncludeChannelGroup(
      channelGroup,
      FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list
    );
    const frequencyListInSelectedFrequencyList = getFrequenciesIncludeChannelGroup(channelGroup, selectedFrequencies);
    checkedStateList[index] =
      frequencyListInChannelGroup.length === frequencyListInSelectedFrequencyList.length ? true : false;
  });

  return checkedStateList;
};

/**
 * @description
 *  CHグループの選択解除した際に、CH グループに含まれる周波数を排除する関数 \
 *  ロジック : \
 *  ・既に選択中のCHグループ CH(m) ・・・ CH(n) (Ch(1), Ch(2), Ch(9), etc...) に含まれる周波数を全てカウント
 *  ・選択したCHグループ CH(x) (CH(2)) に含まれる周波数を、カウントした値から引く
 *  ・周波数毎に値をカウントしているが、カウントした値が 1 未満の場合、削除対象とする
 * @param {FrequencyChannelType[]} selectedChannelGroups CH(n) ~ CH(m) の値
 * @param {FrequencyChannelType} targetChannelGroup CH-B の値
 * @param {FrequencyType[]} selectedFrequencies 選択中の周波数リスト
 * @returns {FrequencyType[]} 削除対象を排除した現在選択中の周波数リスト
 */
export const getRemoveFrequencies = (
  selectedChannelGroups: FrequencyChannelType[],
  targetChannelGroup: FrequencyChannelType,
  selectedFrequencies: FrequencyType[]
): FrequencyType[] => {
  const frequencyCount: { [key: number]: number } = {};
  const selectedFrequencyCounts: number[] = [];

  // 選択中の CH グループのリストから周波数をカウント
  selectedChannelGroups.forEach((channelGroup: FrequencyChannelType) => {
    const frequencies: number[] = getFrequenciesIncludeChannelGroup(
      channelGroup,
      FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list
    ).map((frequency: FrequencyType) => frequency.frequency);
    selectedFrequencyCounts.push(...frequencies);
  });

  selectedFrequencyCounts.forEach((frequency: number) => {
    frequencyCount[frequency] = (frequencyCount[frequency] || 0) + 1;
  });

  // カウントした値から該当する周波数分を引く
  const targetFrequencyCounts: number[] = getFrequenciesIncludeChannelGroup(
    targetChannelGroup,
    FREQUENCIES[FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID].list
  ).map((frequency: FrequencyType) => frequency.frequency);

  targetFrequencyCounts.forEach((frequency: number) => {
    if (frequencyCount[frequency]) {
      frequencyCount[frequency]--;
    }
  });

  // カウントした値が 1 以下の周波数を現在選択中の周波数リストから排除する
  const removedFrequencyKeys: string[] = Object.keys(frequencyCount).filter((key) => frequencyCount[Number(key)] < 1);
  const removedFrequencies: FrequencyType[] = selectedFrequencies.filter(
    (frequency: FrequencyType) => !removedFrequencyKeys.includes(String(frequency.frequency))
  );

  return removedFrequencies;
};

/**
 * @description 周波数バンドから設定に含まれるレベル判別閾値タブの情報を取得する関数
 * @param {SettingType} setting 設定情報
 * @param {string} frequencyBand 周波数バンド
 * @returns {LevelThresholdType|undefined} 指定された周波数バンドのレベル判別閾値タブのオブジェクト
 */
export const getLevelThresholdFromFrequencyBand = (setting: SettingType, frequencyBand: string) => {
  return setting.levelThresholdList.find((val) => val.frequencyBand == frequencyBand);
};

/**
 * @description ノイズデータソース取得する関数
 * @param {LevelThresholdType} levelThreshold レベル判別閾値オブジェクト
 * @param {NoiseDataSourceType} noiseDataSourceType ノイズデータソース
 * @returns {NoiseLevelType} ノイズのレベル判別閾値上下
 */
export const getNoiseLevelFromNoiseDataSource = (
  levelThreshold: LevelThresholdType,
  noiseDataSourceType: NoiseDataSourceType
): NoiseLevelType => {
  if (noiseDataSourceType == NOISE_DATA_SOURCE.RSSIMAX) {
    return {
      bottomNoiseLevel: levelThreshold.bottomNoiseLevelRssiMax,
      topNoiseLevel: levelThreshold.topNoiseLevelRssiMax,
    };
  } else {
    return {
      bottomNoiseLevel: levelThreshold.bottomNoiseLevelRssiMin,
      topNoiseLevel: levelThreshold.topNoiseLevelRssiMin,
    };
  }
};

/**
 * @description 選択中の周波数からRssiグラフデータを取得する関数
 * @param {FrequencyType[]} selectedFrequencies 選択中の周波数
 * @param {RmsData[]} rmsDataList RMSデータリスト
 * @param {string} noiseDataSource ノイズデータソース
 * @param {boolean} isMovingAverage 移動平均
 * @param {number} movingAverageParam 移動平均パラメータ
 * @returns {LineGraphData[]} 折れ線グラフ用データ
 */
export const getRssiGraphData = (
  selectedFrequencies: FrequencyType[],
  rmsDataList: RmsData[],
  noiseDataSource: string,
  isMovingAverage: boolean,
  movingAverageParam: number
): LineGraphData[] => {
  const ret: LineGraphData[] = [];
  selectedFrequencies
    .sort((a, b) => a.frequency - b.frequency)
    .forEach((frequency, index) => {
      let data = getRssiGraphTimeData(rmsDataList, frequency, noiseDataSource);
      if (isMovingAverage) {
        data = getMovingAverageTimeSeries(data, movingAverageParam);
      }
      const tmp: LineGraphData = {
        label: formatGraphFrequencyLabel(frequency),
        data: data,
        yAxisID: 'y',
        pointRadius: data.length == 1 ? DEFAULT_POINT_RADIUS : 0,
      };
      ret[index] = tmp;
    });
  return ret;
};

/**
 * @description Rssi用時系列データを取得する関数
 * @param {RmsData[]} rmsDataList RMSデータリスト
 * @param {FrequencyType[]} frequencyType 周波数
 * @returns {TimeData[]} 時系列データ
 */
export const getRssiGraphTimeData = (
  rmsDataList: RmsData[],
  frequencyType: FrequencyType,
  noiseDatasource: string
): TimeData[] => {
  const ret: TimeData[] = [];

  rmsDataList.forEach((rmsData) => {
    const rssiData: RssiData | undefined = rmsData.rssiDataList.find(
      (rssi) => rssi.frequency == frequencyType.frequency
    );
    if (rssiData == undefined) return;

    const rssi: number = noiseDatasource == NOISE_DATA_SOURCE.RSSIMAX ? rssiData.max : rssiData.min;
    if (rssi == RSSI.NONE) return;

    ret.push({
      x: rmsData.date.toDate(),
      y: rssi,
    });
  });
  return ret;
};

/**
 * @description 選択中の周波数から上限割合グラフデータを取得する関数
 * @param {FrequencyType[]} selectedFrequencies 選択中の周波数
 * @param {RmsData[]} rmsDataList RMSデータリスト
 * @param {boolean} isMovingAverage 移動平均
 * @param {number} movingAverageParam 移動平均パラメータ
 * @returns {LineGraphData[]} 折れ線グラフ用データ
 */
export const getMaxRateGraphData = (
  selectedFrequencies: FrequencyType[],
  rmsDataList: RmsData[],
  isMovingAverage: boolean,
  movingAverageParam: number
): LineGraphData[] => {
  const ret: LineGraphData[] = [];
  selectedFrequencies
    .sort((a, b) => a.frequency - b.frequency)
    .forEach((frequency, index) => {
      let data = getMaxRateGraphTimeData(rmsDataList, frequency);
      if (isMovingAverage) {
        data = getMovingAverageTimeSeries(data, movingAverageParam);
      }
      const tmp: LineGraphData = {
        label: formatGraphFrequencyLabel(frequency),
        data: data,
        yAxisID: 'y',
        pointRadius: data.length == 1 ? DEFAULT_POINT_RADIUS : 0,
      };
      ret[index] = tmp;
    });
  return ret;
};

/**
 * @description 上限割合用時系列データを取得する関数
 * @param {RmsData[]} rmsDataList RMSデータリスト
 * @param {FrequencyType[]} frequencyType 周波数
 * @returns {TimeData[]} 時系列データ
 */
export const getMaxRateGraphTimeData = (rmsDataList: RmsData[], frequencyType: FrequencyType): TimeData[] => {
  const ret: TimeData[] = [];
  rmsDataList.forEach((rmsData) => {
    const rssiData: RssiData | undefined = rmsData.rssiDataList.find(
      (rssiData) => rssiData.frequency == frequencyType.frequency
    );
    if (rssiData == undefined) return;

    if (rssiData.upperRate == RATE.NONE) return;

    ret.push({
      x: rmsData.date.toDate(),
      y: rssiData.upperRate,
    });
  });
  return ret;
};

/**
 * @description デバイスグラフデータを取得する関数
 * @param {FrequencyType[]} selectedFrequencies 選択中の周波数
 * @param {RmsData[]} rmsDataList RMSデータリスト
 * @param {boolean} isMovingAverage 移動平均
 * @param {number} movingAverageParam 移動平均パラメータ
 * @returns {LineGraphData[]} 折れ線グラフ用データ
 */
export const getDeviceGraphData = (
  deviceDataList: DeviceData[],
  yTitle: string,
  y1Title: string,
  isMovingAverage: boolean,
  movingAverageParam: number
): LineGraphData[] => {
  const deviceGraphTimeData = getDeviceGraphTimeData(
    deviceDataList,
    yTitle,
    y1Title,
    isMovingAverage,
    movingAverageParam
  );
  const yData: LineGraphData = {
    label: yTitle,
    data: deviceGraphTimeData[yTitle],
    yAxisID: 'y',
    pointRadius: deviceGraphTimeData[yTitle].length == 1 ? DEFAULT_POINT_RADIUS : 0,
  };

  const y1Data: LineGraphData = {
    label: y1Title,
    data: deviceGraphTimeData[y1Title],
    yAxisID: 'y1',
    pointRadius: deviceGraphTimeData[y1Title].length == 1 ? DEFAULT_POINT_RADIUS : 0,
  };
  return [yData, y1Data];
};

/**
 * @description デバイスグラフ用時系列データを取得する関数
 * @param {DeviceData[]} deviceDataList デバイスデータリスト
 * @param {string} yTitlel y軸タイトル
 * @param {string} y1Titlel y1軸タイトル
 * @param {boolean} isMovingAverage 移動平均
 * @param {number} movingAverageParam 移動平均パラメータ
 * @returns {DeviceTimeData[]} デバイスグラフ用時系列データ
 */
export const getDeviceGraphTimeData = (
  deviceDataList: DeviceData[],
  yTitlel: string,
  y1Title: string,
  isMovingAverage: boolean,
  movingAverageParam: number
): DeviceTimeData => {
  const yLabelDataList: TimeData[] = [];
  const y1LabelDataList: TimeData[] = [];

  let count: number = 0;
  deviceDataList.forEach((deviceData) => {
    yLabelDataList[count] = {
      x: deviceData.date.toDate(),
      y: getDeviceGraphYValue(yTitlel, deviceData),
    };
    y1LabelDataList[count] = {
      x: deviceData.date.toDate(),
      y: getDeviceGraphYValue(y1Title, deviceData),
    };
    count++;
  });
  const ret: DeviceTimeData = {
    [yTitlel]: isMovingAverage ? getMovingAverageTimeSeries(yLabelDataList, movingAverageParam) : yLabelDataList,
    [y1Title]: isMovingAverage ? getMovingAverageTimeSeries(y1LabelDataList, movingAverageParam) : y1LabelDataList,
  };
  return ret;
};

/**
 * @description デバイスグラフ用y軸タイトルから利用する値を取得する関数
 * @param {string} yTitle y軸タイトル名
 * @param {DeviceData} deviceData デバイスデータ
 * @returns {TimeData[]} 時系列データ
 */
export const getDeviceGraphYValue = (yTitle: string, deviceData: DeviceData): number => {
  let ret: number = 0;
  if (yTitle == DEVICE_GRAPH_TITLE.VOLTAGE) {
    ret = deviceData.powerSupplyVoltage;
  } else if (yTitle == DEVICE_GRAPH_TITLE.TEMPERATURE) {
    ret = deviceData.temperature;
  } else if (yTitle == DEVICE_GRAPH_TITLE.HEIGHT) {
    ret = deviceData.height;
  }
  return ret;
};

/**
 * @description 設定値から閾値を取得する処理
 * @param {SettingType} setting 設定値を格納したオブジェクト
 * @param {string} frequencyBand 選択中の周波数バンド
 * @param {string} dataSource 選択中の
 * @returns
 */
export const getLevelSetting = (setting: SettingType, frequencyBand: string, dataSource: string) => {
  const dataSourceType: NoiseDataSourceType = setting.noiseDataSource;
  const level: LevelThresholdType = setting.levelThresholdList.filter(
    (level) => level.frequencyBand === frequencyBand
  )[0];

  let max: number = 1;
  let min: number = 0;

  if (dataSource === DATA_SOURCE.NOISE) {
    if (dataSourceType === NOISE_DATA_SOURCE.RSSIMAX) {
      max = level.topNoiseLevelRssiMax;
      min = level.bottomNoiseLevelRssiMax;
    } else {
      max = level.topNoiseLevelRssiMin;
      min = level.bottomNoiseLevelRssiMin;
    }
  } else {
    max = level.topOccupancyLevel;
    min = level.bottomOccupancyLevel;
  }

  return { max, min };
};

/**
 * @description 定数として指定している色情報を取得する処理
 * @param {number} index 色配列のインデックス
 * @param {number} alpha 0 ~ 1 の透明度 (未入力なら 1)
 * @param {number[]} 色情報の数列
 */
export const getColorRGBA = (index: number, alpha?: number, colorList: number[][] = COLOR_BAR): number[] => {
  if (isNaN(index)) {
    index = 0;
  }
  const rgba = colorList[index];

  if (alpha !== undefined) {
    if (alpha < 0) alpha = 0;
    else if (alpha > 1) alpha = 1;

    rgba[3] = alpha;
  } else {
    rgba[3] = 1;
  }

  return rgba;
};

/**
 * @description 入力値の値 (0 ~ 1) に対応した色情報のインデックスを取得する処理
 * @param {number} value 0 ~ 1 の値
 * @param {number} 色情報のインデックス
 */
export const getColorIndex = (value: number, colorList: number[][] = COLOR_BAR): number => {
  let index = Math.floor(value * (colorList.length - 1));
  if (index < 0) index = 0;
  if (index > colorList.length - 1) index = colorList.length - 1;

  return index;
};

/**
 * @description 配列の各要素である連想配列のプロパティが指定した条件を満たすか判定し、bool 値とインデックスを返す処理 \
 *  条件に一致する要素が複数存在する場合、最初に一致した要素のインデックスを返す
 * @param {T[]} array 対象の配列
 * @param {K} key 対象とするプロパティ名
 * @param {} condition 条件式
 * @returns {[boolean, number]} 判定結果とインデックス (存在しない場合はインデックスは -1 を返す)
 */
export const findFirstMatchWithCondition = <T, K extends keyof T>(
  array: T[],
  key: K,
  condition: (value: T[K]) => boolean
): [boolean, number] => {
  const index = array.findIndex((item) => condition(item[key]));
  return [index !== -1, index];
};

/**
 * @description ピンの周波数データから選択中の周波数リストに存在する値を抽出する処理
 * @param {FreqData[]} freqDataLists ピンの周波数データ
 * @param {FrequencyBandType[]} selectedFrequencies 選択中の周波数リスト
 * @param {DataSourceType} dataSource ソートの対象とするデータソース
 * @returns {{[key:string]:number | undefined}} 対象のピンの周波数データ(最大値)
 *  {frequency: number, noise:number,  }
 */
export const getDisplayedFrequencyData = (
  freqDataLists: FreqData[],
  selectedFrequencies: FrequencyType[],
  dataSource: DataSourceType
): { [key: string]: number | undefined } => {
  let sortKey = 'occupancy';
  if (dataSource === 'NOISE') sortKey = 'noise';
  else sortKey = 'occupancy';

  // NOTE: freqDataList と frequencyBands で同じ周波数の要素のみを抽出し結合
  const mergedData: { [key: string]: number | undefined }[] = freqDataLists
    .map((a) => {
      // TODO: DB 側のデータ型が変わった為、判定処理に型判定を除去
      const matchedFrequencies = selectedFrequencies.find((b) => b.frequency == a.freq);
      if (matchedFrequencies) {
        return { ...a, channel: matchedFrequencies.channel };
      }
      return { ...a, channel: -1 };
    })
    .filter((a) => a.channel !== -1);

  if (mergedData.length < 1) return { frequency: undefined, value: undefined, channel: undefined };
  mergedData.sort((a, b) => {
    if ((a[sortKey] as number) === (b[sortKey] as number)) return (a.freq as number) - (b.freq as number);
    return (b[sortKey] as number) - (a[sortKey] as number);
  });

  return { frequency: mergedData[0].freq, value: mergedData[0][sortKey], channel: mergedData[0].channel };
};

/**
 * @description マップの原点 (左上、北西) の座標を取得する処理
 * @param {google.maps.LatLng} ne マップの北東の境界座標
 * @param {google.maps.LatLng} sw マップの南西の境界座標
 * @returns {google.maps.LatLng} マップの原点座標
 */
export const getLatLngOrigin = (ne: google.maps.LatLng, sw: google.maps.LatLng): google.maps.LatLng => {
  return new google.maps.LatLng({ lat: ne.lat(), lng: sw.lng() });
};

/**
 * @description マップ ID のリストを取得する処理
 * @param {MapObjType[]} maps マップデータのリスト
 * @returns {string[]} マップの ID リスト
 */
export const getMapIds = (maps: MapObjType[]): string[] => {
  const ids = maps.map((map) => map.mapId);
  return ids;
};

/**
 * @description 対象のマップのラベルを取得する処理 (メインマップのフロアピンから取得)
 * @param {string} mapId 取得対象のマップ ID
 * @returns {string} ラベル文字列
 */
export const getMapLabel = (maps: MapObjType[], mapId: string): string => {
  const map = maps.find((map) => map.mapId === MAIN_MAP_ID);
  if (map) {
    const pin = map.pins.find((pin) => pin.pinId === mapId);
    if (pin) return pin.label !== '' ? pin.label : pin.pinId;
  }
  return 'No label';
};

/**
 * @description ピン ID のリストを取得する処理
 * @param {BasePinType[]} pins ピンデータのリスト
 * @returns {string[]} ピンの ID リスト
 */
export const getPinIds = (pins: BasePinType[]) => {
  const ids = pins.map((pins) => pins.pinId);
  return ids;
};

/**
 * @description マップデータから対象のピンデータを取得する処理
 * @param {MapObjType} map マップデータ
 * @param {string} pinId 取得対象のピン ID
 * @returns {BasePinType | MapPinType} ピンデータ
 */
export const getTargetPinData = (map: MapObjType, pinId: string): BasePinType | MapPinType => {
  return map.pins.filter((pin) => pin.pinId === pinId)[0];
};

/**
 * @description ピン候補の ID リストを取得する処理
 * @param {SensorUnitData[]} units デバイスデータのリスト
 * @returns {string[]} ピン候補の ID リスト
 */
export const getCandidatePinIds = (units: SensorUnitData[]): string[] => {
  const ids = units
    .map((unit) => {
      if (unit.devType == UNIT_TYPE_RWM) {
        return unit.devId;
      }
    })
    .filter((id): id is string => id !== undefined);
  return ids;
};

/**
 * @description 登録済みのピンをtrueとして状態配列を取得する関数
 * @param {WorkModeType} workMode 実行モード
 * @param {string[]} allPinIds ピン候補含めたすべてのピン ID リスト
 * @param {string[]} registerPinIds DB 登録済みのピン ID リスト
 * @returns {boolean[]} 登録済みピンの状態配列
 */
export const getPinCheckBoxStates = (
  workMode: WorkModeType,
  allPinIds: string[],
  registerPinIds: string[]
): boolean[] => {
  const states: boolean[] = new Array(allPinIds.length).fill(false);
  if (states.length > 0) {
    if (workMode == 'Graph') {
      states[0] = true;
    } else if (workMode == 'Map') {
      allPinIds.forEach((id, index) => {
        if (registerPinIds.find((_id) => _id == id)) {
          states[index] = true;
        }
      });
    }
  }

  return states;
};

/**
 * @description ヒートマップ用のデータを整形し返す処理
 * @param {HeatPointType[]} heatPoints 表示中ピンの座標 / 測定値のリスト
 * @returns {HeatMapDataType} ヒートマップデータ
 */
export const getHeatMapData = (heatPoints: HeatPointType[]): HeatMapDataType => {
  const xs = [...heatPoints].map((heatPoint) => heatPoint.x);
  const ys = [...heatPoints].map((heatPoint) => heatPoint.y);

  const x = { max: Math.max(...xs), min: Math.min(...xs) };
  const y = { max: Math.max(...ys), min: Math.min(...ys) };

  return { x, y, points: heatPoints };
};

/**
 * @description IoT データの緯度経度情報を取得する処理
 * @param {PinData[]} pinData 測定した IoT データ
 * @param {string} pinId 取得対象のピン ID
 * @returns {{ lat: number; lng: number } | undefined} 対象のピンの緯度経度
 */
export const getGpsInfo = (pinData: PinData[], pinId: string): { lat: number; lng: number } | undefined => {
  const _pin = pinData.filter((data) => data.unitId === pinId);
  if (_pin.length > 0) {
    return {
      lat: _pin[0].lat,
      lng: _pin[0].lng,
    };
  }
  return undefined;
};

/**
 * @description 登録済みのピンを取得し、メインマップ以外ではフロアピンを排除する
 * @param {MapObjType[]} mapsData マップデータのリスト
 * @param {string} selectedMapId 選択中のマップ ID
 * @param {string[]} candidatePinIds ピン候補の ID リスト
 * @returns {ResultType} 登録済みピン ID リストとすべてのピン ID リスト
 */
type ResultType = {
  registeredPinIds: string[];
  allPinIds: string[];
};
export const getPinsAndIds = (mapsData: MapObjType[], selectedMapId: string, candidatePinIds: string[]): ResultType => {
  const result: ResultType = { registeredPinIds: [], allPinIds: [] };
  const map = mapsData.find((map) => map.mapId === selectedMapId);
  if (map) {
    let pins = map.pins;
    if (selectedMapId !== MAIN_MAP_ID) {
      pins = pins.filter((pin) => pin.pinType !== PIN_TYPE.GroupPin);
    }
    result.registeredPinIds = getPinIds(pins);
    result.allPinIds = mergeUnique(result.registeredPinIds, candidatePinIds);
  }
  return result;
};

/**
 * @description 選択中のマップデータを取得
 * @param {MapObjType[]} mapsData マップデータのリスト
 * @param {string} selectedMapId 取得対象のマップ ID
 * @returns {MapObjType} 対象のマップデータ
 */
export const getSelectedMap = (mapsData: MapObjType[], selectedMapId: string) => {
  return mapsData.find((map) => map.mapId === selectedMapId) || MAIN_MAP;
};

/**
 * @description Google map 上でのピンのピクセル位置を取得する処理
 * @param {number} lat 緯度
 * @param {number} lng 経度
 * @param {number} latPerPixel 1ピクセル当たりの緯度
 * @param {number} lngPerPixel 1ピクセル当たりの経度
 * @param {google.maps.LatLng} ne Google map の境界点 (北東)
 * @param {google.maps.LatLng} sw Google map の境界点 (南西)
 * @param {DraggableBounds} bounds ピンの移動可能範囲
 * @param {number} pinSize ピンのサイズ
 * @returns {[key:string] : number} ピンのピクセル位置
 */
export const getPinPosition = (
  lat: number,
  lng: number,
  latPerPixel: number,
  lngPerPixel: number,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  bounds: DraggableBounds,
  pinSize: number
) => {
  const latLng = new google.maps.LatLng({ lat, lng });
  const origin = getLatLngOrigin(ne as google.maps.LatLng, sw as google.maps.LatLng);
  const position = convertActualPosition(convertLatLngToPixel(latPerPixel, lngPerPixel, latLng, origin), pinSize);

  let x = position.x;
  let y = position.y;
  if (latLng.lat() > ne.lat() && bounds.top) y = bounds.top;
  if (latLng.lat() < sw.lat() && bounds.bottom) y = bounds.bottom;
  if (latLng.lng() > ne.lng() && bounds.right) x = bounds.right;
  if (latLng.lng() < sw.lng() && bounds.left) x = bounds.left;

  return { x, y };
};

/**
 * @description 画像イメージ上のピンのピクセル位置を取得する処理
 * @param {number} pinLat ピンの緯度
 * @param {number} pinLng ピンの経度
 * @param {google.maps.LatLng} ne Google map の境界点 (北東)
 * @param {google.maps.LatLng} sw Google map の境界点 (南西)
 * @param {number} mapWidth マップ全体の幅
 * @param {number} mapHeight マップ全体の高さ
 * @param {number} imgLat 画像の緯度
 * @param {number} imgLng 画像の経度
 * @param {number} imgWidth 画像の幅
 * @param {number} imgHeight 画像の高さ
 * @param {number} imgRotate 画像の回転角度
 * @param {number} pinSize ピンのサイズ
 * @returns {[key:string] : number} ピンのピクセル位置
 */
export const getImagePinPosition = (
  pinLat: number,
  pinLng: number,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  mapWidth: number,
  mapHeight: number,
  imgLat: number,
  imgLng: number,
  imgWidth: number,
  imgHeight: number,
  imgRotate: number,
  pinSize: number
) => {
  // 1ピクセル当たりの緯度経度
  const latPerPixel = calcLatPerPixel(mapHeight, ne, sw);
  const lngPerPixel = calcLngPerPixel(mapWidth, ne, sw);

  // NOTE: 元の rotate が時計回りの為、符号を反転させている
  const radians = -imgRotate * (Math.PI / 180);
  const p = { x: imgWidth / 2 + (pinLng - imgLng) / lngPerPixel, y: imgHeight / 2 + (pinLat - imgLat) / latPerPixel };
  const rp = rotatePoint(p, { x: imgWidth / 2, y: imgHeight / 2 }, radians);

  const position = convertActualPosition(
    {
      x: (rp.x * mapWidth) / imgWidth,
      y: (rp.y * mapHeight) / imgHeight,
    },
    pinSize
  );

  if (position.x <= pinSize) position.x = pinSize;
  if (position.x >= mapWidth - pinSize * 2) position.x = mapWidth - pinSize * 2;
  if (position.y <= pinSize) position.y = pinSize;
  if (position.y >= mapHeight - pinSize * 2) position.y = mapHeight - pinSize * 2;

  return position;
};

/**
 * @description 画像イメージ上のピンの緯度経度を取得する処理
 * @param {number} pinX ピンのピクセル位置
 * @param {number} pinY ピンのピクセル位置
 * @param {google.maps.LatLng} ne Google map の境界点 (北東)
 * @param {google.maps.LatLng} sw Google map の境界点 (南西)
 * @param {number} mapWidth マップ全体の幅
 * @param {number} mapHeight マップ全体の高さ
 * @param {number} imgLat 画像の緯度
 * @param {number} imgLng 画像の経度
 * @param {number} imgWidth 画像の幅
 * @param {number} imgHeight 画像の高さ
 * @param {number} imgRotate 画像の回転角度
 * @param {number} pinSize ピンのサイズ
 * @returns
 */
export const getImagePinLatLng = (
  pinX: number,
  pinY: number,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  mapWidth: number,
  mapHeight: number,
  imgLat: number,
  imgLng: number,
  imgWidth: number,
  imgHeight: number,
  imgRotate: number,
  pinSize: number
) => {
  const position = convertCorrectionPosition({ x: pinX, y: pinY }, pinSize);
  // NOTE: 元の rotate が時計回りの為、符号を反転させている
  const radians = -imgRotate * (Math.PI / 180);
  const p = {
    x: (position.x * imgWidth) / mapWidth,
    y: (position.y * imgHeight) / mapHeight,
  };
  const rp = rotatePoint(p, { x: imgWidth / 2, y: imgHeight / 2 }, radians);

  // 1ピクセル当たりの緯度経度
  const latPerPixel = calcLatPerPixel(mapHeight, ne, sw);
  const lngPerPixel = calcLngPerPixel(mapWidth, ne, sw);

  const lat = imgLat + (rp.y - imgHeight / 2) * latPerPixel;
  const lng = imgLng + (rp.x - imgWidth / 2) * lngPerPixel;

  return { lat, lng };
};

/**
 * @description 座標の回転処理
 * @param {PointType} a 回転対象の座標
 * @param {PointType} center 回転中心座標
 * @param {number} radians 回転角度
 * @returns {[key:string] : number} 回転後の座標
 */
export const rotatePoint = (a: PointType, center: PointType, radians: number) => {
  const rpx = (a.x - center.x) * Math.cos(radians) + (a.y - center.y) * Math.sin(radians) + center.x;
  const rpy = (a.x - center.x) * Math.sin(radians) - (a.y - center.y) * Math.cos(radians) + center.y;
  return { x: rpx, y: rpy };
};

/**
 * @description x, y 座標のオブジェクトを要素とする配列を生成する関数
 * @param {number} maxX x の最大幅
 * @param {number} maxY y の最大幅
 * @param {number} pixelSize ステップ数 (指定した数値分、スキップする)
 * @returns 配列
 */
export const createArray = (maxX: number, maxY: number, pixelSize: number) => {
  const result = [];

  if (pixelSize < 1) return [];

  for (let x = 0; x < maxX; x += pixelSize) {
    for (let y = 0; y < maxY; y += pixelSize) {
      result.push({ x, y });
    }
  }

  return result;
};

/**
 * @description Google map 上での画像イメージのピクセル位置を取得する処理
 * @param {number} imgLat 画像の緯度
 * @param {number} imgLng 画像の経度
 * @param {number} mapWidth マップ全体の幅
 * @param {number} mapHeight マップ全体の高さ
 * @param {google.maps.LatLng} ne Google map の境界点 (北東)
 * @param {google.maps.LatLng} sw Google map の境界点 (南西)
 * @param {DraggableBounds} bounds 画像の移動可能範囲
 * @returns {[key:string] : number} 画像のピクセル位置
 */
export const getImagePosition = (
  lat: number,
  lng: number,
  mapWidth: number,
  mapHeight: number,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  bounds: DraggableBounds
) => {
  const latLng = new google.maps.LatLng({ lat, lng });
  const origin = getLatLngOrigin(ne as google.maps.LatLng, sw as google.maps.LatLng);

  // 1ピクセル当たりの緯度経度
  const latPerPixel = calcLatPerPixel(mapHeight, ne, sw);
  const lngPerPixel = calcLngPerPixel(mapWidth, ne, sw);

  const position = convertLatLngToPixel(latPerPixel, lngPerPixel, latLng, origin);

  let x = position.x;
  let y = position.y;
  if (latLng.lat() > ne.lat() && bounds.top) y = bounds.top;
  if (latLng.lat() < sw.lat() && bounds.bottom) y = bounds.bottom;
  if (latLng.lng() > ne.lng() && bounds.right) x = bounds.right;
  if (latLng.lng() < sw.lng() && bounds.left) x = bounds.left;

  return { x, y };
};

/**
 * @description 画像イメージの緯度経度を取得する処理
 * @param {number} imgX 画像のピクセル位置
 * @param {number} imgY 画像のピクセル位置
 * @param {google.maps.LatLng} ne Google map の境界点 (北東)
 * @param {google.maps.LatLng} sw Google map の境界点 (南西)
 * @param {number} mapWidth マップ全体の幅
 * @param {number} mapHeight マップ全体の高さ
 * @returns
 */
export const getImageLatLng = (
  imgX: number,
  imgY: number,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  mapWidth: number,
  mapHeight: number
) => {
  const origin = getLatLngOrigin(ne as google.maps.LatLng, sw as google.maps.LatLng);
  // 1ピクセル当たりの緯度経度
  const latPerPixel = calcLatPerPixel(mapHeight, ne, sw);
  const lngPerPixel = calcLngPerPixel(mapWidth, ne, sw);

  const lat = origin.lat() - imgY * latPerPixel;
  const lng = origin.lng() + imgX * lngPerPixel;

  return { lat, lng };
};

/**
 * @description USBシリアル通信デバイス情報の解析
 * @param {string} data 例
 * @returns
 */
export const analyzeUsbCmdDeviceInfo = (data: string): DeviceInfo | undefined => {
  const checkDataLength = 67;

  if (data.length !== checkDataLength) {
    console.error(`PIG Length error: ${data.length}、data: ${data}`);
    return undefined;
  }

  // コメントアウト部分は利用していない項目

  try {
    // 電波監視側SubGhzモジュール シリアルID
    // const rfMonSerialId = data.substring(3, 15);

    // 電波監視側SubGhzモジュール version
    // const rfMonVerHex = data.substring(15, 19);
    // const rfMonVer = parseInt(rfMonVerHex, 16).toString(16);

    // 送受信側SubGhzモジュール シリアルID
    const rfTrxSerialId = data.substring(19, 31);

    // 送受信側SubGhzモジュール version
    // const rfTrxVerHex = data.substring(31, 35);
    // const rfTrxVer = parseInt(rfTrxVerHex, 16).toString(16);

    // STM32WB シリアルID(BDアドレス)
    // const stm32wbSerialId = data.substring(35, 47);
    // STM32WB version
    // const stm32wbVerHex = data.substring(47, 51);
    // const stm32wbVer = parseInt(stm32wbVerHex, 16).toString();

    // 保存データページ数
    const saveDataPageNumStr = data.substring(51, 59);

    const saveDataPageNum = parseInt(saveDataPageNumStr, 16);
    console.log(`ページ数:${saveDataPageNum}`);
    if (saveDataPageNum > DEFINE_VALUE.SAVE_DATA_MAX_PAGE_NUM) {
      console.error(`save_data_page_num value error:${saveDataPageNum}`);
      return undefined;
    }
    console.log(`保存データページ数: ${saveDataPageNum}`);

    // 保存データステータス
    // const save_data_status_str=data. substring (59 ,2);
    // const save_data_status_i=parseInt(save_data_status_str ,16 );

    // 情報アップデート（仮想関数）
    // updateDeviceInfo (stm32wb_ver ,rf_trx_ver ,rf_trx_serial_id );
    // updateSavedDataInfo (saveDataPageNum ,save_data_status_i );
    const deviceInfo: DeviceInfo = { rfTrxSerialId: rfTrxSerialId, saveDataPageNum: saveDataPageNum };
    return deviceInfo;
  } catch (error) {
    console.error('Error parsing device info:', error);
    return undefined;
  }
};

/**
 * USBシリアル通信デバイス設定情報の解析
 * @param data 例
 * @returns
 */
export const analyzeUsbCmdDeviceConfig = (data: string): DeviceConfig | undefined => {
  const checkDataLength = 89;
  console.log('length', data.length);
  console.log('data', data);
  if (data.length !== checkDataLength) {
    console.error(`PCG Length error: ${data.length}`);
    return undefined;
  }
  try {
    // CH
    const ch = data.substring(3, 5);

    // Port
    const port = data.substring(5, 9);

    // PanID
    const panid = data.substring(9, 13);

    // my_id
    const myId = data.substring(13, 17);

    // Transmission Interval
    const reportIntervalStr = data.substring(17, 21);

    // リレー機能
    const routerStatusStr = data.substring(21, 23);

    // RSSI タイムアウト
    const rssiTimeoutStr = data.substring(23, 25);

    // データ保存機能
    const storageFunc = data.substring(27, 29);

    // 電波監視対象の除外
    const monitorExclusion = data.substring(29, 31);

    // 各バンドの周波数範囲と閾値

    const ism900MonFreq = data.substring(33, 41);

    const ism900MonUpperTh = data.substring(41, 43);

    const w24gMonFreq = data.substring(47, 55);

    const w24gMonUpperTh = data.substring(55, 57);

    const l5gMonFreq = data.substring(61, 69);

    const l5gMonUpperTh = data.substring(69, 71);

    const w5gMonFreq = data.substring(75, 83);
    const w5gMonUpperTh = data.substring(83, 85);

    const ret: DeviceConfig = {
      ch: ch,
      port: port,
      panid: panid,
      myId: myId,
      reportInterval: reportIntervalStr,
      routerStatus: routerStatusStr,
      rssiTime: rssiTimeoutStr,
      storageFunc: storageFunc,
      monitorExclusion: monitorExclusion,
      ism900MonFreq: ism900MonFreq,
      ism900MonUpperTh: ism900MonUpperTh,
      w24gMonFreq: w24gMonFreq,
      w24gMonUpperTh: w24gMonUpperTh,
      l5gMonFreq: l5gMonFreq,
      l5gMonUpperTh: l5gMonUpperTh,
      w5gMonFreq: w5gMonFreq,
      w5gMonUpperTh: w5gMonUpperTh,
    };

    return ret;
  } catch (error) {
    console.error('Error parsing USB config:', error);
    return undefined;
  }
};

/**
 * @description USBシリアル通信監視データの解析
 * @param {string} data デバイスからの応答
 * @returns {string} 解析結果
 */
export const analyzeUsbCmdSaveDataRead = (data: string): JsonRms | undefined => {
  const checkDataLengthShort = 5;
  const checkDataLengthLong = 77;
  console.log('length', data.length);
  console.log('data', data);
  if (data.length < checkDataLengthShort) {
    console.error(`PSG Length(short) error: ${data.length}`);
    return undefined;
  }

  // 状態応答チェック
  if (data.length === checkDataLengthShort) {
    const val = data.substring(3, 5);
    const valInt = parseInt(val, 16);
    console.log('PSG Result', valInt);
    // 0x00:データ異常（or データ無し）、0xFF：異常
    return undefined;
  }

  // Readデータ応答チェック
  if (data.length < checkDataLengthLong) {
    console.error(`PSG Length(long) error: ${data.length}`, data);
    return undefined;
  }

  try {
    // 保存データページ番号
    // const savePageNum = data.substring(3, 11);

    // ID
    const id = data.substring(11, 15);

    // Data Type & Version Masking
    const typeHex = data.substring(15, 17);
    const type = parseInt(typeHex, 16);
    const dataType = type & 0x0f;
    // const pktVer = type & 0xf0;

    // タイムスタンプ
    const datetimeStr = data.substring(17, 33);
    const utcUnixDatetime = parseInt(datetimeStr, 16);

    // JST変換
    // const datetime = new Date(utcUnixDatetime * 1000).toLocaleString();

    // 緯度&経度
    let latitudeValid = true;
    let longitudeValid = true;

    const latitudeStr = data.substring(33, 41);
    let latitude: number | undefined = undefined;
    if (latitudeStr === '80000000') {
      latitudeValid = false;
    }

    const longitudeStr = data.substring(41, 49);
    let longitude: number | undefined = undefined;
    if (longitudeStr === '80000000') {
      longitudeValid = false;
    }

    const locationValid = latitudeValid && longitudeValid;

    let hAcc: number | undefined = undefined;
    let vAcc: number | undefined = undefined;
    let hMSL: number | undefined = undefined;

    if (locationValid) {
      latitude = Math.abs(parseInt(latitudeStr, 16) / 10000000);
      longitude = Math.abs(parseInt(longitudeStr, 16) / 10000000);

      // hAcc vAcc hMSL 抽出と変換 */;
      hAcc = parseInt(data.substring(49, 51), 16);
      vAcc = parseInt(data.substring(51, 53), 16);
      hMSL = parseInt(data.substring(53, 57), 16);
    }

    // 電源電圧と温度抽出と変換 */
    const powerSupplyVoltage = (parseInt(data.substring(57, 61), 16) / 100).toFixed(2);

    const temperature = parseInt(data.substring(61, 63), 16);

    // 開始周波数の解析
    const radioDataNumArray = [
      DEFINE_VALUE.RADIO_DATA_ISM900_MAX_NUM,
      DEFINE_VALUE.RADIO_DATA_W2_4G_MAX_NUM,
      DEFINE_VALUE.RADIO_DATA_L5G_MAX_NUM,
      DEFINE_VALUE.RADIO_DATA_W5G_MAX_NUM,
    ];
    const startFreqStr = data.substring(63, 67);
    let startFreqD;
    let checkRadioDataNum;

    if (startFreqStr === DEFINE_VALUE.START_FREQ_ALL_CH_MONITOR) {
      const startFreqArray = [
        DEFINE_VALUE.RADIO_DATA_START_FREQ_ISM900,
        DEFINE_VALUE.RADIO_DATA_START_FREQ_W2_4G,
        DEFINE_VALUE.RADIO_DATA_START_FREQ_L5G,
        DEFINE_VALUE.RADIO_DATA_START_FREQ_W5G,
      ];
      startFreqD = startFreqArray[dataType];
      checkRadioDataNum = radioDataNumArray[dataType];
    } else {
      const startFreqInt = parseInt(startFreqStr, 16);
      startFreqD = startFreqInt / DEFINE_VALUE.START_FREQ_MULTIPLE;
      checkRadioDataNum = 1;

      if (startFreqD === DEFINE_VALUE.RADIO_DATA_FREQ_W2_4G_CH14) {
        checkRadioDataNum = DEFINE_VALUE.RADIO_DATA_W2_4G_CH14_NUM;
      }
    }

    // 周波数データの解析

    const radioDataNum = radioDataNumArray[dataType];
    const payloadStr = data.substring(67);
    const payloadLen = payloadStr.length;
    const radioDataLen = checkRadioDataNum * DEFINE_VALUE.SAVE_DATA_RADIO_DATA_EACH_LEN;

    if (payloadLen !== radioDataLen) {
      console.error(`AnalyzeRadioData() Radio Data Length error: ${payloadLen}`);
      return undefined;
    }

    const radioDataList = [];

    if (startFreqStr === DEFINE_VALUE.START_FREQ_ALL_CH_MONITOR) {
      for (let i = 0; i < radioDataNum; i++) {
        radioDataList.push(
          payloadStr.substring(
            i * DEFINE_VALUE.SAVE_DATA_RADIO_DATA_EACH_LEN,
            (i + 1) * DEFINE_VALUE.SAVE_DATA_RADIO_DATA_EACH_LEN
          )
        );
      }
    } else if (startFreqD === DEFINE_VALUE.RADIO_DATA_FREQ_W2_4G_CH14) {
      for (let i = 0; i < radioDataNum; i++) {
        if (i < DEFINE_VALUE.RADIO_DATA_W2_4G_CH1_13_NUM) {
          // 無効データ
          radioDataList.push(DEFINE_VALUE.SAVE_DATA_INVALID_RMS_DATA);
        } else {
          radioDataList.push(
            payloadStr.substring(
              (i - DEFINE_VALUE.RADIO_DATA_W2_4G_CH1_13_NUM) * DEFINE_VALUE.SAVE_DATA_RADIO_DATA_EACH_LEN,
              (i - DEFINE_VALUE.RADIO_DATA_W2_4G_CH1_13_NUM + 1) * DEFINE_VALUE.SAVE_DATA_RADIO_DATA_EACH_LEN
            )
          );
        }
      }
    } else {
      for (let i = 0; i < radioDataNum; i++) {
        if (startFreqD === DEFINE_VALUE.FREQ_TABLE[dataType][i]) {
          // 有効データ
          radioDataList.push(payloadStr.substring(0, DEFINE_VALUE.SAVE_DATA_RADIO_DATA_EACH_LEN));
        } else {
          // 無効データ
          radioDataList.push(DEFINE_VALUE.SAVE_DATA_INVALID_RMS_DATA);
        }
      }
    }

    let index = -1;
    const JsonRmsRssiDataList: JsonRmsRssiData[] = [];
    radioDataList.forEach((s) => {
      index++;
      const freq = DEFINE_VALUE.FREQ_TABLE[dataType][index];
      let uOverRate = '',
        rssiMax = '',
        rssiMin = '';

      if (s !== DEFINE_VALUE.SAVE_DATA_INVALID_RMS_DATA) {
        uOverRate = convertSaveDataRate(s.substring(0, 2));
        rssiMax = convertSaveDataRssi(s.substring(4, 6));
        rssiMin = convertSaveDataRssi(s.substring(6, 8));
      }

      const temp: JsonRmsRssiData = {
        frequency: freq,
        upperRate: parseFloat(uOverRate),
        lowerRate: NaN, //ES1はデバイスからこのデータが来てたが、量産はなくなった。
        max: parseFloat(rssiMax),
        min: parseFloat(rssiMin),
        average: NaN, //ES1はデバイスからこのデータが来てたが、量産はなくなった。
      };
      JsonRmsRssiDataList.push(temp);
    });

    //datatype
    const data_type = dataType & DEFINE_VALUE.TYPE_MASK;
    let dataTypeStr = '';
    switch (data_type) {
      case DEFINE_VALUE.TYPE_ISM900:
        dataTypeStr = DEFINE_VALUE.JSON_DATA_TYPE_ISM900;
        break;
      case DEFINE_VALUE.TYPE_W2_4G:
        dataTypeStr = DEFINE_VALUE.JSON_DATA_TYPE_W2_4G;
        break;
      case DEFINE_VALUE.TYPE_L5G:
        dataTypeStr = DEFINE_VALUE.JSON_DATA_TYPE_L5G;
        break;
      case DEFINE_VALUE.TYPE_W5G:
        dataTypeStr = DEFINE_VALUE.JSON_DATA_TYPE_W5G;
        break;
      case DEFINE_VALUE.TYPE_DEVICE_INFO:
        dataTypeStr = DEFINE_VALUE.JSON_DATA_TYPE_DEVICEINFO;
        break;
      default:
        break;
    }

    const rmsData: JsonRms = {
      deviceId: id,
      timestamp: utcUnixDatetime * 1000, //.toString(),//datetime,
      rssi: 0, //Usb経由の場合は0でOK？
      dataType: dataTypeStr,
      dataTypeNum: typeHex,
      gnss: {
        latitude: latitude,
        longitude: longitude,
        hAcc: hAcc,
        vAcc: vAcc,
        height: hMSL,
      },
      data: {
        dataArray: JsonRmsRssiDataList,
        temperature: temperature,
        powerSupplyVoltage: parseFloat(powerSupplyVoltage),
      },
    };
    // console.log('Rms Data', rmsData);
    return rmsData;
  } catch (error) {
    console.error('Error analyzing USB command:', error);
    return undefined;
  }
};

/**
 * 16進数から整数に変換する関数
 * @param {string} rate 16進数の文字列
 * @returns {string} 変換結果
 */
export const convertSaveDataRate = (rate: string) => {
  const tmpI = parseInt(rate, 16);

  if (isNaN(tmpI)) {
    console.error(`ConvertSaveDataRate() rate: ${rate}`);
    return '';
  }

  if (tmpI > 100) {
    console.error(`ConvertSaveDataRate() rate invalid data: ${tmpI.toString(16).toUpperCase()}`);
    return '';
  }

  return tmpI.toString();
};

/**
 * @description 16進数から符号付整数に変換する関数
 * @param {string} rssi 16進数の文字列
 * @returns  変換結果
 */
export const convertSaveDataRssi = (rssi: string) => {
  let tmpI = parseInt(rssi, 16);

  if (isNaN(tmpI)) {
    console.error(`ConvertSaveDataRssi() rssi: ${rssi}`);
    return '';
  }

  // 符号付き整数として扱うための変換
  if (tmpI > 127) {
    tmpI -= 256;
  }

  if (tmpI > 0) {
    console.error(`ConvertSaveDataRssi() rssi invalid data: ${tmpI.toString(16).toUpperCase()}`);
    return '';
  }

  return tmpI.toString();
};

/**
 * @description USBシリアル通信データ削除結果の解析
 * @param {string} data デバイスからの応答
 * @returns {string} 解析結果
 */
export const analyzeUsbSaveDataErase = (data: string): boolean => {
  const checkDataLength = 5;
  console.log('length', data.length);
  console.log('data', data);
  if (data.length !== checkDataLength) {
    console.error(`PSE Length error: ${data.length}`);
    return false;
  }

  const valInt = parseInt(data.substring(3, 5), 16);

  if (isNaN(valInt)) {
    console.error('AnalyzeUsbSaveDataErase() val data error');
    return false;
  }

  return valInt !== 0xff;
};

/**
 * チェックサムを計算する関数
 * @param {string} data 対象の文字列
 * @param {number} len 計算に使う文字列の長さ
 * @returns {number} 計算結果
 */
export const calcCheckSum = (data: string, len: number): number => {
  let checksum = 0;

  for (let i = 0; i < len; i++) {
    checksum ^= data.charCodeAt(i);
    checksum &= 0xff;
  }
  return checksum;
};

/**
 * USBシリアル通信アクセス開始結果の解析
 * @param {string} data デバイスからの応答
 * @returns {boolean} 解析結果
 */
export const analyzeUsbCmdSaveDataAccessStart = (data: string): boolean => {
  const checkDataLength = 5;

  const len = data.length;
  if (len !== checkDataLength) {
    console.error(`PSB Length error!: ${len}`);
    return false;
  }

  const val = data.substring(3, 5);
  const valInt = parseInt(val, 16);

  if (isNaN(valInt)) {
    console.error(`AnalyzeUsbCmdSaveDataAccessStart() val data error: ${val}`);
    return false;
  }

  return valInt === 0x00;
};

/**
 * @description USBシリアル通信アクセス終了結果の解析
 * @param {string} data デバイスからの応答
 * @returns {boolean} 解析結果
 */
export const analyzeUsbCmdSaveDataAccessEnd = (data: string): boolean => {
  const CHECK_DATA_LENGTH = 5;

  const len = data.length;
  if (len !== CHECK_DATA_LENGTH) {
    console.error('PSF Length error!:' + len.toString());
    return false;
  }

  const val = data.substring(3, 5);
  const valInt = parseInt(val, 16);

  if (isNaN(valInt)) {
    console.error('AnalyzeUsbCmdSaveDataAccessEnd() val data error:' + val);
    return false;
  }

  return valInt === 0x00;
};

/**
 * @description 時系列グラフの最大値・最小値のインデックスを取得する関数
 * @param {ChartDataset<'line', TimeData[]>[]} datasets グラフのデータセット
 * @returns {ChartDataMaxMinIndex} 最大値・最小値のインデックス
 */
export const getExtremesIndicesAcrossTimeDatasets = (
  datasets: ChartDataset<'line', TimeData[]>[]
): ChartDataMaxMinIndex => {
  let maxValue = -Infinity;
  let minValue = Infinity;

  let maxIndex: ChartDataIndex = { datasetIndex: 0, index: 0 };
  let minIndex: ChartDataIndex = { datasetIndex: 0, index: 0 };

  datasets.forEach((dataset, datasetIndex) => {
    dataset.data.forEach((point, index) => {
      if (point.y > maxValue) {
        maxValue = point.y;
        maxIndex = { datasetIndex, index };
      }
      if (point.y < minValue) {
        minValue = point.y;
        minIndex = { datasetIndex, index };
      }
    });
  });

  return { maxIndex, minIndex };
};

/**
 * @description グラフの最大値・最小値のインデックスを取得する関数
 * @param {ChartDataset<'line', number[]>[]} datasets グラフのデータセット
 * @returns {ChartDataMaxMinIndex} 最大値・最小値のインデックス
 */
export const getExtremesIndicesAcrossDatasets = (datasets: ChartDataset<'line', number[]>[]): ChartDataMaxMinIndex => {
  let maxValue = -Infinity;
  let minValue = Infinity;

  let maxIndex: ChartDataIndex = { datasetIndex: 0, index: 0 };
  let minIndex: ChartDataIndex = { datasetIndex: 0, index: 0 };

  datasets.forEach((dataset, datasetIndex) => {
    dataset.data.forEach((point, index) => {
      if (point > maxValue) {
        maxValue = point;
        maxIndex = { datasetIndex, index };
      }
      if (point < minValue) {
        minValue = point;
        minIndex = { datasetIndex, index };
      }
    });
  });

  return { maxIndex, minIndex };
};

/**
 * グラフの移動平均を求める関数
 * @param {TimeData[]} timeData デバイスからの応答
 * @returns {number} 移動平均Nの値
 */
export const getMovingAverageTimeSeries = (timeData: TimeData[], paramN: number) => {
  const ret = timeData.map((data, i, array) => {
    let sum = 0;
    let count = 0;

    for (let j = 0; j < paramN; j++) {
      const pos = i - j;
      if (pos >= 0 && pos < array.length) {
        sum += array[pos].y;
        count++;
      }
    }

    const average = sum / count;
    const place = 10;
    const roundDownResult = Math.floor(average * place) / place;
    return {
      x: data.x,
      y: roundDownResult,
    };
  });
  return ret;
};

/**
 * FrequencyChannelTypeから紐づくFrequencyTypeを配列で取得する
 * @param {FrequencyChannelType} frequencyChannel 対象のチャンネル
 * @param {FrequencyType[]} allFrequencies 全ての周波数リスト
 * @returns {FrequencyType[]} 対象チャンネルの周波数リスト
 */
export const getFrequenciesFromChannel = (
  frequencyChannel: FrequencyChannelType,
  allFrequencies: FrequencyType[]
): FrequencyType[] => {
  let ret: FrequencyType[] = [];
  allFrequencies.forEach((element) => {
    if (element.frequency == frequencyChannel.frequency) {
      ret.push(element);
      return;
    } else if (frequencyChannel.min && frequencyChannel.max) {
      const tgtFreqs = allFrequencies.filter(
        (t) =>
          frequencyChannel.min != undefined &&
          frequencyChannel.max != undefined &&
          frequencyChannel.min <= t.frequency &&
          t.frequency <= frequencyChannel.max
      );
      if (tgtFreqs.length >= 1) {
        ret = tgtFreqs;
        return;
      }
    }
  });
  return ret;
};

import { AreaMasterItem, MapItem, PinItem, SettingBody } from 'core/entities/areaMaster';
import { getToken, stage, pfApiEndPoint } from '../base';
import { wrapForObject, wrapForObjects } from 'core/commons/dataConverter';
import { SettingType } from 'domain/types/setting';
import { BasePinType, MapObjType } from 'domain/types/map';
import { SensorUnit } from 'domain/types/sensorUnit';

export const getAreaData = async (areaId: string): Promise<AreaMasterItem> => {
  const token = await getToken();
  const params = {
    area_id: areaId,
  };
  const query = new URLSearchParams(params);
  // console.log(areaId);
  const res = await fetch(`${pfApiEndPoint}/${stage}/areamasters/rwm/methods/areadata?${query}`, {
    headers: { Authorization: token },
  });
  const areaMasterJson = await res.json();
  const item = wrapForObject<AreaMasterItem>(areaMasterJson);
  return item;
};

export const getSensorUnitList = async (areaId: string, gatewayId: string): Promise<SensorUnit[]> => {
  const token = await getToken();
  const params = {
    area_id: areaId,
    gateway_id: gatewayId,
  };
  const query = new URLSearchParams(params);

  const res = await fetch(`${pfApiEndPoint}/${stage}/areamasters/rwm/methods/unitlist?${query}`, {
    headers: { Authorization: token },
  });
  const unitListJson = await res.json();
  const item = wrapForObjects<SensorUnit>(unitListJson);
  return item;
};

export const storeSetting = async (areaId: string, gatewayId: string, body: SettingBody) => {
  const token = await getToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(body),
  } as RequestInit;

  const params = {
    area_id: areaId,
    gateway_id: gatewayId,
  };

  const query = new URLSearchParams(params);
  const res = await fetch(`${pfApiEndPoint}/${stage}/areamasters/rwm/methods/rwmsetting?${query}`, requestOptions);
  const settingJson = await res.json();
  const item = wrapForObject<SettingType>(settingJson);
  return item;
};

export const storePin = async (areaId: string, gatewayId: string, mapId: string, body: PinItem) => {
  const token = await getToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(body),
  } as RequestInit;

  const params = {
    area_id: areaId,
    gateway_id: gatewayId,
    map_id: mapId,
  };

  const query = new URLSearchParams(params);
  const res = await fetch(`${pfApiEndPoint}/${stage}/areamasters/rwm/methods/rwmpin?${query}`, requestOptions);
  const pinJson = await res.json();
  const item = wrapForObject<BasePinType>(pinJson);
  return item;
};

export const storeMap = async (areaId: string, gatewayId: string, body: MapItem) => {
  const token = await getToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(body),
  } as RequestInit;

  const params = {
    area_id: areaId,
    gateway_id: gatewayId,
  };

  const query = new URLSearchParams(params);
  const res = await fetch(`${pfApiEndPoint}/${stage}/areamasters/rwm/methods/rwmmap?${query}`, requestOptions);
  const mapJson = await res.json();
  const item = wrapForObject<MapObjType>(mapJson);
  return item;
};

export const deleteMap = async (areaId: string, gatewayId: string, mapId: string) => {
  const token = await getToken();
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `${token}`,
    },
    mode: 'cors',
  } as RequestInit;

  const params = {
    area_id: areaId,
    gateway_id: gatewayId,
    map_id: mapId,
  };

  const query = new URLSearchParams(params);
  const res = await fetch(`${pfApiEndPoint}/${stage}/areamasters/rwm/methods/rwmmap?${query}`, requestOptions);
  return res.ok;
};

export const deletePin = async (areaId: string, gatewayId: string, mapId: string, pinId: string) => {
  const token = await getToken();
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `${token}`,
    },
    mode: 'cors',
  } as RequestInit;

  const params = {
    area_id: areaId,
    gateway_id: gatewayId,
    map_id: mapId,
    pin_id: pinId,
  };

  const query = new URLSearchParams(params);
  const res = await fetch(`${pfApiEndPoint}/${stage}/areamasters/rwm/methods/rwmpin?${query}`, requestOptions);
  return res.ok;
};

// ------------------------------------------------------
// NOTE: 型定義

// ------------------------------------------------------
// NOTE: 定数の定義

/**
 * @constant
 * @description 色の情報 (R, G, B, A) = (赤, 緑, 青, 透明度)
 */
export const COLOR_BAR = [
  [49, 54, 149, 1],
  [49, 54, 149, 1],
  [50, 56, 150, 1],
  [51, 59, 151, 1],
  [51, 59, 151, 1],
  [51, 61, 153, 1],
  [52, 64, 154, 1],
  [52, 64, 154, 1],
  [53, 66, 155, 1],
  [54, 69, 156, 1],
  [54, 69, 156, 1],
  [54, 71, 158, 1],
  [55, 74, 159, 1],
  [55, 74, 159, 1],
  [56, 76, 160, 1],
  [57, 79, 161, 1],
  [57, 79, 161, 1],
  [58, 81, 162, 1],
  [58, 84, 164, 1],
  [58, 84, 164, 1],
  [59, 86, 165, 1],
  [60, 89, 166, 1],
  [60, 89, 166, 1],
  [61, 91, 167, 1],
  [62, 94, 168, 1],
  [62, 94, 168, 1],
  [62, 96, 170, 1],
  [63, 98, 171, 1],
  [63, 98, 171, 1],
  [64, 101, 172, 1],
  [65, 103, 173, 1],
  [65, 103, 173, 1],
  [65, 106, 175, 1],
  [66, 108, 176, 1],
  [66, 108, 176, 1],
  [67, 111, 177, 1],
  [68, 113, 178, 1],
  [68, 113, 178, 1],
  [69, 116, 179, 1],
  [70, 118, 181, 1],
  [70, 118, 181, 1],
  [72, 120, 182, 1],
  [74, 122, 183, 1],
  [74, 122, 183, 1],
  [75, 125, 184, 1],
  [77, 127, 185, 1],
  [77, 127, 185, 1],
  [79, 129, 186, 1],
  [81, 131, 187, 1],
  [81, 131, 187, 1],
  [83, 133, 189, 1],
  [85, 136, 190, 1],
  [85, 136, 190, 1],
  [87, 138, 191, 1],
  [88, 140, 192, 1],
  [88, 140, 192, 1],
  [90, 142, 193, 1],
  [92, 144, 194, 1],
  [92, 144, 194, 1],
  [94, 147, 195, 1],
  [96, 149, 196, 1],
  [96, 149, 196, 1],
  [98, 151, 198, 1],
  [99, 153, 199, 1],
  [99, 153, 199, 1],
  [101, 155, 200, 1],
  [103, 158, 201, 1],
  [103, 158, 201, 1],
  [105, 160, 202, 1],
  [107, 162, 203, 1],
  [107, 162, 203, 1],
  [109, 164, 204, 1],
  [110, 166, 206, 1],
  [110, 166, 206, 1],
  [112, 169, 207, 1],
  [114, 171, 208, 1],
  [114, 171, 208, 1],
  [116, 173, 209, 1],
  [118, 175, 210, 1],
  [118, 175, 210, 1],
  [120, 176, 211, 1],
  [122, 178, 212, 1],
  [122, 178, 212, 1],
  [125, 180, 213, 1],
  [127, 182, 214, 1],
  [127, 182, 214, 1],
  [129, 183, 215, 1],
  [131, 185, 216, 1],
  [131, 185, 216, 1],
  [133, 187, 217, 1],
  [135, 189, 217, 1],
  [135, 189, 217, 1],
  [138, 190, 218, 1],
  [140, 192, 219, 1],
  [140, 192, 219, 1],
  [142, 194, 220, 1],
  [144, 195, 221, 1],
  [144, 195, 221, 1],
  [146, 197, 222, 1],
  [148, 199, 223, 1],
  [148, 199, 223, 1],
  [151, 201, 224, 1],
  [153, 202, 225, 1],
  [153, 202, 225, 1],
  [155, 204, 226, 1],
  [157, 206, 227, 1],
  [157, 206, 227, 1],
  [159, 208, 228, 1],
  [161, 209, 229, 1],
  [161, 209, 229, 1],
  [163, 211, 230, 1],
  [166, 213, 231, 1],
  [166, 213, 231, 1],
  [168, 214, 232, 1],
  [170, 216, 233, 1],
  [170, 216, 233, 1],
  [172, 218, 233, 1],
  [174, 219, 234, 1],
  [174, 219, 234, 1],
  [176, 220, 234, 1],
  [178, 221, 235, 1],
  [178, 221, 235, 1],
  [180, 222, 236, 1],
  [182, 223, 236, 1],
  [182, 223, 236, 1],
  [185, 224, 237, 1],
  [187, 225, 237, 1],
  [187, 225, 237, 1],
  [189, 226, 238, 1],
  [191, 227, 239, 1],
  [191, 227, 239, 1],
  [193, 228, 239, 1],
  [195, 229, 240, 1],
  [195, 229, 240, 1],
  [197, 230, 240, 1],
  [199, 231, 241, 1],
  [199, 231, 241, 1],
  [201, 232, 242, 1],
  [203, 233, 242, 1],
  [203, 233, 242, 1],
  [205, 234, 243, 1],
  [207, 235, 243, 1],
  [207, 235, 243, 1],
  [209, 236, 244, 1],
  [212, 237, 244, 1],
  [212, 237, 244, 1],
  [214, 238, 245, 1],
  [216, 239, 246, 1],
  [216, 239, 246, 1],
  [218, 240, 246, 1],
  [220, 241, 247, 1],
  [220, 241, 247, 1],
  [222, 242, 247, 1],
  [224, 243, 248, 1],
  [224, 243, 248, 1],
  [225, 243, 246, 1],
  [226, 244, 244, 1],
  [226, 244, 244, 1],
  [228, 244, 241, 1],
  [229, 245, 239, 1],
  [229, 245, 239, 1],
  [230, 245, 237, 1],
  [231, 246, 235, 1],
  [231, 246, 235, 1],
  [233, 246, 232, 1],
  [234, 247, 230, 1],
  [234, 247, 230, 1],
  [235, 247, 228, 1],
  [236, 248, 226, 1],
  [236, 248, 226, 1],
  [237, 248, 223, 1],
  [239, 249, 221, 1],
  [239, 249, 221, 1],
  [240, 249, 219, 1],
  [241, 250, 217, 1],
  [241, 250, 217, 1],
  [242, 250, 214, 1],
  [243, 251, 212, 1],
  [243, 251, 212, 1],
  [245, 251, 210, 1],
  [246, 251, 208, 1],
  [246, 251, 208, 1],
  [247, 252, 206, 1],
  [248, 252, 203, 1],
  [248, 252, 203, 1],
  [250, 253, 201, 1],
  [251, 253, 199, 1],
  [251, 253, 199, 1],
  [252, 254, 197, 1],
  [253, 254, 194, 1],
  [253, 254, 194, 1],
  [254, 255, 192, 1],
  [255, 254, 190, 1],
  [255, 254, 190, 1],
  [255, 253, 188, 1],
  [255, 253, 188, 1],
  [255, 252, 186, 1],
  [255, 251, 185, 1],
  [255, 251, 185, 1],
  [255, 250, 183, 1],
  [255, 248, 181, 1],
  [255, 248, 181, 1],
  [255, 247, 179, 1],
  [255, 246, 177, 1],
  [255, 246, 177, 1],
  [255, 245, 175, 1],
  [255, 243, 173, 1],
  [255, 243, 173, 1],
  [255, 242, 172, 1],
  [255, 241, 170, 1],
  [255, 241, 170, 1],
  [255, 240, 168, 1],
  [254, 239, 166, 1],
  [254, 239, 166, 1],
  [254, 237, 164, 1],
  [254, 236, 162, 1],
  [254, 236, 162, 1],
  [254, 235, 161, 1],
  [254, 234, 159, 1],
  [254, 234, 159, 1],
  [254, 233, 157, 1],
  [254, 231, 155, 1],
  [254, 231, 155, 1],
  [254, 230, 153, 1],
  [254, 229, 151, 1],
  [254, 229, 151, 1],
  [254, 228, 150, 1],
  [254, 226, 148, 1],
  [254, 226, 148, 1],
  [254, 225, 146, 1],
  [254, 224, 144, 1],
  [254, 224, 144, 1],
  [254, 222, 142, 1],
  [254, 220, 140, 1],
  [254, 220, 140, 1],
  [254, 218, 138, 1],
  [254, 216, 137, 1],
  [254, 216, 137, 1],
  [254, 214, 135, 1],
  [254, 212, 133, 1],
  [254, 212, 133, 1],
  [254, 210, 131, 1],
  [254, 208, 129, 1],
  [254, 208, 129, 1],
  [254, 206, 127, 1],
  [254, 204, 126, 1],
  [254, 204, 126, 1],
  [254, 202, 124, 1],
  [254, 200, 122, 1],
  [254, 200, 122, 1],
  [253, 199, 120, 1],
  [253, 197, 118, 1],
  [253, 197, 118, 1],
  [253, 195, 116, 1],
  [253, 193, 115, 1],
  [253, 193, 115, 1],
  [253, 191, 113, 1],
  [253, 189, 111, 1],
  [253, 189, 111, 1],
  [253, 187, 109, 1],
  [253, 185, 107, 1],
  [253, 185, 107, 1],
  [253, 183, 105, 1],
  [253, 181, 103, 1],
  [253, 181, 103, 1],
  [253, 179, 102, 1],
  [253, 177, 100, 1],
  [253, 177, 100, 1],
  [253, 175, 98, 1],
  [253, 173, 96, 1],
  [253, 173, 96, 1],
  [252, 170, 95, 1],
  [252, 168, 94, 1],
  [252, 168, 94, 1],
  [252, 165, 93, 1],
  [251, 163, 92, 1],
  [251, 163, 92, 1],
  [251, 160, 91, 1],
  [251, 157, 89, 1],
  [251, 157, 89, 1],
  [250, 155, 88, 1],
  [250, 152, 87, 1],
  [250, 152, 87, 1],
  [250, 150, 86, 1],
  [249, 147, 85, 1],
  [249, 147, 85, 1],
  [249, 145, 83, 1],
  [249, 142, 82, 1],
  [249, 142, 82, 1],
  [248, 140, 81, 1],
  [248, 137, 80, 1],
  [248, 137, 80, 1],
  [248, 134, 79, 1],
  [247, 132, 78, 1],
  [247, 132, 78, 1],
  [247, 129, 76, 1],
  [246, 127, 75, 1],
  [246, 127, 75, 1],
  [246, 124, 74, 1],
  [246, 122, 73, 1],
  [246, 122, 73, 1],
  [245, 119, 72, 1],
  [245, 117, 71, 1],
  [245, 117, 71, 1],
  [245, 114, 69, 1],
  [244, 112, 68, 1],
  [244, 112, 68, 1],
  [244, 109, 67, 1],
  [243, 107, 66, 1],
  [243, 107, 66, 1],
  [242, 104, 65, 1],
  [241, 102, 64, 1],
  [241, 102, 64, 1],
  [239, 99, 63, 1],
  [238, 97, 62, 1],
  [238, 97, 62, 1],
  [237, 95, 60, 1],
  [236, 92, 59, 1],
  [236, 92, 59, 1],
  [235, 90, 58, 1],
  [234, 87, 57, 1],
  [234, 87, 57, 1],
  [233, 85, 56, 1],
  [231, 83, 55, 1],
  [231, 83, 55, 1],
  [230, 80, 54, 1],
  [229, 78, 53, 1],
  [229, 78, 53, 1],
  [228, 76, 52, 1],
  [227, 73, 51, 1],
  [227, 73, 51, 1],
  [226, 71, 49, 1],
  [225, 68, 48, 1],
  [225, 68, 48, 1],
  [224, 66, 47, 1],
  [222, 64, 46, 1],
  [222, 64, 46, 1],
  [221, 61, 45, 1],
  [220, 59, 44, 1],
  [220, 59, 44, 1],
  [219, 56, 43, 1],
  [218, 54, 42, 1],
  [218, 54, 42, 1],
  [217, 52, 41, 1],
  [216, 49, 40, 1],
  [216, 49, 40, 1],
  [214, 47, 39, 1],
  [212, 45, 39, 1],
  [212, 45, 39, 1],
  [210, 43, 39, 1],
  [208, 41, 39, 1],
  [208, 41, 39, 1],
  [206, 40, 39, 1],
  [204, 38, 39, 1],
  [204, 38, 39, 1],
  [202, 36, 39, 1],
  [200, 34, 39, 1],
  [200, 34, 39, 1],
  [198, 32, 39, 1],
  [196, 30, 39, 1],
  [196, 30, 39, 1],
  [194, 28, 39, 1],
  [192, 26, 39, 1],
  [192, 26, 39, 1],
  [190, 24, 39, 1],
  [189, 23, 38, 1],
  [189, 23, 38, 1],
  [187, 21, 38, 1],
  [185, 19, 38, 1],
  [185, 19, 38, 1],
  [183, 17, 38, 1],
  [181, 15, 38, 1],
  [181, 15, 38, 1],
  [179, 13, 38, 1],
  [177, 11, 38, 1],
  [177, 11, 38, 1],
  [175, 9, 38, 1],
  [173, 8, 38, 1],
  [173, 8, 38, 1],
  [171, 6, 38, 1],
  [169, 4, 38, 1],
  [169, 4, 38, 1],
  [167, 2, 38, 1],
  [165, 0, 38, 1],
  [165, 0, 38, 1],
];

export const HEAT_CIRCLE_COLOR = [
  [0, 0, 128, 1],
  [0, 0, 128, 1],
  [0, 0, 132, 1],
  [0, 0, 137, 1],
  [0, 0, 137, 1],
  [0, 0, 141, 1],
  [0, 0, 146, 1],
  [0, 0, 146, 1],
  [0, 0, 150, 1],
  [0, 0, 155, 1],
  [0, 0, 155, 1],
  [0, 0, 159, 1],
  [0, 0, 164, 1],
  [0, 0, 164, 1],
  [0, 0, 168, 1],
  [0, 0, 173, 1],
  [0, 0, 173, 1],
  [0, 0, 178, 1],
  [0, 0, 182, 1],
  [0, 0, 182, 1],
  [0, 0, 187, 1],
  [0, 0, 191, 1],
  [0, 0, 191, 1],
  [0, 0, 196, 1],
  [0, 0, 200, 1],
  [0, 0, 200, 1],
  [0, 0, 205, 1],
  [0, 0, 209, 1],
  [0, 0, 209, 1],
  [0, 0, 214, 1],
  [0, 0, 218, 1],
  [0, 0, 218, 1],
  [0, 0, 223, 1],
  [0, 0, 227, 1],
  [0, 0, 227, 1],
  [0, 0, 232, 1],
  [0, 0, 237, 1],
  [0, 0, 237, 1],
  [0, 0, 241, 1],
  [0, 0, 246, 1],
  [0, 0, 246, 1],
  [0, 0, 250, 1],
  [0, 0, 255, 1],
  [0, 0, 255, 1],
  [0, 0, 255, 1],
  [0, 0, 255, 1],
  [0, 0, 255, 1],
  [0, 0, 255, 1],
  [0, 1, 255, 1],
  [0, 1, 255, 1],
  [0, 4, 255, 1],
  [0, 9, 255, 1],
  [0, 9, 255, 1],
  [0, 13, 255, 1],
  [0, 17, 255, 1],
  [0, 17, 255, 1],
  [0, 20, 255, 1],
  [0, 25, 255, 1],
  [0, 25, 255, 1],
  [0, 29, 255, 1],
  [0, 33, 255, 1],
  [0, 33, 255, 1],
  [0, 36, 255, 1],
  [0, 41, 255, 1],
  [0, 41, 255, 1],
  [0, 45, 255, 1],
  [0, 49, 255, 1],
  [0, 49, 255, 1],
  [0, 52, 255, 1],
  [0, 57, 255, 1],
  [0, 57, 255, 1],
  [0, 61, 255, 1],
  [0, 65, 255, 1],
  [0, 65, 255, 1],
  [0, 68, 255, 1],
  [0, 73, 255, 1],
  [0, 73, 255, 1],
  [0, 77, 255, 1],
  [0, 81, 255, 1],
  [0, 81, 255, 1],
  [0, 84, 255, 1],
  [0, 89, 255, 1],
  [0, 89, 255, 1],
  [0, 93, 255, 1],
  [0, 97, 255, 1],
  [0, 97, 255, 1],
  [0, 100, 255, 1],
  [0, 105, 255, 1],
  [0, 105, 255, 1],
  [0, 109, 255, 1],
  [0, 113, 255, 1],
  [0, 113, 255, 1],
  [0, 116, 255, 1],
  [0, 121, 255, 1],
  [0, 121, 255, 1],
  [0, 125, 255, 1],
  [0, 129, 255, 1],
  [0, 129, 255, 1],
  [0, 133, 255, 1],
  [0, 136, 255, 1],
  [0, 136, 255, 1],
  [0, 141, 255, 1],
  [0, 145, 255, 1],
  [0, 145, 255, 1],
  [0, 149, 255, 1],
  [0, 153, 255, 1],
  [0, 153, 255, 1],
  [0, 157, 255, 1],
  [0, 161, 255, 1],
  [0, 161, 255, 1],
  [0, 165, 255, 1],
  [0, 168, 255, 1],
  [0, 168, 255, 1],
  [0, 173, 255, 1],
  [0, 177, 255, 1],
  [0, 177, 255, 1],
  [0, 181, 255, 1],
  [0, 185, 255, 1],
  [0, 185, 255, 1],
  [0, 189, 255, 1],
  [0, 193, 255, 1],
  [0, 193, 255, 1],
  [0, 197, 255, 1],
  [0, 200, 255, 1],
  [0, 200, 255, 1],
  [0, 205, 255, 1],
  [0, 209, 255, 1],
  [0, 209, 255, 1],
  [0, 213, 255, 1],
  [0, 217, 255, 1],
  [0, 217, 255, 1],
  [0, 221, 254, 1],
  [0, 225, 251, 1],
  [0, 225, 251, 1],
  [0, 229, 248, 1],
  [2, 232, 244, 1],
  [2, 232, 244, 1],
  [6, 237, 241, 1],
  [9, 241, 238, 1],
  [9, 241, 238, 1],
  [12, 245, 235, 1],
  [15, 249, 231, 1],
  [15, 249, 231, 1],
  [19, 253, 228, 1],
  [22, 255, 225, 1],
  [22, 255, 225, 1],
  [25, 255, 222, 1],
  [28, 255, 219, 1],
  [28, 255, 219, 1],
  [31, 255, 215, 1],
  [35, 255, 212, 1],
  [35, 255, 212, 1],
  [38, 255, 209, 1],
  [41, 255, 206, 1],
  [41, 255, 206, 1],
  [44, 255, 202, 1],
  [48, 255, 199, 1],
  [48, 255, 199, 1],
  [51, 255, 196, 1],
  [54, 255, 193, 1],
  [54, 255, 193, 1],
  [57, 255, 190, 1],
  [60, 255, 186, 1],
  [60, 255, 186, 1],
  [64, 255, 183, 1],
  [67, 255, 180, 1],
  [67, 255, 180, 1],
  [70, 255, 177, 1],
  [73, 255, 173, 1],
  [73, 255, 173, 1],
  [77, 255, 170, 1],
  [80, 255, 167, 1],
  [80, 255, 167, 1],
  [83, 255, 164, 1],
  [86, 255, 160, 1],
  [86, 255, 160, 1],
  [90, 255, 157, 1],
  [93, 255, 154, 1],
  [93, 255, 154, 1],
  [96, 255, 151, 1],
  [99, 255, 148, 1],
  [99, 255, 148, 1],
  [102, 255, 144, 1],
  [106, 255, 141, 1],
  [106, 255, 141, 1],
  [109, 255, 138, 1],
  [112, 255, 135, 1],
  [112, 255, 135, 1],
  [115, 255, 131, 1],
  [119, 255, 128, 1],
  [119, 255, 128, 1],
  [122, 255, 125, 1],
  [125, 255, 122, 1],
  [125, 255, 122, 1],
  [128, 255, 119, 1],
  [128, 255, 119, 1],
  [131, 255, 115, 1],
  [135, 255, 112, 1],
  [135, 255, 112, 1],
  [138, 255, 109, 1],
  [141, 255, 106, 1],
  [141, 255, 106, 1],
  [144, 255, 102, 1],
  [148, 255, 99, 1],
  [148, 255, 99, 1],
  [151, 255, 96, 1],
  [154, 255, 93, 1],
  [154, 255, 93, 1],
  [157, 255, 90, 1],
  [160, 255, 86, 1],
  [160, 255, 86, 1],
  [164, 255, 83, 1],
  [167, 255, 80, 1],
  [167, 255, 80, 1],
  [170, 255, 77, 1],
  [173, 255, 73, 1],
  [173, 255, 73, 1],
  [177, 255, 70, 1],
  [180, 255, 67, 1],
  [180, 255, 67, 1],
  [183, 255, 64, 1],
  [186, 255, 60, 1],
  [186, 255, 60, 1],
  [190, 255, 57, 1],
  [193, 255, 54, 1],
  [193, 255, 54, 1],
  [196, 255, 51, 1],
  [199, 255, 48, 1],
  [199, 255, 48, 1],
  [202, 255, 44, 1],
  [206, 255, 41, 1],
  [206, 255, 41, 1],
  [209, 255, 38, 1],
  [212, 255, 35, 1],
  [212, 255, 35, 1],
  [215, 255, 31, 1],
  [219, 255, 28, 1],
  [219, 255, 28, 1],
  [222, 255, 25, 1],
  [225, 255, 22, 1],
  [225, 255, 22, 1],
  [228, 255, 19, 1],
  [231, 255, 15, 1],
  [231, 255, 15, 1],
  [235, 255, 12, 1],
  [238, 255, 9, 1],
  [238, 255, 9, 1],
  [241, 252, 6, 1],
  [244, 248, 2, 1],
  [244, 248, 2, 1],
  [248, 245, 0, 1],
  [251, 241, 0, 1],
  [251, 241, 0, 1],
  [254, 237, 0, 1],
  [255, 234, 0, 1],
  [255, 234, 0, 1],
  [255, 230, 0, 1],
  [255, 226, 0, 1],
  [255, 226, 0, 1],
  [255, 222, 0, 1],
  [255, 219, 0, 1],
  [255, 219, 0, 1],
  [255, 215, 0, 1],
  [255, 211, 0, 1],
  [255, 211, 0, 1],
  [255, 208, 0, 1],
  [255, 204, 0, 1],
  [255, 204, 0, 1],
  [255, 200, 0, 1],
  [255, 196, 0, 1],
  [255, 196, 0, 1],
  [255, 193, 0, 1],
  [255, 189, 0, 1],
  [255, 189, 0, 1],
  [255, 185, 0, 1],
  [255, 182, 0, 1],
  [255, 182, 0, 1],
  [255, 178, 0, 1],
  [255, 174, 0, 1],
  [255, 174, 0, 1],
  [255, 171, 0, 1],
  [255, 167, 0, 1],
  [255, 167, 0, 1],
  [255, 163, 0, 1],
  [255, 159, 0, 1],
  [255, 159, 0, 1],
  [255, 156, 0, 1],
  [255, 152, 0, 1],
  [255, 152, 0, 1],
  [255, 148, 0, 1],
  [255, 145, 0, 1],
  [255, 145, 0, 1],
  [255, 141, 0, 1],
  [255, 137, 0, 1],
  [255, 137, 0, 1],
  [255, 134, 0, 1],
  [255, 130, 0, 1],
  [255, 130, 0, 1],
  [255, 126, 0, 1],
  [255, 122, 0, 1],
  [255, 122, 0, 1],
  [255, 119, 0, 1],
  [255, 115, 0, 1],
  [255, 115, 0, 1],
  [255, 111, 0, 1],
  [255, 108, 0, 1],
  [255, 108, 0, 1],
  [255, 104, 0, 1],
  [255, 100, 0, 1],
  [255, 100, 0, 1],
  [255, 96, 0, 1],
  [255, 93, 0, 1],
  [255, 93, 0, 1],
  [255, 89, 0, 1],
  [255, 85, 0, 1],
  [255, 85, 0, 1],
  [255, 82, 0, 1],
  [255, 78, 0, 1],
  [255, 78, 0, 1],
  [255, 74, 0, 1],
  [255, 71, 0, 1],
  [255, 71, 0, 1],
  [255, 67, 0, 1],
  [255, 63, 0, 1],
  [255, 63, 0, 1],
  [255, 59, 0, 1],
  [255, 56, 0, 1],
  [255, 56, 0, 1],
  [255, 52, 0, 1],
  [255, 48, 0, 1],
  [255, 48, 0, 1],
  [255, 45, 0, 1],
  [255, 41, 0, 1],
  [255, 41, 0, 1],
  [255, 37, 0, 1],
  [255, 34, 0, 1],
  [255, 34, 0, 1],
  [255, 30, 0, 1],
  [255, 26, 0, 1],
  [255, 26, 0, 1],
  [255, 22, 0, 1],
  [255, 19, 0, 1],
  [255, 19, 0, 1],
  [250, 15, 0, 1],
  [246, 11, 0, 1],
  [246, 11, 0, 1],
  [241, 8, 0, 1],
  [237, 4, 0, 1],
  [237, 4, 0, 1],
  [232, 0, 0, 1],
  [228, 0, 0, 1],
  [228, 0, 0, 1],
  [223, 0, 0, 1],
  [218, 0, 0, 1],
  [218, 0, 0, 1],
  [214, 0, 0, 1],
  [209, 0, 0, 1],
  [209, 0, 0, 1],
  [205, 0, 0, 1],
  [200, 0, 0, 1],
  [200, 0, 0, 1],
  [196, 0, 0, 1],
  [191, 0, 0, 1],
  [191, 0, 0, 1],
  [187, 0, 0, 1],
  [182, 0, 0, 1],
  [182, 0, 0, 1],
  [178, 0, 0, 1],
  [173, 0, 0, 1],
  [173, 0, 0, 1],
  [168, 0, 0, 1],
  [164, 0, 0, 1],
  [164, 0, 0, 1],
  [159, 0, 0, 1],
  [155, 0, 0, 1],
  [155, 0, 0, 1],
  [150, 0, 0, 1],
  [146, 0, 0, 1],
  [146, 0, 0, 1],
  [141, 0, 0, 1],
  [137, 0, 0, 1],
  [137, 0, 0, 1],
  [132, 0, 0, 1],
  [128, 0, 0, 1],
  [128, 0, 0, 1],
];

/**
 * @description 推奨度の色の定数
 * @constant
 */
export const RECOMMEND_LEVEL_COLOR = {
  LOW: '#0078D7',
  MID: '#808000',
  HIGH: '#DC0000',
};

/**
 * @description 推奨度 (マップ用) の色の定数
 * @constant
 */
export const RECOMMEND_LEVEL_COLOR_MAP = {
  LOW: '#0078D7',
  MID: '#CDBE1C',
  HIGH: '#DC0000',
  NONE: '#A9A9A9',
};

import { UseCase } from 'core/usecases/usecase';
import { AreaMasterRepository } from 'domain/types/area';
import { SettingType } from 'domain/types/setting';

export class StoreSetting implements UseCase<Promise<SettingType>, StoreSettingParams> {
  private areaMasterRepository: AreaMasterRepository;
  constructor(areaMasterRepository: AreaMasterRepository) {
    this.areaMasterRepository = areaMasterRepository;
  }

  public async call(params: StoreSettingParams): Promise<SettingType> {
    const areaId = params.areaId;
    const gatewayId = params.gatewayId;
    const setting = params.setting;
    return await this.areaMasterRepository.storeSetting(areaId, gatewayId, setting);
  }
}

export class StoreSettingParams {
  constructor(
    public areaId: string,
    public gatewayId: string,
    public setting: SettingType
  ) {}
}

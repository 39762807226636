import { FrequencyGraphData } from '../../../domain/types/graph';
import { getToken, stage, appApiEndPoint } from '../base';
import { wrapForObject } from '../../../core/commons/dataConverter';

export const getFreqGraph = async (
  startTime: string,
  endTime: string,
  deviceId: string,
  dataType: string
): Promise<FrequencyGraphData> => {
  const token = await getToken();
  const params = new URLSearchParams({
    start_time: startTime,
    end_time: endTime,
    data_type: dataType,
    device_id: deviceId,
  });

  const res = await fetch(`${appApiEndPoint}/${stage}/chart/charttypes/freq?${params}`, {
    headers: { Authorization: token },
  });
  const json = await res.json();
  const item = wrapForObject<FrequencyGraphData>(json);
  return item;
};

import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogActions, DialogTitle, Box, Typography, Grid, Input } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { fetchAuthSession } from 'aws-amplify/auth';
import { CustomDialogButton } from 'components/Common/CustomDialogButton';
import CustomIconButton from 'components/Common/CustomIconButton';
import { CustomSlider } from 'components/Common/CustomSlider';
import { SelectedGatewayContext, TenantIdContext } from 'radioMonitoringPage';
import { ConfirmDialogResult } from 'domain/types/setting';
import { MapObjType, MAP_SIZE, PointType } from 'domain/types/map';
import { putFileObject } from 'core/api/aws/s3';
import { encodeString } from 'utils/format';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { DEFAULT_IMAGE_CONF } from 'domain/types/image';
import EditImage from '../Map/EditImage';
import { getCurrentDateTime } from 'utils/dateHelper';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { useErrorBoundary } from 'react-error-boundary';
import { getSelectedMap } from 'utils/extract';

interface Props {
  open: boolean;
  handleClose: (result: ConfirmDialogResult) => void;
}

const EditImageWindow = ({ open, handleClose }: Props) => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { mapsData, updateMap } = useMaps();
  const { selectedMapId } = useContext(LayoutContext);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  const { tenantId } = useContext(TenantIdContext);
  const { selectedGateway } = useContext(SelectedGatewayContext);
  const { tmpImage, setImage, setTmpImage } = useContext(RwmMapAreaContext);
  const { setImageWidth, setImageHeight, setImageRotate, setImageLat, setImageLng } = useContext(RwmMapTabContext);

  const [rotation, setRotation] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const [backgroundOpacity, setBackgroundOpacity] = useState<number>(100);
  const [uploadImagePosition, setUploadImagePosition] = useState({ x: 0, y: 0 });
  const [uploadImageWidth, setUploadImageWidth] = useState<number>(MAP_SIZE.width);
  const [uploadImageHeight, setUploadImageHeight] = useState<number>(MAP_SIZE.height);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    setRotation(0);
    setScale(1);
    setBackgroundOpacity(100);
    setUploadImagePosition({ x: 0, y: 0 });
  }, []);

  const handleRotationChange = () => {
    setRotation((prevRotation) => (prevRotation + 45) % 360);
  };

  /**
   * @description ズームイン / アウトボタン押下時の処理
   */
  const handleScaleChange = (increment: number) => {
    setScale((prevScale) => Math.min(3, Math.max(0.1, prevScale + increment)));
  };

  /**
   * @description リセットボタン押下時の処理
   */
  const handleReset = () => {
    setRotation(0);
    setScale(1);
    setBackgroundOpacity(100);
    setUploadImagePosition({ x: 0, y: 0 });
  };

  /**
   * @description 不透明度のスライダー移動時の処理
   */
  const handleBackgroundOpacityChange = (newValue: number | number[]) => {
    setBackgroundOpacity(newValue as number);
  };

  /**
   * @description 不透明度の入力ボックス変更時の処理
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBackgroundOpacity(event.target.value === '' ? 0 : Number(event.target.value));
  };

  /**
   * @description ダイアログ外を選択した際に動作する関数
   * @param {React.MouseEvent<HTMLButtonElement>} event 対象のイベント
   * @param {[string]} reason ダイアログ外を選択したか判定する為の指標
   * @param {[string]} action 追加 / 閉じる為のアクション
   * @returns
   */
  const handleClickClosedDialog = (
    event: React.MouseEvent<HTMLButtonElement>,
    reason: string,
    action: ConfirmDialogResult
  ) => {
    if (reason === 'backdropClick') return;
    setTmpImage(null);
    handleClose(action);
  };

  /**
   * @description 画像の保存処理用の関数
   */
  const handleClickSubmit = (action: ConfirmDialogResult) => {
    if (tmpImage) {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          // 背景色の設定
          ctx.fillStyle = `rgba(0, 0, 0, 1)`;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = `rgba(255, 255, 255, ${backgroundOpacity / 100})`;
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          // 画像の設定
          const img = new Image();
          img.src = tmpImage;
          img.onload = async () => {
            ctx.save();
            // NOTE: 原点の位置を画像中心に設定
            const adjustedX = canvas.width / 2 + uploadImagePosition.x;
            const adjustedY = canvas.height / 2 + uploadImagePosition.y;
            ctx.translate(adjustedX, adjustedY);
            ctx.rotate((rotation * Math.PI) / 180);
            ctx.scale(scale, scale);
            ctx.drawImage(img, -uploadImageWidth / 2, -uploadImageHeight / 2, uploadImageWidth, uploadImageHeight);

            ctx.restore();

            try {
              // canvas から JPEG データを生成
              const dataURL = canvas.toDataURL('image/png');
              const binary = atob(dataURL.split(',')[1]);
              const array = [];
              for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
              }
              const file = new Blob([new Uint8Array(array)], { type: 'image/png' });

              const { tokens } = await fetchAuthSession();
              if (!tokens || !tokens.idToken) throw 'Token is not existed.';

              const objPath = `${tenantId}/${selectedGateway}/${encodeString(selectedMap.mapId)}`;
              const objKey: string = `${objPath}/${getCurrentDateTime()}.png`;

              // TODO: 既存 S3 バケットのファイルを削除 (エラーの解消に時間がかかる為、既存オブジェクトの削除は一旦後回し)
              // await removeSpecificFileObject(objPath, tokens.idToken.toString());
              // S3バケットにファイルをアップロード
              await putFileObject(file, objKey, tokens.idToken.toString());

              // NOTE: マップのタイプを変更する
              const tmp: MapObjType = {
                ...selectedMap,
                mapType: 'imageMap',
                imgUrl: objKey,
              };

              // NOTE: マップの更新
              (async () => {
                try {
                  await updateMap(tmp, true);
                } catch (error) {
                  showBoundary(error);
                  return;
                }
              })();

              setTmpImage(null);
              setImage('');
              setImageWidth(DEFAULT_IMAGE_CONF.width);
              setImageHeight(DEFAULT_IMAGE_CONF.height);
              setImageRotate(DEFAULT_IMAGE_CONF.rotate);
              setImageLat(DEFAULT_IMAGE_CONF.lat);
              setImageLng(DEFAULT_IMAGE_CONF.lng);
              handleClose(action);
            } catch (error) {
              alert('ファイルのアップロードに失敗しました。\n' + error);
            }
          };
        }
      }
    } else {
      handleClose(action);
    }
  };

  const setImageSizeState = useCallback((width: number, height: number) => {
    setUploadImageWidth(width);
    setUploadImageHeight(height);
  }, []);

  const setImagePositionState = useCallback((position: PointType) => {
    setUploadImagePosition(position);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e: React.MouseEvent<HTMLButtonElement>) => handleClickClosedDialog(e, 'backdropClick', 'close')}
      maxWidth='lg'
    >
      <DialogTitle sx={{ backgroundColor: '#000', borderTop: 2, borderLeft: 2, borderRight: 2, borderColor: '#FFF' }}>
        {t('トリミング')}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: '#000', borderLeft: 2, borderRight: 2, borderColor: '#FFF' }}>
        {tmpImage && (
          <Box
            sx={{
              width: MAP_SIZE.width,
              height: MAP_SIZE.height,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              backgroundColor: `rgba(255, 255, 255, ${backgroundOpacity / 100})`,
              position: 'relative',
              border: 1,
              borderColor: 'white',
            }}
          >
            <EditImage
              image={tmpImage}
              position={uploadImagePosition}
              zoom={scale}
              rotation={rotation}
              setSizeState={setImageSizeState}
              setPositionState={setImagePositionState}
            />
          </Box>
        )}
        <Grid container sx={{ pt: 0, mt: 0 }} spacing={2} alignItems='center'>
          <Grid item>
            <CustomIconButton
              onClick={handleRotationChange}
              Icon={RotateRightIcon}
              tooltipTitle={'右回転'}
              size='medium'
            />
          </Grid>
          <Grid item>
            <CustomIconButton
              onClick={() => handleScaleChange(0.1)}
              Icon={ZoomInIcon}
              tooltipTitle={'ズームイン'}
              size='medium'
            />
          </Grid>
          <Grid item>
            <CustomIconButton
              onClick={() => handleScaleChange(-0.1)}
              Icon={ZoomOutIcon}
              tooltipTitle={'ズームアウト'}
              size='medium'
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>{t('不透明度')}</Typography>
          </Grid>
          <Grid item sx={{ width: 2 / 5 }}>
            <CustomSlider
              defaultValue={50}
              value={backgroundOpacity}
              step={10}
              min={0}
              max={100}
              onChangeCommitted={(event, newValue) => handleBackgroundOpacityChange(newValue)}
            />
          </Grid>
          <Grid item>
            <Input
              value={backgroundOpacity}
              size='medium'
              onChange={handleInputChange}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
            <CustomIconButton onClick={handleReset} Icon={WallpaperIcon} tooltipTitle={'リセット'} size='medium' />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ backgroundColor: '#000', borderBottom: 2, borderLeft: 2, borderRight: 2, borderColor: '#FFF' }}
      >
        <CustomDialogButton
          onClick={(e) => handleClickClosedDialog(e, '', 'close')}
          variant='contained'
          sx={{ backgroundColor: '#333' }}
          title={t('Cancel')}
        />
        <CustomDialogButton
          onClick={() => handleClickSubmit('add')}
          variant='contained'
          sx={{ backgroundColor: '#333' }}
          title={t('OK')}
        />
      </DialogActions>
      <canvas ref={canvasRef} style={{ display: 'none' }} width={MAP_SIZE.width} height={MAP_SIZE.height} />
    </Dialog>
  );
};

export default React.memo(EditImageWindow);

import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const CustomCheckBox = (props: CheckboxProps) => {
  return (
    <Checkbox
      checkedIcon={<CheckBoxIcon className='component-blue' />}
      icon={<CheckBoxOutlineBlankIcon className='text-white' />}
      {...props}
    />
  );
};

export default React.memo(CustomCheckBox);

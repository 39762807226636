import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import {
  SettingContext,
  SelectedFrequencyBandContext,
  SelectedDataSourceContext,
  DateTimeContext,
} from 'radioMonitoringPage';
import { getLevelSetting, getColorIndex, getColorRGBA, createArray } from 'utils/extract';
import { normalization } from 'utils/transform';
import { formatColorRGBA } from 'utils/format';
import { idwInterpolation } from 'utils/interpolate';
import { HeatPointType, PointType } from 'domain/types/map';

interface ImageCanvasProps extends BoxProps {
  imagepath: string;
}

const ImageCanvas = styled(Box)<ImageCanvasProps>(({ imagepath }) => ({
  background: 'transparent',
  backgroundImage: `url(${imagepath})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
}));

interface Props {
  position: PointType;
  size: { width: number; height: number };
  knownPoints: HeatPointType[];
  alpha?: number;
}

const HeatMap = ({ position, size, knownPoints, alpha = 0.7 }: Props) => {
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { setting } = useContext(SettingContext);
  const { dateTime } = useContext(DateTimeContext);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageSrc, setImageSrc] = useState<string>('');

  const { max, min } = getLevelSetting(setting, selectedFrequencyBand, selectedDataSource);
  const pixelSize = 1;

  /**
   * @description キャンバスの初期化
   */
  const initializeCanvas = useCallback(
    (canvas: HTMLCanvasElement) => {
      const context = canvas.getContext('2d');
      if (!context) {
        console.error('Object "canvas context" is null.');
        return;
      }
      // NOTE: 親要素のサイズ設定
      canvas.width = size.width;
      canvas.height = size.height;
    },
    [size.height, size.width]
  );

  /**
   * @description ヒートマップの描画
   */
  const drawHeatMap = useCallback(
    (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
      const interpolationPoints = createArray(size.width, size.height, pixelSize);
      const power = 2;
      const width = canvas.width;
      const height = canvas.height;
      const result: HeatPointType[] = [];

      if (!width || !height) return;
      try {
        // NOTE: knowPoint の (x, y) 座標はマップ全体における (x, y) 座業の為、ヒートマップ上での (x, y) に変換が必要
        const _knownPoints = knownPoints.map((point) => {
          return {
            ...point,
            x: point.x - position.x,
            y: point.y - position.y,
          };
        });

        interpolationPoints.forEach((interpolationPoint) => {
          // TODO: IDW (元の Windows アプリは RBF 補間だがライブラリがない為、まずはこちらで実装)
          let interpolatedValue = idwInterpolation(_knownPoints, interpolationPoint, power);

          if (interpolatedValue < min) interpolatedValue = min;
          if (interpolatedValue > max) interpolatedValue = max;
          result.push({ x: interpolationPoint.x, y: interpolationPoint.y, value: interpolatedValue, id: '' });
        });
      } catch (error) {
        console.error('Error:', error);
      }
      if (context) {
        let cnt = 0;
        for (let x = 0; x < width; x += pixelSize) {
          for (let y = 0; y < height; y += pixelSize) {
            const value = result[cnt];
            const norm = normalization(value.value, max, min);
            const index = getColorIndex(norm);

            // NOTE: 縁を薄める処理
            let adjustedAlpha = alpha;
            if (setting.isHMEdgeLightColor) {
              // 各エッジからの距離を計算
              const distanceToLeftEdgeX = value.x;
              const distanceToRightEdgeX = size.width - value.x;
              const distanceToTopEdgeY = value.y;
              const distanceToBottomEdgeY = size.height - value.y;

              // 最も近いエッジまでの距離（対角線上は断念、、）
              const edgeDistance = Math.min(
                distanceToLeftEdgeX,
                distanceToRightEdgeX,
                distanceToTopEdgeY,
                distanceToBottomEdgeY
              );
              if (edgeDistance < setting.heatMapEdgeSize) {
                adjustedAlpha *= edgeDistance / setting.heatMapEdgeSize;
              }
            }

            const color = formatColorRGBA(getColorRGBA(index, adjustedAlpha));

            context.fillStyle = color;
            context.fillRect(value.x, value.y, pixelSize, pixelSize);

            if (setting.isDisplayHMValue) {
              if (value.x % setting.heatMapBlockSize == 0 && value.y % setting.heatMapBlockSize == 0) {
                context.fillStyle = 'black';
                context.font = `10px Arial`;
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillText(value.value.toFixed(1), value.x, value.y);
              }
            }

            cnt++;
          }
        }
        setImageSrc(canvas.toDataURL());
      }
    },
    [
      alpha,
      knownPoints,
      max,
      min,
      position.x,
      position.y,
      size.height,
      size.width,
      pixelSize,
      setting.isDisplayHMValue,
      setting.isHMEdgeLightColor,
      setting.heatMapEdgeSize,
      setting.heatMapBlockSize,
    ]
  );

  /**
   * @description キャンバス描画処理
   */
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    initializeCanvas(canvas);

    const context = canvas.getContext('2d');

    if (context) {
      drawHeatMap(canvas, context);
    }

    return () => {
      setImageSrc('');
    };
  }, [initializeCanvas, drawHeatMap, dateTime]);

  if (min === max) return <></>;
  return (
    <ImageCanvas
      imagepath={imageSrc}
      sx={{
        position: 'absolute',
        top: position.y + setting.pinSize,
        left: position.x + setting.pinSize / 2,
        width: size.width,
        height: size.height,
      }}
    >
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </ImageCanvas>
  );
};

export default React.memo(HeatMap);

import React, { createContext, useContext, useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import { MAIN_MAP_ID, MapObjType, MapPinType } from 'domain/types/map';
import { useErrorBoundary } from 'react-error-boundary';
import {
  handleDeleteMap,
  handleDeletePin,
  handleDeletePinToMainMap,
  handleGetMap,
  handleGetMaps,
  handleStoreMap,
  handleStorePin,
  handleStorePinToMainMap,
} from 'utils/wrapperApi';

interface MapsContextType {
  mapsData: MapObjType[];
  setMapsData: Dispatch<SetStateAction<MapObjType[]>>;
  getMaps: (isSet?: boolean) => void;
  getMap: (mapId: string) => Promise<MapObjType | undefined>;
  addMap: (newMap: MapObjType, isSet?: boolean) => void;
  updateMap: (updatedMap: MapObjType, isSet?: boolean) => void;
  deleteMap: (mapId: string, isSet?: boolean) => void;
  addPinToMap: (mapId: string, newPin: MapPinType, isSet?: boolean) => void;
  updatePinInMap: (mapId: string, updatedPin: MapPinType, isSet?: boolean) => void;
  deletePinFromMap: (mapId: string, pinId: string, isSet?: boolean) => void;
  addPinToMainMap: (newMap: MapObjType, newPin: MapPinType, isSet?: boolean) => void;
  updatePinInMainMap: (updatedPin: MapPinType, isSet?: boolean) => void;
  deletePinFromMainMap: (mapId: string, pinId: string, isSet?: boolean) => void;
}

const defaultValue: MapsContextType = {
  mapsData: [],
  setMapsData: () => {},
  getMaps: () => {},
  getMap: () => {
    return new Promise((resolve, reject) => {
      try {
        resolve(undefined);
      } catch {
        reject(undefined);
      }
    });
  },
  addMap: () => {},
  updateMap: () => {},
  deleteMap: () => {},
  addPinToMap: () => {},
  updatePinInMap: () => {},
  deletePinFromMap: () => {},
  addPinToMainMap: () => {},
  updatePinInMainMap: () => {},
  deletePinFromMainMap: () => {},
};

// Create the context
const MapsContext = createContext(defaultValue);

// Custom hook to use the MapsContext
export const useMaps = () => {
  return useContext(MapsContext);
};

interface Props {
  areaId: string;
  gatewayId: string;
  children: ReactNode;
}

// Context provider component
export const MapsProvider = ({ areaId, gatewayId, children }: Props) => {
  const [mapsData, setMapsData] = useState<MapObjType[]>([]);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleGetMaps(areaId, gatewayId);
        if (response)
          setMapsData(
            response.sort((a, b) => {
              if (a.mapId === MAIN_MAP_ID) return -1;
              if (b.mapId === MAIN_MAP_ID) return 1;
              return 0;
            })
          );
      } catch (error) {
        showBoundary(error);
      }
    };

    fetchData();
  }, [areaId, gatewayId, showBoundary]);

  /**
   * @description マップ一覧取得用メソッド
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const getMaps = async (isSet: boolean = false) => {
    try {
      const response = await handleGetMaps(areaId, gatewayId);
      if (response)
        if (isSet)
          setMapsData(
            response.sort((a, b) => {
              if (a.mapId === MAIN_MAP_ID) return -1;
              if (b.mapId === MAIN_MAP_ID) return 1;
              return 0;
            })
          );
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description マップ取得用メソッド
   * @param {string} mapId 対象のマップ ID
   */
  const getMap = async (mapId: string) => {
    try {
      const response = await handleGetMap(areaId, gatewayId, mapId);
      if (response) {
        return response;
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description マップ追加用メソッド
   * @param {MapObjType} newMap 対象のマップ情報
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const addMap = async (newMap: MapObjType, isSet: boolean = false) => {
    try {
      const response = await handleStoreMap(areaId, gatewayId, newMap);
      if (isSet) setMapsData([...mapsData, response]);
      else mapsData.push(response);
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description マップ更新用メソッド
   * @param {MapObjType} updatedMap 対象のマップ情報
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const updateMap = async (updatedMap: MapObjType, isSet: boolean = false) => {
    try {
      const response = await handleStoreMap(areaId, gatewayId, updatedMap);
      if (isSet) setMapsData(mapsData.map((map) => (map.mapId === updatedMap.mapId ? response : map)));
      else mapsData.map((map) => (map.mapId === updatedMap.mapId ? response : map));
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description マップ削除用メソッド
   * @param {string} mapId 対象のマップ ID
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const deleteMap = async (mapId: string, isSet: boolean = false) => {
    try {
      await handleDeleteMap(areaId, gatewayId, mapId);
      if (isSet) setMapsData(mapsData.filter((map) => map.mapId !== mapId));
      else mapsData.filter((map) => map.mapId !== mapId);
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description ピン追加用メソッド
   * @param {string} mapId 対象のマップ ID
   * @param {MapPinType} newPin 対象のピン情報
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const addPinToMap = async (mapId: string, newPin: MapPinType, isSet: boolean = false) => {
    try {
      const response = await handleStorePin(areaId, gatewayId, mapId, newPin);
      if (isSet)
        setMapsData(mapsData.map((map) => (map.mapId === mapId ? { ...map, pins: [...map.pins, response] } : map)));
      else mapsData.map((map) => (map.mapId === mapId ? { ...map, pins: [...map.pins, response] } : map));
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description ピン更新用メソッド
   * @param {string} mapId 対象のマップ ID
   * @param {MapPinType} updatedPin 対象のピン情報
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const updatePinInMap = async (mapId: string, updatedPin: MapPinType, isSet: boolean = false) => {
    try {
      const response = await handleStorePin(areaId, gatewayId, mapId, updatedPin);
      if (isSet)
        setMapsData(
          mapsData.map((map) =>
            map.mapId === mapId
              ? { ...map, pins: map.pins.map((pin) => (pin.pinId === updatedPin.pinId ? response : pin)) }
              : map
          )
        );
      else
        mapsData.map((map) =>
          map.mapId === mapId
            ? { ...map, pins: map.pins.map((pin) => (pin.pinId === updatedPin.pinId ? response : pin)) }
            : map
        );
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description ピン削除用メソッド
   * @param {string} mapId 対象のマップ ID
   * @param {string} pinId 対象のピン ID
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const deletePinFromMap = async (mapId: string, pinId: string, isSet: boolean = false) => {
    try {
      await handleDeletePin(areaId, gatewayId, mapId, pinId);
      if (isSet)
        setMapsData(
          mapsData.map((map) =>
            map.mapId === mapId ? { ...map, pins: map.pins.filter((pin) => pin.pinId !== pinId) } : map
          )
        );
      else
        mapsData.map((map) =>
          map.mapId === mapId ? { ...map, pins: map.pins.filter((pin) => pin.pinId !== pinId) } : map
        );
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description メインマップへのピン追加用メソッド
   * @param {MapObjType} newMap 対象のマップ情報
   * @param {MapPinType} newPin 対象のピン情報
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const addPinToMainMap = async (newMap: MapObjType, newPin: MapPinType, isSet: boolean = false) => {
    try {
      // console.log(mapsData);
      const responsePin = await handleStorePinToMainMap(areaId, gatewayId, newPin);
      const responseMap = await handleStoreMap(areaId, gatewayId, newMap);
      if (isSet) {
        const _maps = [...mapsData, responseMap].map((map) =>
          map.mapId === MAIN_MAP_ID ? { ...map, pins: [...map.pins, responsePin] } : map
        );
        setMapsData(_maps);
      } else {
        mapsData.push(responseMap);
        mapsData.map((map) => (map.mapId === MAIN_MAP_ID ? { ...map, pins: [...map.pins, responsePin] } : map));
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description メインマップのピン更新用メソッド
   * @param {MapPinType} updatedPin 対象のピン情報
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const updatePinInMainMap = async (updatedPin: MapPinType, isSet: boolean = false) => {
    try {
      const response = await handleStorePinToMainMap(areaId, gatewayId, updatedPin);
      if (isSet)
        setMapsData(
          mapsData.map((map) =>
            map.mapId === MAIN_MAP_ID
              ? { ...map, pins: map.pins.map((pin) => (pin.pinId === updatedPin.pinId ? response : pin)) }
              : map
          )
        );
      else
        mapsData.map((map) =>
          map.mapId === MAIN_MAP_ID
            ? { ...map, pins: map.pins.map((pin) => (pin.pinId === updatedPin.pinId ? response : pin)) }
            : map
        );
    } catch (error) {
      showBoundary(error);
    }
  };

  /**
   * @description メインマップのピン削除用メソッド
   * @param {string} mapId 対象のマップ ID
   * @param {string} pinId 対象のピン ID
   * @param {boolean} isSet ローカルのステート変数に値をセットする為のフラグ
   */
  const deletePinFromMainMap = async (mapId: string, pinId: string, isSet: boolean = false) => {
    try {
      await handleDeletePinToMainMap(areaId, gatewayId, pinId);
      await handleDeleteMap(areaId, gatewayId, mapId);

      if (isSet) {
        const _maps = mapsData
          .filter((map) => map.mapId !== mapId)
          .map((map) =>
            map.mapId === MAIN_MAP_ID ? { ...map, pins: map.pins.filter((pin) => pin.pinId !== pinId) } : map
          );
        setMapsData(_maps);
      } else
        mapsData
          .filter((map) => map.mapId !== mapId)
          .map((map) =>
            map.mapId === MAIN_MAP_ID ? { ...map, pins: map.pins.filter((pin) => pin.pinId !== pinId) } : map
          );
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <MapsContext.Provider
      value={{
        mapsData,
        setMapsData,
        getMaps,
        getMap,
        addMap,
        updateMap,
        deleteMap,
        addPinToMap,
        updatePinInMap,
        deletePinFromMap,
        addPinToMainMap,
        updatePinInMainMap,
        deletePinFromMainMap,
      }}
    >
      {children}
    </MapsContext.Provider>
  );
};

import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { SxProps } from '@mui/material';

interface CircleProps {
  color: string;
  circleSize: number;
  sx?: SxProps;
}

/**
 * @property {string} color 色情報
 * @property {number} circleSize RGBA 値 (formatColorRGBA 関数の形式)
 * @property {SxProps} sx CSS の設定
 */
const CustomCircle = ({ color, circleSize = 24, sx }: CircleProps) => {
  return (
    <CircleIcon
      sx={{
        position: 'absolute',
        width: circleSize,
        height: circleSize,
        backgroundColor: 'transparent',
        color: color,
        ...sx,
      }}
    />
  );
};

export default React.memo(CustomCircle);

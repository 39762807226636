import { UseCase } from 'core/usecases/usecase';
import { AreaMasterRepository } from 'domain/types/area';
import { MapObjType } from 'domain/types/map';

export class StoreMap implements UseCase<Promise<MapObjType>, StoreMapParams> {
  private areaMasterRepository: AreaMasterRepository;
  constructor(areaMasterRepository: AreaMasterRepository) {
    this.areaMasterRepository = areaMasterRepository;
  }

  public async call(params: StoreMapParams): Promise<MapObjType> {
    const areaId = params.areaId;
    const gatewayId = params.gatewayId;
    const map = params.map;
    return await this.areaMasterRepository.storeMap(areaId, gatewayId, map);
  }
}

export class StoreMapParams {
  constructor(
    public areaId: string,
    public gatewayId: string,
    public map: MapObjType
  ) {}
}

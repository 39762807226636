import { DATA_SOURCE } from '../domain/types/common/consts';
import { BasePinType, MapObjType, MapPinType, PinData, PointType, SizeType, TrackData } from '../domain/types/map';
import { FrequencyType } from '../domain/types/frequency';
import { DataSourceType } from '../domain/types/setting';
import { getDisplayedFrequencyData } from './extract';
import { convertTimestampToTime } from './dateHelper';

/**
 * @description 配列の重複した要素を排除する関数
 * @param {T[]} 対象の配列
 * @returns {T[]} 重複を排除した配列
 */
export const removeDuplicatedElements = <T>(targetArrays: T[]): T[] => {
  return Array.from(new Set(targetArrays));
};

/**
 * @description 指定されたプロパティが特定の値と一致する配列内の要素を新しい要素で置換する関数
 * @param {T[]} baseArray 置換を行う元の配列
 * @param {keyof T} searchKeyName 一致を確認するプロパティの名前
 * @param {T[keyof T]} searchValue 一致を確認するプロパティの値
 * @param {T} updateKeyName - 置換後に挿入する新しい要素のプロパティの名前
 * @param {T} updateValue - 置換後に挿入する新しい要素
 */
export const replaceElementInArray = <T>(
  baseArray: T[],
  searchKeyName: keyof T,
  searchValue: T[keyof T],
  updateKeyName: keyof T,
  updateValue: T[keyof T]
) => {
  baseArray.map((item) => (item[searchKeyName] === searchValue ? (item[updateKeyName] = updateValue) : item));
};

interface Size {
  width: number;
  height: number;
  offsetX?: number;
  offsetY?: number;
}

/**
 * @description アスペクト比を維持しつつ幅と高さを変換する関数
 * @param {Size} targetSize 変換対象の要素のサイズ
 * @param {Size} baseSize 変換する際の基準となる要素のサイズ
 * @return {Size} 変換後のサイズ
 */
export const changeSize = (targetSize: Size, baseSize: Size) => {
  // 画像のアスペクト比を維持してキャンバスに最大化するための計算
  const imgAspectRatio = targetSize.width / targetSize.height;
  const canvasAspectRatio = baseSize.width / baseSize.height;

  let width, height, offsetX, offsetY;

  if (canvasAspectRatio > imgAspectRatio) {
    height = baseSize.height;
    width = imgAspectRatio * height;
    offsetX = (baseSize.width - width) / 2;
    offsetY = 0;
  } else {
    width = baseSize.width;
    height = width / imgAspectRatio;
    offsetX = 0;
    offsetY = (baseSize.height - height) / 2;
  }

  return {
    width,
    height,
    offsetX,
    offsetY,
  };
};

/**
 * @description 正規化
 * @param {number} value 正規化対象の値
 * @param {number} max 最大値
 * @param {number} min 最小値
 * @returns {number} 正規化後の値
 */
export const normalization = (value: number, max: number, min: number) => {
  if (max <= min) return 0;
  if (value < min) return 0;
  if (value > max) return 1;

  return (value - min) / (max - min);
};

/**
 * @description BasePinType から MapPinType に変換する処理
 * @param {BasePinType} pin 変換前ピンデータ
 * @param {number} lat 緯度
 * @param {number} lng 経度
 * @param {string} mapId マップ ID
 * @param {number} ratio占有率
 * @param {number} peakRatioFreq 占有率がピーク時の周波数
 * @param {number} noise ノイズ (RSSI)
 * @param {number} peakNoiseFreq ノイズがピーク時の周波数
 * @param {number} channel チャネル
 * @returns {MapPinType} 変換後のピンデータ
 */
export const basePinTypeToMapPinType = (
  pin: BasePinType,
  lat: number,
  lng: number,
  mapId: string,
  ratio?: number,
  peakRatioFreq?: number,
  noise?: number,
  peakNoiseFreq?: number,
  channel?: number
) => {
  return {
    ...pin,
    lat: lat,
    lng: lng,
    mapId: mapId,
    ratio: ratio !== undefined ? ratio : undefined,
    peakRatioFreq: peakRatioFreq !== undefined ? peakRatioFreq : undefined,
    noise: noise !== undefined ? noise : undefined,
    peakNoiseFreq: peakNoiseFreq !== undefined ? peakNoiseFreq : undefined,
    channel: channel !== undefined ? channel : undefined,
  };
};

/**
 * @description BasePinType から MapPinType に変換する処理 (初期化)
 * @param {BasePinType} pin 変換前ピンデータ
 * @param {mapId} mapId マップ ID
 * @returns {MapPinType} 変換後ピンデータ
 */
export const convertInitialBasePinToMapPin = (pin: BasePinType, mapId: string): MapPinType => {
  return {
    ...pin,
    mapId,
    ratio: undefined,
    peakRatioFreq: undefined,
    noise: undefined,
    peakNoiseFreq: undefined,
    channel: undefined,
  };
};

/**
 * @description IoT データ (占有率やノイズなど) とピンデータを結合する処理 (フロアピン以外用)
 * @param {BasePinType} pin 結合前ピンデータ
 * @param {PinData[]} pinData IoT データリスト
 * @param {FrequencyType[]} frequencies 対象の周波数リスト
 * @param {DataSourceType} dataSource 対象のデータソース (占有率、ノイズ)
 * @param {string} mapId マップ ID
 * @returns {MapPinType} 占有率やノイズなどの IoT データが追加されたピンデータ
 */
export const setPropertyForMapPin = (
  pin: BasePinType,
  pinData: PinData[],
  frequencies: FrequencyType[],
  dataSource: DataSourceType,
  mapId: string
): MapPinType => {
  const data = pinData.find((data) => data.unitId === pin.pinId);
  if (data) {
    const _pinData = getDisplayedFrequencyData(
      data.freqDataList,
      frequencies,
      dataSource === DATA_SOURCE.NOISE ? 'NOISE' : 'OCCUPANCY_RATE'
    );
    const updatePin = {
      ...pin,
      mapId: mapId,
      noise: dataSource === DATA_SOURCE.NOISE ? _pinData.value : undefined,
      peakNoiseFreq: dataSource === DATA_SOURCE.NOISE ? _pinData.frequency : undefined,
      ratio: dataSource === DATA_SOURCE.NOISE ? undefined : _pinData.value,
      peakRatioFreq: dataSource === DATA_SOURCE.NOISE ? undefined : _pinData.frequency,
      channel: _pinData.channel,
    };
    return updatePin;
  }

  return {
    ...pin,
    mapId,
    ratio: undefined,
    peakRatioFreq: undefined,
    noise: undefined,
    peakNoiseFreq: undefined,
    channel: undefined,
  };
};

/**
 * @description IoT データとピンデータを結合する処理 (フロアピン用)
 * @param {MapObjType[]} mapsData マップリスト
 * @param {BasePinType} pin 結合前ピンデータ
 * @param {PinData[]} pinData IoT データリスト
 * @param {FrequencyType[]} frequencies 対象の周波数リスト
 * @param {DataSourceType} dataSource 対象のデータソース (占有率、ノイズ)
 * @param {string} mapId マップ ID
 * @returns {MapPinType} 占有率やノイズなどの IoT データが追加されたピンデータ
 */
export const setPropertyForGroupMapPin = (
  mapsData: MapObjType[],
  pin: BasePinType,
  pinData: PinData[],
  frequencies: FrequencyType[],
  dataSource: DataSourceType,
  mapId: string
): MapPinType => {
  const targetMap = mapsData.find((map) => map.mapId === mapId);
  if (targetMap) {
    const registeredPinIds = targetMap.pins.map((pin) => pin.pinId);
    const freqData: { [key: string]: number | undefined }[] = [];
    for (const registeredPinId of registeredPinIds) {
      const data = pinData.find((data) => data.unitId === registeredPinId);
      if (data) {
        const _pinData = getDisplayedFrequencyData(
          data.freqDataList,
          frequencies,
          dataSource === DATA_SOURCE.NOISE ? 'NOISE' : 'OCCUPANCY_RATE'
        );
        freqData.push(_pinData);
      }
    }

    if (freqData.length > 0) {
      freqData.sort((a, b) => {
        if ((a['frequency'] as number) === (b['frequency'] as number))
          return (b['frequency'] as number) - (a['frequency'] as number);
        return (a.frequency as number) - (b.frequency as number);
      });
      const result = freqData.reduce((maxElement, currentElement) => {
        // currentElement[key]がundefinedの場合を考慮
        if (currentElement['value'] === undefined) {
          return maxElement;
        }
        // maxElement[key]がundefinedの場合も考慮
        if (maxElement['value'] === undefined || currentElement['value'] > maxElement['value']) {
          return currentElement;
        } else {
          return maxElement;
        }
      }, freqData[0]);

      const updatePin = {
        ...pin,
        mapId: mapId,
        noise: dataSource === DATA_SOURCE.NOISE ? result.value : undefined,
        peakNoiseFreq: dataSource === DATA_SOURCE.NOISE ? result.frequency : undefined,
        ratio: dataSource === DATA_SOURCE.NOISE ? undefined : result.value,
        peakRatioFreq: dataSource === DATA_SOURCE.NOISE ? undefined : result.frequency,
        channel: result.channel,
      };
      return updatePin;
    }
  }

  return {
    ...pin,
    mapId,
    ratio: undefined,
    peakRatioFreq: undefined,
    noise: undefined,
    peakNoiseFreq: undefined,
    channel: undefined,
  };
};

/**
 * @description 移動モード用の IoT データ (占有率やノイズなど) とピンデータを結合する処理
 * @param {BasePinType} pin 結合前ピンデータ
 * @param {PinData[]} pinData IoT データリスト
 * @param {FrequencyType[]} frequencies 対象の周波数リスト
 * @param {DataSourceType} dataSource 対象のデータソース (占有率、ノイズ)
 * @param {string} mapId マップ ID
 * @returns {MapPinType} 占有率やノイズなどの IoT データが追加されたピンデータ
 */
export const setPropertyForTrackPin = (
  pin: BasePinType,
  trackData: TrackData,
  frequencies: FrequencyType[],
  dataSource: DataSourceType,
  mapId: string
): MapPinType => {
  const date = convertTimestampToTime(trackData.date, 'Asia/Tokyo', 'HH:mm');
  if (pin.pinId === trackData.unitId) {
    const _pinData = getDisplayedFrequencyData(
      trackData.freqDataList,
      frequencies,
      dataSource === DATA_SOURCE.NOISE ? 'NOISE' : 'OCCUPANCY_RATE'
    );
    const updatePin = {
      ...pin,
      pinId: date,
      label: date,
      mapId: mapId,
      lat: trackData.lat,
      lng: trackData.lng,
      hasGpsInfo: trackData.hasGpsInfo,
      noise: dataSource === DATA_SOURCE.NOISE ? _pinData.value : undefined,
      peakNoiseFreq: dataSource === DATA_SOURCE.NOISE ? _pinData.frequency : undefined,
      ratio: dataSource === DATA_SOURCE.NOISE ? undefined : _pinData.value,
      peakRatioFreq: dataSource === DATA_SOURCE.NOISE ? undefined : _pinData.frequency,
      channel: _pinData.channel,
    };
    return updatePin;
  }

  return {
    ...pin,
    mapId,
    ratio: undefined,
    peakRatioFreq: undefined,
    noise: undefined,
    peakNoiseFreq: undefined,
    channel: undefined,
    lat: 0,
    lng: 0,
    hasGpsInfo: false,
  };
};

/**
 * @description objA のプロパティを objB のプロパティで更新する処理 \
 *  対象は、A, B の両方に存在するプロパティのみ \
 *  深い階層までは見ない
 * @param {T} objA 更新元のオブジェクト
 * @param {Partial<T>} objB
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateDuplicateProperty = <T extends { [key: string]: any }>(A: T, B: Partial<T>): T => {
  for (const key in A) {
    if (Object.prototype.hasOwnProperty.call(B, key)) {
      if (Array.isArray(A[key]) && Array.isArray(B[key])) {
        // 両方とも配列の場合、Bの配列で上書き
        A[key] = B[key]! as (typeof A)[typeof key];
      } else if (typeof A[key] === 'object' && typeof B[key] === 'object') {
        // 両方ともオブジェクトの場合、再帰的にマージ
        A[key] = updateDuplicateProperty(A[key], B[key]!);
      } else {
        // それ以外の場合、B の値で上書き
        A[key] = B[key]!;
      }
    }
  }
  return A;
};

/**
 * @description 1ピクセル当たりの緯度 (y軸) を計算する処理
 * @param {number} height マップの高さ
 * @param {google.maps.LatLng} ne マップの北東の点
 * @param {google.maps.LatLng} sw マップの南西の点
 * @returns {number} 1ピクセル当たりの緯度
 */
export const calcLatPerPixel = (height: number, ne: google.maps.LatLng, sw: google.maps.LatLng): number => {
  return Math.abs(ne.lat() - sw.lat()) / height;
};

/**
 * @description 1ピクセル当たりの経度 (x軸) を計算する処理
 * @param {number} width マップの幅
 * @param {google.maps.LatLng} ne マップの北東の点
 * @param {google.maps.LatLng} sw マップの南西の点
 * @returns {number} 1ピクセル当たりの経度
 */
export const calcLngPerPixel = (width: number, ne: google.maps.LatLng, sw: google.maps.LatLng): number => {
  return Math.abs(ne.lng() - sw.lng()) / width;
};

/**
 * @description ピクセル値から緯度経度を計算する処理
 * @param {number} latPerPixel 1ピクセル当たりの緯度 (y軸)
 * @param {number} lngPerPixel 1ピクセル当たりの経度 (x軸)
 * @param {PointType} pixel 対象のピクセル値
 * @param {google.maps.LatLng} origin マップの原点 (左上、北西) の座標
 * @returns {google.maps.LatLng} 変換後の座標値
 */
export const convertPixelToLatLng = (
  latPerPixel: number,
  lngPerPixel: number,
  pixel: PointType,
  origin: google.maps.LatLng
): google.maps.LatLng => {
  const diffLat = latPerPixel * pixel.y;
  const diffLng = lngPerPixel * pixel.x;

  const latLng = {
    lat: origin.lat() - diffLat,
    lng: origin.lng() + diffLng,
  };

  return new google.maps.LatLng(latLng);
};

/**
 * @description 緯度経度からピクセル値を計算する処理
 * @param {number} latPerPixel 1ピクセル当たりの緯度 (y軸)
 * @param {number} lngPerPixel 1ピクセル当たりの経度 (x軸)
 * @param {google.maps.LatLng} latLng 対象の緯度経度
 * @param {google.maps.LatLng} origin マップの原点 (左上、北西) の座標
 * @returns {PointType} 変換後のピクセル値
 */
export const convertLatLngToPixel = (
  latPerPixel: number,
  lngPerPixel: number,
  latLng: google.maps.LatLng,
  origin: google.maps.LatLng
): PointType => {
  const diffLat = Math.abs(origin.lat() - latLng.lat());
  const diffLng = Math.abs(origin.lng() - latLng.lng());

  const pixel = {
    x: diffLng / lngPerPixel,
    y: diffLat / latPerPixel,
  };

  return pixel;
};

/**
 * @description ピンのピクセル位置を左上から中央下部に補正するための処理
 * @param {PointType} point 左上のピクセル位置
 * @param {number} pinSize ピンのサイズ
 * @returns {PointType} 補正後のピクセル位置
 */
export const convertCorrectionPosition = (point: PointType, pinSize: number): PointType => {
  return { x: point.x + pinSize / 2, y: point.y + pinSize };
};

/**
 * @description ピンのピクセル位置を中央下部から左上に補正するための処理
 * @param {PointType} point 中央下部のピクセル位置
 * @param {number} pinSize ピンのサイズ
 * @returns {PointType} 補正後のピクセル位置
 */
export const convertActualPosition = (point: PointType, pinSize: number): PointType => {
  return { x: point.x - pinSize / 2, y: point.y - pinSize };
};

/**
 * @description 配列の重複を排除して結合した結果を返す処理 \
 *  ※array2 の重複した要素が排除される \
 *  ※Object の重複は排除されないが、同じオブジェクトを格納した時は排除される
 * @param {T[]} array1
 * @param {T[]} array2
 * @returns {T[]} 重複が排除された配列
 */
export const mergeUnique = <T>(array1: T[], array2: T[]) => {
  return Array.from(new Set([...array1, ...array2]));
};

/**
 * @description ヒートマップの表示位置を補正する処理
 * @param {number} x
 * @param {number} y
 * @returns {PointType} 補正後のヒートマップの位置 (左上の座標)
 */
export const convertHeatMapPosition = (x: number, y: number, edge: number): PointType => {
  return { x: x - edge, y: y - edge * 1.5 };
};

/**
 * @description ヒートマップのサイズを補正する処理
 * @param {number} xMax x 座標の最大値
 * @param {number} xMin x 座標の最小値
 * @param {number} yMax y 座標の最大値
 * @param {number} yMin y 座標の最小値
 * @returns {PointType} 補正後のヒートマップのサイズ
 */
export const convertHeatMapSize = (xMax: number, xMin: number, yMax: number, yMin: number, edge: number): SizeType => {
  return { width: xMax - xMin + edge * 2, height: yMax - yMin + edge * 2 };
};

/**
 * 2 つの連想配列を比較し、target にフィールドが存在しない / 値が undefined / 値が null の場合、source の値をセットする関数 \
 * 連想配列は再帰的に要素を確認するが、配列は要素ごとに確認しない
 * @param target 変更対象のオブジェクト
 * @param source 変更元となる値を保持するオブジェクト
 */
export const setTargetWithSource = <T extends object>(target: T, source: T): void => {
  // source の各フィールドに対してループ
  for (const key in source) {
    const sourceValue = source[key];
    const targetValue = target[key];

    // target に key が存在しない
    if (!Object.prototype.hasOwnProperty.call(target, key)) {
      target[key] = sourceValue;
    } else {
      if (targetValue === undefined || targetValue === null) {
        target[key] = sourceValue;
      } else if (Array.isArray(targetValue)) {
        continue;
      } else if (typeof targetValue === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setTargetWithSource(targetValue, sourceValue as any);
      }
    }
  }
};

import React, { KeyboardEvent, useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap } from '@react-google-maps/api';
import { DialogTitle, Stack, Box, Dialog, TextField, DialogActions, Autocomplete, Tooltip } from '@mui/material';
import { CustomDialogButton } from 'components/Common/CustomDialogButton';
import { DEFAULT_MAP_OPTIONS, MAP_SIZE, MapObjType, MapTypeIdType } from 'domain/types/map';
import { getElevation } from 'core/api/googleMap/googleMap';
import ChangeMapTypeButton from 'components/SplitLayout/MainPage/MapTab/Button/ChangeMapTypeButton';
import ZoomInMapButton from 'components/SplitLayout/MainPage/MapTab/Button/ZoomInMapButton';
import ZoomOutMapButton from 'components/SplitLayout/MainPage/MapTab/Button/ZoomOutMapButton';
import { RwmMapAreaContext } from '../RwmMapContext';
import { getSelectedMap } from 'utils/extract';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { useErrorBoundary } from 'react-error-boundary';

const SEARCH_HISTORY_KEY = 'searchHistory';
const MAX_HISTORY_ITEMS = 10;

const SearchMapWindow = () => {
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { mapsData, updateMap } = useMaps();
  const { selectedMapId } = useContext(LayoutContext);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  const {
    center,
    zoom,
    mapTypeId,
    elevation,
    isOpenedSearchWindow,
    setCenter,
    setZoom,
    setMapTypeId,
    setElevation,
    setIsOpenedSearchWindow,
  } = useContext(RwmMapAreaContext);

  // NOTE: マップの状態を保持するステート
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchCenter, setSearchCenter] = useState<google.maps.LatLng | undefined>(center);
  const [searchZoom, setSearchZoom] = useState<number>(zoom);
  const [searchMapTypeId, setSearchMapTypeId] = useState<MapTypeIdType>(mapTypeId);
  const [searchElevation, setSearchElevation] = useState<number>(elevation);

  // NOTE: 検索処理の状態を保持するステート
  const [inputValue, setInputValue] = useState<string>('');
  const [searchHistory, setSearchHistory] = useState<string[]>(() => {
    const history = localStorage.getItem(SEARCH_HISTORY_KEY);
    return history ? JSON.parse(history) : [];
  });

  /**
   * @description 検索履歴を更新し、ローカルストレージに保存する処理
   */
  const updateSearchHistory = (searchTerm: string) => {
    const newHistory = [searchTerm, ...searchHistory.filter((item) => item !== searchTerm)];

    // NOTE: 履歴が最大数を超えた場合は古い履歴を削除
    if (newHistory.length > MAX_HISTORY_ITEMS) {
      newHistory.length = MAX_HISTORY_ITEMS;
    }
    setSearchHistory(newHistory);
    localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(newHistory));
  };

  /**
   * @description OK を選択した際に動作する関数
   * @param {React.MouseEvent<HTMLButtonElement>} event 対象のイベント
   * @param {[string]} action 追加 / 閉じる為のアクション
   * @returns
   */
  const handleClickSubmit = async () => {
    if (searchCenter) {
      const tmp: MapObjType = {
        ...selectedMap,
        lat: searchCenter.lat(),
        lng: searchCenter.lng(),
        elevation: searchElevation,
        zoom: searchZoom,
        mapTypeId: searchMapTypeId,
        mapType: 'googleMap',
      };

      // NOTE: マップの更新
      (async () => {
        try {
          await updateMap(tmp, true);
        } catch (error) {
          showBoundary(error);
          return;
        }
      })();

      setCenter(searchCenter);
      setZoom(searchZoom);
      setMapTypeId(searchMapTypeId);
      setElevation(searchElevation);
      setIsOpenedSearchWindow(false);
    }
  };

  /**
   * @description ダイアログ外を選択した際に動作する関数
   * @param {React.MouseEvent<HTMLButtonElement>} event 対象のイベント
   * @param {[string]} reason ダイアログ外を選択したか判定する為の指標
   * @param {[string]} action 追加 / 閉じる為のアクション
   * @returns
   */
  const handleClickClosedDialog = (event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
    if (reason === 'backdropClick') return;
    setIsOpenedSearchWindow(false);
  };

  /**
   * @description Google Map 検索処理
   */
  const handleSearch = () => {
    if (!inputValue.trim()) return;

    let _zoom = searchZoom;

    if (_zoom < 13) _zoom = 13;

    // 検索履歴に追加
    updateSearchHistory(inputValue);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: inputValue }, (results, status) => {
      if (status === 'OK') {
        if (results) {
          setSearchCenter(
            new google.maps.LatLng({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            })
          );
          setSearchZoom(_zoom);
        }
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

  /**
   * @description テキストフィールドで Enter 押下時の処理
   */
  const handleKeyDownTextField = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  /**
   * @description マップインスタンスが読み込まれた時の処理
   */
  const onLoad = useCallback(async (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  /**
   * @description マップインスタンスがアンマウント (レンダリング対象から外れた) 時の処理
   */
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  /**
   * @description マップをドラッグで移動し、止まった時の処理
   */
  const onDragEnd = useCallback(async () => {
    if (map) {
      const newCenter = map.getCenter();
      if (newCenter) {
        const newElevation = await getElevation(newCenter.lat(), newCenter.lng());

        setSearchCenter(newCenter);
        setSearchElevation(newElevation);
      }
    }
  }, [map]);

  /**
   * @description マップのズーム倍率が変更された時の処理
   */
  const onZoomChanged = useCallback(() => {
    if (map) {
      const newZoom = map.getZoom();
      if (newZoom) setSearchZoom(newZoom);
    }
  }, [map]);

  /**
   * @description マップ変更時に初期値を設定
   */
  useEffect(() => {
    setInputValue('');
  }, [selectedMap]);

  /**
   * @description マップインスタンスに値を設定
   */
  useEffect(() => {
    if (map && searchCenter) {
      map.setCenter(searchCenter);
      map.setZoom(searchZoom);
      map.setMapTypeId(searchMapTypeId);
    }
  }, [searchCenter, map, searchMapTypeId, searchZoom]);

  /**
   * @description
   */
  useEffect(() => {
    setSearchCenter(center);
    setSearchZoom(zoom);
    setSearchMapTypeId(mapTypeId);
  }, [center, mapTypeId, zoom]);

  /**
   * @description 検索履歴をローカルストレージに保存する処理
   */
  useEffect(() => {
    localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(searchHistory));
  }, [searchHistory]);

  return (
    <Dialog
      open={isOpenedSearchWindow}
      onClose={(e: React.MouseEvent<HTMLButtonElement>) => handleClickClosedDialog(e, 'backdropClick')}
      fullWidth={true}
      maxWidth='md'
      sx={{
        '& .MuiPaper-root': {
          background: '#000',
          borderBlockColor: '#333',
          borderWidth: 1,
        },
      }}
    >
      <DialogTitle>{t('マップ編集')}</DialogTitle>
      <Stack direction='row' justifyContent='flex-start' alignContent='center' spacing={1} sx={{ m: 1 }}>
        <Box sx={{ px: 2, width: 1 / 8, textAlign: 'right', alignContent: 'center' }}>{t('住所')} : </Box>
        <Autocomplete
          freeSolo
          options={searchHistory} // 検索履歴をオプションとして渡す
          value={inputValue}
          onChange={(event, newValue) => {
            setInputValue(newValue || '');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              onKeyDown={handleKeyDownTextField}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              fullWidth
            />
          )}
          sx={{ width: 3 / 5 }}
        />
        <Tooltip
          title={t('低倍率のズームで検索を行うと、検索結果は自動的に一定の大きさにズームされます')}
          placement='top-start'
        >
          <CustomDialogButton
            variant='contained'
            color='primary'
            onClick={handleSearch}
            title={'検索'}
          ></CustomDialogButton>
        </Tooltip>
      </Stack>
      <Box sx={{ display: 'flex', height: 1, width: 1, justifyContent: 'center', alignContent: 'center' }}>
        <GoogleMap
          mapContainerStyle={{ width: MAP_SIZE.width, height: MAP_SIZE.height }}
          center={searchCenter}
          zoom={searchZoom}
          mapTypeId={searchMapTypeId}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={onDragEnd}
          onZoomChanged={onZoomChanged}
          options={{ ...DEFAULT_MAP_OPTIONS, gestureHandling: 'greedy', scrollwheel: true }}
        >
          <Stack
            direction='column'
            justifyContent='flex-end'
            alignItems='flex-end'
            spacing={1}
            sx={{ mb: 3, p: 1, height: 1, width: 1 }}
          >
            <ChangeMapTypeButton setMapTypeId={setSearchMapTypeId} />
            <ZoomInMapButton zoom={searchZoom} setZoom={setSearchZoom} />
            <ZoomOutMapButton zoom={searchZoom} setZoom={setSearchZoom} />
          </Stack>
        </GoogleMap>
      </Box>
      <DialogActions>
        <CustomDialogButton
          onClick={handleClickSubmit}
          variant='contained'
          sx={{ backgroundColor: '#333' }}
          title={t('OK')}
        />
        <CustomDialogButton
          onClick={(e) => handleClickClosedDialog(e, '')}
          variant='contained'
          sx={{ backgroundColor: '#333' }}
          title={t('閉じる')}
        />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(SearchMapWindow);

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { MAIN_MAP_ID, MapObjType } from '../../domain/types/map';
import { InputLabelStyle, SelectStyle } from '../../domain/types/sxProps';
import { getMapLabel } from 'utils/extract';

/**
 * @param {string} id ラベルID
 * @param {string} title 表示名称
 * @param {string} value 選択状態のマップ
 * @param {(mapId: string) => void} handleMapSelect 選択マップを変更する関数
 * @param {string[]} options 選択されるマップの候補
 */
interface Props {
  id: string;
  title: string;
  value: string;
  handleSelect: (mapId: string) => void;
  options: string[];
  mapsData?: MapObjType[];
}

const CustomMapSelect = ({ id, title, value, options, handleSelect, mapsData }: Props) => {
  /**
   * @description 選択項目を変更した際に動作する関数
   * @param {SelectChangeEvent} event 対象のイベント
   */
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = options.find((id) => id == event.target.value);
    if (selectedValue) {
      handleSelect(selectedValue);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          ...InputLabelStyle,
        }}
        id={id}
      >
        {title}
      </InputLabel>
      <Select
        sx={{
          ...SelectStyle,
          height: '45px',
        }}
        labelId={id + '-select-label'}
        id={id + '-select'}
        value={value}
        label={<span style={{ color: '#FFFFFF' }}>{title}</span>}
        onChange={handleChange}
        defaultValue={value}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {/* 表示する値はフロアピンのラベルの値だが、実際に変数にセットされるのは ID の値である */}
            {mapsData ? (option === MAIN_MAP_ID ? option : getMapLabel(mapsData, option)) : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMapSelect;

import { UseCase } from 'core/usecases/usecase';
import { AreaMasterRepository } from 'domain/types/area';
import { INITIAL_PIN, MapPinType } from '../types/map';
import { UNIT_TYPE_RWM } from '../types/sensorUnit';

export class FetchPinCandidateList implements UseCase<Promise<MapPinType[]>, FetchPinCandidateListParams> {
  private areaMasterRepository: AreaMasterRepository;
  constructor(areaMasterRepository: AreaMasterRepository) {
    this.areaMasterRepository = areaMasterRepository;
  }

  public async call(params: FetchPinCandidateListParams): Promise<MapPinType[]> {
    const areaId = params.areaId;
    const gatewayId = params.gatewayId;
    const unitList = await this.areaMasterRepository.fetchUnitList(areaId, gatewayId);
    const rwmUnitList = unitList.filter((unit) => unit.devType == UNIT_TYPE_RWM);
    const ret: MapPinType[] = [];
    rwmUnitList.forEach((unit) => {
      const pin: MapPinType = {
        ...INITIAL_PIN,
        pinId: unit.devId,
        label: unit.devId,
      };
      ret.push(pin);
    });
    return ret;
  }
}

export class FetchPinCandidateListParams {
  constructor(
    public areaId: string,
    public gatewayId: string
  ) {}
}

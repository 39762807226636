import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useTranslation } from 'react-i18next';
import { MAP_SIZE } from 'domain/types/map';

interface Props {
  width: number | undefined;
  height: number | undefined;
}
const InitialImage = ({ width, height }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction='column'
      spacing={3}
      sx={{
        width: width ? width : MAP_SIZE.width,
        height: height ? height : MAP_SIZE.height,
        backgroundColor: 'white',
        color: 'gray',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='h3'>{t('マップを選択してください')}</Typography>
      <Box sx={{ width: width, display: 'flex', justifyContent: 'center' }}>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            width: '400px',
            minWidth: '300px',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <MapIcon sx={{ fontSize: 35 }} />
          <Typography variant='h4'>{t('地図から選択')}</Typography>
        </Stack>
      </Box>
      <Box sx={{ width: width, display: 'flex', justifyContent: 'center' }}>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            width: '400px',
            minWidth: '300px',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <CollectionsIcon sx={{ fontSize: 35 }} />
          <Typography variant='h4'>{t('イメージから選択')}</Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default React.memo(InitialImage);

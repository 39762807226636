import { UseCase } from 'core/usecases/usecase';
import { AreaMasterRepository } from 'domain/types/area';

export class DeleteMap implements UseCase<Promise<boolean>, DeleteMapParams> {
  private areaMasterRepository: AreaMasterRepository;
  constructor(areaMasterRepository: AreaMasterRepository) {
    this.areaMasterRepository = areaMasterRepository;
  }

  public async call(params: DeleteMapParams): Promise<boolean> {
    const areaId = params.areaId;
    const gatewayId = params.gatewayId;
    const mapId = params.mapId;
    return await this.areaMasterRepository.deleteMap(areaId, gatewayId, mapId);
  }
}

export class DeleteMapParams {
  constructor(
    public areaId: string,
    public gatewayId: string,
    public mapId: string
  ) {}
}

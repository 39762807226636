import React, { type Dispatch, useContext, useEffect } from 'react';
import { DialogTitle, Dialog, DialogActions, Box, Stack } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DialogContent } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { getSecondsFromDate } from '../../utils/dateHelper';
import { useTranslation } from 'react-i18next';
import { CustomDialogButton } from '../Common/CustomDialogButton';
import { IsPlayingContext, IsSyncContext } from '../../radioMonitoringPage';
import 'dayjs/locale/ja';
import CustomIconButton from 'components/Common/CustomIconButton';
import { handleGetNearestIotData } from '../../utils/wrapperApi';
import { RwmContext } from '../../RwmContext';

interface Props {
  open: boolean;
  dateTime: Dayjs | null;
  selectedFrequencyBand: string;
  onClose: () => void;
  onSubmit: (value: Dayjs) => void;
  setSliderValue: Dispatch<React.SetStateAction<number>>;
}
export const DialogDateTime = (props: Props) => {
  const { candidatePinIds } = useContext(RwmContext);
  const { onClose, dateTime, open, onSubmit, setSliderValue, selectedFrequencyBand } = props;
  const [value, setValue] = React.useState<Dayjs | null>(dateTime);
  const { t } = useTranslation();

  // ダイアログを開いた際に再生中の場合は、停止する
  const { isPlaying, setIsPlaying } = useContext(IsPlayingContext);
  const { isSync, setIsSync } = useContext(IsSyncContext);

  useEffect(() => {
    if (open) {
      if (isSync) {
        setIsSync(false);
        setIsPlaying(false);
      } else if (isPlaying) {
        setIsPlaying(false);
      }
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    setValue(dateTime);
  }, [dateTime]);

  const handleClose = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
    if (reason === 'backdropClick') return;
    onClose();
  };
  const handleSubmit = () => {
    if (value != null) {
      onSubmit(value);
      setSliderValue(getSecondsFromDate(value));
    }
    onClose();
  };

  const setValidDataDate = async (isBefore: boolean) => {
    if (value == null) return;
    if (candidatePinIds.length == 0) {
      alert(t('電波監視デバイスが登録されていません。'));
      return;
    }
    const radioWaves = await handleGetNearestIotData(value, selectedFrequencyBand, candidatePinIds, isBefore);
    if (radioWaves.length >= 1) {
      setValue(dayjs(radioWaves[0].timestamp));
    } else {
      alert(t('データが見つかりません。'));
      return;
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          background: '#000',
          borderBlockColor: '#333333',
          borderWidth: 1,
        },
      }}
    >
      <DialogTitle>{t('日時選択')}</DialogTitle>
      <DialogContent>
        <Stack direction={'row'} spacing={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
            <DateTimePicker
              views={['year', 'day', 'hours', 'minutes', 'seconds']}
              value={value}
              onChange={(newValue) => setValue(newValue)}
              ampm={false}
              slotProps={{
                layout: {
                  sx: {
                    '& .MuiDayCalendar-weekDayLabel': {
                      color: 'white',
                    },
                    '& .MuiButtonBase-root': {
                      color: 'white',
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomIconButton
              onClick={() => setValidDataDate(true)}
              Icon={KeyboardArrowLeftIcon}
              tooltipTitle='有効なデータが存在する日時を逆方向に検索します'
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomIconButton
              onClick={() => setValidDataDate(false)}
              Icon={KeyboardArrowRightIcon}
              tooltipTitle='有効なデータが存在する日時を前方向に検索します'
            />
          </Box>
        </Stack>
      </DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingX: 2, paddingBottom: 1 }}>
        <CustomDialogButton
          onClick={() => setValue(dayjs())}
          variant='contained'
          sx={{ backgroundColor: '#333333' }}
          title='現在時刻'
        />
      </Box>

      <DialogActions>
        <CustomDialogButton
          onClick={onClose}
          variant='contained'
          sx={{ backgroundColor: '#333333' }}
          title='キャンセル'
        />
        <CustomDialogButton onClick={handleSubmit} variant='contained' sx={{ backgroundColor: '#333333' }} title='OK' />
      </DialogActions>
    </Dialog>
  );
};

import React, { useContext, useMemo } from 'react';
import { MapObjType } from 'domain/types/map';
import CustomButton from 'components/Common/CustomButton';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { useErrorBoundary } from 'react-error-boundary';
import { getSelectedMap } from 'utils/extract';

const EditMapConfirmButton = () => {
  const { showBoundary } = useErrorBoundary();
  const { mapsData, updateMap } = useMaps();
  const { selectedMapId } = useContext(LayoutContext);
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);
  const { tmpCenter, tmpZoom, tmpMapTypeId, tmpElevation, setCenter, setZoom, setMapTypeId, setElevation } =
    useContext(RwmMapAreaContext);
  const {
    tmpImageWidth,
    tmpImageHeight,
    tmpImageRotate,
    tmpImageLat,
    tmpImageLng,
    setMapMode,
    setImageWidth,
    setImageHeight,
    setImageRotate,
    setImageLat,
    setImageLng,
  } = useContext(RwmMapTabContext);

  /**
   * @description マップ位置編集 OK 選択時の処理
   */
  const handleClick = async () => {
    if (tmpCenter) {
      let tmp: MapObjType = {
        ...selectedMap,
        lat: tmpCenter.lat(),
        lng: tmpCenter.lng(),
        elevation: tmpElevation,
        zoom: tmpZoom,
        mapTypeId: tmpMapTypeId,
      };

      if (tmp.mapType === 'imageMap') {
        tmp = {
          ...tmp,
          imgWidth: tmpImageWidth,
          imgHeight: tmpImageHeight,
          imgRotate: tmpImageRotate,
          imgLat: tmpImageLat,
          imgLng: tmpImageLng,
        };
      }

      // NOTE: マップの更新
      (async () => {
        try {
          await updateMap(tmp, true);
        } catch (error) {
          showBoundary(error);
          return;
        }
      })();

      setCenter(tmpCenter);
      setZoom(tmpZoom);
      setMapTypeId(tmpMapTypeId);
      setElevation(tmpElevation);
      setImageWidth(tmpImageWidth);
      setImageHeight(tmpImageHeight);
      setImageRotate(tmpImageRotate);
      setImageLat(tmpImageLat);
      setImageLng(tmpImageLng);
      setMapMode('read');
    }
  };

  return <CustomButton title={'OK'} sx={{ width: 'fit-content' }} onClick={() => handleClick()} />;
};

export default React.memo(EditMapConfirmButton);

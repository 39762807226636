import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomIconButton from '../../../Common/CustomIconButton';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SquareIcon from '@mui/icons-material/Square';
import useSWR from 'swr';

// NOTE: カスタムコンポーネント
import CustomMapSelect from '../../../Common/CustomMapSelect';
import {
  SelectedFrequencyBandContext,
  SelectedSpanContext,
  DateTimeContext,
  SettingContext,
  IsSyncContext,
  PlayingSpeedIndexContext,
} from '../../../../radioMonitoringPage';
import { ChannelTable } from './ChannelTable';
import { DetailTable } from './ChannelDetail';
import { CHANNEL_DETAIL_ROW_COUNT } from '../../../../domain/types/channel';
import { LevelThresholdType } from '../../../../domain/types/setting';
import {
  getNoiseLevelFromNoiseDataSource,
  getLevelThresholdFromFrequencyBand,
  getSelectedMap,
} from '../../../../utils/extract';
import { FetchChannelDataParams } from 'domain/usecases/fetchChannelData';
import { FREQ_NOTE, FREQUENCY_BAND } from 'domain/types/frequency';
import { FETCH_FAILED } from 'core/commons/messages';
import { getDateTimeWithTimeOffset } from 'utils/dateHelper';
import { handleGetChannelData, SWR_URLS } from 'utils/swrWrapperApi';
import { useMaps } from 'MapsContext';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import LoadingScreen from 'components/Common/LoadingScreen';
import { getLabelFromCalcmethod } from 'utils/format';
import { RECOMMEND_LEVEL_COLOR } from 'domain/types/color';
import { PLAYING_SPEED } from 'domain/types/common/consts';

/**
 * @param {string} selectedMapId 選択状態のマップ
 * @param {(mapId:string)=>void} handleMapSelect マップをセットする関数
 */
interface Props extends React.ComponentPropsWithoutRef<'div'> {
  selectedMapId: string;
  handleMapSelect: (mapId: string) => void;
}

export const ChannelTab = ({ selectedMapId, handleMapSelect }: Props) => {
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { selectedSpan } = useContext(SelectedSpanContext);
  const { dateTime } = useContext(DateTimeContext);
  const { setting } = useContext(SettingContext);
  const { isSync } = useContext(IsSyncContext);
  const { playingSpeedIndex } = useContext(PlayingSpeedIndexContext);
  const { allMapIds, selectedPinIds, isDisplayedChannel } = useContext(LayoutContext);
  const { mapsData } = useMaps();
  const selectedMap = useMemo(() => getSelectedMap(mapsData, selectedMapId), [mapsData, selectedMapId]);

  const { t } = useTranslation();

  // NOTE:詳細表示状態のステート
  const [isDetail, setIsDetail] = useState<boolean>(false);

  // NOTE:レベル閾値タブの値を保持するステート
  const [currentLevelThreshold, setCurrentLevelThreshold] = useState<LevelThresholdType | undefined>(
    getLevelThresholdFromFrequencyBand(setting, selectedFrequencyBand)
  );

  // NOTE:セルに値を表示する設定を保持するステート
  const [isDisplayValue, setIsDisplayValue] = useState<boolean>(false);

  // NOTE:詳細表示ボタンクリックイベント
  const handleDetailButtonClick = () => {
    setIsDetail(!isDetail);
  };

  useEffect(() => {
    setCurrentLevelThreshold(getLevelThresholdFromFrequencyBand(setting, selectedFrequencyBand));
  }, [setting, selectedFrequencyBand]);

  // パラメーター生成関数
  const generateParams = (timeOffset: number) =>
    new FetchChannelDataParams(
      getDateTimeWithTimeOffset(
        dateTime,
        Number(selectedSpan) * PLAYING_SPEED[playingSpeedIndex.current ?? 0],
        timeOffset,
        isSync
      ),
      selectedSpan,
      selectedPinIds.length == 0 ? [''] : selectedPinIds.map((pinId) => pinId),
      selectedFrequencyBand,
      selectedMap.minHeight,
      selectedMap.maxHeight,
      CHANNEL_DETAIL_ROW_COUNT,
      selectedFrequencyBand,
      setting,
      isDisplayedChannel
    );
  // データ取得用SWフック（初回）
  const params = generateParams(0);
  // console.log(params);
  const key = JSON.stringify(params);
  const { data, error } = useSWR([SWR_URLS.CHANNEL, key], () => handleGetChannelData(params));

  // 次データ取得用SWR フック（3つ先のまで取得する）
  const nextParams1 = generateParams(1);
  // console.log(nextParams1);
  const nextKey1 = JSON.stringify(nextParams1);
  useSWR([SWR_URLS.CHANNEL, nextKey1], () => handleGetChannelData(nextParams1));

  const nextParams2 = generateParams(2);
  const nextKey2 = JSON.stringify(nextParams2);
  useSWR([SWR_URLS.CHANNEL, nextKey2], () => handleGetChannelData(nextParams2));

  const nextParams3 = generateParams(3);
  const nextKey3 = JSON.stringify(nextParams3);
  useSWR([SWR_URLS.CHANNEL, nextKey3], () => handleGetChannelData(nextParams3));

  // エラーハンドリング
  if (error) {
    console.log(error);
    alert(t(FETCH_FAILED));
    return <div>Failed to load...</div>;
  }
  if (!data) return <LoadingScreen />;

  return (
    <Stack alignItems={'center'} spacing={2} sx={{ height: 1, mt: 1 }}>
      <Stack direction={'row'} spacing={5} sx={{ alignItems: 'center', height: '8%' }}>
        <Box sx={{ width: '250px' }}>
          <CustomMapSelect
            id='recommendedMap'
            title={t('マップ')}
            value={selectedMapId}
            options={allMapIds}
            handleSelect={handleMapSelect}
            mapsData={mapsData}
          />
        </Box>
        <CustomIconButton
          Icon={AssessmentOutlinedIcon}
          tooltipTitle={t('各時間の詳細を表示')}
          size='small'
          onClick={handleDetailButtonClick}
          isEnabled={isDetail}
        />
      </Stack>

      <Box>
        <Stack direction={'row'} spacing={3}>
          <Box>{t('使用推奨')}</Box>
          <Box>{<SquareIcon sx={{ color: RECOMMEND_LEVEL_COLOR.LOW }} />}〇</Box>
          <Box>{<SquareIcon sx={{ color: RECOMMEND_LEVEL_COLOR.MID }} />}△</Box>
          <Box>{<SquareIcon sx={{ color: RECOMMEND_LEVEL_COLOR.HIGH }} />}×</Box>
        </Stack>
      </Box>

      {isDetail ? (
        <DetailTable
          channelData={data}
          selectedSpan={selectedSpan}
          dateTime={dateTime}
          isDisplayValue={isDisplayValue}
        />
      ) : (
        <ChannelTable channelData={data} isDisplayValue={isDisplayValue} setIsDisplayValue={setIsDisplayValue} />
      )}

      <Box margin={3}>
        {selectedFrequencyBand == FREQUENCY_BAND.FREQUENCY_WLAN5G.ID ? (
          <Box>{t(`${FREQ_NOTE['1'].text}${FREQ_NOTE['2'].text}${FREQ_NOTE['3'].text}`)}</Box>
        ) : (
          ''
        )}
        <Stack direction='row' spacing={3}>
          <Box>{t('占有率：上限閾値を超える割合')}</Box>
          <Box>
            〇：{currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('未満')}
          </Box>
          <Box>
            △：{currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('以上')}
          </Box>
          <Box>
            ×：{currentLevelThreshold ? currentLevelThreshold.topOccupancyLevel : ''}%{t('以上')}
          </Box>
        </Stack>
        <Stack direction='row' spacing={3}>
          <Box>{t(`ノイズ：電波強度（RSSIの${getLabelFromCalcmethod(setting.noiseCalcuMethod)}）`)}</Box>
          <Box>
            〇：
            {currentLevelThreshold
              ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
              : ''}
            dBm{t('未満')}
          </Box>
          <Box>
            △：
            {currentLevelThreshold
              ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
              : ''}
            dBm{t('以上')}
          </Box>
          <Box>
            ×：
            {currentLevelThreshold
              ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).topNoiseLevel
              : ''}
            dBm
            {t('以上')}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HeatMap from '../Map/HeatMap';
import {
  DEFAULT_MAP_OPTIONS,
  HeatPointType,
  MAIN_MAP_ID,
  MapObjType,
  MapPinType,
  PIN_TYPE,
  PointType,
  SizeType,
} from 'domain/types/map';
import { GoogleMap } from '@react-google-maps/api';
import { RwmMapAreaContext } from '../RwmMapContext';
import OpenSearchMapWindowButton from '../Button/OpenSearchMapWindowButton';
import OpenEditImageWindowButton from '../Button/OpenEditImageWindowButton';
import OpenEditMapWindowButton from '../Button/OpenEditMapWindowButton';
import HeightRangeButton from '../Button/HeightRangeButton';
import PositionRangeButton from '../Button/PositionRangeButton';
import Pin from '../Pin/Pin';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { getHeatMapData, getLevelThresholdFromFrequencyBand, getNoiseLevelFromNoiseDataSource } from 'utils/extract';
import InitialImage from '../Map/InitialImage';
import { convertHeatMapPosition, convertHeatMapSize } from 'utils/transform';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import { useMaps } from 'MapsContext';
import { useRwmCache } from 'RwmContext';
import SetUpDialogWindow from 'components/SetUpDialogWindow/SetUpDialogWindow';
import { SelectedDataSourceContext, SettingContext, SelectedFrequencyBandContext } from 'radioMonitoringPage';
import { DATA_SOURCE } from 'domain/types/common/consts';
import { LevelThresholdType } from 'domain/types/setting';
import { getLabelFromCalcmethod } from 'utils/format';
import CustomSlider from '../../../../Common/SliderWithInputField';

interface Props {
  selectedMap: MapObjType;
}

const MapWindow = ({ selectedMap }: Props) => {
  const {
    mapWidth,
    mapHeight,
    center,
    zoom,
    mapTypeId,
    bounds,
    setNe,
    setSw,
    elevation,
    isOpenedHeightRange,
    maxHeightConf,
    minHeightConf,
    setIsOpenedHeightRange,
    setMaxHeightConf,
    setMinHeightConf,
    isOpenedPositionRange,
    verticalConf,
    horizontalConf,
    setIsOpenedPositionRange,
    setVerticalConf,
    setHorizontalConf,
    clipboardPin,
    setClipboardPin,
    setIsOpenedSearchWindow,
  } = useContext(RwmMapAreaContext);
  const { t } = useTranslation();
  const { showBoundary } = useErrorBoundary();
  const { setCaSelectedPinIds } = useRwmCache();
  const {
    selectedPinIds,
    selectedMapId,
    allPinIds,
    registeredPinIds,
    setAllPinIds,
    setSelectedPinIds,
    pinCheckBoxStates,
    setPinCheckBoxStates,
    setRegisteredPinIds,
    isEnableGps,
    setHideMapPinIds,
  } = useContext(LayoutContext);
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { setting } = useContext(SettingContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);

  const [position, setPosition] = useState<PointType | undefined>(undefined);
  const [size, setSize] = useState<SizeType>({ width: 0, height: 0 });
  const [points, setPoints] = useState<HeatPointType[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [heatPoints, setHeatPoints] = useState<HeatPointType[]>([]);

  const { addPinToMap } = useMaps();

  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);
  const [selectedPinType, setSelectedPinType] = useState<number>(PIN_TYPE.UnitPin);
  const [backgroundAnchorEl, setBackgroundAnchorEl] = useState<null | HTMLElement>(null);
  const subMenuImageButtonRef = useRef<HTMLButtonElement>(null);
  const [currentLevelThreshold, setCurrentLevelThreshold] = useState<LevelThresholdType | undefined>(
    getLevelThresholdFromFrequencyBand(setting, selectedFrequencyBand)
  );
  const [alpha, setAlpha] = useState<number>(70);

  const handleSetHeatPoints = useCallback(
    (heatPoint: HeatPointType | undefined, pinId: string) => {
      if (selectedMapId === MAIN_MAP_ID) return;

      if (heatPoint === undefined) {
        setHeatPoints((prev) =>
          prev.filter((point) => point.id !== pinId).filter((point) => selectedPinIds.includes(point.id))
        );
        return;
      }

      setHeatPoints((prev) => {
        let newHeatPoints = [];
        const index = prev.findIndex((prevPoint) => prevPoint.id === pinId);

        if (index === -1) {
          newHeatPoints = [...prev, heatPoint];
        } else {
          newHeatPoints = [...prev];
          newHeatPoints[index] = heatPoint;
        }

        return newHeatPoints.filter((heatPoint) => selectedPinIds.includes(heatPoint.id));
      });
    },
    [selectedMapId, selectedPinIds]
  );

  /**
   * @description ヒートマップ用のデータを作成
   */
  useEffect(() => {
    if (selectedMapId === MAIN_MAP_ID) return;

    if (heatPoints && heatPoints.length > 0) {
      const heatMapData = getHeatMapData(heatPoints);
      setPosition(convertHeatMapPosition(heatMapData.x.min, heatMapData.y.min, setting.heatMapEdgeSize));
      setSize(
        convertHeatMapSize(
          heatMapData.x.max,
          heatMapData.x.min,
          heatMapData.y.max,
          heatMapData.y.min,
          setting.heatMapEdgeSize
        )
      );
      setPoints(heatMapData.points);
    }

    return () => {
      setPosition(undefined);
      setSize({ width: 0, height: 0 });
      setPoints([]);
    };
  }, [heatPoints, selectedMapId, setting.heatMapEdgeSize]);

  useEffect(() => {
    setHeatPoints([]);
  }, [selectedMapId]);

  useEffect(() => {
    if (selectedPinIds.length < 1) {
      setPosition(undefined);
      setSize({ width: 0, height: 0 });
      setPoints([]);
      setHeatPoints([]);
    }
  }, [selectedPinIds]);

  /**
   * @description マップインスタンスが読み込まれた時の処理
   */
  const onLoad = useCallback(async (mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  /**
   * @description マップインスタンスがアンマウント (レンダリング対象から外れた) 時の処理
   */
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  /**
   * @description マップインスタンスに値を設定
   */
  useEffect(() => {
    if (map && center) {
      map.setCenter(center);
      map.setZoom(zoom);
      map.setMapTypeId(mapTypeId);
    }
  }, [map, center, mapTypeId, zoom, setNe, setSw, selectedMap.mapId]);

  /**
   * @description 1ピクセル当たりの緯度経度と境界点 (北東、南西) を更新
   */
  useEffect(() => {
    if (map) {
      const overlayView = new google.maps.OverlayView();
      overlayView.onAdd = function () {
        const _bounds = map.getBounds();
        if (_bounds) {
          // NOTE : 表示されている地図の右上 (北東) の座標
          const _ne = _bounds.getNorthEast();
          // NOTE : 表示されている地図の左下 (南西) の座標
          const _sw = _bounds.getSouthWest();
          setNe(_ne);
          setSw(_sw);
        }
      };
      overlayView.draw = function () {};
      overlayView.setMap(map);
    }
  }, [map, setNe, setSw, selectedMap, mapWidth]);

  useEffect(() => {
    setCurrentLevelThreshold(getLevelThresholdFromFrequencyBand(setting, selectedFrequencyBand));
  }, [setting, selectedFrequencyBand]);

  // NOTE: 貼り付け処理
  const handlePastePin = async () => {
    if (!clipboardPin) return;
    console.log('clipboardPin', clipboardPin);
    try {
      const newPin = { ...clipboardPin.mapPin };
      if (clipboardPin.rightClickAction == 'cut') {
        // NOTE: ピンの非表示リストに追加する
        setHideMapPinIds((prevState) => {
          const currentArray = prevState[clipboardPin.mapPin.mapId] || [];
          // 重複チェック
          if (!currentArray.includes(clipboardPin.mapPin.pinId)) {
            return {
              ...prevState,
              [clipboardPin.mapPin.mapId]: [...currentArray, clipboardPin.mapPin.pinId],
            };
          }
          // 重複があれば状態はそのまま
          return prevState;
        });
      }
      await addPin(newPin);
    } catch (error) {
      showBoundary(error);
    } finally {
      setClipboardPin(null);
      setMenuPosition(null);
    }
  };

  // NOTE: ピン追加処理
  const addPin = async (newPin: MapPinType): Promise<boolean> => {
    const newPinIndex = allPinIds.indexOf(newPin.pinId);

    if (newPinIndex != -1 && !registeredPinIds.includes(newPin.pinId)) {
      // NOTE: 未登録の場合は登録状態にする

      // NOTE: デバイスピンの追加
      await addPinToMap(selectedMap.mapId, newPin, true);
      setAllPinIds([...allPinIds]);
      setSelectedPinIds([...selectedPinIds, newPin.pinId]);
      pinCheckBoxStates[newPinIndex] = true;
      setPinCheckBoxStates([...pinCheckBoxStates]);
      setRegisteredPinIds([...registeredPinIds, newPin.pinId]);
      setCaSelectedPinIds([...selectedPinIds, newPin.pinId]);
    } else {
      // NOTE: 既に ID が存在するか判定
      if (newPinIndex != -1) {
        alert(t('既に ID が存在しています。別の ID を入力してください。'));
        return false;
      }

      // NOTE: デバイスピンの追加
      await addPinToMap(selectedMap.mapId, newPin, true);
      setAllPinIds([...allPinIds, newPin.pinId]);
      setSelectedPinIds([...selectedPinIds, newPin.pinId]);
      setPinCheckBoxStates([...pinCheckBoxStates, true]);
      setRegisteredPinIds([...registeredPinIds, newPin.pinId]);
      setCaSelectedPinIds([...selectedPinIds, newPin.pinId]);
    }
    return true;
  };

  /**
   * @description 画面上での右クリック
   */
  const handleRightClickImage = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
    });
  };

  /**
   * @description ダイアログを閉じる処理
   */
  const handleClickClosedDialog = () => {
    setIsOpenedDialog(false);
  };

  /**
   * @description ピン / グループ追加時の処理
   */
  const handleClickMapPinAdd = (pinType: number) => {
    setSelectedPinType(pinType);
    setIsOpenedDialog(true);
    setMenuPosition(null);
  };

  /**
   * @description 背景変更クリック
   */
  const handleBackgroundClick = (event: React.MouseEvent<HTMLElement>) => {
    setBackgroundAnchorEl(event.currentTarget);
  };

  const handleLeaveSubMenu = () => {
    setBackgroundAnchorEl(null);
  };

  /**
   * @description 右クリックメニューイメージ読み込み
   */
  const handleImageWindow = () => {
    if (subMenuImageButtonRef.current) {
      subMenuImageButtonRef.current.click();
    }
    setBackgroundAnchorEl(null);
    setMenuPosition(null);
  };

  /**
   * @description 右クリックメニュー地図から選択
   */
  const handleMapWindow = () => {
    setIsOpenedSearchWindow(true);
    setBackgroundAnchorEl(null);
    setMenuPosition(null);
  };

  if (selectedMap.mapType === 'none')
    return (
      <Box sx={{ width: mapWidth, height: mapHeight }}>
        <InitialImage width={mapWidth} height={mapHeight} />
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={1}
          m={1}
          sx={{ zIndex: 10, width: 'fit-content', height: 'fit-content', position: 'absolute', top: 0, left: 0 }}
        >
          <OpenSearchMapWindowButton />
          <OpenEditImageWindowButton />
        </Stack>
      </Box>
    );

  return (
    <GoogleMap
      mapContainerStyle={{
        width: mapWidth,
        height: mapHeight,
      }}
      center={center}
      zoom={zoom}
      mapTypeId={mapTypeId}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ ...DEFAULT_MAP_OPTIONS }}
    >
      <Box
        sx={{
          width: mapWidth,
          height: mapHeight,
          position: 'absolute',
        }}
        onContextMenu={handleRightClickImage}
      />
      {selectedMap.mapId !== MAIN_MAP_ID && position !== undefined && selectedPinIds.length > 0 && (
        <HeatMap position={position} size={size} knownPoints={points} alpha={alpha * 0.01} />
      )}
      {selectedPinIds.map((id, index) => {
        return (
          <Pin
            key={`googleMapMarker-${id}`}
            pinId={id}
            bounds={bounds}
            index={index}
            handleSetHeatPoints={handleSetHeatPoints}
            selectedMap={selectedMap}
          />
        );
      })}
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={1}
        m={1}
        sx={{ zIndex: 10, width: 'fit-content', height: 'fit-content', position: 'absolute', top: 0, left: 0 }}
      >
        <OpenEditMapWindowButton />
        <OpenSearchMapWindowButton />
        <OpenEditImageWindowButton ref={subMenuImageButtonRef} />
      </Stack>
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-end'
        spacing={1}
        m={1}
        sx={{ zIndex: 10, width: 'fit-content', height: 'fit-content', position: 'absolute', top: 0, right: 0 }}
      >
        <HeightRangeButton
          elevation={elevation}
          isOpenedHeightRange={isOpenedHeightRange}
          maxHeightConf={maxHeightConf}
          minHeightConf={minHeightConf}
          setIsOpenedHeightRange={setIsOpenedHeightRange}
          setMaxHeightConf={setMaxHeightConf}
          setMinHeightConf={setMinHeightConf}
        />
        <PositionRangeButton
          isOpenedPositionRange={isOpenedPositionRange}
          verticalConf={verticalConf}
          horizontalConf={horizontalConf}
          setIsOpenedPositionRange={setIsOpenedPositionRange}
          setVerticalConf={setVerticalConf}
          setHorizontalConf={setHorizontalConf}
        />
      </Stack>
      <Menu
        open={Boolean(menuPosition)}
        onClose={() => setMenuPosition(null)}
        anchorReference='anchorPosition'
        anchorPosition={menuPosition !== null ? { top: menuPosition.top, left: menuPosition.left } : undefined}
      >
        <MenuItem onClick={() => handleClickMapPinAdd(PIN_TYPE.UnitPin)}>{t(`ピンを追加`)}</MenuItem>
        {selectedMapId === MAIN_MAP_ID && (
          <MenuItem onClick={() => handleClickMapPinAdd(PIN_TYPE.GroupPin)}>{t(`グループを追加`)}</MenuItem>
        )}
        <MenuItem onClick={handlePastePin} disabled={!clipboardPin}>
          {t(`貼り付け`)}
        </MenuItem>
        <MenuItem
          onMouseEnter={handleBackgroundClick}
          aria-controls={backgroundAnchorEl ? 'background-menu' : undefined}
          aria-haspopup='true'
        >
          <ListItemText primary={t(`背景変更`)} />
          <ListItemIcon>
            <ArrowRightIcon fontSize='medium' />
          </ListItemIcon>
        </MenuItem>
        <Menu
          id='background-menu'
          anchorEl={backgroundAnchorEl}
          open={Boolean(backgroundAnchorEl)}
          onClose={() => setBackgroundAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          MenuListProps={{
            onMouseLeave: handleLeaveSubMenu,
          }}
        >
          <MenuItem onClick={handleMapWindow}>
            <ListItemText primary={t('地図から選択')} />
          </MenuItem>
          <MenuItem onClick={handleImageWindow}>
            <ListItemText primary={t('ファイルから読み込み')} />
          </MenuItem>
        </Menu>
      </Menu>
      {isOpenedDialog && (
        <SetUpDialogWindow
          isOpenedDialog={isOpenedDialog}
          onClosedDialog={handleClickClosedDialog}
          selectedPinId={undefined}
          selectedPinType={selectedPinType}
          pinActionType={'create'}
          isEnableGps={isEnableGps}
        />
      )}

      <Grid
        container
        spacing={2}
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={6}>
          <Box
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 0,
              backgroundColor: 'rgba(100, 100, 100, 0.8)',
              color: '#FFFFFF',
              padding: '5px',
              borderRadius: '0px',
              fontSize: '11px',
            }}
          >
            {selectedDataSource == DATA_SOURCE.OCCUPANCY_RATE && (
              <Stack direction='row' spacing={1}>
                <Box>{t('占有率：上限閾値を超える割合')}</Box>
                <Box>
                  {t('青：')}
                  {currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('未満')}
                </Box>
                <Box>
                  {t('黄：')}
                  {currentLevelThreshold ? currentLevelThreshold.bottomOccupancyLevel : ''}%{t('以上')}
                </Box>
                <Box>
                  {t('赤：')}
                  {currentLevelThreshold ? currentLevelThreshold.topOccupancyLevel : ''}%{t('以上')}
                </Box>
              </Stack>
            )}
            {selectedDataSource == DATA_SOURCE.NOISE && (
              <Stack direction='row' spacing={1}>
                <Box>{t(`ノイズ：電波強度（RSSIの${getLabelFromCalcmethod(setting.noiseCalcuMethod)}）`)}</Box>
                <Box>
                  {t('青：')}
                  {currentLevelThreshold
                    ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
                    : ''}
                  dBm{t('未満')}
                </Box>
                <Box>
                  {t('黄：')}
                  {currentLevelThreshold
                    ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).bottomNoiseLevel
                    : ''}
                  dBm{t('以上')}
                </Box>
                <Box>
                  {t('赤：')}
                  {currentLevelThreshold
                    ? getNoiseLevelFromNoiseDataSource(currentLevelThreshold, setting.noiseDataSource).topNoiseLevel
                    : ''}
                  dBm
                  {t('以上')}
                </Box>
              </Stack>
            )}
          </Box>
        </Grid>
        {selectedMap.mapId != MAIN_MAP_ID && (
          <Grid item xs={4}>
            <Box
              sx={{
                position: 'absolute',
                bottom: 10,
                left: 20,
                backgroundColor: 'rgba(100, 100, 100, 0.8)',
                color: '#FFFFFF',
                padding: '5px',
                borderRadius: '0px',
                fontSize: '11px',
                width: '250px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomSlider
                max={100}
                min={0}
                originalMin={0}
                originalMax={100}
                value={alpha}
                setValue={setAlpha}
                title={t('透明度：')}
                sx={{ width: '250px' }} // スライダーの幅を指定
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </GoogleMap>
  );
};
export default React.memo(MapWindow);

import { FrequencyType } from 'domain/types/frequency';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';

interface RwmContext {
  // NOTE: ピン候補の ID リスト
  candidatePinIds: string[];
  setCandidatePinIds: Dispatch<SetStateAction<string[]>>;

  // NOTE: 選択中の周波数 ID リスト
  selectedFrequencies: FrequencyType[];
  setSelectedFrequencies: Dispatch<SetStateAction<FrequencyType[]>>;
}

export const RwmContext = createContext<RwmContext>({
  candidatePinIds: [],
  setCandidatePinIds: () => {},

  selectedFrequencies: [],
  setSelectedFrequencies: () => {},
});

// ----------------------------------------------
interface RwmCacheContextType {
  caSelectedPinIds: string[] | undefined;
  setCaSelectedPinIds: Dispatch<SetStateAction<string[] | undefined>>;
}

const defaultValue: RwmCacheContextType = {
  caSelectedPinIds: undefined,
  setCaSelectedPinIds: () => {},
};

// Create the context
const RwmCacheContext = createContext(defaultValue);

// Custom hook to use the MapsContext
export const useRwmCache = () => {
  return useContext(RwmCacheContext);
};

interface Props {
  children: ReactNode;
}
// Context provider component
export const RwmCacheProvider = ({ children }: Props) => {
  const [caSelectedPinIds, setCaSelectedPinIds] = useState<string[] | undefined>(undefined);
  useEffect(() => {
    setCaSelectedPinIds(undefined);
  }, []);
  return (
    <RwmCacheContext.Provider value={{ caSelectedPinIds, setCaSelectedPinIds }}>{children}</RwmCacheContext.Provider>
  );
};

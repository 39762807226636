import { DEFAULT_MAP_CONF } from './map';

// ------------------------------------------------------
// NOTE: 定数の定義

/**
 * @description デフォルトのイメージ
 * @constant
 */
export const DEFAULT_IMAGE = '/images/defaultImage.png';

export const DEFAULT_IMAGE_CONF = {
  width: 320,
  height: 240,
  rotate: 0,
  lat: DEFAULT_MAP_CONF.center.lat,
  lng: DEFAULT_MAP_CONF.center.lat,
};

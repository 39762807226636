import Split from 'react-split';
import React from 'react';

// NOTE: カスタムコンポーネント
import FrequencySelectMenu from './FrequencySelectMenu/FrequencySelectMenu';
import PinSelectMenu from './PinSelectMenu/PinSelectMenu';

interface Props {
  className?: string;
}

const CheckBoxList = ({ className }: Props) => {
  return (
    <Split
      className={`${className} mt-2`}
      sizes={[50, 50]}
      gutterSize={5}
      gutter={() => {
        const gutterElement = document.createElement('div');
        gutterElement.className = `bg-neutral-600 cursor-pointer`;
        return gutterElement;
      }}
      direction='vertical'
    >
      <FrequencySelectMenu />
      <PinSelectMenu />
    </Split>
  );
};

export default React.memo(CheckBoxList);
